import React, { Component } from 'react'
import { Dialog, withStyles } from '@material-ui/core'
import "./animation.css"

const style = () => ({
  container: {
    position: 'relative',
    height: 250,
    width: 250
  }
})
class LoadingDialog extends Component {

  render() {
    const { classes, open } = this.props
    return (
      <Dialog open={open} >
        <div className={classes.container}>
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(LoadingDialog)