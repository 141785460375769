import React, { Component } from "react"
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core"

export default class SelectInput extends Component {
    renderMenu() {
        const { options } = this.props
        return options.map((option) => {
            return (
                <MenuItem value={option.value} key={option.value}>
                    {option.label}
                </MenuItem>
            )
        })
    }
    render() {
        const { value, onChange, label, name, placeholder, multiple, disabled = false, required } = this.props
        const sValue = value ? value + "" : ""
        return (
            <>
                <FormControl fullWidth style={{ margin: "12px 0" }}>
                    <Select
                        value={sValue || []}
                        fullWidth
                        name={name}
                        onChange={onChange}
                        variant="outlined"
                        displayEmpty
                        multiple={multiple}
                        disabled={disabled}
                    >
                        {placeholder && (
                            <MenuItem value="" disabled>
                                {placeholder}
                            </MenuItem>
                        )}
                        {this.renderMenu()}
                    </Select>
                    <InputLabel shrink variant="outlined">
                        {`${label}${required ? "*" : ""}`}
                    </InputLabel>
                </FormControl>
            </>
        )
    }
}

SelectInput.propTypes = {}

SelectInput.defaultProps = {
    options: [],
}
