import React, { Component } from 'react'
import { Dialog, Paper, Typography, withStyles } from '@material-ui/core'
import GeneralTable from '../../../Shared/GeneralTable'
import GeneralForm from '../../../Shared/GeneralForm'
import FormContext from '../../../Shared/Cards/Provider'
import autobind from '../../../Utils/autobind'
import { connect } from 'react-redux'
import { getParamsAction } from '../../../Actions/ParamsActions'
import { createSettingsActivityAction, editSettingsActivityAction, getSettingsActivitiesAction } from '../../../Actions/SettingsActions'
import TableChip from '../../../Shared/TableRenders/TableChip'
import { getCriticity } from '../../../Utils/formats'
import { transformToOptions, validateForm } from '../../../Utils/functions'
import { Edit } from '@material-ui/icons'
import LoaderAnimator from '../../../Shared/LoaderAnimator'

const style = theme => ({
  container: {
    padding: 12,
  },
  card: {

  },
  cardTitle: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > h1": {
      color: 'white'
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
  },
  input: {
    maxWidth: 700,
    marginRight: 24,
    width: '100%'
  },
  title: {
    marginBottom: 24
  },
  table: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > h1": {
      color: 'white'
    },
    marginTop: 36,
    marginBottom: 24,
  },
  loaderContainer: {
    position: 'relative',
    height: 300
  }
})



class ActivitySettings extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        probability_id: 5,
        consequence_id: 5,
        residual_consequence_id: 5,
        residual_probability_id: 5
      },
      editParams: {},
      openDialog: false,
      loading: true
    }

    autobind(ActivitySettings, this)
  }

  componentDidMount() {
    const { getParams, getSettingsActivities } = this.props
    getParams()
    getSettingsActivities().then(() => this.setState({ loading: false }))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSubmit() {
    const activityInfo = [
      { required: true, label: "Nombre Actividad", name: "name" },
    ]
    const { params } = this.state
    const { createSettingsActivity } = this.props
    const body = { ...params }
    if (validateForm(activityInfo, params)) {
      createSettingsActivity(body)
      this.setState({ params: {} })
    }
  }

  handleSubmitEdit() {
    const { editParams, selected } = this.state
    const { editSettingsActivity } = this.props
    const body = { ...editParams, id: selected.id }
    editSettingsActivity(body)
    this.setState({ editParams: {}, openDialog: false })
  }

  handleEdit(row) {
    return () => {
      const newParams = {
        name: row.name,
        risks_id: row.risks[0].map(r => r.id),
        epps_id: row.epps[0].map(r => r.id),
        measures_id: row.preventive_measures[0].map(r => r.id),
        probability_id: row.probability_id,
        consequence_id: row.consequence_id,
        residual_probability_id: row.residual_probability_id,
        residual_consequence_id: row.residual_consequence_id,
      }
      this.setState({ selected: row, openDialog: true, editParams: newParams })
    }
  }

  handleClose() {
    this.setState({ openDialog: false })
  }

  handleChangeEdit(event) {
    const { target } = event
    const { editParams } = this.state
    editParams[target.name] = target.value
    this.setState({ editParams })
  }

  createFormInfo() {
    const { propParams } = this.props
    const activityInfo = [
      { required: true, label: "Nombre Actividad", name: "name" },
      { label: "Selecciona Riesgos", name: "risks_id", type: "multiselect", options: transformToOptions(propParams.all.risks) },
      { label: "Selecciona EPPs", name: "epps_id", type: "multiselect", options: transformToOptions(propParams.all.epps) },
      { label: "Selecciona Medidas Preventivas", name: "measures_id", type: "multiselect", options: transformToOptions(propParams.all.preventive_measures) },
      { label: "Selecciona una Probabilidad", name: "probability_id", type: "select", options: transformToOptions(propParams.all.probabilities) },
      { label: "Selecciona una Consecuencia", name: "consequence_id", type: "select", options: transformToOptions(propParams.all.consequences) },
      { label: "Probabilidad Residual", name: "residual_probability_id", type: "select", options: transformToOptions(propParams.all.probabilities) },
      { label: "Consecuencia Residual", name: "residual_consequence_id", type: "select", options: transformToOptions(propParams.all.consequences) },
    ]
    return activityInfo
  }

  render() {
    const { classes, settings } = this.props
    const { params, editParams, openDialog, loading } = this.state
    const allActivities = settings.activities.all || []
    const tableInfo = [
      { label: "name", name: "Nombre" },
      { label: "consequence&name", name: "Consecuencia", render: TableChip },
      { label: "probability&name", name: "Probabilidad", render: TableChip },
      {
        label: "all",
        name: "Criticidad",
        format: getCriticity,
        render: TableChip,
      },
    ]

    const actions = [
      { name: "Editar Actividad", icon: Edit, action: this.handleEdit },
    ]

    return (
      <div className={classes.container}>
        <div className={classes.container}>
          <Typography variant="h1" className={classes.title}>Configuración Actividades</Typography>
          <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
            <GeneralForm title="Crear Actividades" open submit="Crear" info={this.createFormInfo()} onSubmit={this.handleSubmit} />
          </FormContext.Provider>
          <Paper className={classes.table} square>
            <Typography variant="h1">Actividades</Typography>
          </Paper>
          {loading ?
            <div className={classes.loaderContainer}>
              <LoaderAnimator />
            </div>
            :
            <GeneralTable data={allActivities} info={tableInfo} actions={actions} />
          }
        </div>
        <Dialog open={openDialog} onClose={this.handleClose}>
          <div>
            <FormContext.Provider value={{ handleChange: this.handleChangeEdit, params: editParams }}>
              <GeneralForm title="Editar Actividad" open submit="Guardar" info={this.createFormInfo()} onSubmit={this.handleSubmitEdit} />
            </FormContext.Provider>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  propParams: state.params,
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getParams: () => dispatch(getParamsAction()),
  getSettingsActivities: () => dispatch(getSettingsActivitiesAction()),
  createSettingsActivity: (body) => dispatch(createSettingsActivityAction(body)),
  editSettingsActivity: (body) => dispatch(editSettingsActivityAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ActivitySettings))