import React, { Component } from "react"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import {
	withStyles,
	Paper,
	Tab,
	Typography,
	Divider,
	Button,
	IconButton,
} from "@material-ui/core"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import {
	getWorkerAction,
	editWorkerAction,
	getBranches,
	getWorkersAction,
} from "../../Actions/EnterpriseAction"
import { connect } from "react-redux"
import { userEditInfo, pwdForm } from "./Info"
import { mergeMultiplePdfFilesAndGet, signDocument, transformToOptions } from "../../Utils/functions"
import GeneralForm from "../../Shared/GeneralForm"
import { sha512 } from "js-sha512"
import { changePasswordAction } from "../../Actions/LoginActions"
import { getTrainingsAction } from "../../Actions/TrainingActions"
import { getTalksAction } from "../../Actions/TalkActions"
import ImageContainer from "../../Shared/ImageContainer"
import { GetApp, KeyboardArrowRight } from "@material-ui/icons"
import FormContext from "../../Shared/Cards/Provider"
import { getUserDocumentsAction } from "../../Actions/WorkerActions"
import { getSettingsUserCategoriesAction } from "../../Actions/SettingsActions"
import WorkerDocuments from "./WorkerDocuments"
import WorkerSigns from "./WorkerSigns"
import LoaderAnimator from "../../Shared/LoaderAnimator"

const style = theme => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0",
	},
	button: {
		textAlign: "end",
		margin: "12px 0",
	},
	titleText: {
		padding: 12,
		background: theme.palette.primary.main,
		color: "white",
		marginTop: 12
	},
	sign: {
		padding: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 350,
		margin: 12,
		"@media (max-width:500px)": {
			width: '100%',
			margin: '12px 0',
		},
	},
	signs: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	greentag: {
		color: "white",
		background: theme.palette.green.main,
		borderRadius: 24,
		padding: "0px 6px",
		width: "50%",
		textAlign: "center",
		marginBottom: 6,
	},
	bluetag: {
		color: "white",
		background: theme.palette.blue.main,
		borderRadius: 24,
		padding: "0px 6px",
		width: "50%",
		textAlign: "center",
		marginBottom: 6,
	},
	actionButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
})

class Worker extends Component {
	constructor() {
		super()
		this.state = {
			tab: 0,
			params: {},
			pwdparams: {},
			openpwd: false,
			loading: true,
			loadingDocument: true,
		}
		autobind(Worker, this)
	}

	componentDidMount() {
		const {
			match,
			getWorker,
			enterprise,
			getBranches,
			getWorkers,
			getTalks, getTrainings,
			getUserDocuments,
			getSettingsUserCategories
		} = this.props
		const body = {}
		body.companyId = enterprise.selected.id
		getBranches(body)
		getWorkers(body)
		const fullBody = { ...body }
		fullBody.id = match.params.id
		const documentBody = {
			user_id: match.params.id
		}
		getWorker(fullBody).then(() => { this.setState({ loading: false }) })
		getTalks()
		getTrainings()
		getUserDocuments(documentBody).then(() => this.setState({ loadingDocument: false }))
		getSettingsUserCategories()
	}

	componentDidUpdate(prevProps) {
		const { match, user } = this.props
		const level = user.account.user.userType
		const user_id = user.account.user.id
		if (level > 1 && match.params.id !== user_id) {
			window.location.href = "/"
		}
	}

	handleSend(values) {
		const { editWorker, user, match, workers } = this.props
		const worker = workers ? workers.selected : null
		const body = Object.assign({}, worker, values)
		body.companyId = user.account.user.idCompany
		body.id = match.params.id
		editWorker(body)
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleOpenPwd() {
		this.setState({ openpwd: !this.state.openpwd })
	}

	handleChangePwd(event) {
		const { target } = event
		const { pwdparams } = this.state
		pwdparams[target.name] = target.value
		this.setState({ pwdparams })
	}

	handleSendPwd() {
		const { pwdparams } = this.state
		const { workers, changePassword } = this.props
		const newpassword = sha512(pwdparams.newpassword)
		const newpasswordconfirm = sha512(pwdparams.newpasswordconfirm)
		const worker = workers.selected
		const body = {
			newpassword,
			newpasswordconfirm,
			pwt: worker.pwt,
			email: worker.email,
			companyId: worker.companyId,
			id: worker.id,
		}
		changePassword(body)
		this.setState({ pwdparams: {}, openpwd: false })
	}

	setFormInfo(info) {
		const { enterprise, workers, branch } = this.props
		const allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
		info.map((input) => {
			switch (input.name) {
				case "branch_id": {
					input.options = transformToOptions(enterprise.branches)
					return input
				}
				case "user_id": {
					input.options = transformToOptions(filteredWorkers)
					return input
				}
				default:
					return input
			}
		})
		return info
	}

	renderSings(signs, type) {
		const { classes, talks, trainings, user, workers } = this.props

		const worker = workers ? workers.selected : null

		let imgUrl = ""
		if (type === "talks") {
			imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER
		} else {
			imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER
		}

		function goto(id, own) {
			return () => {
				const { history } = own.props
				history.push(`/${type}/${id}`)
			}
		}

		const allTalks = talks.all || []
		const allTrainings = trainings.all || []

		return signs.map(sign => {

			let selectedEvent
			if (type === "talks") {
				selectedEvent = allTalks.find(talk => talk.id === sign.el_id)
			} else {
				selectedEvent = allTrainings.find(talk => talk.id === sign.el_id)
			}

			async function startSignDocument() {
				const file = selectedEvent.file

				let url1, url2, imgUrl

				if (type === "talks") {
					url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
					url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
					imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
				} else {
					url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
					url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER + "/" + file
					imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER + '/' + sign.sign
				}

				let signedDocument
				if (file.includes(".pdf")) {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1, url2], "Reporte Charla")
				} else {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1], "Reporte Charla")
				}

				const officialSign = { ...sign, name: worker?.name }

				signDocument(signedDocument, officialSign, imgUrl, `${type === "talks" ? "Charla" : "Capacitación"} ${worker?.name}`)
			}

			return (
				<Paper className={classes.sign} key={sign.id}>
					<div>
						<Typography className={sign.isOwner ? classes.greentag : classes.bluetag} variant="subtitle2">{sign.isOwner ? "Supervisor" : "Asistente"}</Typography>
						<ImageContainer src={`${imgUrl}/${sign.sign}`} width="150px" height="75px" />
					</div>
					<div className={classes.infoContainer}>
						<Typography variant="body1">Fecha: {sign.date}</Typography>
						<Typography variant="body1">Tipo: {sign.name}</Typography>
						<Typography variant="body1">Tema: {sign.category_name}</Typography>
					</div>
					<div className={classes.actionButton}>
						<IconButton color="primary" onClick={goto(sign.el_id, this)}>
							<KeyboardArrowRight />
						</IconButton>
						{!sign.isOwner &&
							<IconButton color="primary" onClick={startSignDocument}>
								<GetApp />
							</IconButton>}
					</div>
				</Paper>
			)
		})

	}

	render() {
		const { classes, workers, talks, trainings, settings, user, match } = this.props
		const { openpwd, pwdparams, tab, loading, loadingDocument } = this.state
		const worker = workers ? workers.selected : null
		const level = user.account.user.userType
		const isSameUser = user.account.user.id === match.params.id
		const allTalks = talks.all || []
		const allTrainings = trainings.all || []
		const allTalksSigns = allTalks
			.reduce(
				(x, y) => x
					.concat(y.participants_signs.map(s => ({ ...s, isOwner: false, name: y.name, category_name: y.category_name, el_id: y.id })))
					.concat(y.user_sign.map(s => ({ ...s, isOwner: true, name: y.name, category_name: y.category_name, el_id: y.id }))),
				[]
			)
		const allTrainingsSigns = allTrainings
			.reduce(
				(x, y) => x
					.concat(y.participants_signs.map(s => ({ ...s, isOwner: false, name: y.name, category_name: y.category_name, el_id: y.id })))
					.concat(y.user_sign.map(s => ({ ...s, isOwner: true, name: y.name, category_name: y.category_name, el_id: y.id }))),
				[]
			)


		let myTalksSigns = []
		let myTrainingsSigns = []

		if (worker) {
			myTalksSigns = allTalksSigns.filter(sign => sign?.user_id?.toString() === worker?.id?.toString())
			myTrainingsSigns = allTrainingsSigns.filter(sign => sign?.user_id?.toString() === worker?.id?.toString())
		}

		const allCategories = settings.user_documents.all || []



		return (
			<div className={classes.container}>
				<MutableInfoCard
					title="Datos Colaborador"
					submit="Guardar Cambios"
					subtitle="Editar Colaborador"
					submitAction={this.handleSend}
					onChange={this.handleChange}
					formInfo={this.setFormInfo(userEditInfo)}
					showInfo={this.setFormInfo(userEditInfo)}
					response={worker}
					loading={loading}
					disableEdit={level > 1}

				/>
				{(level === 1 || isSameUser) &&
					<div className={classes.button}>
						<Button
							color="primary"
							variant="outlined"
							size="small"
							onClick={this.handleOpenPwd}
						>
							Cambiar Contraseña
                    </Button>
					</div>
				}
				<FormContext.Provider value={{ handleChange: this.handleChangePwd, params: pwdparams }}>
					<GeneralForm
						open={openpwd}
						info={pwdForm}
						title="Cambiar Contraseña"
						submit="Cambiar"
						onSubmit={this.handleSendPwd}
					/>
				</FormContext.Provider>
				<WorkerSigns />
				<Paper square className={classes.title}>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h1">
									Charlas
								</Typography>
							}
							value={2}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Capacitaciones
								</Typography>
							}
							value={1}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Documentos
								</Typography>
							}
							value={0}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={2}>
					<Typography variant="h1" className={classes.title}>
						Participación en Charlas
          </Typography>
					<Divider style={{ marginBottom: 24 }} />
					<div className={classes.signs}>
						{this.renderSings(myTalksSigns, 'talks')}
					</div>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						Participación en Capacitaciones
          </Typography>
					<Divider style={{ marginBottom: 24 }} />
					<div className={classes.signs}>
						{this.renderSings(myTrainingsSigns, "trainings")}
					</div>
				</TabPanel>
				<TabPanel value={tab} index={0}>
					<Typography variant="h1" className={classes.title}>
						Documentos
					</Typography>
					<Divider style={{ marginBottom: 24 }} />
					{loadingDocument ?
						<div style={{ position: 'relative', height: 200 }}>
							<LoaderAnimator />
						</div>
						:
						<WorkerDocuments categories={allCategories} documents={[]} />
					}
				</TabPanel>
			</div>
		)
	}
}

Worker.propTypes = {}

Worker.defaultProps = {
	workers: { all: [] },
}

const mapStateToProps = (state) => ({
	workers: state.workers,
	enterprise: state.enterprise,
	user: state.user,
	talks: state.talks,
	trainings: state.trainings,
	settings: state.settings,
	branch: state.branch,
})

const mapDispatchToProps = (dispatch) => ({
	getWorker: (body) => dispatch(getWorkerAction(body)),
	editWorker: (body) => dispatch(editWorkerAction(body)),
	getBranches: (body) => dispatch(getBranches(body)),
	getWorkers: (body) => dispatch(getWorkersAction(body)),
	changePassword: (body) => dispatch(changePasswordAction(body)),
	getTalks: () => dispatch(getTalksAction()),
	getTrainings: () => dispatch(getTrainingsAction()),
	getUserDocuments: (body) => dispatch(getUserDocumentsAction(body)),
	getSettingsUserCategories: () => dispatch(getSettingsUserCategoriesAction())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Worker))
