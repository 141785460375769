import stateMaker from "../Utils/stateMaker"
const EnterpriseReducer = (state = { selected: {}, branches: [], documents: [] }, action) => {
    const newState = { ...state }
    switch (action.type) {
        case "GET_ENTERPRISE":
            return stateMaker(newState, "selected", "fetch", action)
        case "UPDATE_ENTERPRISE":
            return stateMaker(newState, "selected", "fetch", action)
        case "GET_BRANCHES":
            return stateMaker(newState, "branches", "fetch", action)
        case "UPDATE_BRANCH":
            return stateMaker(newState, "branches", "update", action)
        case "CREATE_BRANCH":
            return stateMaker(newState, "branches", "create", action)
        case "GET_ENTERPRISE_DOCUMENTS":
            return stateMaker(state, "documents", "fetch", action)
        case "EDIT_ENTERPRISE_DOCUMENT": {
            const newState = { ...state }
            const newDocuments = newState.documents
            if (action.payload.data.status === "success") {
                const newDocument = action.payload.data.info
                const index = newDocuments.findIndex(document => document.id === newDocument.id)
                newDocuments[index] = newDocument
            }
            return newState
        }
        case "DELETE_ENTERPRISE_DOCUMENT":
            return stateMaker(state, "documents", "fetch", action)
        case "CREATE_ENTERPRISE_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newDocument = action.payload.data.info
                newState.documents.push(newDocument)
            }
            return newState
        }
        case "UPDATE_ENTERPRISE_LOGO": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newLogo = action.payload.data.info.logo
                newState.selected.logo = newLogo
            }
            return newState
        }
        default:
            return newState
    }
}

export default EnterpriseReducer
