import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import SubmitButton from '../../../Shared/SubmitButton'
import { CheckCircleOutline } from '@material-ui/icons'
import instance from '../../../Utils/instance'

const style = (theme) => ({
  container: {
    padding: 12,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phaseStatus: {
    height: 85,
    width: 85,
    border: '1px solid grey',
    borderRadius: 15,
    position: 'relative'
  },
  phaseContent: {
    flexGrow: 1,
    marginRight: 24
  },
  checkIcon: {
    color: theme.palette.green.main,
    height: 64,
    width: 64,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.2s linear'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 12
    }
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

class FilePhase extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      checked: false
    }
    autobind(FilePhase, this)
  }
  componentDidMount() {
    const { phase } = this.props
    if (phase.value) {
      const startingParams = {
        [phase.label]: phase.value
      }
      this.setState({ params: startingParams, checked: true })
    }
  }

  handleSimulateClick() {
    const { phase } = this.props
    document.getElementById(phase.label).click()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state

    const file = target.files[0]

    const body = new FormData()
    body.append("file", file)

    instance({
      method: 'post',
      url: 'workflows/upload-file.php',
      data: body
    }).then(response => {
      params[target.name] = response.data.info.file_name
      this.setState({ params })
    })
  }

  handleEdit() {
    this.setState({ checked: false })
  }

  handleSubmit() {
    const { onSubmit, phase, index } = this.props
    const { params } = this.state
    const body = {
      ...phase,
      value: params[phase.label]
    }
    onSubmit(body, index)
    this.setState({ checked: true })
  }

  handleOpenDoc() {
    const { params } = this.state
    const { phase } = this.props
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${params[phase.label]}`
    window.open(url)
  }

  render() {
    const { params, checked } = this.state
    const { classes, phase, disabled } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.phaseContent}>
          <Typography variant="subtitle1">{phase.label}</Typography>
          <Typography variant="subtitle1" className={classes.link} onClick={this.handleOpenDoc}>{params[phase.label]}</Typography>
          <input type="file" style={{ display: 'none' }} onChange={this.handleChange} id={phase.label} name={phase.label} />
          <Button disabled={checked || disabled} style={{ margin: '12px 0' }} color="primary" variant="contained" onClick={this.handleSimulateClick}>Seleccionar Documento</Button>
          <div className={classes.buttons}>
            {!disabled && checked && <Button variant="outlined" onClick={this.handleEdit} size="small">
              Editar
            </Button>}
            <SubmitButton onClick={this.handleSubmit} disabled={disabled || checked || !params[phase.label]}>Terminar Fase</SubmitButton>
          </div>
        </div>
        <div className={classes.phaseStatus}>
          <CheckCircleOutline className={classes.checkIcon} style={{ opacity: checked ? 1 : 0 }} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(FilePhase)