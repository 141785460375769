import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import { editStepAction, getSettingsWorkflowsAction, getWorkflowsAction } from '../../Actions/WorkFlows'
import { connect } from 'react-redux'
import TextPhase from './Phases/TextPhase'
import autobind from '../../Utils/autobind'
import SelectPhase from './Phases/SelectPhase'
import { getWorkersAction } from '../../Actions/EnterpriseAction'
import MultiSelectPhase from './Phases/MultiSelectPhase'
import CheckPhase from './Phases/CheckPhase'
import SubmitButton from '../../Shared/SubmitButton'
import { callSnackbar } from '../../Utils/snackbar'
import FilePhase from './Phases/FilePhase'
import StepsStepper from './StepsStepper'
import moment from 'moment'


const style = () => ({
  stageContainer: {
    margin: 24
  },
  buttonsContainer: {
    margin: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  stage: {
    padding: 24,
    '& > h5': {
      marginBottom: 6
    }
  }
})

function getPhaseType(phase) {
  switch (phase.type) {
    case "text": return TextPhase
    case "select": return SelectPhase
    case "multiselect": return MultiSelectPhase
    case "number": return TextPhase
    case "check": return CheckPhase
    case "file": return FilePhase
    default: return TextPhase
  }
}

class Workflow extends Component {
  constructor() {
    super()
    this.state = {
      currentStep: 0,
      params: [],
      workflow: null,
      completed: false
    }
    autobind(Workflow, this)
  }

  componentDidMount() {
    const { getSettingsWorkflows, getWorkers, getWorkflows, match } = this.props
    getSettingsWorkflows()
    getWorkflows().then((response) => {
      if (response.payload.data.status !== "success") return false
      const { currentStep } = this.state
      const selected = match.params.id
      const allWorkflows = response.payload.data.info || []
      const selectedWorkflow = allWorkflows.find(wf => wf.id === selected)
      const steps = selectedWorkflow.workflow_steps || []
      for (const [index, step] of steps.entries()) {
        if (!step.data.finished) {
          this.setState({ currentStep: index })
          break
        }
      }

      const completed = steps.map(step => !!step.data.finished).every(finished => finished)
      console.log(completed)
      const phases = steps[currentStep].data.phases || []
      this.setState({ workflow: selectedWorkflow, params: [...phases], completed })
    })
    getWorkers()
  }

  checkReady(step) {
    if (!step) return false
    const { params } = this.state
    let ready = true
    params.forEach(phase => {
      ready = phase.value !== undefined
    })
    return ready
  }

  handleFinishPhase(values, index) {
    const { params, workflow, currentStep } = this.state
    const { workflows, editStep } = this.props
    const allWorkflows = workflows.all || []
    const newParams = [...params]
    const selectedWorkflow = allWorkflows.find(wf => wf.id === workflow.id)
    const steps = selectedWorkflow.workflow_steps || []
    const step = steps[currentStep]
    newParams[index] = values
    const data = {
      phases: [...newParams],
      name: step.data.name,
    }
    this.setState({ params: newParams })
    const body = {
      id: step.id,
      step_data: data,
      workflow_id: workflow.id
    }
    editStep(body)

  }

  handleNextStep() {
    const { currentStep, workflow, completed } = this.state
    const { editStep, history } = this.props

    if (completed) return this.setState({ currentStep: currentStep + 1 })

    const steps = workflow?.workflow_steps || []
    const step = steps[currentStep]
    const oldData = { ...step.data }
    const newData = {
      ...oldData,
      finished: true,
      finished_on: moment(new Date()).format("YYYY-MM-DD")
    }
    const body = {
      id: step.id,
      step_data: newData,
      workflow_id: workflow.id
    }
    editStep(body).then(response => {
      console.log(response)
      if (response.payload.data.status !== 'success') return false

      const workflow = response.payload.data.info
      const steps = workflow?.workflow_steps || []

      if (currentStep === steps.length - 1) {
        callSnackbar("Proceso terminado con exito!", "success")
        return history.push("/workflows")
      }

      const phases = steps[currentStep + 1].data.phases || []
      console.log(phases)
      this.setState({ currentStep: currentStep + 1, params: [...phases], workflow })
    })

  }

  handlePrevStep() {
    const { currentStep, workflow, completed } = this.state
    const { editStep } = this.props

    if (completed) return this.setState({ currentStep: currentStep - 1 })

    const steps = workflow?.workflow_steps || []
    const step = steps[currentStep - 1]
    const oldData = { ...step.data }
    const newData = {
      ...oldData,
      finished: false
    }
    const body = {
      id: step.id,
      step_data: newData,
      workflow_id: workflow.id
    }
    editStep(body)

    const phases = steps[currentStep - 1].data.phases || []
    this.setState({ currentStep: currentStep - 1, params: [...phases] })
  }

  renderPhases(step) {
    if (!step) return null
    const { classes, user } = this.props
    const { currentStep, completed } = this.state
    const phases = step.data.phases || []
    const responsibles = step?.data?.responsible_ids || []
    const disabled = (responsibles.length > 0 && !responsibles.includes(user.account.user.id)) || completed

    return phases.map((phase, index) => {
      const Phase = getPhaseType(phase)
      return (
        <Paper className={classes.phase} key={`${currentStep}-${index}`}>
          <Phase disabled={disabled} index={index} phase={phase} onSubmit={this.handleFinishPhase} />
        </Paper>
      )
    })
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => {
      return (
        <div>
          <Typography variant="subtitle1">* {allWorkers.find(w => w.id === id)?.name || null}</Typography>
        </div>
      )
    })
  }

  renderStep() {
    const { classes } = this.props
    const { currentStep, workflow } = this.state
    const steps = workflow?.workflow_steps || []
    const current = steps[currentStep]
    return (
      <div>
        <Paper className={classes.stage}>
          <Typography variant="h5">{current?.data?.name || "Sin nombre"}</Typography>
          <Typography style={{ marginTop: 12, fontWeight: 600 }} variant="subtitle1">Responsables</Typography>
          {this.getUsersNames(current?.data?.responsible_ids || [])}
          <Typography style={{ marginTop: 12, fontWeight: 600 }} variant="subtitle1">Autorizan</Typography>
          {this.getUsersNames(current?.data?.auth_ids || [])}
        </Paper>
        {this.renderPhases(current)}
      </div>
    )
  }

  render() {
    const { classes, user } = this.props
    const { currentStep, workflow, completed } = this.state
    const steps = workflow?.workflow_steps || []
    const current = steps[currentStep]
    const authorize = current?.data?.auth_ids || []
    const disabled = authorize.length > 0 && !authorize.includes(user.account.user.id)
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h6">{workflow?.name}</Typography>
        </div>
        <StepsStepper steps={steps} current={currentStep} />
        <div className={classes.stageContainer}>
          {this.renderStep()}
        </div>
        <div className={classes.buttonsContainer}>
          {currentStep > 0 ?
            <Button disabled={disabled} size="large" variant="outlined" onClick={this.handlePrevStep}>
              Etapa Anterior
          </Button> :
            <div />}
          {(currentStep < steps.length - 1 || !completed) && <SubmitButton size="large" disabled={disabled || !this.checkReady(current)} onClick={this.handleNextStep}>
            {currentStep === steps.length - 1 ? "Finalizar Proceso" : "Siguiente Etapa"}
          </SubmitButton>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workflows: state.workflows,
  settings: state.settings,
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  getWorkflows: () => dispatch(getWorkflowsAction()),
  getWorkers: () => dispatch(getWorkersAction()),
  editStep: body => dispatch(editStepAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Workflow))