import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from 'chart.js'
import moment from 'moment'

const style = () => ({

})
class CriticalityDistribution extends Component {

  processData() {
    const { findings, startDate, endDate } = this.props
    const filtered = findings.filter(finding => finding.status !== "Desestimado")
    const inDate = filtered.filter(finding => moment(finding.date_detected) >= moment(startDate) && moment(finding.date_detected) <= moment(endDate))
    const no = inDate.filter(finding => finding.criticality === 1).length
    const low = inDate.filter(finding => finding.criticality === 2).length
    const med = inDate.filter(finding => finding.criticality === 3).length
    const high = inDate.filter(finding => finding.criticality === 4).length

    const data = [no, low, med, high]

    return data
  }

  componentDidMount() {
    const data = this.processData()
    const { idName } = this.props
    const chartElement = document.getElementById(idName)
    const myChartRef = chartElement.getContext('2d')
    chartElement.parentNode.style.height = '100%'
    chartElement.parentNode.style.width = '100%'

    this.chart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: ["No Crítico", "Poco Crítico", "Medianamente Crítico", "Altamente Crítico"],
        datasets: [
          {
            data: data,
            backgroundColor: ["#f2f5ff", "#f6ec79", "#ffab6e", "#ff6c87"],
            borderColor: "white",
            pointRadius: 0,
          },
        ]
      },
    })
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      const data = this.processData()
      this.chart.data.datasets = [{
        data: data,
        backgroundColor: ["#f2f5ff", "#f6ec79", "#ffab6e", "#ff6c87"],
        borderColor: "white",
        pointRadius: 0,
      }]
      this.chart.update()
    }
  }

  render() {
    const { classes, idName } = this.props
    return (
      <div className={classes.container}>
        <div>
          <canvas id={idName} ref={this.chartRef} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(CriticalityDistribution)