import React, { Component } from "react"
import { TextField } from "@material-ui/core"

export default class TextInput extends Component {
    render() {
        const { value, onChange, label, name, type, disabled, options, required } = this.props
        return (
            <TextField
                value={value || ""}
                disabled={disabled}
                onChange={onChange}
                label={`${label}${required ? "*" : ""}`}
                name={name}
                variant="outlined"
                fullWidth
                style={{ margin: "12px 0" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type={type}
                rows={options.rows}
                multiline={options.multiline || false}
            />
        )
    }
}

TextInput.propTypes = {

}


TextInput.defaultProps = {
    options: {}
}