import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import CountCard from './CountCard'
import { getFindingsAction } from '../../Actions/FindingActions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import CriticalityVsStatus from '../Reports/Findings/CriticalityVsStatus'
import LineHomeChart from '../Reports/Findings/LineHomeChart'

const style = () => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    "& > *": {
      flexBasis: 'calc(50% - 6px)',
      minWidth: 480,
      margin: '12px 0',
      "@media (max-width:500px)": {
        minWidth: '100%'
      }
    },
  },
  loader: {
    position: 'relative',
    height: 300
  },
  chartTitle: {
    padding: 24
  }
})

class FindingManagement extends Component {
  constructor() {
    super()
    this.state = {
      loading: true
    }
    autobind(FindingManagement, this)
  }

  componentDidMount() {
    const { getFindings } = this.props
    getFindings().then(() => this.setState({ loading: false }))
  }

  handleClick(option) {
    return () => {
      const { history } = this.props
      history.push(`/panel/findings/${option}`)
    }
  }

  render() {
    const { classes, findings } = this.props
    const { loading } = this.state

    const allFindings = findings.all || []
    const pendings = allFindings.filter(finding => finding.status === "Pendiente")
    const overdue = allFindings.filter(finding => finding.status === "Vencido")
    const processing = allFindings.filter(finding => finding.status === "Procesando" || finding.status === "Vencido")
    const done = allFindings.filter(finding => finding.status === "Terminado")

    const startOfLastMonth = moment(new Date()).subtract(1, "month").startOf("month")
    const endOfLastMonth = moment(new Date()).subtract(1, "month").endOf("month")

    const lastMonthDone = done.filter(finding => moment(finding.date_detected).isBetween(startOfLastMonth, endOfLastMonth))
    const lastMonthOverdue = overdue.filter(finding => moment(finding.date_detected).isBetween(startOfLastMonth, endOfLastMonth))
    const lastMonthCompliance = lastMonthDone.length + lastMonthOverdue.length > 0 ? lastMonthDone.length / (lastMonthDone.length + lastMonthOverdue.length) * 100 : 100

    const startDate = moment(new Date()).startOf("month").format("YYYY-MM-DD")
    const endDate = moment(new Date()).endOf("month").format("YYYY-MM-DD")

    const allUnfinished = pendings.concat(overdue)
    const lastMonthUnfinished = allUnfinished.filter(finding => moment(finding.date_detected) <= moment(new Date()))




    return (
      <div className={classes.container}>
        {loading ?
          <div className={classes.loader}>
            <LoaderAnimator />
          </div>
          :
          <div className={classes.cardContainer}>
            {/* <CountCard
              color="darkgold"
              title="Hallazgos sin asignar"
              amount={pendings.length}
              onClick={this.handleClick("pending")}
            />
            <CountCard
              color="#91deb1"
              title="Hallazgos en proceso"
              amount={processing.length}
              onClick={this.handleClick("assigned")}
            />
            <CountCard
              color="#ff6c87"
              title="Hallazgos vencidos"
              amount={overdue.length}
              onClick={this.handleClick("overdue")}
            />
            <CountCard
              color="#91deb1"
              title="Hallazgos por aprobar"
              amount={done.length}
              onClick={this.handleClick("finished")}
            />
            <CountCard
              color="#91deb1"
              title="Porcentaje cumplimiento mes anterior"
              amount={Math.round(lastMonthCompliance, 10)}
            /> */}
            <Paper>
              <Typography className={classes.chartTitle} variant="h1">DISTRIBUCIÓN DE HALLAZGOS</Typography>
              <CriticalityVsStatus idName={"cristvsstatus-findings"} findings={allFindings} startDate={startDate} endDate={endDate} />
            </Paper>
            <Paper>
              <Typography className={classes.chartTitle} variant="h1">HALLAZGOS ACUMULADOS ÚLTIMAS 2 SEMANAS</Typography>
              <LineHomeChart idName={"otro-findings"} findings={allFindings.filter(finding => finding.status !== "Desestimado")} startDate={startDate} endDate={endDate} />
            </Paper>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  findings: state.findings
})

const mapDispatchToProps = dispatch => ({
  getFindings: () => dispatch(getFindingsAction())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FindingManagement)))