import React, { Component } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from '@fullcalendar/interaction'
import { Dialog, Paper, Tooltip, Typography, withStyles } from "@material-ui/core"
import { connect } from "react-redux"
import SelectInput from "../../Shared/Inputs/SelectInput"
import autobind from "../../Utils/autobind"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { transformToOptions } from "../../Utils/functions"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import Conditional from "../../Shared/Conditional"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
// import { Close } from "@material-ui/icons"
import SubmitButton from "../../Shared/SubmitButton"
import { createMultipleTrainingsAction, createTrainingAction, getTrainingsAction } from "../../Actions/TrainingActions"
import { getSettingsTrainingsAction, getTrainingsCategoriesAction } from "../../Actions/SettingsActions"
import { withRouter } from "react-router"
import MultiSelectInput from "../../Shared/Inputs/MultiSelectInput"


const style = () => ({
  container: {
    padding: 12,
  },
  paper: {
    height: "80vh",
    padding: 12,
  },
  event: {
    padding: 6,
    "& > p": {
      color: "white",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  formContainer: {
    display: 'flex',
    flexWrap: "wrap",
  },
  inputContainer: {
    maxWidth: 700,
    minWidth: 300,
    marginRight: 14,
    "@media (max-width:500px)": {
      minWidth: "100%"
    },
    display: 'flex',
    alignItems: 'center'

  },
  circle: {
    paddingLeft: 24,
    position: 'relative',
    '&::before': {
      content: '""',
      height: 8,
      width: 8,
      borderRadius: '50%',
      background: 'white',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(0, -50%)'
    }

  },
  textInput: {
    margin: "0 12px"
  },
  deleteButton: {
    color: 'white',
    zIndex: 1,
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  }
})

class TrainingsCalendar extends Component {
  constructor() {
    super()
    this.state = {
      user_id: "",
      months: 3,
      events: [],
      loading: true,
      viewType: "",
      openDelete: false,
      selectedEvent: null,
      newTraining: null
    }
    this.selected = ""
    autobind(TrainingsCalendar, this)
  }

  componentDidMount() {
    const { getWorkers, getTrainings, online, getTrainingCategories, getSettingsTrainings } = this.props
    if (online.status) {
      getWorkers()
      getSettingsTrainings()
      getTrainingCategories()
      getTrainings().then(() => { this.setState({ loading: false, events: this.getEvents(this.filter()) }) })
    } else {
      this.setState({ events: this.getEvents(this.filter()), loading: false })
    }
  }

  componentDidUpdate(prevProps) {
    const { trainings } = this.props
    if (prevProps.trainings.all.length !== trainings.all.length) {
      this.setState({ events: this.getEvents(this.filter()) })
    }

  }

  getEvents(pendings) {
    return pendings.map(event => ({
      title: event.name,
      start: event.date_scheduled,
      backgroundColor: event.date_end.includes("0000-00-00") ? "#5867dd" : '#0abb87',
      borderColor: event.date_end.includes("0000-00-00") ? "#5867dd" : '#0abb87',
      editable: false,
      eventStartEditable: false,
      display: 'block',
      extendedProps: {
        user_id: event.user_id,
        user: event.user_name,
        id: event.id,
        category_name: event.category_name,
        date_scheduled: event.date_scheduled,
        title: event.name,
        start_date: event.date_start,
      },
    }))
  }

  handleChange(event) {
    const { target } = event
    const value = target.value
    this.setState({ [target.name]: value },
      () => this.setState({ events: this.getEvents(this.filter()) })
    )
  }

  handleOpenDelete(info) {
    const selectedEvent = info.event.extendedProps
    this.setState({ openDelete: !this.state.openDelete, selectedEvent })
  }

  handleDelete() {
    const { selectedEvent } = this.state
    const { deleteChecklist } = this.props
    const body = { id: selectedEvent.id }
    deleteChecklist(body)
    this.setState({ openDelete: false, selectedEvent: null })
  }

  handleDrop(info) {
    const { editChecklist } = this.props
    const body = {
      ...this.selected,
      date_scheduled: info.dateStr
    }
    editChecklist(body)
  }

  handleDropStop(info) {
    this.selected = {
      user_id: info.event.extendedProps.user_id,
      id: info.event.extendedProps.id,
    }
  }

  handleClick(info) {
    return () => {
      const { history } = this.props
      const trainingId = info.event.extendedProps.id
      history.push(`/trainings/${trainingId}`)
    }
  }

  handleCreate(info) {
    const newTraining = {
      date_scheduled: info.dateStr
    }
    this.setState({ newTraining })
  }

  handleChangeCreate(event) {
    const { target } = event
    const { newTraining } = this.state
    newTraining[target.name] = target.value
    this.setState({ newTraining })
  }

  handleCreateTraining() {
    const { createTrainings } = this.props
    const { newTraining } = this.state
    const body = { ...newTraining }
    createTrainings(body).then(() => {
      this.setState({ events: this.getEvents(this.filter()), loading: false })
    })
    this.setState({ newTraining: null })
  }


  getOptions() {
    const { workers, trainings, branch } = this.props
    const { months } = this.state
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
    const workersWithEvents = filteredWorkers.filter(worker => {
      const events = trainings.all || []
      const userEvents = events.filter(event => {
        const isSameId = event.user_id.toString() === worker.id.toString()
        const eventDate = event.date_scheduled
        const startDate = moment(new Date()).subtract(months, "months")
        const endDate = moment(new Date()).add(1, 'years')
        const isBetweenDates = moment(eventDate).isBetween(startDate, endDate)
        return isSameId && isBetweenDates
      })
      return userEvents.length > 0
    })
    const options = transformToOptions(workersWithEvents || [])
    options.push({ value: "", label: "Todos" })
    return options
  }

  filter() {
    const { user_id, months } = this.state
    const { trainings } = this.props
    const events = trainings.all || []
    let result = [...events]
    result = events.filter(event => {
      let isSameId = true
      if (user_id !== "") {
        isSameId = event.user_id.toString() === user_id.toString()
      }
      const eventDate = event.date_scheduled
      const startDate = moment(new Date()).subtract(months, "months").startOf("month")
      const isBetweenDates = moment(eventDate).isSameOrAfter(startDate)
      return isSameId && isBetweenDates
    }
    )
    return result
  }

  handleRenderEvent(info) {
    const { classes } = this.props
    const { viewType } = this.state
    return (
      <Tooltip title={
        <div>
          <Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{info.event.title}</Typography>
          <Typography variant="subtitle2" style={{ color: 'white' }}>Categoría: {info.event.extendedProps.category_name}</Typography>
          <Typography variant="subtitle2" style={{ color: 'white' }}>Responsable: {info.event.extendedProps.user}</Typography>
        </div>
      } className={classes.tooltip}>
        <div className={classes.event} onClick={this.handleClick(info)}>
          <Typography
            className={classes.circle}
            variant="body1"
            style={{ color: viewType === 'listWeek' ? '#1a1a1a' : "white" }}
          >{info.event.title}
          </Typography>
          {/* {info.event.extendedProps.status !== 'Terminado' &&
            <IconButton
              className={classes.deleteButton}
              size="small"
              style={{ color: viewType === 'listWeek' ? '#1a1a1a' : "white" }}
              onClick={e => {
                e.stopPropagation()
                this.handleOpenDelete(info)
              }}
            >
              <Close />
            </IconButton>
          } */}
        </div>
      </Tooltip>
    )

  }

  render() {
    const { classes, settings, workers, branch } = this.props
    const { user_id, events, loading, months, selectedEvent, newTraining } = this.state
    const isMobile = window.innerWidth <= 500

    const allSettingsTrainings = settings.trainings.all || []
    const allTrainingCategories = settings.trainings.categories || []
    console.log(newTraining?.category_id)
    console.log(allSettingsTrainings)
    const filteredTrainings = allSettingsTrainings.filter(training => training.category_id.toString() === newTraining?.category_id)
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)

    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <SelectInput label="responsable" name="user_id" onChange={this.handleChange} options={this.getOptions()} value={user_id} />
          </div>
          <div className={classes.inputContainer}>
            <Typography variant="subtitle1">Hasta</Typography>
            <div className={classes.textInput}>
              <SelectInput label="" name="months" onChange={this.handleChange} value={months} options={[...Array(60).keys()].map(number => ({ value: number + 1, label: number + 1 }))} />
            </div>
            <Typography variant="subtitle1">meses atrás</Typography>
          </div>
        </div>
        <Paper className={classes.paper}>
          <Conditional condition={loading} hasElse>
            <LoaderAnimator />
            <FullCalendar
              plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
              initialView={isMobile ? "listWeek" : "dayGridMonth"}
              headerToolbar={{
                left: "prev,today,next",
                center: "title",
                right: "listWeek,dayGridMonth",
              }}
              buttonText={{
                prev: "Atrás",
                next: "siguiente",
                today: "Hoy",
                month: "Mes",
                week: "Semana"

              }}
              events={events}
              height={"80vh"}
              editable={true}
              eventDurationEditable={false}
              eventDragStop={this.handleDropStop}
              drop={this.handleDrop}
              locale="es"
              eventContent={this.handleRenderEvent.bind(this)}
              datesSet={(info) => this.setState({ viewType: info.view.type })}
              dateClick={this.handleCreate}
            />
          </Conditional>
        </Paper>
        <Dialog fullWidth maxWidth="sm" open={selectedEvent !== null} onClose={() => this.setState({ openDelete: false, selectedEvent: null })}>
          <div className={classes.dialog}>
            <Typography variant="h1">¿Borrar {selectedEvent?.title}?</Typography>
            <Typography variant="caption">Evento agendado el: {selectedEvent?.start_date}</Typography>
            <SubmitButton onClick={this.handleDelete} >Eliminar</SubmitButton>
          </div>
        </Dialog>
        <Dialog fullWidth maxwidth="sm" open={newTraining !== null} onClose={() => this.setState({ newTraining: null })}>
          <div className={classes.dialog}>
            <Typography variant="h1">Crear capacitación</Typography>
            <Typography variant="caption">Capacitación agendada para el: {' '}
              <span style={{ fontWeight: 600 }}>
                {moment(newTraining?.date_scheduled).format("DD [de] MMMM [del] YYYY")}
              </span>
            </Typography>
            <SelectInput value={newTraining?.user_id} options={transformToOptions(filteredWorkers)} label="Responsable" name="user_id" onChange={this.handleChangeCreate} />
            <SelectInput value={newTraining?.category_id} options={transformToOptions(allTrainingCategories)} label="Categoría" name="category_id" onChange={this.handleChangeCreate} />
            <MultiSelectInput value={newTraining?.documents_ids} options={transformToOptions(filteredTrainings)} label="Capacitación" name="documents_ids" onChange={this.handleChangeCreate} />
            <SubmitButton variant="contained" onClick={this.handleCreateTraining} disabled={newTraining && Object.keys(newTraining)?.length < 3}>Crear</SubmitButton>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user,
  online: state.online,
  trainings: state.trainings,
  settings: state.settings,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getWorkers: body => dispatch(getWorkersAction(body)),
  getTrainings: body => dispatch(getTrainingsAction(body)),
  createTrainings: body => dispatch(createMultipleTrainingsAction(body)),
  createTraining: body => dispatch(createTrainingAction(body)),
  getSettingsTrainings: () => dispatch(getSettingsTrainingsAction()),
  getTrainingCategories: () => dispatch(getTrainingsCategoriesAction())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(TrainingsCalendar)))