import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({

})
class CheckPhase extends Component {

  render() {
    const { classes, phase } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Tipo: Confirmar una acción</Typography>
        <Typography variant="caption">Acción: {phase.label}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(CheckPhase)