const autobind = (object, self) => {
    const functions = Object.getOwnPropertyNames(object.prototype).filter((f) =>
        f.includes("handle")
    );
    functions.forEach((f) => {
        self[f] = self[f].bind(self);
    });
};

export default autobind;
