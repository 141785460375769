import React, { Component } from 'react'
import { Tab, Typography, withStyles } from '@material-ui/core'
import MyTabs from '../../../Shared/MyTabs'
import TabPanel from '../../../Shared/TabPanel'
import autobind from '../../../Utils/autobind'
import Epps from './Permissions/Epps'
import Requirements from './Permissions/Requirements'
import Areas from './Permissions/Areas'
import Types from './Permissions/Types'

const style = () => ({
  container: {
    marginTop: 36
  },
  tabs: {
    background: 'white',
    marginTop: 12,
    display: 'table'
  }
})

class Permissions extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0
    }
    autobind(Permissions, this)
  }

  handleChangeTab(event, tab) {
    this.setState({ tab })
  }

  render() {
    const { classes } = this.props
    const { tab } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h1">Configuración de permisos</Typography>
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={0} label={<Typography variant="h2">Epps</Typography>} />
            <Tab value={1} label={<Typography variant="h2">Exigencias</Typography>} />
            <Tab value={2} label={<Typography variant="h2">Tipos</Typography>} />
            <Tab value={3} label={<Typography variant="h2">Áreas</Typography>} />
          </MyTabs>
        </div >
        <TabPanel value={tab} index={0}>
          <Epps />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Requirements />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Types />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Areas />
        </TabPanel>
      </div>
    )
  }
}

export default withStyles(style)(Permissions)