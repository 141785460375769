import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"

export const getTalksAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: "talks/get.php"
  })
  return {
    type: "GET_TALKS",
    payload: REQUEST
  }
}

export const getTalkAction = id => {
  return {
    type: "SELECT_TALK",
    payload: id
  }
}

export const signTalkAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "SIGN_TALK",
    payload: REQUEST
  }
}

export const endTalkAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/end.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "END_TALK",
    payload: REQUEST
  }
}

export const userSignTalkAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/user_sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "USER_SIGN_TALK",
    payload: REQUEST
  }
}

export const editTalkAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_TALK",
    payload: REQUEST
  }
}

export const createTalkAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_TALK",
    payload: REQUEST
  }
}

export const createMultipleTalksAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'talks/create_multiple.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_MULTIPLE_TALKS",
    payload: REQUEST
  }
}