import React, { Component, Fragment } from "react"
import propTypes from "prop-types"
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    withStyles,
    IconButton,
    Tooltip,
    TableSortLabel,
    TextField,
    Menu,
    MenuItem,
    Button,
} from "@material-ui/core"
import {
    Search,
    KeyboardArrowDown,
    CancelRounded,
    KeyboardArrowRight,
    KeyboardArrowLeft,
    ArrowDownward,
} from "@material-ui/icons"
import autobind from "../Utils/autobind"
import { compareValuesByKey, getTableCellValue } from "../Utils/functions"
import RowCard from "./RowCard"

const style = (theme) => ({
    row: {
        transition: "all 0.2s, ease-in-out",
        transform: "scale(1)",
        "&:hover": {
            background: theme.palette.grey.main,
        },
    },
    actionMenu: {
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        background: `linear-gradient(90deg, transparent 0%, ${theme.palette.indigo.lighter} 30%)`,
        transition: "all 0.2s, ease-in-out",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 12px",
        paddingLeft: 32,
    },
    hiddenMenu: {
        position: "absolute",
        right: 0,
        top: 0,
        background: "#fff",
        opacity: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 12px",
        paddingLeft: 32,
    },
    topBar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "3px 0 6px 0",
        flexWrap: 'wrap-reverse',
        "&>*": {
            margin: '0 12px 12px 12px',
            minWidth: 150
        },
    },
    subTopBar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "3px 0 6px 0",
        flexWrap: "wrap",
        "&>*": {
            margin: '0 12px 12px 12px',
            "@media (max-width:500px)": {
                minWidth: 130,
                flexBasis: 150,
                flexGrow: 1
            }
        },
    },
    dropdown: {
        borderRadius: 5,
        padding: "5px 8px",
        fontSize: 13,
        color: 'whitesmoke',
        "& p": {
            marginRight: 6, fontSize: 13, color: "whitesmoke"
        }
    },
    sortable: {
        borderRadius: 5,
        padding: "5px 8px",
        fontSize: 13,
        color: theme.palette.primary,
        "& p": {
            marginRight: 6, fontSize: 13
        }
    },
    tag: {
        background: "#22607b",
        padding: "3px 9px",
        borderRadius: 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: 12,
    },
    filterResult: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        marginBottom: 12,
    },
    cardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: '12px',
        '&>*': {
            maxWidth: 350,
            "@media (max-width:500px)": {
                width: '100%',
                maxWidth: 'unset'
            }
        }
    },
})

class GeneralTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderBy: "",
            orderOn: "desc",
            word: "",
            activeDropdown: "",
            filterTags: [],
            options: {},
            left: 0,
            right: 25,
            step: 25,
        }
        autobind(GeneralTable, this)
    }

    componentDidMount() {
        const { data, filters } = this.props
        const options = {}
        filters.forEach((filter) => {
            const result = []
            data.forEach((element) => {
                if (result.indexOf(filter.format ? filter.format(element[filter.label]) : element[filter.label]) === -1) {
                    result.push(filter.format ? filter.format(element[filter.label]) : element[filter.label])
                }
            })
            options[filter.label] = result
        })
        this.setState({ options })
    }

    handleSort(label) {
        return () => {
            const { orderBy, orderOn } = this.state
            if (label === orderBy) {
                this.setState({
                    orderBy: label,
                    orderOn: orderOn === "desc" ? "asc" : "desc",
                })
            } else {
                this.setState({ orderBy: label, orderOn: "desc" })
            }
        }
    }

    handleSearch(event) {
        const { target } = event
        this.setState({ word: target.value })
    }

    search(data) {
        const { info, name } = this.props
        const target = document.getElementById(`searchBar-${name}`)
        if (target && target.value !== "") {
            const result = data.filter((cell) => {
                let bool = false
                info.forEach((element) => {
                    const labels = element.label.split("&")
                    if (labels.length > 1) {
                        if (
                            String(cell[labels[0]][labels[1]])
                                .toLocaleLowerCase()
                                .includes(target.value.toLocaleLowerCase())
                        ) {
                            bool = true
                        }
                    } else if (
                        String(cell[labels[0]])
                            .toLocaleLowerCase()
                            .includes(target.value.toLocaleLowerCase())
                    ) {
                        bool = true
                    }
                })
                return bool
            })
            return result
        }
        return data
    }

    filter(data) {
        const { filterTags } = this.state
        let temp = data
        filterTags.forEach((tag) => {
            const name = tag.unformat ? tag.unformat(tag.name) : tag.name
            temp = temp.filter((element) => element[tag.label] === name)
        })
        return temp
    }

    sortedElements() {
        const { data } = this.props
        const { orderBy, orderOn } = this.state
        const sortedData = data.sort(compareValuesByKey(orderBy, orderOn))
        return sortedData
    }

    handleClose() {
        this.setState({ anchorEl: null, activeDropdown: "" })
    }

    handleAddFilter(name, newFilter) {
        return () => {
            const { filterTags, step } = this.state
            const { disablePagination, data } = this.props
            const newFilters = [...filterTags]
            const isFound = newFilters.find(
                (filter) => filter.name === name && filter.label === newFilter.label
            )
            if (!isFound) {
                newFilters.push({ name, label: newFilter.label, format: newFilter.format, unformat: newFilter.unformat })
            }
            this.setState({
                filterTags: newFilters,
                left: 0,
                right: disablePagination ? data.length : step,
            })
            this.handleClose()
        }
    }

    handleRemoveFilter(name, label) {
        return () => {
            const { filterTags, step } = this.state
            const { disablePagination, data } = this.props
            const newFilters = [...filterTags]
            const resultFilters = newFilters.filter(
                (filter) => !(filter.name === name && filter.label === label)
            )
            this.setState({
                filterTags: resultFilters,
                left: 0,
                right: disablePagination ? data.length : step,
            })
            this.handleClose()
        }
    }

    handleOpenDropdown(event, label) {
        this.setState({ anchorEl: event.target, activeDropdown: label })
    }

    handleNextPage(option) {
        return () => {
            const processedData = this.filter(
                this.search(this.sortedElements())
            )
            const { left, right, step } = this.state
            if (option > 0) {
                if (right < processedData.length) {
                    this.setState({ left: left + step, right: right + step })
                }
            } else if (left > 0) {
                this.setState({ left: left - step, right: right - step })
            }
        }
    }

    renderFilters() {
        const { filters, classes } = this.props
        const { activeDropdown, anchorEl, options } = this.state
        return filters.map((filter, index) => {
            const dropdownOptions = options[filter.label] || []
            return (
                <Fragment key={index}>
                    <Button
                        className={classes.dropdown}
                        color="primary"
                        variant="contained"
                        onClick={(e) =>
                            this.handleOpenDropdown(e, filter.label)
                        }
                    >
                        <Typography variant="body2">
                            {filter.name}
                        </Typography>
                        <KeyboardArrowDown style={{ paddingLeft: 6 }} />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={activeDropdown === filter.label}
                        onClose={this.handleClose}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem
                                key={option}
                                onClick={this.handleAddFilter(
                                    option,
                                    filter
                                )}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Fragment>
            )
        })
    }

    renderSortings() {
        const { sortings, classes } = this.props
        const { orderBy, orderOn } = this.state
        return sortings.map((sortableHeader, index) => (
            <Fragment key={index}>
                <Button
                    className={classes.sortable}
                    color="primary"
                    variant="outlined"
                    onClick={this.handleSort(sortableHeader.label)}
                >
                    <Typography variant="body2">
                        {sortableHeader.name}
                    </Typography>
                    {orderBy === sortableHeader.label && (
                        <ArrowDownward
                            color="primary"
                            style={{
                                height: 18,
                                width: 18,
                                transform:
                                    orderOn === "desc" ? "" : "rotate(180deg)",
                                transition: "all 0.1s linear",
                            }}
                        />
                    )}
                </Button>
            </Fragment>
        ))
    }

    renderFilterTags() {
        const { filterTags } = this.state
        const { classes } = this.props
        return filterTags.map((tag, index) => (
            <div className={classes.tag} key={index}>
                <Typography
                    variant="body2"
                    style={{ color: "#fff", fontWeight: 600 }}
                >
                    {tag.name}
                </Typography>
                <IconButton
                    size="small"
                    style={{ marginLeft: 6 }}
                    onClick={this.handleRemoveFilter(tag.name, tag.label)}
                >
                    <CancelRounded
                        style={{ color: "#fff", height: 16, width: 16 }}
                    />
                </IconButton>
            </div>
        ))
    }

    renderActions(row) {
        const { actions } = this.props
        const actionCells = actions.map((action, index) => (
            <Tooltip title={action.name} key={index.toString()}>
                <IconButton onClick={action.action(row)} color={action.color} disabled={action.disabled ? action.disabled(row) : false}>
                    <action.icon />
                </IconButton>
            </Tooltip>
        ))
        return (
            <TableCell style={{ textAlign: 'end' }}>
                {actionCells}
            </TableCell>
        )

    }

    renderTableHead() {
        const { info, actions } = this.props
        const { orderBy, orderOn } = this.state
        const row = info.map((header, index) => (
            <TableCell key={index.toString()}>
                <TableSortLabel
                    direction={orderOn}
                    active={header.label === orderBy}
                    onClick={this.handleSort(header.label)}
                >
                    <Typography variant="subtitle1">{header.name}</Typography>
                </TableSortLabel>
            </TableCell>
        ))
        return (
            <TableRow>
                {row}
                {actions.length > 0 && (
                    <TableCell style={{ textAlign: 'end' }}>
                        <Typography variant="subtitle1">Acciones</Typography>
                    </TableCell>
                )}
            </TableRow>
        )
    }

    renderTableBody() {
        const { info, classes } = this.props
        const { left, right } = this.state
        const processedData = this.filter(
            this.search(this.sortedElements())
        ).slice(left, right)
        return processedData.map((rowElement, index) => {
            const row = info.map((header, index2) => {
                let value = getTableCellValue(rowElement, header)
                if (header.format) {
                    value = header.format(value)
                }
                const rendered = header.render ? (
                    <header.render
                        element={rowElement}
                        header={header}
                        value={value}
                    />
                ) : (
                    false
                )
                return (
                    <TableCell
                        key={index2.toString()}
                        style={{
                            maxWidth: header.max_width
                                ? header.max_width
                                : "unset",
                        }}
                    >
                        {rendered !== false ? (
                            <>{rendered}</>
                        ) : (
                            <Tooltip title={value}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Tooltip>
                        )}
                    </TableCell>
                )
            })
            const toReturn = (
                <TableRow className={classes.row} key={(index * 10).toString()}>
                    {row}
                    {this.renderActions(rowElement)}
                </TableRow>
            )
            return toReturn
        })
    }

    renderCards() {
        const { info, actions, callbackForRendering } = this.props
        const processedData = this.filter(this.search(this.sortedElements()))
        return processedData.map((rowElement, index) => {
            if (callbackForRendering) {
                return callbackForRendering(rowElement, index)
            }
            return (
                <RowCard
                    row={rowElement}
                    info={info}
                    key={index}
                    actions={actions}
                />
            )
        })
    }

    handleGenerateFile() {
        const { data, name, info } = this.props
        const headers = info.map(element => element.name).map(header => `"${header}"`).join(",")
        const valueHeaders = info.map(element => element.label)
        const body = data.map(entry => valueHeaders.map(key => entry[key]).map(value => `"${value}"`).join(",")).join('\n')
        const file = `${headers}\n${body}`
        var element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file))
        element.setAttribute('download', `${name}.xls`)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }

    render() {
        const { name, classes, disablePagination, disableFilters, onlyCards, exportable } = this.props
        const processedData = this.filter(this.search(this.sortedElements()))
        const { filterTags } = this.state
        const { word, left, right, step } = this.state
        const mobile = window.innerWidth < 500

        return (
            <div>
                {exportable && <Button onClick={this.handleGenerateFile} variant="outlined" size="small">Exportar a Excel</Button>}
                {!disableFilters && (
                    <div id={`top-${name}`}>
                        <div className={classes.topBar}>
                            <div className={classes.subTopBar}>
                                {this.renderFilters()}
                                {this.renderSortings()}
                            </div>
                            <TextField
                                id={`searchBar-${name}`}
                                placeholder="Buscar... "
                                onChange={this.handleSearch}
                                InputProps={{
                                    endAdornment: <Search />,
                                }}
                                value={word}
                            />
                        </div>
                        <div style={{ height: 28, marginBottom: 12 }}>
                            {filterTags.length > 0 && (
                                <div className={classes.filterResult}>
                                    <Typography
                                        variant="subtitle2"
                                        color="primary"
                                        style={{ marginRight: 12 }}
                                    >
                                        {"Resultado: "}
                                    </Typography>
                                    {this.renderFilterTags()}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {mobile || onlyCards ? (
                    <div className={classes.cardsContainer}>
                        {this.renderCards()}
                    </div>
                ) : (
                    <>
                        <Paper
                            style={{
                                maxHeight: disablePagination ? "70vh" : "unset",
                                overflow: "auto",
                                padding: 24,
                                borderRadius: 15
                            }}
                            onScroll={this.handleScrollBottom}
                            id={`table-${name}`}
                            square
                        >
                            <Table stickyHeader={disablePagination}>
                                <TableHead id={`head-${name}`}>
                                    {this.renderTableHead()}
                                </TableHead>
                                <TableBody id={`body-${name}`}>
                                    {this.renderTableBody()}
                                </TableBody>
                            </Table>
                        </Paper>
                        <Paper
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: 12,
                                padding: "0px 12px",
                            }}
                            id={`bottom-${name}`}
                            square
                        >
                            <Typography variant="body1">{`Página ${left / step + 1
                                } de ${Math.ceil(
                                    processedData.length / step
                                )}`}</Typography>
                            <Typography variant="body1">{`${left + 1} - ${processedData.length < right
                                ? processedData.length
                                : right
                                }`}</Typography>
                            <div style={{ display: "flex" }}>
                                <IconButton onClick={this.handleNextPage(-1)}>
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton onClick={this.handleNextPage(1)}>
                                    <KeyboardArrowRight />
                                </IconButton>
                            </div>
                        </Paper>
                    </>
                )}
            </div>
        )
    }
}

GeneralTable.propTypes = {
    classes: propTypes.object.isRequired,
    info: propTypes.arrayOf(propTypes.object),
    data: propTypes.arrayOf(propTypes.object),
    actions: propTypes.arrayOf(propTypes.object),
    filters: propTypes.arrayOf(propTypes.object),
    sortings: propTypes.arrayOf(propTypes.object),
    name: propTypes.string,
    disableFilters: propTypes.bool,
    disablePagination: propTypes.bool,
    onlyCards: propTypes.bool,
    callbackForRendering: propTypes.func
}

GeneralTable.defaultProps = {
    info: [],
    data: [],
    actions: [],
    filters: [],
    sortings: [],
    name: "table",
    disableFilters: false,
    disablePagination: false,
    onlyCards: false,
    callbackForRendering: undefined,
    exportable: false,
}

export default withStyles(style)(GeneralTable)
