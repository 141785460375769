import { Button, Divider, Paper, Typography, withStyles } from "@material-ui/core"
import { AddAPhoto } from "@material-ui/icons"
import React, { Component } from "react"
import { connect } from "react-redux"
import ImageContainer from "../../Shared/ImageContainer"
import TextInput from "../../Shared/Inputs/TextInput"
import autobind from "../../Utils/autobind"
import moment from 'moment'
import { resizeImageAndGetFile } from "../../Utils/functions"

const style = () => ({
    container: {
        padding: 12,
        marginTop: 24,
    },
    divider: {
        margin: "6px 0",
    },
    form: {
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
        "& > *": {
            flexBasis: 250,
            display: "block",
            "@media (max-width:500px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: "unset",
                flexBasis: "100%",
            },
        },
    },
    button: {
        textAlign: 'end'
    },
    hiddenInput: {
        display: "none"
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 24
        },
        margin: "12px 0"
    }
})

function simulateClick(finding) {
    return () => {
        const element = document.getElementById(`fileInput-${finding.id}`)
        element.click()
    }
}

class FindingAction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            base64File: ""
        }
        autobind(FindingAction, this)
    }

    componentDidMount() {
        const { finding } = this.props
        const newParams = {
            solution_comment: finding.solution_comment,
            solution_evidence: finding.solution_evidence,
        }
        this.setState({ params: newParams })
    }

    componentDidUpdate(prevProps) {
        const { finding } = this.props
        if (finding.id !== prevProps.finding.id) {
            const newParams = {
                solution_comment: finding.solution_comment,
                solution_evidence: finding.solution_evidence,
            }
            this.setState({ params: newParams })
        }
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleSelectFile(event) {
        const { target } = event
        const file = target.files[0]

        const callback = (image, file) => {
            const { params } = this.state
            params.solution_evidence = file
            this.setState({ base64File: image, params })
        }

        resizeImageAndGetFile(file, callback)
    }

    handleSave() {
        const { params, base64File } = this.state
        const { finding, online, solveFinding } = this.props
        const body = {
            ...params,
            id: finding.id,
            status: 2,
            online: online.status,
            base64: base64File
        }

        const data = new FormData()
        data.append("solution_evidence", params.solution_evidence)
        data.append("id", finding.id)
        data.append("status", 2)
        data.append("solution_comment", params.solution_comment)
        data.append("date_solved", moment(new Date()).format("YYYY-MM-DD"))
        solveFinding(body, data)
    }

    render() {
        const { classes, finding, user } = this.props
        const { params, base64File } = this.state
        const isResponsible = user.account.user.userType === 1 || user.account.user.id.toString() === finding?.user_id?.toString()
        const disabled = finding.status === "Pendiente" || finding.status === "Desestimado" || !isResponsible
        return (
            <Paper square className={classes.container}>
                <div className={classes.title}>
                    <Typography variant="subtitle1">
                        Resolución
                    </Typography>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.form}>
                    <TextInput
                        name="solution_comment"
                        value={params.solution_comment}
                        onChange={this.handleChange}
                        label="Comentarios acción inmediata"
                    />
                </div>
                <input className={classes.hiddenInput} type="file" id={`fileInput-${finding.id}`} onChange={this.handleSelectFile} />
                <div className={classes.inline}>
                    <Button color="primary" variant="outlined" onClick={simulateClick(finding)}>
                        <AddAPhoto />
                    </Button>
                    <ImageContainer src={base64File || "/noimage.png"} alt="foto" height="32px" width="32px" />
                </div>
                <div className={classes.button}>
                    <Button color="primary" variant="outlined" onClick={this.handleSave} disabled={disabled}>
                        Resolver
                    </Button>
                </div>
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
    online: state.online,
    user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(FindingAction))
