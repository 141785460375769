import React, { Component } from 'react'
import { Collapse, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import { createWorkflowAction, getSettingsWorkflowsAction, getWorkflowsAction } from '../../Actions/WorkFlows'
import { connect } from 'react-redux'
import AddElementButton from '../../Shared/AddElementButton'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../Utils/functions'
import SubmitButton from '../../Shared/SubmitButton'
import moment from 'moment'
import { KeyboardArrowRight } from '@material-ui/icons'

const style = () => ({
  container: {
    margin: 12
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  workflow: {
    padding: 24,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divider: {
    margin: '12px 0'
  },
  form: {
    padding: 24
  }
})

function getCompleted(workflow) {
  const steps = workflow?.workflow_steps || []
  return steps.map(step => !!step.data.finished).every(finished => finished)
}

class Workflows extends Component {
  constructor() {
    super()
    this.state = {
      openCreate: false,
      params: {}
    }
    autobind(Workflows, this)
  }

  componentDidMount() {
    const { getSettingsWorkflows, getWorkflows } = this.props
    getSettingsWorkflows()
    getWorkflows()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { createWorkflow } = this.props
    const { params } = this.state
    const body = {
      ...params,
      date_created: moment(new Date()).format("YYYY-MM-DD")
    }
    createWorkflow(body)
    this.setState({ params: {} })
  }

  handleOpenCreate() {
    this.setState({ openCreate: !this.state.openCreate })
  }

  handleOpenWorkflow(wf) {
    return () => {
      const { history } = this.props
      history.push(`/workflows/${wf.id}`)
    }
  }

  renderCoursingWorkflows() {
    const { workflows, classes, settings } = this.props
    const allWorkflows = workflows.all || []
    return allWorkflows.filter(wf => !getCompleted(wf)).map(wf => {
      const allWorkflowsSettings = settings.workflows.all || []
      const selectedWorkflow = allWorkflowsSettings.find(swf => swf.id === wf.workflow_base_id)
      return (
        <Paper className={classes.workflow} onClick={this.handleOpenWorkflow(wf)}>
          <div>
            <Typography variant="h4">{wf.name}</Typography>
            <Typography variant="subtitle1">{selectedWorkflow?.name}</Typography>
            <Typography variant="caption">{`Etapas: ${wf.workflow_steps.length}`}</Typography>
          </div>
          <KeyboardArrowRight />
        </Paper>
      )
    })
  }

  renderFinishedWorkflows() {
    const { workflows, classes, settings } = this.props
    const allWorkflows = workflows.all || []
    return allWorkflows.filter(getCompleted).map(wf => {
      const allWorkflowsSettings = settings.workflows.all || []
      const selectedWorkflow = allWorkflowsSettings.find(swf => swf.id === wf.workflow_base_id)
      return (
        <Paper className={classes.workflow} onClick={this.handleOpenWorkflow(wf)}>
          <div>
            <Typography variant="h4">{wf.name}</Typography>
            <Typography variant="subtitle1">{selectedWorkflow?.name}</Typography>
            <Typography variant="caption">{`Etapas: ${wf.workflow_steps.length}`}</Typography>
          </div>
          <KeyboardArrowRight />
        </Paper>
      )
    })
  }

  renderForm() {
    const { settings, classes } = this.props
    const { params } = this.state
    const allWorkflowsSettings = settings.workflows.all || []
    return (
      <div className={classes.form}>
        <TextInput value={params.name} name="name" onChange={this.handleChange} label="Nombre del proceso" />
        <SelectInput options={transformToOptions(allWorkflowsSettings)} value={params.workflow_base_id} name="workflow_base_id" onChange={this.handleChange} label="Seleccione base del proceso" />
        <SubmitButton onClick={this.handleCreate} disabled={!params.name || !params.workflow_base_id}>
          Crear
        </SubmitButton>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { openCreate } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h1">Procesos en curso</Typography>
          <AddElementButton name="Iniciar proceso" open={openCreate} onClick={this.handleOpenCreate} />
        </div>
        <Collapse in={openCreate}>
          <Paper>
            {this.renderForm()}
          </Paper>
        </Collapse>
        <Divider className={classes.divider} />
        {this.renderCoursingWorkflows()}
        <Divider className={classes.divider} />
        <Typography variant="h4">Procesos terminados</Typography>
        {this.renderFinishedWorkflows()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workflows: state.workflows,
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  getWorkflows: () => dispatch(getWorkflowsAction()),
  createWorkflow: body => dispatch(createWorkflowAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Workflows))