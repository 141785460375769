import React, { Component } from 'react'
import { Dialog, DialogContent, withStyles } from '@material-ui/core'
import autobind from '../Utils/autobind'

const style = () => ({
  img: {
    borderRadius: "50%"
  }
})
class ImageContainer extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }

    autobind(ImageContainer, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { classes, src, width, height, alt, border } = this.props
    const { open } = this.state
    const extension = src.split(".")[src.split(".").length - 1].toLowerCase()
    // const images = ["png", "jpg", "jpeg"]
    const videos = ["mp4", "ogg"]
    const isVideo = videos.includes(extension)
    return (
      <div className={classes.container}>
        {!isVideo ?
          <img src={src} height={height} width={width} alt={alt} onClick={this.handleOpen} style={{ borderRadius: border }} />
          :
          <video width={width} height={height} onClick={this.handleOpen} className={classes.img}>
            <source src={src} type={`video/${extension}`} />
          </video>
        }
        <Dialog open={open} onClose={this.handleOpen}>
          <DialogContent>
            {!isVideo ?
              <img src={src} alt={alt} width="100%" />
              :
              <video controls width="100%">
                <source src={src} type={`video/${extension}`} />
              </video>
            }
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

ImageContainer.defaultProps = {
  heigth: 64,
  width: 64,
  src: "",
  alt: "alt",
  border: '50%'
}

export default withStyles(style)(ImageContainer)