import { Button, Dialog, IconButton, Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import { connect } from "react-redux"
import { editTalkAction, endTalkAction, getTalkAction, signTalkAction, userSignTalkAction } from "../../Actions/TalkActions"
import autobind from "../../Utils/autobind"
import MySignaturePad from "./MySignaturePad"
import moment from 'moment'
import { Edit, GetApp, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import SelectInput from "../../Shared/Inputs/SelectInput"
import DateInput from "../../Shared/Inputs/DateInput"
import DateTimeInput from "../../Shared/Inputs/DateTimeInput"
import { mergeMultiplePdfFiles, mergeMultiplePdfFilesAndGet, signDocument, transformToOptions } from "../../Utils/functions"
import { getSettingsTalksAction } from "../../Actions/SettingsActions"
import TextInput from "../../Shared/Inputs/TextInput"
import { callSnackbar } from "../../Utils/snackbar"

const style = theme => ({
	container: {
		padding: 12,
	},
	singForm: {
		padding: 12,
	},
	title: {
		background: theme.palette.primary.main,
		color: 'white',
		padding: 12,
		margin: "24px 0 0 0"
	},
	dialogTitle: {
		background: theme.palette.primary.main,
		color: 'white',
		padding: 12,
	},
	signatureContainer: {
		padding: 12,
		display: "flex",
		justifyContent: "center",
	},
	signature: {
		background: "lightgrey",
		borderRadius: 24,
		padding: 12,
		position: "relative",
	},
	signs: {
		padding: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 350,
		margin: 12,
		"@media (max-width:500px)": {
			width: '100%',
			margin: '12px 0',
		},
	},
	participantSigns: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	signsOpener: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: "0 12px"
	},
	userSignForm: {
		padding: 12,
		position: 'relative'
	},
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: "12px 0",
		"@media (max-width:500px)": {
			width: '100%',
		},
	},
	observations: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		width: '100%'
	},
	observation: {
		padding: "12px 0",
		margin: "0 12px",
		width: 400,
		"@media (max-width:500px)": {
			width: '100%',
			margin: 0
		},
	},
	observationContent: {
		padding: 12,
		borderRadius: 5,
		border: 'solid 1px lightgrey',
	},
	userSigns: {
		margin: '12px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	userSignTitle: {
		marginTop: 12
	},
	infoContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginBottom: 12,
		width: '100%',
		'& > *': {
			width: '100%',
			maxWidth: 400
		}
	},
	infoElement: {
		alignItems: "center",
		justifyContent: "space-between",
		maxWidth: "unset",
		flexBasis: "21%",
		minWidth: 200,
		padding: "0 2%",
		"@media (max-width:500px)": {
			display: 'flex',
			flexBasis: '100%'
		},
	},
	text: {
		margin: "12px 0 ",
		'&>p': {
			fontSize: "1.25rem",
			"@media (max-width:500px)": {
				fontSize: "0.95rem"
			},
		}
	},
	selectedContainer: {
		display: "flex",
		flexWrap: "wrap",
	},
	titleContainer: {
		padding: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		background: theme.palette.primary.main,
		'& > *': {
			color: "white"
		},
		marginBottom: 6
	},
	dialog: {
		padding: 12
	},
	dialogButton: {
		padding: 12,
		textAlign: 'end'
	},
	userInput: {
		maxWidth: 350,
		flexBasis: 350,
		margin: 6
	},
	userInputs: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	input: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& > *': {
			maxWidth: 350
		}
	},
	hidden: {
		display: 'none'
	},
	file: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	downloadText: {
		color: theme.palette.blue.main,
		cursor: 'pointer'
	},
	downloadButton: {
		position: 'absolute',
		bottom: 12,
		right: 12
	}
})

function simulateClick() {
	document.getElementById("saved-file").click()
}

class Talk extends Component {
	constructor() {
		super()
		this.state = {
			signatures: [],
			userSignatures: [],
			openSigns: false,
			openDialog: false,
			params: {
				date_start: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
				date_end: moment(new Date()).format("YYYY-MM-DDTHH:mm")
			}
		}
		autobind(Talk, this)
	}

	componentDidMount() {
		const { match, selectTalk, getSettingsTalks } = this.props
		const id = match.params.id
		selectTalk(id)
		getSettingsTalks()
	}

	handleOpenSigns() {
		this.setState({ openSigns: !this.state.openSigns })
	}

	handleSelectFile(event) {
		const { target } = event
		const file = target.files[0]
		const newParams = { ...this.state.params }
		newParams.file = file
		newParams.file_name = file.name
		this.setState({ params: newParams })
	}

	handleOpenDialog() {
		const { openDialog, params } = this.state
		const { talks } = this.props
		const selected = talks.selected
		if (!openDialog) {
			const newParams = {
				...params,
				date_scheduled: moment(selected.date_scheduled).format("YYYY-MM-DD"),
				user_id: selected.user_id,
				document_id: selected.document_id
			}
			this.setState({ openDialog: !openDialog, params: newParams })
		} else {
			this.setState({ openDialog: !openDialog })
		}
	}

	handleSave(value) {
		const { params } = this.state
		const { workers, match, signTalk, talks } = this.props
		const allWorkers = workers.all || []
		const selectedWorker = allWorkers.find(worker => worker.id === params.participant_id)
		const selected = talks.selected
		const signatures = selected.participants_signs
		if (!signatures.map(sign => sign.user_id?.toString()).includes(params.participant_id.toString())) {
			fetch(value)
				.then(res => res.blob())
				.then(blob => {
					const file = new File([blob], "File name", { type: "image/png" })
					const data = new FormData()
					data.append("user_id", params.participant_id)
					data.append("file", file)
					data.append("name", selectedWorker.name)
					data.append("id", match.params.id)
					data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
					signTalk(data)
					this.setState({ params: { ...this.state.params, participant_id: null } })
				})
		} else {
			callSnackbar("Solo se puede firmar una vez", "warning")
		}
	}

	handleUserSave(value) {
		const { match, userSignTalk, user, talks } = this.props
		const selected = talks.selected
		const signatures = selected.user_sign
		if (!signatures.map(sign => sign.user_id?.toString()).includes(user.account.user.id.toString())) {
			fetch(value)
				.then(res => res.blob())
				.then(blob => {
					const file = new File([blob], "File name", { type: "image/png" })
					const data = new FormData()
					data.append("user_id", user.account.user.id)
					data.append("file", file)
					data.append("name", user.account.user.name)
					data.append("id", match.params.id)
					data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
					userSignTalk(data)
				})
		} else {
			callSnackbar("Solo se puede firmar una vez", "warning")
		}
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleEdit() {
		const { params } = this.state
		const { match, editTalk } = this.props
		const body = {
			document_id: params.document_id,
			user_id: params.user_id,
			date_scheduled: params.date_scheduled,
			id: match.params.id
		}
		editTalk(body)
	}

	handleDownloadWithSign(sign) {
		return () => {
			const signDoc = async () => {
				const { user, talks } = this.props
				const selected = talks.selected || {}
				const file = selected.file
				const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${selected.id}&u=${user.account.user.id}`
				const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
				const imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
				let signedDocument
				if (file.includes(".pdf")) {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1, url2], "Reporte Charla")
				} else {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1], "Reporte Charla")
				}

				signDocument(signedDocument, sign, imgUrl, `Charla ${sign.name}`)
			}
			signDoc()
		}
	}

	handleEnd() {
		const { params } = this.state
		const { user, endTalk, match } = this.props
		const body = new FormData()
		body.append("name", user.account.user.name)
		body.append("id", match.params.id)
		body.append("file", params.file)
		body.append("date_start", moment(params.date_start).format("YYYY-MM-DD HH:mm:ss"))
		body.append("date_end", moment(params.date_end).format("YYYY-MM-DD HH:mm:ss"))
		body.append("date_saved", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
		body.append("participants_observations", params.participant_observation || "")
		body.append("user_observations", params.user_observation || "")
		endTalk(body)
	}

	handleSeeFile(file) {
		return () => {
			if (file) {
				const url = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
				window.location.href = url
			}
		}
	}

	renderSignatures() {
		const { classes, talks } = this.props
		const { openSigns } = this.state
		const selected = talks.selected
		const signatures = selected.participants_signs || []
		const isMobile = window.innerWidth <= 500
		const singatureElement = signatures.map((sign) => {
			const imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
			return (
				<Paper className={classes.signs}>
					<img src={imgUrl} height="75px" width="150px" alt="firma" />
					<div>
						<Typography variant="body2">{sign.name}</Typography>
						<Typography variant="body1">{sign.date}</Typography>
					</div>
					<div className={classes.buttons}>
						<IconButton onClick={this.handleDownloadWithSign(sign)}>
							<GetApp />
						</IconButton>
					</div>
				</Paper>
			)
		})
		return (
			<>
				{isMobile ? openSigns ? singatureElement : null : singatureElement}
			</>
		)
	}

	renderUserSignatures() {
		const { classes, talks } = this.props
		const selected = talks.selected
		const signatures = selected.user_sign || []
		return signatures.map((sign) => {
			const imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
			return (
				<Paper className={classes.signs} square>
					<img src={imgUrl} height="75px" width="150px" alt="firma" />
					<div>
						<Typography variant="body2">{sign.name}</Typography>
						<Typography variant="body1">{sign.date}</Typography>
					</div>
				</Paper>
			)
		})
	}

	renderInfo() {
		const { classes, talks } = this.props
		const selected = talks.selected
		return (
			<div className={classes.selectedContainer}>
				<div className={classes.infoElement}>
					<div className={classes.text}>
						<Typography variant="subtitle2">
							Supervisor
						</Typography>
					</div>
					<div className={classes.text}>
						<Typography variant="body2">
							{selected.user_name}
						</Typography>
					</div>
				</div>
				<div className={classes.infoElement}>
					<div className={classes.text}>
						<Typography variant="subtitle2">
							Fecha programado
						</Typography>
					</div>
					<div className={classes.text}>
						<Typography variant="body2">
							{moment(selected.date_scheduled).format("YYYY-MM-DD")}
						</Typography>
					</div>
				</div>
				<div className={classes.infoElement}>
					<div className={classes.text}>
						<Typography variant="subtitle2">
							Tema
						</Typography>
					</div>
					<div className={classes.text}>
						<Typography variant="body2">
							{selected.name}
						</Typography>
					</div>
				</div>
				<div className={classes.infoElement}>
					<div className={classes.text}>
						<Typography variant="subtitle2">
							Categoría
						</Typography>
					</div>
					<div className={classes.text}>
						<Typography variant="body2">
							{selected.category_name}
						</Typography>
					</div>
				</div>
				<div className={classes.infoElement}>
					<div className={classes.text}>
						<Typography variant="subtitle2">
							Documento
						</Typography>
					</div>
					<div className={classes.text}>
						<Typography variant="body2" className={selected.file ? classes.downloadText : ""} onClick={this.handleSeeFile(selected.file)}>
							{selected.file ? "Ver Documento" : "No hay documento"}
						</Typography>
					</div>
				</div>
			</div>
		)
	}

	handleDownload(row) {
		return () => {
			const { user, talks } = this.props
			const selected = talks.selected || {}
			const file = selected.file
			const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${row.id}&u=${user.account.user.id}`
			const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file

			if (file.includes(".pdf")) return mergeMultiplePdfFiles([url1, url2], "Reporte Charla")
			mergeMultiplePdfFiles([url1], "Reporte Charla")
		}
	}

	render() {
		const { classes, talks, workers, settings, user, branch } = this.props
		const { openSigns, openDialog, params } = this.state
		const selected = talks.selected || {}
		const level = user.account.user.userType
		const isMobile = window.innerWidth <= 500
		const isFinished = !moment(selected.date_saved).format("YYYY-MM-DD").includes("Fecha")

		const allTalks = settings.talks.all || []
		const isCurrentOwner = selected?.user_id?.toString() === user.account.user.id
		const isAbleToEnd = isCurrentOwner || level === 1

		let allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
		allWorkers = filteredWorkers
		if (level > 1 && !isCurrentOwner) {
			allWorkers = allWorkers.filter(worker => worker.id === user?.account?.user?.id?.toString())
		}
		if (isCurrentOwner) {
			allWorkers = allWorkers.filter(worker => worker.id !== user?.account?.user?.id?.toString())
		}


		return (
			<div className={classes.container}>
				<Paper className={classes.selectedInfo}>
					<div className={classes.titleContainer}>
						<Typography variant="h1">Información de Charla</Typography>
						{!isFinished && level === 1 &&
							<IconButton size="small" onClick={this.handleOpenDialog}>
								<Edit />
							</IconButton>
						}
					</div>
					{this.renderInfo()}
				</Paper>
				<Typography variant="h2" className={classes.title}>Firmas de participantes</Typography>
				{!isFinished &&
					<Paper className={classes.singForm} square>
						<div className={classes.input}>
							<SelectInput
								name="participant_id"
								value={params.participant_id}
								onChange={this.handleChange}
								label="Asistente"
								options={transformToOptions(allWorkers)}
							/>
						</div>
						<div className={classes.signatureContainer}>
							<Typography variant="body1">Firma Asistente</Typography>
						</div>
						<div className={classes.signatureContainer}>
							<MySignaturePad
								clearButton="true"
								save={this.handleSave}
								disabled={!params.participant_id}
							/>
						</div>
					</Paper>
				}
				{isMobile &&
					<Paper className={classes.signsOpener} square>
						<Typography variant="body1">Mostrar firmas ({selected.participants_signs.length} elementos)</Typography>
						<IconButton onClick={this.handleOpenSigns}>
							{openSigns ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					</Paper>
				}
				<div className={classes.participantSigns}>
					{this.renderSignatures()}
				</div>
				{(isAbleToEnd || isFinished) && <Typography variant="h2" className={classes.title}>Firma de Supervisor e Información final</Typography>}
				{isFinished ?
					<Paper className={classes.userSignForm} square>
						<div className={classes.infoContainer}>
							<div>
								<div className={classes.info}>
									<Typography variant="body1">Hora de inicio:</Typography>
									<Typography variant="body1">{selected.date_start}</Typography>
								</div>
								<div className={classes.info}>
									<Typography variant="body1">Hora de Término:</Typography>
									<Typography variant="body1">{selected.date_end}</Typography>
								</div>
							</div>
							<div>
								<div className={classes.info}>
									<Typography variant="body1">Documento original:</Typography>
									<Typography variant="body1" className={selected.file ? classes.downloadText : ""} onClick={this.handleSeeFile(selected.file)}>{selected.file ? "Ver Documento" : "No contiene documento"}</Typography>
								</div>
								<div className={classes.info}>
									<Typography variant="body1">Documento de charla:</Typography>
									<Typography variant="body1" className={selected.file_saved ? classes.downloadText : ""} onClick={this.handleSeeFile(selected.file_saved)}>{selected.file_saved ? "Ver Documento" : "No contiene documento"}</Typography>
								</div>
							</div>
						</div>
						<div className={classes.observations}>
							<div className={classes.observation}>
								<Typography variant="subtitle2">Observación participantes</Typography>
								<Typography variant="body1" className={classes.observationContent}>{selected.participants_observations ? selected.participants_observations : "Sin observación..."}</Typography>
							</div>
							<div className={classes.observation}>
								<Typography variant="subtitle2">Observación supervisores</Typography>
								<Typography variant="body1" className={classes.observationContent}>{selected.user_observations ? selected.user_observations : "Sin observación..."}</Typography>
							</div>
						</div>
						<Typography variant="subtitle1" className={classes.userSignTitle}>Firmas supervisor/es</Typography>
						<div className={classes.userSigns}>
							{this.renderUserSignatures()}
						</div>
						<IconButton className={classes.downloadButton} onClick={this.handleDownload(selected)}>
							<GetApp />
						</IconButton>
					</Paper>
					:
					(isAbleToEnd &&
						<Paper className={classes.userSignForm}>
							<div className={classes.userInputs}>
								<div className={classes.userInput}>
									<DateTimeInput name="date_start" value={params.date_start} onChange={this.handleChange} label="Fecha y hora de inicio" />
								</div>
								<div className={classes.userInput}>
									<DateTimeInput name="date_end" value={params.date_end} onChange={this.handleChange} label="Fecha y hora de término" />
								</div>
							</div>
							<div className={classes.userInputs}>
								<div className={classes.userInput}>
									<TextInput options={{ multiline: true, rows: 4 }} name="participant_observation" value={params.participant_observation} onChange={this.handleChange} label="Observación participantes" />
								</div>
								<div className={classes.userInput}>
									<TextInput options={{ multiline: true, rows: 4 }} name="user_observation" value={params.user_observation} onChange={this.handleChange} label="Observación supervisor" />
								</div>
							</div>
							<div className={classes.file}>
								<input type="file" id="saved-file" className={classes.hidden} onChange={this.handleSelectFile} />
								<div className={classes.fileInput}>
									<Typography variant="body1">{params.file_name ? `Documento: ${params.file_name}` : "No se ha seleccionado documento"}</Typography>
									<Button onClick={simulateClick} color="primary" variant="outlined">
										Agregar Documento
								</Button>
								</div>
							</div>
							<div className={classes.signatureContainer}>
								<Typography variant="body1">Firma Supervisor</Typography>
							</div>
							<div className={classes.signatureContainer}>
								<MySignaturePad
									clearButton="true"
									save={this.handleUserSave}
								/>
							</div>
							<div className={classes.userSigns}>
								{this.renderUserSignatures()}
							</div>
							<div className={classes.dialogButton}>
								<Button color="primary" variant="contained" onClick={this.handleEnd} disabled={selected.user_sign.length === 0}>Terminar Charla</Button>
							</div>
						</Paper>
					)
				}
				<Dialog open={openDialog} onClose={this.handleOpenDialog}>
					<Typography variant="h1" className={classes.dialogTitle}>Editar Charla</Typography>
					<div className={classes.dialog}>
						<SelectInput label="Responsable" options={transformToOptions(filteredWorkers)} onChange={this.handleChange} name="user_id" value={params.user_id} />
						<SelectInput label="Tipo" options={transformToOptions(allTalks)} onChange={this.handleChange} name="document_id" value={params.document_id} />
						<DateInput label="Fecha de Realización" onChange={this.handleChange} name="date_scheduled" value={params.date_scheduled} />
					</div>
					<div className={classes.dialogButton}>
						<Button color="primary" variant="outlined" size="small" onClick={this.handleEdit}>
							Guardar
						</Button>
					</div>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	talks: state.talks,
	workers: state.workers,
	settings: state.settings,
	user: state.user,
	branch: state.branch
})

const mapDispatchToProps = dispatch => ({
	selectTalk: id => dispatch(getTalkAction(id)),
	getSettingsTalks: () => dispatch(getSettingsTalksAction()),
	signTalk: body => dispatch(signTalkAction(body)),
	userSignTalk: body => dispatch(userSignTalkAction(body)),
	endTalk: body => dispatch(endTalkAction(body)),
	editTalk: body => dispatch(editTalkAction(body))

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Talk))
