import React, { Component } from 'react'
import { Typography, withStyles, Button } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import SubmitButton from '../../../Shared/SubmitButton'
import { CheckCircleOutline } from '@material-ui/icons'
import { connect } from 'react-redux'
import { transformToOptions } from '../../../Utils/functions'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'

const style = (theme) => ({
  container: {
    padding: 12,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phaseStatus: {
    height: 85,
    width: 85,
    border: '1px solid grey',
    borderRadius: 15,
    position: 'relative'
  },
  phaseContent: {
    flexGrow: 1,
    marginRight: 24
  },
  checkIcon: {
    color: theme.palette.green.main,
    height: 64,
    width: 64,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.2s linear'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 12
    }
  }
})
class SelectPhase extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      checked: false
    }
    autobind(SelectPhase, this)
  }
  componentDidMount() {
    const { phase } = this.props
    if (phase.value) {
      const startingParams = {
        [phase.label]: phase.value
      }
      this.setState({ params: startingParams, checked: true })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSubmit() {
    const { onSubmit, phase, index } = this.props
    const { params } = this.state
    const body = {
      ...phase,
      value: params[phase.label]
    }
    onSubmit(body, index)
    this.setState({ checked: true })
  }

  handleEdit() {
    this.setState({ checked: false })
  }

  showOwnOptions(options) {
    const finalOptions = options.map((option, index) => {
      return {
        label: option,
        value: index
      }
    })
    return finalOptions
  }

  showEntityOptions(phase) {
    const { workers, branch } = this.props
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
    // Switch for phase entity selected
    return transformToOptions(filteredWorkers)

  }

  render() {
    const { params, checked } = this.state
    const { classes, phase, disabled } = this.props
    const hasCreatedOptions = phase.options ? true : false
    const options = hasCreatedOptions ? this.showOwnOptions(phase.options) : this.showEntityOptions(phase)
    return (
      <div className={classes.container}>
        <div className={classes.phaseContent}>
          <Typography variant="subtitle1">{phase.label}</Typography>
          <MultiSelectInput disabled={disabled || checked} value={params[phase.label]} label={""} name={phase.label} onChange={this.handleChange} options={options} />
          <div className={classes.buttons}>
            {!disabled && checked && <Button variant="outlined" onClick={this.handleEdit} size="small">
              Editar
            </Button>}
            <SubmitButton onClick={this.handleSubmit} disabled={disabled || checked || !params[phase.label]}>Terminar Fase</SubmitButton>
          </div>
        </div>
        <div className={classes.phaseStatus}>
          <CheckCircleOutline className={classes.checkIcon} style={{ opacity: checked ? 1 : 0 }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  branch: state.branch,
})

export default connect(mapStateToProps)(withStyles(style)(SelectPhase))