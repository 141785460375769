import Chip from "./Chip"

export const pendingTableInfo = [
  { label: 'date_scheduled', name: "Fecha de Programación" },
  { label: "preventive_measure", name: "Medida preventiva" },
  { label: "user", name: "Responsable" },
  { label: "compliance", name: "Cumplimiento" },
  { label: "progress", name: "Avance" },
  { label: "status", name: "Estado", render: Chip },
]

export const doneTableInfo = [
  { label: 'date_scheduled', name: "Fecha de Programación" },
  { label: 'date_done', name: "Fecha de Realización" },
  { label: "preventive_measure", name: "Medida preventiva" },
  { label: "user", name: "Responsable" },
  { label: "compliance", name: "Cumplimiento" },
]