export const enqueueRequest = request => {
  let savedRequests = localStorage.getItem("SavedRequests")
  if (savedRequests) {
    const saved = JSON.parse(savedRequests)
    saved.push(request)
    localStorage.setItem("SavedRequests", JSON.stringify(saved))
  } else {
    savedRequests = [request]
    localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
  }
}