import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import "./animation.css"

const style = () => ({

})
class MiniLoaderAnimator extends Component {

  render() {
    return (
      <div className="mini-lds-container">
        <div className="mini-lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(MiniLoaderAnimator)