import React, { Component } from "react"
import { withStyles, Typography, Divider, Tab, Paper } from "@material-ui/core"
import GeneralForm from "../../Shared/GeneralForm"
import GeneralTable from "../../Shared/GeneralTable"
import autobind from "../../Utils/autobind"
import { activityInfo, activityTableInfo } from "./Info"
import { ExitToApp } from "@material-ui/icons"
import { connect } from "react-redux"
import { activityDispatcher } from "../../Actions/ActivityActions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { transformToOptions, validateForm } from "../../Utils/functions"
import moment from "moment"
import LoaderComponent from "../../Shared/LoaderComponent"
import AddElementButton from "../../Shared/AddElementButton"
import FormContext from "../../Shared/Cards/Provider"
import MyTabs from "../../Shared/MyTabs"
import TabPanel from "../../Shared/TabPanel"

const style = () => ({
	container: {
		margin: 12,
	},
	divider: {
		margin: "24px 0",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 12,
	},
	inline: {
		display: "flex",
		alignItems: "center",
		gap: "12px",
	},
})



class Activities extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {
				start_date: moment(new Date()).format("YYYY-MM-DD"),
				end_date: moment(new Date())
					.add(1, "months")
					.format("YYYY-MM-DD"),
			},
			open: false,
			tab: 0
		}
		autobind(Activities, this)
	}

	componentDidMount() {
		const {
			enterprise,
			getGlobalActivities,
			getWorkers,
			branch,
		} = this.props
		const body = {
			companyId: enterprise.selected.id,
		}
		getGlobalActivities(body)
		getWorkers(body)
		const params = { ...this.state.params }
		params.branch_id = branch?.global?.id
		this.setState({ params })
	}

	componentDidUpdate(prevProps) {
		const { branch } = this.props
		if (prevProps?.branch?.global?.id !== branch?.global?.id) {
			const params = { ...this.state.params }
			params.branch_id = branch.global.id
			this.setState({ params })
		}
	}

	handleOpen() {
		this.setState({ open: !this.state.open })
	}

	handleChange(event) {
		const { target } = event
		const params = { ...this.state.params }
		params[target.name] = target.value
		this.setState({ params })
	}

	handleCreate() {
		const { params } = this.state
		const body = { ...this.state.params }
		const { createActivity } = this.props
		if (validateForm(activityInfo, params)) {
			createActivity(body)
			this.setState({
				params: {
					start_date: moment(new Date()).format("YYYY-MM-DD"),
					end_date: moment(new Date())
						.add(1, "months")
						.format("YYYY-MM-DD"),
				}, open: false
			})
		}
	}

	handleGoto(row) {
		return () => {
			const { history } = this.props
			history.push(`/activities/${row.id}`)
		}
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleFormatFilter(value) {
		const { workers } = this.props
		const allWorkers = workers.all || []
		return allWorkers.find(worker => worker.id === value).name
	}

	handleUnformatFilter(value) {
		const { workers } = this.props
		const allWorkers = workers.all || []
		return allWorkers.find(worker => worker.name === value).id
	}

	setFormInfo(info) {
		const { enterprise, workers, activities } = this.props
		const { params } = this.state
		const allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === params?.branch_id)
		info.map((input) => {
			switch (input.name) {
				case "branch_id": {
					input.options = transformToOptions(enterprise?.branches)
					return input
				}
				case "user_id": {
					input.options = transformToOptions(filteredWorkers)
					return input
				}
				case "activity_id": {
					input.options = transformToOptions(activities?.global)
					return input
				}
				default:
					return input
			}
		})

		return info
	}

	render() {
		const {
			classes,
			enterprise,
			activities,
			getEnterpriseActivities,
			branch,
		} = this.props
		const { params, open, tab } = this.state
		const allActivities = activities.all || []
		const activeActivities = allActivities.filter(activity => !moment(activity?.end_date).isBefore(moment(new Date())))
		const archivedActivities = allActivities.filter(activity => moment(activity?.end_date).isBefore(moment(new Date())))
		const actions = [
			{ name: "Ver actividad", icon: ExitToApp, action: this.handleGoto },
		]
		const body = {
			companyId: enterprise?.selected?.id,
			branch_id: branch?.global?.id,
		}

		const filters = [
			{ name: "Responsable", label: "user_id", format: this.handleFormatFilter, unformat: this.handleUnformatFilter },
		]

		return (
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography variant="h1">
						Actividades {enterprise?.selected?.name}
					</Typography>
					<AddElementButton name="Agregar actividad" onClick={this.handleOpen} open={open} />
				</div>
				<FormContext.Provider value={{ handleChange: this.handleChange, params }}>
					<GeneralForm
						open={open}
						title={`Seleccionar actividades para ${enterprise?.selected?.name}`}
						info={this.setFormInfo(activityInfo)}
						onSubmit={this.handleCreate}
						submit="Agregar Actividad"
					/>
				</FormContext.Provider>
				<Paper square>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={<Typography variant="h4">Activas</Typography>}
							value={0}
						/>
						<Tab
							label={<Typography variant="h4">Archivadas</Typography>}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<Divider className={classes.divider} />
				<TabPanel index={0} value={tab}>
					<LoaderComponent
						action={getEnterpriseActivities}
						params={[body]}
					>
						<GeneralTable
							filters={filters}
							info={activityTableInfo}
							data={activeActivities}
							actions={actions}
						/>
					</LoaderComponent>
				</TabPanel>
				<TabPanel index={1} value={tab}>
					<LoaderComponent
						action={getEnterpriseActivities}
						params={[body]}
					>
						<GeneralTable
							filters={filters}
							info={activityTableInfo}
							data={archivedActivities}
							actions={actions}
						/>
					</LoaderComponent>
				</TabPanel>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise,
	workers: state.workers,
	activities: state.activities,
	branch: state.branch,
})

const mapDispatchToProps = (dispatch) => ({
	getWorkers: (body) => dispatch(getWorkersAction(body)),
	...activityDispatcher(dispatch),
})

Activities.propTypes = {}

Activities.defaultProps = {
	workers: { all: [] },
	activities: { global: [] },
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Activities))
