import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"
import { enqueueRequest } from "../Utils/offline"

export const getNonConformitiesAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: "/nonconformities/get.php"
  })
  return {
    type: "GET_NON_CONFORMITIES",
    payload: REQUEST
  }
}

export const assignNonConformityAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "nonconformities/assign.php",
    data: body,
    name: "Asignación de No Conformidad"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "ASSIGN_NON_CONFORMITY",
    payload: body
  }
}

export const approveNonConformityAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "nonconformities/approve.php",
    data: body,
    name: "Aprobación de No Conformidad"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "APPROVE_NON_CONFORMITY",
    payload: body
  }
}

export const dismissNonConformityAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "nonconformities/dismiss.php",
    data: body,
    name: "Desestimación de No Conformidad"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "DISMISS_NON_CONFORMITY",
    payload: body
  }
}

export const solveNonConformityAction = (body, formData) => {
  const requestBody = {
    method: 'post',
    url: "nonconformities/solve.php",
    data: formData,
    name: "Resolución de No Conformidad"
  }
  const fileBody = {
    method: 'post',
    url: "nonconformities/solve.php",
    data: body,
    name: "Resolución de No Conformidad"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(fileBody)
  }

  return {
    type: "SOLVE_NON_CONFORMITY",
    payload: body
  }
}

export const selectNonConformityAction = (id) => {
  return {
    type: "SELECT_NON_CONFORMITY",
    payload: id
  }
}

export const createNonConformityAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: "nonconformities/create.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_NON_CONFORMITY",
    payload: REQUEST
  }
}