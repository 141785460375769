import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"

const event = new Event("offlineRequest")

export const getPendingChecklistsAction = (body) => {
    // params = companyId*, id, status, branch_id*
    const REQUEST = instance({
        method: 'get',
        url: 'checklists/get.php',
        params: { ...body, status: "pending" }
    })
    return {
        type: 'GET_PENDING_CHECKLISTS',
        payload: REQUEST
    }
}

export const getDoneChecklistsAction = (body) => {
    // params = companyId*, id, status, branch_id*
    const REQUEST = instance({
        method: 'get',
        url: 'checklists/get.php',
        params: { ...body, status: "done" }
    })
    return {
        type: 'GET_DONE_CHECKLISTS',
        payload: REQUEST
    }
}

export const getChecklistAction = body => {
    return {
        type: "GET_CHECKLIST",
        payload: body
    }
}

export const answerCheckElementAction = body => {
    // "companyId": 4, "branch_id": 12, "checklist_id": 129, "checklist_item_id": 150, "answer": 1
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/answer.php',
            data: body,
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/answer.php',
            data: body,
            name: "Respuesta lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "ANSWER_CHECKLIST",
        payload: body
    }
}

export const criticCheckElementAction = body => {
    //"companyId": 4, "branch_id": 12, "checklist_id": 129, "checklist_item_id": 150, "criticality": 1
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/criticality.php',
            data: body
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/criticality.php',
            data: body,
            name: "Criticidad lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "CRITICITY_CHECKLIST",
        payload: body
    }
}

export const commentCheckElementAction = body => {
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/comments.php',
            data: body
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/comments.php',
            data: body,
            name: "Comentario lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "COMMENT_CHECKLIST",
        payload: body
    }
}

export const counterCheckElementAction = body => {
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/counter.php',
            data: body
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/counter.php',
            data: body,
            name: "Contador en lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "COUNTER_CHECKLIST",
        payload: body
    }
}

export const fileForCheckElementAction = (data, body) => {
    // On de body goes the file element
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/file.php',
            data: data
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/file.php',
            data: body,
            name: "Foto lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "IMAGE_CHECKLIST",
        payload: body
    }
}

export const file2ForCheckElementAction = (data, body) => {
    // On de body goes the file element
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/file2.php',
            data: data
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/file2.php',
            data: body,
            name: "Foto lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "IMAGE_CHECKLIST_2",
        payload: body
    }
}

export const file3ForCheckElementAction = (data, body) => {
    // On de body goes the file element
    if (body.online) {
        instance({
            method: 'post',
            url: 'checklists/file3.php',
            data: data
        })
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/file3.php',
            data: body,
            name: "Foto lista de chequeo"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "IMAGE_CHECKLIST_3",
        payload: body
    }
}

export const saveChecklistAction = body => {
    if (body.online) {
        const REQUEST = instance({
            method: 'post',
            url: 'checklists/finish.php',
            data: body
        })
        createSnackbar(REQUEST)
    } else {
        const requestToBeSaved = {
            method: 'post',
            url: 'checklists/finish.php',
            data: body,
            name: "Lista de chequeo terminada"
        }
        let savedRequests = localStorage.getItem("SavedRequests")
        if (savedRequests) {
            const saved = JSON.parse(savedRequests)
            saved.push(requestToBeSaved)
            localStorage.setItem("SavedRequests", JSON.stringify(saved))
        } else {
            savedRequests = [requestToBeSaved]
            localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
        }
        window.dispatchEvent(event)
    }
    return {
        type: "SAVE_CHECKLIST",
        payload: body
    }
}

export const createChecklistAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'checklists/create.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_CHECKLIST",
        payload: REQUEST
    }
}

export const editChecklistAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'checklists/edit.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "EDIT_SNACKBAR",
        payload: REQUEST
    }
}

export const deleteChecklistAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'checklists/delete.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "DELETE_CHECKLIST",
        payload: body.id
    }
}