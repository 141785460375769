export const cardStyle = theme => ({
  container: {
    maxWidth: 350,
    width: 350,
    "@media (max-width:500px)": {
      width: '100%',
      maxWidth: 'unset'
    },
    position: 'relative'
  },
  title: {
    padding: 12
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    paddingTop: 0
  },
  titleState: {
    padding: "6px 12px",
    background: theme.palette.red.main,
    borderRadius: 24,
    "& > p": {
      color: "white"
    }
  },
  titleText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  resume: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
    padding: 12
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    margin: '6px 0',
    '& > p': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  statsContainer: {
    maxWidth: "75%"
  },
  actionContainer: {
    transition: 'all 0.2s linear',
    maxHeight: 45,
    overflow: 'hidden',
    '&.open': {
      maxHeight: 500
    }
  },
  actionButton: {
    padding: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 12,
    '&.open': {
      background: theme.palette.primary.main,
      color: 'white'
    }
  },
  arrowIcon: {
    transition: 'all 0.2s ease-in-out',
    '&.open': {
      transform: 'rotate(180deg)'
    }
  },
  buttonText: {
    transition: 'all 0.2s ease-in-out',
    color: theme.palette.primary.main,
    '&.open': {
      transform: 'translateX(-150%)'
    }
  },
  buttonHiddenText: {
    position: "absolute",
    color: "white",
    transition: 'all 0.2s ease-in-out',
    '&.open': {
      transform: 'translateX(-150%)',
      zIndex: -1000,
      opacity: 0
    }
  },
  form: {
    padding: 12
  },
  finalButton: {
    textAlign: 'end'
  },
  hoverMenu: {
    position: 'absolute',
    top: 12,
    left: 12,
    padding: "7px 12px",
    display: 'flex',
    alignItems: 'center',
    '&>h6': {
      marginRight: 6
    }
  },
  tooltip: {
    color: "white"
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      margin: "0 6px"
    }
  },
  mainText: {
    display: "-webkit-box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden",
    height: 50,
    marginBottom: 6
  },
  titleStateFinished: {
    padding: "6px 12px",
    background: theme.palette.green.main,
    borderRadius: 24,
    minWidth: '25%',
    textAlign: 'center',
    "& > p": {
      color: "white"
    }
  },
  action: {
    padding: 12
  },
  evidence: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 6
  },
  resolveContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
    padding: 12
  },
  finalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    padding: 12
  },
  label: {
    gap: '12px'
  },
  greenButton: {
    background: theme.palette.green.main
  },
  titleStateAsigned: {
    padding: "6px 12px",
    background: theme.palette.blue.main,
    borderRadius: 24,
    minWidth: '25%',
    textAlign: 'center',
    "& > p": {
      color: "white"
    }
  },
  titleStateOverdue: {
    padding: "6px 12px",
    background: theme.palette.red.main,
    borderRadius: 24,
    minWidth: '25%',
    textAlign: 'center',
    "& > p": {
      color: "white"
    }
  },
  menu: {
    padding: 12
  },
})