import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from "chart.js"
import moment from 'moment'

const style = () => ({

})

class CriticalityVsStatus extends Component {
  componentDidMount() {
    const { idName } = this.props
    const [labels, data] = this.processData()
    const chartElement = document.getElementById(idName)
    const myChartRef = chartElement.getContext("2d")
    chartElement.parentNode.style.height = "100%"
    chartElement.parentNode.style.width = "100%"

    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: "#ADF5FF8a",
            borderColor: '#ADF5FF',
            pointBackgroundColor: '#7373e8',
            pointBorderColor: '#7373e8'
          },
        ],
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              fontFamily: "'Poppins', sans-serif",
            },
            display: false
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            display: false
          }]
        },
        legend: {
          display: false
        }
      }
    })
  }

  processData() {
    const { findings } = this.props
    const dates = []
    for (let i = 0; i <= 14; i++) {
      const date = moment(new Date()).subtract(i, "days")
      dates.push(date)
    }
    const processedDates = dates.reverse()

    const data = processedDates.map(date => {
      return findings.filter(finding => moment(finding.date_detected) <= date).length - findings.filter(finding => moment(finding.date_solved) <= date).length
    })

    console.log(data)

    return [processedDates.map(date => moment(date).format("YYYY-MM-DD")), data]
  }

  render() {
    const { classes, idName } = this.props
    return (
      <div className={classes.container}>
        <div>
          <canvas id={idName} ref={this.chartRef} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(CriticalityVsStatus)