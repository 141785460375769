import React, { Component } from 'react'
import { Button, InputLabel, withStyles } from '@material-ui/core'
import ImageContainer from '../ImageContainer'
import { AddAPhoto } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { resizeImageAndGetFile } from '../../Utils/functions'

const style = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 74,
    width: '100%'
  },
  button: {
    padding: "8px 16px"
  },
  hidden: {
    display: 'none'
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 12,
    background: 'white',
    padding: 6
  },
  input: {
    border: "1px solid lightgrey",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    width: '100%',
    borderRadius: 5
  }
})

function simulateClick(id) {
  return () => {
    document.getElementById(id).click()
  }
}

class ImgInput extends Component {
  constructor() {
    super()
    this.state = {
      selected: ""
    }
    autobind(ImgInput, this)
  }

  handleSelectFile(event) {
    const { onChange, name } = this.props
    const { target } = event
    const file = target.files[0]

    const callback = (image, file) => {
      const newEvent = {
        target: { name: name, value: file }
      }
      onChange(newEvent)
      this.setState({ selected: image })
    }

    resizeImageAndGetFile(file, callback)
  }

  render() {
    const { label, name, disabled, classes, required } = this.props
    const { selected } = this.state
    return (
      <div className={classes.container}>
        <input type="file" id={name} className={classes.hidden} onChange={this.handleSelectFile} />
        <InputLabel shrink className={classes.label}>
          {`${label}${required ? "*" : ""}`}
        </InputLabel>
        <div className={classes.input}>
          <ImageContainer src={selected ? selected : "/noimage.png"} width="32px" height="32px" />
          <Button variant="outlined" color="primary" className={classes.button} onClick={simulateClick(name)} disabled={disabled}>
            <AddAPhoto />
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ImgInput)