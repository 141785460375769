import React, { Component } from "react"
import {
    FormControl,
    InputLabel,
    Typography,
    Divider,
    TextField,
    ListItem,
    InputAdornment,
    Dialog,
    DialogContent,
    ListItemIcon,
    ListItemText,
    Checkbox,
    IconButton,
} from "@material-ui/core"
import { KeyboardArrowDown, Close } from "@material-ui/icons"
import autobind from "../../Utils/autobind"

export default class MultiSelectInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDialog: false,
        }
        autobind(MultiSelectInput, this)
    }

    handleOpenDialog() {
        this.setState({ openDialog: !this.state.openDialog })
    }

    renderMenu() {
        const { options, onChange, name, value, label } = this.props
        const sValue = value ? value : []
        const menu = options.map((option) => {
            const returnValue = [...sValue]
            if (sValue.includes(option.value)) {
                returnValue.splice(returnValue.indexOf(option.value), 1)
            } else {
                returnValue.push(option.value)
            }
            const event = {
                target: {
                    name: name,
                    value: returnValue,
                },
            }
            return (
                <ListItem button onClick={() => onChange(event)} key={option.value}>
                    <ListItemIcon>
                        <Checkbox
                            checked={sValue.includes(option.value)}
                            color="primary"
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2">
                                {option.label}
                            </Typography>
                        }
                    />
                </ListItem>
            )
        })
        const selectedOptions = options.filter((option) =>
            sValue.includes(option.value)
        )
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 12,
                    }}
                >
                    <Typography variant="h1">
                        {label} seleccionados/as:
                    </Typography>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={this.handleOpenDialog}
                    >
                        <Close />
                    </IconButton>
                </div>
                <div style={{ maxHeight: 250, overflow: "auto" }}>
                    {selectedOptions.map((option) => (
                        <Typography variant="subtitle2" style={{ padding: 6 }} key={option.label}>
                            {option.label}
                        </Typography>
                    ))}
                </div>
                <Divider style={{ margin: "12px 0" }} />
                <Typography variant="subtitle1">Opciones:</Typography>
                <Divider style={{ margin: "12px 0" }} />
                <div style={{ maxHeight: 250, overflow: "auto" }}>{menu}</div>
            </>
        )
    }

    transformValues(values) {
        const { options } = this.props
        const selectedOptions = options.filter((option) =>
            values.includes(option.value)
        )
        const names = selectedOptions.map((option) => `${option.label}, `)
        return names
    }

    render() {
        const { value, onChange, label, name, required } = this.props
        const sValue = value ? value : []
        const { openDialog } = this.state
        return (
            <>
                <FormControl fullWidth style={{ margin: "12px 0" }}>
                    <TextField
                        value={this.transformValues(sValue) || []}
                        fullWidth
                        name={name}
                        onChange={onChange}
                        variant="outlined"
                        multiple
                        disabled
                        onClick={this.handleOpenDialog}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <KeyboardArrowDown />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
                        <DialogContent>{this.renderMenu()}</DialogContent>
                    </Dialog>
                    <InputLabel shrink variant="outlined">
                        {`${label}${required ? "*" : ""}`}
                    </InputLabel>
                </FormControl>
            </>
        )
    }
}

MultiSelectInput.propTypes = {}

MultiSelectInput.defaultProps = {
    options: [],
}
