import React, { Component } from 'react'
import { Collapse, Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, Edit } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import NewPhaseDialog from './Steps/NewPhaseDialog'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { withRouter } from 'react-router'
import PhaseRenderer from './Steps/Phases/PhaseRenderer'
import ArrowButton from '../../../Shared/ArrowButton'
import EditPhaseDialog from './Steps/EditPhaseDialog'
import { transformToOptions } from '../../../Utils/functions'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'

const style = () => ({
  container: {
    background: 'white',
    borderRadius: 15,
    padding: 12,
    minHeight: 300,
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  buttons: {
    position: 'absolute',
    bottom: 6,
    right: 12
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phase: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  phases: {
    marginBottom: 48
  },
  dialog: {
    padding: 12
  },
  separator: {
    margin: '6px 0'
  },
  title: {
    margin: '12px 0'
  },
})

class Stage extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      openNewPhase: false,
      name: "",
      auth_ids: [],
      responsible_ids: [],
      openDialog: false,
      openEditPhase: false,
      phase: null,
      index: -1
    }
    autobind(Stage, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleOpenNewPhase() {
    const { openNewPhase } = this.state
    this.setState({ openNewPhase: !openNewPhase })
  }

  handleEditPhase(phase, index) {
    return () => {
      this.setState({ openEditPhase: !this.state.openEditPhase, phase, index })
    }
  }

  renderPhases() {
    const { open } = this.state
    const { classes, stage } = this.props
    const { data } = stage
    const phases = data.phases || []
    let index = -1
    const alwaysShowPhases = phases.slice(0, 4).map((phase) => {
      index += 1
      return (
        <div>
          {index > 0 && <Divider className={classes.separator} />}
          <div className={classes.inline}>
            <PhaseRenderer phase={phase} />
            <IconButton onClick={this.handleEditPhase(phase, index)}>
              <Edit />
            </IconButton>
          </div>
        </div>
      )
    })
    const collapsePhases = phases.slice(4).map((phase) => {
      index += 1
      return (
        <div>
          <Divider className={classes.separator} />
          <div className={classes.inline}>
            <PhaseRenderer phase={phase} />
            <IconButton onClick={this.handleEditPhase(phase, index)}>
              <Edit />
            </IconButton>
          </div>
        </div>
      )
    })
    return (
      <>
        {alwaysShowPhases}
        <Collapse in={open}>
          {collapsePhases}
        </Collapse>
        {collapsePhases.length > 0 && <ArrowButton open={open} onClick={this.handleOpen} />}
      </>
    )
  }

  handleChangeName(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleOpenDialog() {
    this.setState({ openDialog: !this.state.openDialog })
  }

  handleOpenEdit() {
    const { stage } = this.props
    const { openDialog } = this.state
    this.setState({
      name: stage?.data?.name,
      responsible_ids: stage?.data?.responsible_ids || [],
      auth_ids: stage?.data?.auth_ids || [],
      openDialog: !openDialog
    })
  }

  handleEdit() {
    const { name, auth_ids, responsible_ids } = this.state
    const { stage, match, editStage } = this.props
    const newData = { ...stage.data }
    newData.name = name
    newData.responsible_ids = responsible_ids
    newData.auth_ids = auth_ids
    const body = {
      id: stage.id,
      workflow_id: match.params.id,
      step_data: newData
    }
    editStage(body)
    this.setState({ name: null, openDialog: false })
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => {
      return (
        <div>
          <Typography variant="subtitle1">* {allWorkers.find(w => w.id === id)?.name || null}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes, stage, editStage, workers, branch } = this.props
    const { openNewPhase, name, openDialog, openEditPhase, phase, index, responsible_ids, auth_ids } = this.state
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
    // Switch for phase entity selected
    const options = transformToOptions(filteredWorkers)
    return (
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title} >{`Etapa ${stage?.data?.name || "Sin nombre"}`}</Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }} >Responsables: {this.getUsersNames(stage?.data?.responsible_ids || [])}</Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }} >Autorizan: {this.getUsersNames(stage?.data?.auth_ids || [])}</Typography>
        <Divider />
        <div className={classes.inline}>
          <Typography variant="subtitle1">Fases:</Typography>
          <IconButton onClick={this.handleOpenNewPhase}>
            <AddCircleOutline />
          </IconButton>
        </div>
        <NewPhaseDialog onSubmit={editStage} open={openNewPhase} onClose={this.handleOpenNewPhase} stage={stage} />
        <EditPhaseDialog onSubmit={editStage} open={openEditPhase} onClose={this.handleEditPhase(null, null)} stage={stage} phase={phase} index={index} />
        <div className={classes.phases}>
          {this.renderPhases()}
        </div>
        <div className={classes.buttons}>
          <IconButton onClick={this.handleOpenEdit}>
            <Edit />
          </IconButton>
        </div>
        <Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="subtitle1">Editar Etapa</Typography>
            <TextInput label="Nuevo nombre" name={"name"} value={name} onChange={this.handleChangeName}></TextInput>
            <MultiSelectInput label="Responsables (opcional)" name={"responsible_ids"} value={responsible_ids} onChange={this.handleChangeName} options={options} />
            <MultiSelectInput label="Autorizan (opcional)" name={"auth_ids"} value={auth_ids} onChange={this.handleChangeName} options={options} />
            <SubmitButton onClick={this.handleEdit}>
              Guardar
            </SubmitButton>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(withStyles(style)(Stage))