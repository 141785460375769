export const userInfo = [
  {
    name: "userTypeId",
    label: "Tipo de usuario",
    type: "select",
    options: [
      { value: "1", label: "Admin" },
      { value: "2", label: "Supervisor" },
      { value: "3", label: "Colaborador" },
    ],
  },
  { name: "email", label: "Correo" },
  { name: "firstname", label: "Nombre" },
  { name: "lastname", label: "Apellido" },
  { name: "rut", label: "RUT" },
  { name: "phone", label: "Teléfono" },
  { name: "address", label: "Dirección" },
  { name: "dateBirth", label: "Fecha de Nacimiento", type: "date" },
  { name: "position", label: "Cargo" },
  // {
  //   name: "branch_id",
  //   label: "Sucursal",
  //   type: "select",
  //   options: [],
  //   placeholder: "Seleccionar Sucursal",
  // },
  // {
  //   name: "user_id",
  //   label: "Supervisor",
  //   type: "select",
  //   options: [],
  //   placeholder: "Seleccionar Supervisor",
  // },
]