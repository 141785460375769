import React, { Component } from 'react'
import { Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import { withRouter } from 'react-router'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'

const style = () => ({
  container: {
    margin: '24px 0'
  },
  process: {
    padding: 12,
    background: 'white',
    position: 'relative',
    borderRadius: 15,
    margin: '12px 0',
    maxWidth: 500,
    display: 'flex',
    minHeight: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child > *:first-child': {
      fontWeight: 600
    }
  },
  invprocess: {
    background: 'white',
    position: 'relative',
    borderRadius: 15,
    margin: '12px 0',
    maxWidth: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'lightgrey',
    height: 48,
    width: 48,
    cursor: 'pointer',
    transition: 'all 0.2s linear'
  }
})

class Accidents extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      open: false,
    }
    autobind(Accidents, this)
  }

  handleOpenProcess(process) {
    return () => {
      const { history } = this.props
      history.push(`/settings/processes/${process}`)
    }
  }

  handleCreate() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ name: target.value })
  }

  handleSubmit() {
    const { name } = this.state
    const { onCreate } = this.props
    const body = {
      name,
      workflow_type_id: 1
    }
    onCreate(body)
    this.setState({ name: "", open: false })
  }

  renderProcesses() {
    const { classes, workflows } = this.props
    return workflows.map(process => (
      <>
        <div className={classes.process}>
          <div>
            <Typography variant="subtitle1">{`Proceso ${process.name}`}</Typography>
            <Typography variant="caption">Etapas: {process.workflow_steps.length}</Typography>
          </div>
          <IconButton onClick={this.handleOpenProcess(process.id)}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </>
    ))
  }
  render() {
    const { classes } = this.props
    const { open, name } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h2">Procesos de Accidentes</Typography>
        <div className={classes.process}>
          <AddCircleOutline
            className={classes.center}
            style={{ left: open ? "75%" : "50%", transform: open ? "translate(-50%, -50%) rotate(45deg)" : "translate(-50%, -50%) rotate(0deg)" }}
            onClick={this.handleCreate} />
          <Collapse in={open}>
            <TextInput label="Nombre Proceso" value={name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleSubmit}>
              Crear
            </SubmitButton>
          </Collapse>
        </div>
        {this.renderProcesses()}
      </div>
    )
  }
}

export default withRouter(withStyles(style)(Accidents))