import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import Home from "../Components/Home/Home"
import EnterpriseInfo from "../Components/Enterprise/EnterpriseInfo"
import Branch from "../Components/Branch/Branch"
import Workers from "../Components/Workers/Workers"
import Worker from "../Components/Workers/Worker"
import Activities from "../Components/Activities/Activities"
import Activity from "../Components/Activities/Activity"
import Findings from "../Components/Findings/Findings"
import Talks from "../Components/Talks/Talks"
import Revisions from "../Components/Quality/Revisions"
import IPER from "../Components/IPER/IPER"
import Calendar from "../Components/Calendar/Calendar"
import Checklists from "../Components/Checklists/Checklists"
import CheckList from "../Components/Checklists/CheckList"
import Talk from "../Components/Talks/Talk"
import Finding from "../Components/Findings/Finding"
import NonConformity from "../Components/Unconformities/NonConformity"
import Revision from "../Components/Quality/Revision"
import ChecklistSettings from "../Components/Settings/Checklists/ChecklistSettings"
import ActivitySettings from "../Components/Settings/Activities/ActivitySettings"
import TalkSettings from "../Components/Settings/Talks/TalkSettings"
import TrainingSettings from "../Components/Settings/Trainings/TrainingSettings"
import ChecklistsResume from "../Components/Home/Intermediate/ChecklistsResume"
import FindingsResume from "../Components/Home/Intermediate/FindingsResume"
import Reports from "../Components/Reports/Findings/Reports"
import SingleChecklist from "../Components/Settings/Checklists/SingleChecklist"
import Trainings from "../Components/Trainings/Trainings"
import Training from "../Components/Trainings/Training"
import NonConformities from "../Components/Unconformities/NonConformities"
import UserDocuments from "../Components/Settings/UserDocuments/UserDocuments"
import EnterpriseDocuments from "../Components/Settings/EnterpriseDocuments/EnterpriseDocuments"
import CompanyDocument from "../Components/Enterprise/CompanyDocument"
import UserDocumentsResume from "../Components/Home/Intermediate/UserDocumentsResume"
import NonConformitiesResume from "../Components/Home/Intermediate/NonConformitiesResume"
import ActivityReport from "../Components/Reports/Activities/ActivityReport"
import SubBranchDocuments from "../Components/Settings/SubBranchDocuments/SubBranchDocuments"
import Branches from "../Components/Enterprise/Branches"
import SubBranch from "../Components/Branch/SubBranch"
import SubBranches from "../Components/Settings/SubBranches/SubBranches"
import PublicChecklist from "../Components/Checklists/PublicChecklist/PublicChecklist"
import CalendarTabs from "../Components/Calendar/CalendarTabs"
import Processes from "../Components/Settings/Processes/Processes"
import Process from "../Components/Settings/Processes/Process"
import Workflows from "../Components/Workflows/Workflows"
import Workflow from "../Components/Workflows/Workflow"

export default class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/panel" exact component={Home} />
                <Route path="/panel/checklists/:option" exact component={ChecklistsResume} />
                <Route path="/panel/findings/:option" exact component={FindingsResume} />
                <Route path="/panel/unconformities/:option" exact component={NonConformitiesResume} />
                <Route path="/panel/user/documents/:id" exact component={UserDocumentsResume} />
                <Route path="/enterprise" exact component={EnterpriseInfo} />
                <Route path="/enterprise/documents" exact component={CompanyDocument} />
                <Route path="/branches" exact component={Branches} />
                <Route path="/branches/:id" exact component={Branch} />
                <Route path="/branches/:id/sub_branches/:sub_branch_id" exact component={SubBranch} />
                <Route path="/workers" exact component={Workers} />
                <Route path="/workers/:id" exact component={Worker} />
                <Route path="/activities/" exact component={Activities} />
                <Route path="/activities/:id" exact component={Activity} />
                <Route path="/findings/" exact component={Findings} />
                <Route path="/findings/:id" exact component={Finding} />
                <Route path="/talks/" exact component={Talks} />
                <Route path="/talks/:id" exact component={Talk} />
                <Route path="/trainings/" exact component={Trainings} />
                <Route path="/trainings/:id" exact component={Training} />
                <Route
                    path="/unconformities/"
                    exact
                    component={NonConformities}
                />
                <Route
                    path="/unconformities/:id"
                    exact
                    component={NonConformity}
                />
                <Route path="/revisions/" exact component={Revisions} />
                <Route path="/revisions/:id" exact component={Revision} />
                <Route path="/iper/" exact component={IPER} />
                <Route path="/calendar/" exact component={CalendarTabs} />
                <Route path="/checklists/" exact component={Checklists} />
                <Route path="/checklists/:id" exact component={CheckList} />
                <Route path="/settings/checklists" exact component={ChecklistSettings} />
                <Route path="/settings/checklists/:id" exact component={SingleChecklist} />
                <Route path="/settings/activities" exact component={ActivitySettings} />
                <Route path="/settings/talks" exact component={TalkSettings} />
                <Route path="/settings/trainings" exact component={TrainingSettings} />
                <Route path="/settings/users/documents" exact component={UserDocuments} />
                <Route path="/settings/enterprise/documents" exact component={EnterpriseDocuments} />
                <Route path="/settings/sub_branch/documents" exact component={SubBranchDocuments} />
                <Route path="/settings/sub_branches" exact component={SubBranches} />
                <Route path="/settings/processes" exact component={Processes} />
                <Route path="/settings/processes/:id" exact component={Process} />
                <Route path="/workflows" exact component={Workflows} />
                <Route path="/workflows/:id" exact component={Workflow} />

                <Route path="/reports/findings" exact component={Reports} />
                <Route path="/reports/activities" exact component={ActivityReport} />
                <Route path="/public/:token" exact component={PublicChecklist} />
            </Switch>
        )
    }
}
