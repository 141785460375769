import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import TextInput from '../../../../../Shared/Inputs/TextInput'

const style = () => ({

})
class CheckStepCreate extends Component {

  render() {
    const { classes, params, onChange } = this.props
    return (
      <div className={classes.container}>
        <TextInput label="Acción a confirmar" name="label" value={params.label} onChange={onChange} />
      </div>
    )
  }
}

export default withStyles(style)(CheckStepCreate)