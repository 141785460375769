import React, { Component } from 'react'
import { Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import Stage from './Stage'
import { createSettingsWorkflowAction, createWorkflowStageAction, editSettingsWorkflowAction, editWorkflowStageAction, getSettingsWorkflowsAction } from '../../../Actions/WorkFlows'
import { connect } from 'react-redux'
import { AddCircleOutline, Edit } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { getWorkersAction } from '../../../Actions/EnterpriseAction'

const style = () => ({
  container: {
    margin: 12,
  },
  card: {
    padding: 12,
    borderRadius: 15,
    background: 'white',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stage: {
    padding: 24,
    maxWidth: 400,
    width: '100%',
    "@media (max-width:500px)": {
      padding: '24px 0'
    }
  },
  stages: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  newStages: {
    padding: 24,
    maxWidth: 400,
    width: '100%',
    "@media (max-width:500px)": {
      padding: '24px 0'
    }
  },
  addCard: {
    background: '#ffffffaa',
    height: 300,
    borderRadius: 15,
    position: 'relative',
    cursor: 'pointer',
    '& > *': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 64,
      width: 64,
      color: 'lightgrey'
    }
  }
})

class Process extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      edit: false
    }
    autobind(Process, this)
  }


  componentDidMount() {
    const { getSettingsWorkflows, getWorkers } = this.props
    getSettingsWorkflows()
    getWorkers()
  }

  handleCreateStep() {
    const { createWorkflowStage, match } = this.props
    const body = {
      workflow_id: match.params.id,
      step_data: {
        name: "Nueva etapa"
      }
    }
    createWorkflowStage(body)
  }

  handleEdit() {
    const { settings, match } = this.props
    const allWorkflows = settings.workflows.all || []
    const selectedWF = allWorkflows.find(w => w.id === match.params.id) || {}
    this.setState({ edit: !this.state.edit, name: selectedWF.name })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ name: target.value })
  }

  handleSubmit() {
    const { name } = this.state
    const { editSettingsWorkflow, match } = this.props
    const body = {
      id: match.params.id,
      workflow_type_id: 1,
      name
    }
    editSettingsWorkflow(body)
    this.setState({ name: "", edit: false })
  }

  renderStages() {
    const { classes, settings, match, editWorkflowStage, workers, branch } = this.props
    const allWorkflows = settings.workflows.all || []
    const selectedWF = allWorkflows.find(w => w.id === match.params.id) || {}
    const states = selectedWF.workflow_steps || []
    return states.map(stage => (
      <div className={classes.stage}>
        <Stage branch={branch} workers={workers} stage={stage} editStage={editWorkflowStage} />
      </div>
    ))
  }

  render() {
    const { classes, settings, match } = this.props
    const { name, edit } = this.state
    const allWorkflows = settings.workflows.all || []
    const selectedWF = allWorkflows.find(w => w.id === match.params.id) || {}
    return (
      <div className={classes.container}>
        <div className={classes.card}>
          <div className={classes.inline}>
            <Typography variant="h1">{selectedWF.name}</Typography>
            <IconButton onClick={this.handleEdit}>
              <Edit />
            </IconButton>
          </div>
          <Collapse in={edit}>
            <TextInput label="Nombre de proceso" value={name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleSubmit}>
              Guardar
            </SubmitButton>
          </Collapse>
        </div>
        <div className={classes.stages}>
          {this.renderStages()}
          <div className={classes.newStages}>
            <div className={classes.addCard} onClick={this.handleCreateStep}>
              <AddCircleOutline />
            </div>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  workers: state.workers,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  createSettingsWorkflow: body => dispatch(createSettingsWorkflowAction(body)),
  editSettingsWorkflow: body => dispatch(editSettingsWorkflowAction(body)),
  createWorkflowStage: body => dispatch(createWorkflowStageAction(body)),
  editWorkflowStage: body => dispatch(editWorkflowStageAction(body)),
  getWorkers: () => dispatch(getWorkersAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Process))