import { createStore, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import ReduxPromise from "redux-promise";
import ReduxLogger from "redux-logger";
import reducers from "./Reducers/Reducers";
import hardSet from "redux-persist/es/stateReconciler/hardSet";

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
};

const reducersCombined = reducers;
const pReducer = persistReducer(persistConfig, reducersCombined);
const store = createStore(pReducer, applyMiddleware(ReduxPromise, ReduxLogger));

export default store;
