import React, { Component } from "react"
import TextInput from "./TextInput"
import DateInput from "./DateInput"
import SelectInput from "./SelectInput"
import MultiSelectInput from "./MultiSelectInput"
import FileInput from "./FileInput"
import ImgInput from "./ImgInput"

export default class FieldRenrerer extends Component {
    selectInput() {
        const { input, onChange, value } = this.props
        switch (input.type) {
            case "text": {
                return (
                    <TextInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
            }
            case "file": {
                return (
                    <FileInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
            }
            case "date": {
                return (
                    <DateInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                    />
                )
            }
            case "select": {
                return (
                    <SelectInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "multiselect": {
                return (
                    <MultiSelectInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "img": {
                return (
                    <ImgInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            default:
                return (
                    <TextInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
        }
    }
    render() {
        return <>{this.selectInput()}</>
    }
}
