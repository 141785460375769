import React, { Component } from "react"
import {
    withStyles,
    Paper,
    Typography,
    Button,
    Fab,
    IconButton,
    Collapse,
} from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import autobind from "../../Utils/autobind"
import { ArrowUpward, ErrorOutline, GetApp } from "@material-ui/icons"
import CheckListElement from "./CheckListElement"
import { getChecklistAction, counterCheckElementAction, answerCheckElementAction, criticCheckElementAction, fileForCheckElementAction, saveChecklistAction, editChecklistAction, commentCheckElementAction, file2ForCheckElementAction, file3ForCheckElementAction } from "../../Actions/CheckListActions"
import { connect } from "react-redux"
import TextInput from "../../Shared/Inputs/TextInput"
import LoaderComponent from "../../Shared/LoaderComponent"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { resizeImageAndGetFile, transformToOptions } from "../../Utils/functions"
import { callSnackbar } from "../../Utils/snackbar"
import SelectInput from "../../Shared/Inputs/SelectInput"
import ImgInput from "../../Shared/Inputs/ImgInput"
import instance from "../../Utils/instance"


const style = (theme) => ({
    container: {
        margin: 12,
        paddingBottom: 48
    },
    title: {
        padding: 12,
    },
    paper: {
        marginTop: 24,
        padding: 12,
    },
    inline: {
        display: "flex",
        gap: "12px",
        alignItems: "flex-end",
        margin: "3px 0",
    },
    inlineContainer: {
        margin: "12px 0",
    },
    endButton: {
        textAlign: 'end'
    },
    upFab: {
        position: 'fixed',
        bottom: 12,
        right: -64,
        transition: 'all 0.2s ease-in-out'
    },
    observations: {
        marginTop: 24
    },
    progressLabel: {
        position: "sticky",
        top: "78px",
        zIndex: "5",
        width: 200,
    },
    label: {
        background: theme.palette.primary.main,
        maxWidth: "150px",
        borderTopRightRadius: "24px",
        borderBottomRightRadius: "24px",
        opacity: 0.7,
        margin: '3px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 12,
        '&>h6': {
            color: 'white',
            padding: 6
        }
    },
    inlineSep: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    responsible: {
        background: theme.palette.green.main,
        padding: '6px 12px',
        borderRadius: 5,
        float: 'right',
        '& > *': {
            color: 'white'
        }
    },
    extraTitle: {
        margin: "12px 0"
    },
    fields: {

    },
    extraField: {
        marginBottom: 24,
        "@media (max-width:500px)": {
            maxWidth: "unset",
            margin: 0,
            marginBottom: 24,
            width: '100%'
        }
    },
    divisor: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& > *': {
            flexBasis: 'calc(50% - 36px)',
        }
    },
    fieldsContainer: {
        padding: 24,
        background: 'white',
        borderRadius: 15,
        margin: '24px 0'
    }
})

// Create styles
// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: 'white'
//     },
//     section: {
//         margin: 24,
//         padding: 24,
//         flexGrow: 1
//     },
//     view: {
//         flex: 1,
//         flexDirection: 'row',
//         justifyContent: 'space-between'
//     },
//     title: {
//         marginBottom: 32
//     },
//     subtitle: {
//         fontSize: 13,
//         marginLeft: 12
//     }
// })

// Create Document Component

// function renderAnswerList(answers = []) {
//     return answers.map((element, index) => (
//         <View style={styles.view} key={index}>

//             <Text style={styles.subtitle}>{element.name}</Text>

//             <Text style={styles.subtitle}>{element.answer.value}</Text>

//         </View>
//     ))
// }

// const MyDocument = ({ element, answers }) => (
//     <Document>
//         <Page size="A4" style={styles.page}>
//             <View style={styles.section}>
//                 <Text style={styles.title}>Reporte Lista de Chequeo {element.activity}</Text>
//                 {renderAnswerList(answers)}
//             </View>
//         </Page>
//     </Document>
// )

function renderMessages(options, value) {
    return options.map(option => {
        const [expected, message, color] = option.split('&')
        return (
            <Collapse in={expected === value && message}>
                <Typography style={{ color }} variant="subtitle1">{message}</Typography>
            </Collapse>
        )
    })
}

function getTotalCounters(checklist) {
    function getFactor(item) {
        const crit = item?.answer?.criticality
        if (crit === 4) return 2
        if (crit === 3) return 1.5
        return 1
    }
    const items = checklist.checklist_items
    const filtered = items
        .filter(item => item.counter === 1 && item.answer.counter !== null)
    if (filtered.length === 0) return 0
    const total = filtered
        .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
    return total.answer.counter
}

class CheckList extends Component {
    constructor() {
        super()
        this.state = {
            elements: [],
            openHelp: false,
            observations: "",
            loading: true,
            answers: [],
            extra: {}
        }

        this.oldValue = 0
        this.timer = null

        autobind(CheckList, this)
    }

    handleScroll(e) {
        // Get the new Value
        const newValue = window.pageYOffset
        const element = document.getElementById("fab-container")
        //Subtract the two and conclude
        if (this.oldValue - newValue < 0) {
            element.style.right = "12px"
        } else if (this.oldValue - newValue > 0) {
            element.style.right = "-64px"
        }

        // Update the old value
        this.oldValue = newValue
        clearTimeout(this.timer)
        this.timer = setTimeout(() => element.style.right = "-64px", 2000)
    }

    componentDidMount() {
        const { getChecklist, match, checklists } = this.props
        const body = {
            id: match.params.id,
        }
        getChecklist(body)
        const oldObservations = checklists?.selected?.observations
        const oldExtraFields = checklists?.selected?.extra_fields ? [...checklists?.selected?.extra_fields] : []
        const newExtra = {}
        oldExtraFields.forEach((value, index) => newExtra[index] = value.value)
        this.setState({ observations: oldObservations, loading: false, answers: checklists?.selected?.checklist_items, extra: newExtra })
        window.addEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate(prevProps) {
        const { checklists } = this.props
        const selected = checklists.selected
        if (prevProps?.checklists?.selected?.id !== selected.id) {
            const oldObservations = checklists?.selected?.observations
            const oldExtraFields = checklists?.selected?.extra_fields ? [...checklists?.selected?.extra_fields] : []
            const newExtra = {}
            oldExtraFields.forEach((value, index) => newExtra[index] = value.value)
            this.setState({ observations: oldObservations, loading: false, answers: checklists?.selected?.checklist_items, extra: newExtra })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    gotoTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll)
                window.scrollTo(0, currentScroll - (currentScroll / 5))
            }
        })()

    }

    handleChangeExtra(event) {
        const { target } = event
        const { extra } = this.state
        extra[target.name] = target.value
        this.setState({ extra })
    }

    handleChangeExtraImg(event) {
        const { target } = event
        const { extra } = this.state
        const file = target.value

        //Upload File and get the name of the saved one
        const body = new FormData()
        body.append("file", file)
        const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
        instance({
            method: 'post',
            url,
            data: body
        }).then(response => {
            extra[target.name] = response.data?.info?.file_name
            this.setState({ extra })
        })
    }


    handleClick(element, value) {
        return () => {
            const { match, user, answerChecklist, online } = this.props
            const body = {
                "companyId": user.account.user.idCompany,
                "branch_id": 12,
                "checklist_id": match.params.id,
                "checklist_item_id": element.id,
                "answer": value,
                online: online.status
            }
            answerChecklist(body)
            const answers = [...this.state.answers]
            const newElement = answers.find(answer => answer.id === element.id)
            const newElementIndex = answers.findIndex(answer => answer.id === element.id)
            newElement.answer.value_id = value
            answers[newElementIndex] = newElement
            this.setState({ answers })
        }
    }

    handleCrit(element, value) {
        return () => {
            const { match, user, criticChecklist, online } = this.props
            const body = {
                "companyId": user.account.user.idCompany,
                "branch_id": 12,
                "checklist_id": match.params.id,
                "checklist_item_id": element.id,
                "criticality": value,
                online: online.status
            }
            criticChecklist(body)
            const answers = [...this.state.answers]
            const newElement = answers.find(answer => answer.id === element.id)
            const newElementIndex = answers.findIndex(answer => answer.id === element.id)
            newElement.answer.criticality = value
            answers[newElementIndex] = newElement
            this.setState({ answers })

        }
    }

    handleDownload(row) {
        const { user } = this.props
        return () => {
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${user.account.user.id}`
            fetch(url).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = `Reporte Lista de Chequeo`
                    a.click()
                })
            }).catch(() => callSnackbar("Error al descargar documento", "error"))
        }
    }

    handleComment(element, value) {
        const { match, user, commentChecklist, online } = this.props
        const body = {
            "companyId": user.account.user.idCompany,
            "branch_id": 12,
            "checklist_id": match.params.id,
            "checklist_item_id": element.id,
            "comments": value,
            online: online.status
        }
        commentChecklist(body)
        const answers = [...this.state.answers]
        const newElement = answers.find(answer => answer.id === element.id)
        const newElementIndex = answers.findIndex(answer => answer.id === element.id)
        newElement.answer.comments = value
        answers[newElementIndex] = newElement
        this.setState({ answers })
    }

    handleCounter(element, value) {
        const { match, user, counterElement, online } = this.props
        const body = {
            "companyId": user.account.user.idCompany,
            "branch_id": 12,
            "checklist_id": match.params.id,
            "checklist_item_id": element.id,
            "counter": value,
            online: online.status
        }
        counterElement(body)
        const answers = [...this.state.answers]
        const newElement = answers.find(answer => answer.id === element.id)
        const newElementIndex = answers.findIndex(answer => answer.id === element.id)
        newElement.answer.counter = value
        answers[newElementIndex] = newElement
        this.setState({ answers })
    }

    handleChange(event) {
        const { target } = event
        let { observations } = this.state
        observations = target.value
        this.setState({ observations })
    }

    simulateClick(element, index) {
        return () => {
            const input = document.getElementById(`fileInput${index}-${element.id}`)
            input.click()
        }
    }

    handleSave() {
        const { match, user, saveChecklist, online, checklists } = this.props
        const { observations, extra } = this.state
        const selected = checklists.selected
        let newExtraFields = []
        if (selected.extra_fields) {
            newExtraFields = [...selected.extra_fields]
            Object.entries(extra).forEach(([key, value]) => {
                newExtraFields[parseInt(key, 10)].value = value
            })
        }
        const body = {
            "companyId": user.account.user.idCompany,
            "branch_id": 12,
            "checklist_id": match.params.id,
            "observations": observations,
            extra_fields: newExtraFields,
            online: online.status
        }
        saveChecklist(body)
    }

    handleSelectFile(event, element, indexType) {
        const { target } = event
        const { match, user, fileChecklist, file2Checklist, file3Checklist, online } = this.props
        const data = new FormData()
        const file = target.files[0]

        console.log(file)

        const index = ["", "2", "3"].indexOf(indexType)
        const avaliableFunctions = [fileChecklist, file2Checklist, file3Checklist]

        const callback = (image, file) => {
            data.append("file", file)
            data.append("companyId", user.account.user.idCompany)
            data.append("branch_id", 12)
            data.append("checklist_id", match.params.id)
            data.append("checklist_item_id", element.id)
            const body = {
                file: image,
                companyId: user.account.user.idCompany,
                branch_id: 12,
                checklist_id: match.params.id,
                checklist_item_id: element.id,
                online: online.status
            }
            return avaliableFunctions[index](data, body)
        }

        resizeImageAndGetFile(file, callback)
    }

    renderList() {
        const { checklists, online } = this.props
        const answers = checklists?.selected?.checklist_items || []
        return answers.map((element, index) => {
            return (
                <CheckListElement
                    element={element}
                    key={index}
                    simulateClick={this.simulateClick}
                    handleSelectFile={this.handleSelectFile}
                    handleClick={this.handleClick}
                    handleCrit={this.handleCrit}
                    handleComment={this.handleComment}
                    handleCounter={this.handleCounter}
                    disabled={checklists.selected.status === "Terminado"}
                    online={online.status}
                />
            )
        })
    }

    getProgress() {
        const { checklists } = this.props
        const checklistElements = checklists?.selected?.checklist_items || []
        if (checklistElements.length === 0) return 100
        const total = checklistElements.length
        let added = 0
        checklistElements.forEach(element => {
            if (element.answer.value_id !== null) {
                added++
            }
        })
        return Math.round(added / total * 100, 10)
    }

    getCompliance() {
        const { checklists } = this.props
        const checklistElements = checklists?.selected?.checklist_items || []
        if (checklistElements.length === 0) return 100
        let total = 0
        let added = 0
        checklistElements.forEach(element => {
            if (element.answer.value_id !== null && element.answer.value_id !== 3) {
                total++
                if (element.answer.value_id === 1) {
                    added++
                }
            }
        })
        return total > 0 ? Math.round(added / total * 100, 10) : 0
    }

    handleSubmit(values) {
        const { match, editChecklist } = this.props
        const body = { ...values, id: match.params.id }
        editChecklist(body)
    }

    renderExtraFields() {
        const { classes, checklists } = this.props
        const { extra } = this.state
        const selected = checklists?.selected
        const fields = selected?.extra_fields || []

        function transofrmToExtraOptions(options) {
            return options.map(option => {
                return {
                    label: option.split('&')[0],
                    value: option.split('&')[0]
                }
            })
        }

        return fields.map((field, index) => {
            switch (field.type) {
                case "text": {
                    return (
                        <div className={classes.extraField}>
                            <Typography variant="subtitle1">{field.label}</Typography>
                            <TextInput
                                disabled={selected.status === "Terminado"}
                                name={index}
                                value={extra[index]}
                                onChange={this.handleChangeExtra}
                                label={""}
                            />
                        </div>
                    )
                }
                case "select": {
                    return (
                        <div className={classes.extraField}>
                            <Typography variant="subtitle1">{field.label}</Typography>
                            <SelectInput
                                disabled={selected.status === "Terminado"}
                                name={index}
                                value={extra[index]}
                                onChange={this.handleChangeExtra}
                                label={""}
                                options={transofrmToExtraOptions(field.options)}
                            />
                            {renderMessages(field.options, extra[index])}
                        </div>
                    )
                }
                case "img": {
                    return (
                        <div className={classes.extraField}>
                            <Typography variant="subtitle1">{field.label}</Typography>
                            <ImgInput
                                disabled={selected.status === "Terminado"}
                                name={index}
                                value={extra[index]}
                                onChange={this.handleChangeExtraImg}
                                label={""}
                            />
                        </div>
                    )
                }
                case "ids": {
                    return (
                        <div className={classes.extraField}>
                            <Typography variant="subtitle1">{field.label}</Typography>
                            <Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(selected)}</Typography>
                            <TextInput
                                disabled={selected.status === "Terminado"}
                                name={index}
                                type="number"
                                value={extra[index]}
                                onChange={this.handleChangeExtra}
                                label={"Cantidad de trabajadores"}
                            />
                            <Typography variant="subtitle1">Resultado: {extra[index] ? (getTotalCounters(selected) * 100) / extra[index] : 0}</Typography>
                        </div>
                    )
                }
                default: {
                    return (
                        <div className={classes.extraField}>
                            <Typography variant="subtitle1">{field.label}</Typography>
                            <TextInput
                                disabled={selected.status === "Terminado"}
                                name={index}
                                value={extra[index]}
                                onChange={this.handleChangeExtra}
                                label={""}
                            />
                        </div>
                    )
                }
            }

        })
    }

    render() {
        const { classes, checklists, workers, user, branch } = this.props
        const { observations, loading } = this.state
        const activityName = checklists?.selected?.activity
        const PMName = checklists?.selected?.preventive_measure
        const checklist = checklists?.selected
        const disabled = checklist?.status === "Terminado"
        const progress = this.getProgress()
        const compliance = this.getCompliance()
        const answers = checklists?.selected?.checklist_items || []
        const allWorkers = workers.all || []
        const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
        return (
            <>
                <div className={classes.progressLabel}>
                    <div className={classes.label}>
                        <Typography variant="subtitle1">Cumplimiento:</Typography>
                        <Typography variant="subtitle1">{compliance} %</Typography>
                    </div>
                    <div className={classes.label}>
                        <Typography variant="subtitle1">Avance:</Typography>
                        <Typography variant="subtitle1">{progress} %</Typography>
                    </div>
                </div>
                <div className={classes.container}>
                    <MutableInfoCard
                        title="Información Actividad"
                        formInfo={[{ name: "user_id", label: "Responsable", type: "select", options: transformToOptions(filteredWorkers) }, { name: "date_scheduled", label: "Fecha programación", type: "date" }]}
                        showInfo={[{ name: "user_id", label: "Responsable", type: "select", options: transformToOptions(workers.all) }, { name: "date_scheduled", label: "Fecha programación" }]}
                        response={checklist}
                        loading={loading}
                        submitAction={this.handleSubmit}
                        disableEdit={disabled || user.account.user.userType > 1}
                    />
                    <Paper className={classes.paper}>
                        <LoaderComponent loading={loading}>
                            <div className={classes.inlineSep}>
                                <div className={classes.title}>
                                    <Typography variant="h1" >
                                        {activityName}
                                    </Typography>
                                    <Typography variant="caption">
                                        {PMName}
                                    </Typography>
                                </div>
                                <div>
                                    <IconButton onClick={this.handleDownload(checklist)} disabled={checklist?.status !== "Terminado"}>
                                        <GetApp />
                                    </IconButton>
                                </div>
                            </div>
                        </LoaderComponent>
                    </Paper>

                    <div className={classes.divisor}>
                        {checklist?.preventive_measure_code && <Paper className={classes.paper}>
                            <div className={classes.title}>
                                <Typography variant="h1" >
                                    Código
                                    </Typography>
                                <Typography variant="subtitle1">
                                    {checklist?.preventive_measure_code}
                                </Typography>
                            </div>
                        </Paper>}
                        {checklist?.preventive_measure_revision && <Paper className={classes.paper}>
                            <div className={classes.title}>
                                <Typography variant="h1" >
                                    Revisión
                                    </Typography>
                                <Typography variant="subtitle1">
                                    {checklist?.preventive_measure_revision}
                                </Typography>
                            </div>
                        </Paper>}
                    </div>
                    {checklist?.preventive_measure_description && <Paper className={classes.paper}>
                        <div className={classes.title}>
                            <Typography variant="h1" >
                                Descripción
                                    </Typography>
                            <Typography variant="subtitle1" style={{ whiteSpace: "pre-wrap" }}>
                                {checklist?.preventive_measure_description}
                            </Typography>
                        </div>
                    </Paper>}
                    {answers.length > 0 &&
                        <div className={classes.inlineContainer}>
                            <div className={classes.inline}>
                                <ErrorOutline style={{ color: "#ff6c87" }} />
                                <Typography variant="caption">
                                    Altamente Crítico
                        </Typography>
                            </div>
                            <div className={classes.inline}>
                                <ErrorOutline style={{ color: "#ffab6e" }} />
                                <Typography variant="caption">
                                    Medianamente Crítico
                        </Typography>
                            </div>
                            <div className={classes.inline}>
                                <ErrorOutline style={{ color: "#f6ec79" }} />
                                <Typography variant="caption">Poco Crítico</Typography>
                            </div>
                            <div className={classes.inline}>
                                <ErrorOutline style={{ color: "#f2f5ff" }} />
                                <Typography variant="caption">No Crítico</Typography>
                            </div>
                        </div>
                    }
                    <LoaderComponent loading={loading}>
                        {this.renderList()}
                        <div className={classes.fieldsContainer}>
                            <Typography variant="h4" className={classes.extraTitle}>Información a completar</Typography>
                            <div className={classes.fields}>
                                {this.renderExtraFields()}
                            </div>
                        </div>

                        <div className={classes.fieldsContainer}>
                            <div className={classes.extraTitle}>
                                <Typography variant="h4">Observaciones</Typography>
                            </div>
                            <TextInput
                                name="observations"
                                label=""
                                onChange={this.handleChange}
                                value={observations}
                                disabled={disabled}
                            />
                        </div>
                        {disabled ?
                            <div className={classes.responsible}>
                                <Typography variant="subtitle1">Completada por {checklist?.user} el {checklist?.date_done}</Typography>
                            </div>
                            :
                            <div className={classes.endButton}>
                                <Button variant="contained" color="primary" size="small" onClick={this.handleSave} disabled={progress < 100}>
                                    Guardar y Terminar
                                </Button>
                            </div>
                        }
                    </LoaderComponent>
                    <div id="fab-container" className={classes.upFab}>
                        <Fab size="small" onClick={this.gotoTop}>
                            <ArrowUpward />
                        </Fab>
                    </div>
                </div>
            </>
        )
    }
}

CheckList.propTypes = {

}


CheckList.defaultProps = {
    checklists: {}
}

const mapStateToProps = state => ({
    checklists: state.checklists,
    user: state.user,
    workers: state.workers,
    online: state.online,
    branch: state.branch
})

const mapDispatchToProps = dispatch => ({
    getChecklist: (body) => dispatch(getChecklistAction(body)),
    answerChecklist: body => dispatch(answerCheckElementAction(body)),
    criticChecklist: body => dispatch(criticCheckElementAction(body)),
    fileChecklist: (data, body) => dispatch(fileForCheckElementAction(data, body)),
    file2Checklist: (data, body) => dispatch(file2ForCheckElementAction(data, body)),
    file3Checklist: (data, body) => dispatch(file3ForCheckElementAction(data, body)),
    saveChecklist: body => dispatch(saveChecklistAction(body)),
    getWorkers: body => dispatch(getWorkersAction(body)),
    editChecklist: body => dispatch(editChecklistAction(body)),
    commentChecklist: body => dispatch(commentCheckElementAction(body)),
    counterElement: body => dispatch(counterCheckElementAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CheckList))
