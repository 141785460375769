import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = (theme) => ({
  steps: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
  },
  step: {
    flexBasis: 200,
    position: 'relative',
    paddingTop: 24,
    textAlign: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      background: 'whitesmoke',
      borderRadius: "50%",
      height: 12,
      width: 12,
      zIndex: 1,
      top: 8,
      left: '50%',
      transform: 'translate(-50%, 0)',
      border: `2px solid ${theme.palette.blue.main}`
    },
    '&.passed::after': {
      background: theme.palette.blue.main
    },
    '&.actual::after': {
      background: theme.palette.blue.light
    },
    '&.future::after': {
      background: 'whitesmoke',
      border: `2px solid grey`
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      background: 'grey',
      height: 3,
      width: '100%',
      top: 14,
      right: '50%',
    },
    '&:first-child::before': {
      display: 'none'
    },
    '&.passed::before': {
      background: theme.palette.blue.main
    },
    '&.actual::before': {
      background: theme.palette.blue.main
    },
  }
})

class StepsStepper extends Component {
  renderSteps() {
    const { steps, classes, current } = this.props
    return steps.map((step, index) => {
      return (
        <div className={`${classes.step} ${current > index ? "passed" : current < index ? "future" : "actual"}`}>
          <Typography variant="subtitle1">{step?.data?.name}</Typography>
        </div>
      )
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.steps}>
          {this.renderSteps()}
        </div>
      </div>
    )
  }
}

StepsStepper.propTypes = {

}


StepsStepper.defaultProps = {
  steps: []
}

export default withStyles(style)(StepsStepper)