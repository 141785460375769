const TalksReducer = (state = { all: [], selected: {} }, action) => {
  switch (action.type) {
    case "GET_TALKS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.all = action.payload.data.info
      }
      return newState
    }
    case "SELECT_TALK": {
      const newState = { ...state }
      const selectedTalk = newState.all.find(talk => talk.id === action.payload)
      newState.selected = selectedTalk
      return newState
    }
    case "SIGN_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.selected = newTalk
        const selectedIndex = newState.all.findIndex(talk => talk.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
      }
      return newState
    }
    case "USER_SIGN_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.selected = newTalk
        const selectedIndex = newState.all.findIndex(talk => talk.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
      }
      return newState
    }
    case "EDIT_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.selected = newTalk
        const selectedIndex = newState.all.findIndex(talk => talk.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
      }
      return newState
    }
    case "END_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.selected = newTalk
        const selectedIndex = newState.all.findIndex(talk => talk.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
      }
      return newState
    }
    case "CREATE_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.all.push(newTalk)
      }
      return newState
    }
    case "CREATE_MULTIPLE_TALKS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalks = action.payload.data.info
        newState.all = newState.all.concat(newTalks)
      }
      return newState
    }
    default: {
      return state
    }
  }
}

export default TalksReducer