import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

const style = theme => ({
  container: {
    padding: 24,
    background: 'white',
    borderRadius: 15,
    marginBottom: 12
  },
  shortcut: {
    background: theme.palette.blue.main,
    padding: 12,
    borderRadius: 15,
    textAlign: 'center',
    margin: '6px 0',
    '& > *': {
      color: 'white'
    },
    cursor: 'pointer'
  },
  title: {
    marginBottom: 24
  }
})

class SubBranchShortcuts extends Component {
  constructor() {
    super()
    autobind(SubBranchShortcuts, this)
  }

  handleClick(option) {
    return () => {
      const { history, user } = this.props
      const branch_id = user?.account?.user?.branch_id
      const sub_branch_id = user?.account?.user?.sub_branch_id
      history.push(`/branches/${branch_id}/sub_branches/${sub_branch_id}?option=${option}`)
    }
  }

  handleGotoProfile() {
    const { history, user } = this.props
    const user_id = user?.account?.user?.id
    history.push(`workers/${user_id}`)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>Accesos Rápidos</Typography>
        <div className={classes.shortcut} onClick={this.handleGotoProfile}>
          <Typography variant="h2">Perfil</Typography>
        </div>
        <div className={classes.shortcut} onClick={this.handleClick("workers")}>
          <Typography variant="h2">Colaboradores</Typography>
        </div>
        <div className={classes.shortcut} onClick={this.handleClick("documents")}>
          <Typography variant="h2">Documentos</Typography>
        </div>
        <div className={classes.shortcut} onClick={this.handleClick("permissions")}>
          <Typography variant="h2">Permisos</Typography>
        </div>
        <div className={classes.shortcut} onClick={this.handleClick("lists")}>
          <Typography variant="h2">Listas</Typography>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withRouter(withStyles(style)(SubBranchShortcuts)))