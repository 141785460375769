import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from "chart.js"
import moment from 'moment'

const style = () => ({

})
class CriticalityVsStatus extends Component {
  componentDidMount() {
    const { idName, labels, data } = this.props
    const chartElement = document.getElementById(idName)
    const myChartRef = chartElement.getContext("2d")
    chartElement.parentNode.style.height = "100%"
    chartElement.parentNode.style.width = "100%"

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels: labels,
        datasets: [
          {
            label: "Sin Criticidad",
            data: Object.keys(data).map(key => data[key].empty),
            barThickness: 30,
            backgroundColor: "#f2f5ff",
          },
          {
            label: "No Crítico",
            data: Object.keys(data).map(key => data[key].no),
            barThickness: 30,
            backgroundColor: "#f2f5ff",
          },
          {
            label: "Poco Crítico",
            data: Object.keys(data).map(key => data[key].low),
            barThickness: 30,
            backgroundColor: "#f6ec79",
          },
          {
            label: "Medianamente Crítico",
            data: Object.keys(data).map(key => data[key].med),
            barThickness: 30,
            backgroundColor: "#ffab6e",
          },
          {
            label: "Altamente Crítico",
            data: Object.keys(data).map(key => data[key].high),
            barThickness: 30,
            backgroundColor: "#ff6c87",
          },
        ],
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: function (value) {
                if (value.length > 30) {
                  return value.substr(0, 30) + '...' //truncate
                } else {
                  return value
                }
              },
            }
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      const data = this.processData()
      const newDatasets = [
        {
          label: "No Crítico",
          data: Object.keys(data).map(key => data[key].no),
          barThickness: 30,
          backgroundColor: "#f2f5ff",
        },
        {
          label: "Poco Crítico",
          data: Object.keys(data).map(key => data[key].low),
          barThickness: 30,
          backgroundColor: "#f6ec79",
        },
        {
          label: "Medianamente Crítico",
          data: Object.keys(data).map(key => data[key].medium),
          barThickness: 30,
          backgroundColor: "#ffab6e",
        },
        {
          label: "Altamente Crítico",
          data: Object.keys(data).map(key => data[key].high),
          barThickness: 30,
          backgroundColor: "#ff6c87",
        },
      ]
      this.chart.data.datasets = newDatasets
      this.chart.update()
    }
  }

  processData() {
    const { findings, startDate, endDate } = this.props
    const filtered = findings.filter(finding => moment(finding.date_detected) > moment(startDate) && moment(finding.date_detected) < moment(endDate))
    const overdue = filtered.filter(finding => finding.status === "Vencido")
    const pending = filtered.filter(finding => finding.status === "Pendiente")
    const assigned = filtered.filter(finding => finding.status === "Procesando")
    const done = filtered.filter(finding => finding.status === "Terminado")
    const approved = filtered.filter(finding => finding.status === "Aprobado")

    const data = {
      overdue: {
        no: overdue.filter(finding => finding.criticality === 1).length,
        low: overdue.filter(finding => finding.criticality === 2).length,
        medium: overdue.filter(finding => finding.criticality === 3).length,
        high: overdue.filter(finding => finding.criticality === 4).length,
      },
      pending: {
        no: pending.filter(finding => finding.criticality === 1).length,
        low: pending.filter(finding => finding.criticality === 2).length,
        medium: pending.filter(finding => finding.criticality === 3).length,
        high: pending.filter(finding => finding.criticality === 4).length,
      },
      assigned: {
        no: assigned.filter(finding => finding.criticality === 1).length,
        low: assigned.filter(finding => finding.criticality === 2).length,
        medium: assigned.filter(finding => finding.criticality === 3).length,
        high: assigned.filter(finding => finding.criticality === 4).length,
      },
      done: {
        no: done.filter(finding => finding.criticality === 1).length,
        low: done.filter(finding => finding.criticality === 2).length,
        medium: done.filter(finding => finding.criticality === 3).length,
        high: done.filter(finding => finding.criticality === 4).length,
      },
      approved: {
        no: approved.filter(finding => finding.criticality === 1).length,
        low: approved.filter(finding => finding.criticality === 2).length,
        medium: approved.filter(finding => finding.criticality === 3).length,
        high: approved.filter(finding => finding.criticality === 4).length,
      },
    }

    return data
  }

  render() {
    const { classes, idName } = this.props
    return (
      <div className={classes.container}>
        <div>
          <canvas id={idName} ref={this.chartRef} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(CriticalityVsStatus)