import React, { Component } from "react"
import { Typography, withStyles, Paper } from "@material-ui/core"
import { connect } from "react-redux"
import { getEnterpriseAction } from "../../Actions/EnterpriseAction"
import autobind from "../../Utils/autobind"
import { TokenTest } from "../../Actions/LoginActions"
import { transformToSuperDispatch } from "../../Utils/functions"
import Shortcuts from "./Shortcuts"
import ChecklistsManagement from "./ChecklistsManagement"
import FindingManagement from "./FindingManagement"
// import UserDocumentManagement from "./UserDocumentManagement"
// import NonConformitiesManagement from "./NonConformitiesManagement"
import Pendings from "./Pendings"
import moment from 'moment'
import SubBranchShortcuts from "./SubBranchShortcuts"
import { getEnterpriseActivitiesAction } from "../../Actions/ActivityActions"
import QuickAccessChecklists from "./QuickAccessChecklists"

export const agesData = [
	{
		label: "20-25 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "26-25 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "31-35 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "36-40 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "41-45 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "46-40 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
	{
		label: "51-55 años",
		value: Math.floor(Math.random() * 60),
		color: "#6CD2B8",
	},
]

export const agesOptions = {
	height: "250px",
	width: "90%",
	enableHover: false,
	detachedLabels: false,
	minValue: 0,
	maxValue: 60,
	stepSize: 10,
	xEnableGrid: false,
	xHasTitle: true,
	xTitle: "N° Trabajadores",
}

const positions = ["Admin", "Supervisor", "Común"]

export const positionData = positions.map((position) => ({
	label: position,
	value: Math.floor(Math.random() * 50),
	color: "#48A4B0",
}))

export const positionOptions = {
	height: "250px",
	width: "80%",
	barThickness: 16,
	enableHover: false,
	detachedLabels: false,
	minValue: 0,
	maxValue: 50,
	stepSize: 10,
	type: "bar",
	xEnableGrid: true,
	xHasTitle: true,
	xTitle: "N° Trabajadores",
}

const style = (theme) => ({
	container: {
		margin: 12,
	},
	grid: {
		display: "flex",
		gap: "24px",
		flexWrap: "wrap",
		justifyContent: "space-between",
		alignItems: "center",
	},
	divider: {
		margin: "24px 0",
	},
	cardsContainer: {
		padding: 12,
		borderRadius: 5,
		"& > h1": {
			color: theme.palette.grey.dark2,
			margin: "0 0 12px 0",
			fontSize: "1.25rem",
		},
	},
	fullContainer: {
		background: theme.palette.primary.light,
	},
	titleContainer: {
		background: theme.palette.primary.main,
		padding: 12,
		maxWidth: 500
	},
	title: {
		padding: 24
	},
	card: {
		marginBottom: 12
	},
	inlineCards: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'stretch',
		'& > *': {
			flexBasis: '49%',
			maxWidth: '49%'
		}
	},
	welcome: {
		display: 'flex',
		'& > *': {
			flexBasis: '50%'
		}
	},
	welcomeMessage: {
		padding: '12px 24px',
		'& > h1': {
			color: theme.palette.purple.light,
			fontSize: '3rem',
		},
		'& > h2': {
			color: theme.palette.primary.main,
			fontSize: '1.7rem',
		},
		'& > h3': {
			color: theme.palette.primary.main,
			fontSize: '1rem',
			marginTop: 24
		},
	},
	welcomeMessageMobile: {
		"@media (max-width:500px)": {
			background: theme.palette.blue.dark,
			position: 'relative',
			'&::before': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				right: 0,
				height: 24,
				width: '100%',
				background: theme.palette.blue.dark,
				transform: 'translate(0, 100%)',
			},
			'&::after': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				left: 0,
				height: 24,
				width: '100%',
				background: 'whitesmoke',
				transform: 'translate(0, 100%)',
				borderRadius: '30px 30px 0 0',
			}
		},
	},
})

class Home extends Component {
	constructor(props) {
		super(props)
		autobind(Home, this)
	}

	componentDidMount() {
		const body = {
			id: 4,
		}
		this.props.getEnterprise(body)
		// mergeMultiplePdfFiles(["pdf1.pdf", "pdf2.pdf"], "SuperBacan")
	}



	render() {
		const { classes, history, user, getActivities } = this.props
		// const selectedId = enterprise?.selected?.id
		const level = user?.account?.user?.userType
		const isMobile = window.innerWidth <= 500
		const isSubBranch = user?.account?.user?.sub_branch_id !== ""
		return (
			<div className={classes.container}>
				{/* {isMobile && <div className={classes.welcomeMessageMobile}>
					<Typography variant="h6">Bienvenido</Typography>
				</div>} */}
				<Typography variant="h6">Panel de Control</Typography>
				{level > 1 &&
					<>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>PENDIENTES</Typography>
							<Pendings history={history} />
						</Paper>
						{isSubBranch && <SubBranchShortcuts />}
					</>
				}
				{!isMobile &&
					<div className={classes.inlineCards}>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>BIENVENIDO</Typography>
							<div className={classes.welcome}>
								<div className={classes.welcomeMessage}>
									<Typography variant="h1">Hola</Typography>
									<Typography variant="h2">{user?.account?.user?.name}</Typography>
									<Typography variant="h3">{moment(new Date()).format("dddd DD [de] MMMM YYYY")}</Typography>
								</div>
								<img src="/welcome.jpeg" alt="bienvenido" width="50%" />
							</div>
						</Paper>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>ACCESOS RÁPIDOS</Typography>
							<Shortcuts history={history} level={level} />
						</Paper>
					</div>
				}
				<Paper className={classes.card}>
					<Typography variant="h1" className={classes.title}>ACCIONES RÁPIDAS</Typography>
					<QuickAccessChecklists load={getActivities} />
				</Paper>
				{level === 1 &&
					<>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>GESTIÓN LISTAS DE CHEQUEO</Typography>
							<ChecklistsManagement />
						</Paper>
						<FindingManagement />
						{/* <Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>Gestion {selectedId === 14 ? "IPAs" : "No Conformidades"}</Typography>
							<NonConformitiesManagement />
						</Paper> */}
						{/* <div className={classes.titleContainer}>
							<Typography variant="h1" className={classes.title}>Gestion de Documentos Colaboradores</Typography>
						</div> */}
						{/* <UserDocumentManagement /> */}
					</>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise,
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	getEnterprise: (body) => dispatch(getEnterpriseAction(body)),
	tokenTest: () => dispatch(TokenTest()),
	getActivities: (body) => dispatch(getEnterpriseActivitiesAction(body)),
})

export default withStyles(style)(
	connect(mapStateToProps, transformToSuperDispatch(mapDispatchToProps))(Home)
)
