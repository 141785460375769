import moment from 'moment'

export const frequencyFormat = (data) => {
    const options = [
        { value: 1, label: "Diario" },
        { value: 2, label: "Cada dos días" },
        { value: 3, label: "Cada tres días" },
        { value: 7, label: "Semanal" },
        { value: 15, label: "Quincenal" },
        { value: 30, label: "Mensual" },
        { value: 60, label: "Bimensual" },
        { value: 90, label: "Trimestral" },
        { value: 180, label: "Semestral" },
    ]

    const element = options.find((option) => {
        return option.value.toString() === data.toString()
    })
    if (element) {
        return element.label
    }
    return "No asignado..."
}

export const getCriticity = (data) => {
    const crit = data.consequence.value * data.probability.value
    if (crit >= 9) {
        return "Critico"
    } else if (crit >= 4) {
        return "Media"
    } else if (crit < 4 && crit > 0) {
        return "Baja"
    } else {
        return "No aplica"
    }
}

export const getResidualCriticity = (data) => {
    const crit = data.residual_consequence.value * data.residual_probability.value
    if (crit >= 9) {
        return "Critico"
    } else if (crit >= 4) {
        return "Media"
    } else if (crit < 4 && crit > 0) {
        return "Baja"
    } else {
        return "No aplica"
    }
}

export const percentageCell = (value) => {
    return `${value} %`
}

export const dateTimeFormat = value => {
    return moment(value).format("DD [de] MMMM YYYY HH:mm")
}