import React, { Component } from 'react'
import { Button, Divider, IconButton, Menu, MenuItem, Paper, Typography, withStyles } from '@material-ui/core'
import { AccountCircle, AddAPhoto, CalendarToday, Delete, Edit, ErrorOutline, EventAvailable, KeyboardArrowDown, MoreVert } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import { criticities, criticities_index } from './Findings'
import ImageContainer from '../../Shared/ImageContainer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { resizeImageAndGetFile } from '../../Utils/functions'
import { cardStyle } from './CardStyles'
import ClickTooltip from '../../Shared/ClickTooltip'

function simulateClick(finding) {
  return () => {
    const element = document.getElementById(`fileInput-${finding.id}`)
    element.click()
  }
}

class PendingCard extends Component {
  constructor() {
    super()
    this.state = {
      openActions: false,
      openMenu: false,
      anchorEl: "",
      params: {}
    }

    autobind(PendingCard, this)
  }

  handleOpenActions() {
    this.setState({ openActions: !this.state.openActions })
  }

  handleOpenMenu(event) {
    this.setState({ openMenu: !this.state.openMenu, anchorEl: event.target })
  }

  handleClickFileSelector(event, finding) {
    const { target } = event
    const file = target.files[0]

    const callback = (image, file) => {
      const { params } = this.state
      params.solution_evidence = file
      this.setState({ base64File: image, params })
    }

    resizeImageAndGetFile(file, callback)

  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSolve() {
    const { params, base64File } = this.state
    const { solve, online, group } = this.props
    group.forEach(finding => {
      const body = { ...params, id: finding.id, status: 2, online: online.status, base64: base64File, date_solved: moment(new Date()).format("YYYY-MM-DD") }
      const data = new FormData()
      data.append("solution_evidence", params.solution_evidence)
      data.append("id", finding.id)
      data.append("status", 2)
      data.append("solution_comment", params.solution_comment)
      data.append("date_solved", moment(new Date()).format("YYYY-MM-DD"))
      solve(body, data)
    })
  }

  handleDismiss() {
    const { dismiss, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 4,
        online: online.status
      }
      dismiss(body)
    })
    this.setState({ openMenu: false })
  }

  handleGoto() {
    const { history, finding, goto } = this.props
    history.push(`/${goto}/${finding.id}`)
  }

  renderTitle() {
    const { title = { main: [], text: [] }, classes } = this.props
    const { main, text } = title
    return (
      <div>
        {main.map(m => (
          <div className={classes.mainText} key={m}>
            <ClickTooltip
              title={<Typography variant="subtitle1" className={classes.tooltip}>{m}</Typography>}
            >
              <Typography variant="h4">{m}</Typography>
            </ClickTooltip>
          </div>
        ))}
        {text.map(t => (
          <div className={classes.titleText} key={t}>
            <ClickTooltip
              title={<Typography variant="subtitle1" className={classes.tooltip}>{t}</Typography>}
            >
              <Typography variant="caption" className={classes.titleText}>{t}</Typography>
            </ClickTooltip>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { openActions, openMenu, anchorEl, params, base64File } = this.state
    const { classes, finding, urls } = this.props
    return (
      <Paper className={classes.container}>
        <div className={classes.title}>
          {this.renderTitle()}
        </div>
        <div className={classes.subtitle}>
          <div>
            <IconButton size="small" onClick={this.handleOpenMenu}>
              <MoreVert />
            </IconButton>
          </div>
          <div className={finding.status === "Procesando" ? classes.titleStateAsigned : classes.titleStateOverdue}>
            <Typography>{finding.status}</Typography>
          </div>
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={classes.resume}>
          <div className={classes.statsContainer}>
            <div className={classes.stat}>
              <CalendarToday />
              <Typography variant="body1">Fecha de detección: {finding.date_detected}</Typography>
            </div>
            <div className={classes.stat}>
              <EventAvailable />
              <Typography variant="body1">Fecha Limite: {finding.date_limit}</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Detectado: {finding.user_creator}</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Responsable: {finding.user}</Typography>
            </div>
            <div className={classes.stat}>
              <ErrorOutline style={{ color: criticities[criticities_index[finding.criticality]] }} />
              <Typography variant="caption">
                {criticities_index[finding.criticality]}
              </Typography>
            </div>
          </div>
          <ImageContainer src={finding.evidence ? `${urls[1]}${finding.evidence}` : "/noimage.png"} alt="foto" height="64px" width="64px" />
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={classes.action}>
          <Typography variant="subtitle1">Acción Inmediata:</Typography>
          <Typography variant="body2">{finding.inmediate_action || "No especifica..."}</Typography>
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={`${classes.actionContainer} ${openActions ? "open" : ""}`}>
          <div className={`${classes.actionButton} ${openActions ? "open" : ""}`} onClick={this.handleOpenActions}>
            <Typography className={`${classes.buttonText} ${openActions ? "open" : ""}`} variant="subtitle1">Resolver Acción Inmediata</Typography>
            <Typography className={`${classes.buttonHiddenText} ${openActions ? "" : "open"}`} variant="subtitle1">Resolviendo Acción Inmediata</Typography>
            <KeyboardArrowDown className={`${classes.arrowIcon} ${openActions ? "open" : ""}`} />
          </div>
          <div className={classes.form}>
            <Typography variant="caption">Evidencia de resolución</Typography>
            <div className={classes.evidence}>
              <Button
                variant="outlined"
                onClick={simulateClick(finding)}
                style={{ padding: "7px 12px" }}
              >
                <AddAPhoto />
              </Button>
              <ImageContainer src={params.solution_evidence ? base64File : "/noimage.png"} alt="foto" height="32px" width="32px" />
            </div>
            <TextInput label="Comentarios" onChange={this.handleChange} name="solution_comment" value={params.solution_comment} />
            <div className={classes.finalButton}>
              <Button size="small" variant="outlined" color="primary" onClick={this.handleSolve}>Resolver</Button>
            </div>
          </div>
        </div>
        <Menu open={openMenu} anchorEl={anchorEl} onClose={this.handleOpenMenu} >
          <MenuItem className={classes.menuItem} onClick={this.handleGoto}>
            <Typography variant="subtitle1">Ver</Typography>
            <Edit />
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.handleDismiss}>
            <Typography variant="subtitle1">Desestimar</Typography>
            <Delete />
          </MenuItem>
        </Menu>
        <input
          type="file"
          id={`fileInput-${finding.id}`}
          style={{
            visibility: "hidden",
            display: "none",
          }}
          onChange={e => this.handleClickFileSelector(e, finding)}
        />
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  online: state.online
})

export default withRouter(connect(mapStateToProps)(withStyles(cardStyle)(PendingCard)))