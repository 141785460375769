import React, { Component } from "react"
import { loginAction } from "../../Actions/LoginActions"
import sha512 from "js-sha512"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import autobind from "../../Utils/autobind"
import { TextField, Button, withStyles, Typography } from "@material-ui/core"
import {
	getBranches,
	getEnterpriseAction,
} from "../../Actions/EnterpriseAction"
import { setBranchAction } from "../../Actions/BranchActions"

const style = () => ({
	container: {
		padding: 24,
		textAlign: "left",
		maxWidth: 600,
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		"@media (max-width:500px)": {
			width: "80%",
		},
	},
	input: {
		margin: "12px 0",
	},
	button: {
		display: "flex",
		justifyContent: "center",
		marginTop: 36,
	},
	welcome: {
		margin: "24px 0",
		fontSize: 30,
		textAlign: 'center',
		color: 'white'
	},
	background: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: -1,
		height: '100vh',
		width: '100vw'
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 12
	},
	logo: {
		width: '80%'
	},
	loginButton: {
		background: 'white',
		width: 200,
		borderRadius: 36,
		'& > span': {
			fontWeight: 600
		}
	},
	inputWithUnderline: {
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			height: 2,
			width: '100%',
			bottom: 0,
			left: 0,
			background: 'white'
		}
	},
	recovery: {
		textAlign: 'center',
		marginTop: 6,
		'& > *': {
			color: 'white',
			fontSize: '0.8rem'
		}
	},
	newAccount: {
		textAlign: 'center',
		marginTop: 48,
		'& > *': {
			margin: '6px 0'
		}
	},
})

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {},
		}

		autobind(Login, this)
	}

	handleSend() {
		const {
			login,
			enqueueSnackbar,
			getEnterprise,
			getBranches,
			setBranch,
		} = this.props
		const { params } = this.state
		const password = sha512(params.password)
		const body = { username: params.username, password }
		login(body, enqueueSnackbar).then((response) => {
			if (response.payload.data.status === "success") {
				const companyId = response.payload.data.info.user.idCompany
				const body = { companyId }
				getEnterprise(body)
				getBranches(body).then((response2) => {
					if (response2.payload.data.status === "success") {
						const allBranches = response2.payload.data.info || []
						const actualBranchId = response?.payload?.data?.info?.user?.branch_id?.toString()
						const branch = actualBranchId === null ? allBranches[0] : allBranches.find(b => b.id === actualBranchId)
						setBranch(branch)
					}
				})
			}
		})
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	render() {
		const { params } = this.state
		const { classes } = this.props
		const isMobile = window.innerWidth <= 500
		return (
			<>
				<img alt="fondo" src={isMobile ? "/fondo-mobile.png" : "/fondo-desktop.png"} className={classes.background} />
				<div className={classes.container}>
					<div className={classes.logoContainer}>
						<img alt="logo" src="/logo-blanco-olitas.png" className={classes.logo} />
					</div>
					<Typography
						variant="h1"
						className={classes.welcome}

					>
						Bienvenido
                </Typography>
					<div className={classes.input}>
						{/* <Typography variant="subtitle1" style={{ marginBottom: 6 }}>
                        Usuario
                    </Typography> */}
						<TextField
							fullWidth
							value={params.username || ""}
							type="text"
							name="username"
							label="Usuario"
							className={classes.inputWithUnderline}
							onChange={this.handleChange}
							inputProps={{
								style: {
									padding: "12px 18px",
									color: 'white'
								},
							}}
							InputProps={{
								style: { borderRadius: 12 },
								disableUnderline: true
							}}
							InputLabelProps={{
								style: { color: 'white', paddingLeft: 12 },
							}}
						/>
					</div>
					<div className={classes.input}>
						{/* <Typography variant="subtitle1" style={{ marginBottom: 6 }}>
                        Contraseña
                    </Typography> */}
						<TextField
							fullWidth
							value={params.password || ""}
							type="password"
							name="password"
							label="Contraseña"
							onChange={this.handleChange}
							className={classes.inputWithUnderline}
							inputProps={{
								style: {
									padding: "12px 18px",
									color: 'white'
								},
							}}
							InputProps={{
								style: { borderRadius: 12 },
								disableUnderline: true
							}}
							InputLabelProps={{
								style: { color: 'white', paddingLeft: 12 },
							}}
						/>
					</div>
					<div className={classes.button}>
						<Button
							variant="contained"
							className={classes.loginButton}
							onClick={this.handleSend}
						>
							Iniciar Sesión
                    </Button>
					</div>
					{/* <div className={classes.recovery} onClick={() => console.log("OPEN MODAL")}>
						<Typography variant="body1">Recuperar contraseña</Typography>
					</div> */}
					<div className={classes.newAccount}>
						<div className={classes.recovery}>
							<Typography variant="body1">¿No tengo cuenta?</Typography>
						</div>
						<Button
							variant="contained"
							className={classes.loginButton}
							onClick={() => window.open("https://tazki.cl/cotizar/")}
						>
							Solicitar
						</Button>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	login: (body, snackbar) => dispatch(loginAction(body, snackbar)),
	getEnterprise: (body) => dispatch(getEnterpriseAction(body)),
	getBranches: (body) => dispatch(getBranches(body)),
	setBranch: (branch) => dispatch(setBranchAction(branch)),
})

export default withStyles(style)(
	withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Login))
)
