import React, { Component } from 'react'
import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core'
import autobind from '../Utils/autobind'

const style = () => ({

})
class ClickTooltip extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(ClickTooltip, this)
  }

  renderChildren() {
    const { children } = this.props
    const newChild = React.cloneElement(children, { onClick: this.handleOpen })
    return newChild
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { title } = this.props
    const { open } = this.state
    return (
      <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
        <div>
          <Tooltip
            title={title}
            open={open}
            onClose={this.handleOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            {this.renderChildren()}
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }
}

export default withStyles(style)(ClickTooltip)