import { Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import autobind from "../../Utils/autobind"

const style = () => ({
    container: {
        height: 5,
        borderRadius: 16,
        width: "100%",
        background: "lightgrey",
        marginBottom: 6,
        position: "relative",
    },
    bar: {
        transition: "all 1s ease-in-out",
        height: 5,
        borderRadius: 16,
        width: 0,
        background: "pink",
        marginBottom: 6,
        position: "absolute",
    },
    inline: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: 0.8,
    },
})

class Progress extends Component {
    constructor(props) {
        super(props)
        autobind(Progress, this)
    }
    componentDidMount() {
        setTimeout(this.handleSetStyle, 1000)
    }

    handleSetStyle() {
        const { color, percentage, name } = this.props
        const element = document.getElementById(name)
        if (element) {
            element.style.background = color
            element.style.width = `${percentage}%`
        }
    }

    render() {
        const { classes, percentage, name, legend } = this.props
        return (
            <div>
                <div className={classes.container}>
                    <div className={classes.bar} id={name} />
                </div>
                <div className={classes.inline}>
                    <Typography variant="subtitle2">{`${legend}`}</Typography>
                    <Typography variant="subtitle2">{`${percentage} %`}</Typography>
                </div>
            </div>
        )
    }
}

Progress.propTypes = {}

Progress.defaultProps = {
    name: "bar",
    color: "#ff6c87",
    percentage: 28,
    legend: "Leyenda",
}

export default withStyles(style)(Progress)
