import React, { Component } from 'react'
import { Button, Divider, IconButton, Menu, MenuItem, Paper, Typography, withStyles } from '@material-ui/core'
import { AccountCircle, CalendarToday, Delete, Edit, ErrorOutline, EventAvailable, KeyboardArrowDown, MoreVert } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import DateInput from '../../Shared/Inputs/DateInput'
import { criticities, criticities_index } from './Findings'
import ImageContainer from '../../Shared/ImageContainer'
import { connect } from 'react-redux'
import { transformToOptions } from '../../Utils/functions'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { cardStyle } from './CardStyles'
import ClickTooltip from '../../Shared/ClickTooltip'

class PendingCard extends Component {
  constructor() {
    super()
    this.state = {
      openActions: false,
      openMenu: false,
      anchorEl: "",
      params: {
        date_limit: moment(new Date()).format("YYYY-MM-DD")
      }
    }

    autobind(PendingCard, this)
  }

  handleOpenActions() {
    this.setState({ openActions: !this.state.openActions })
  }

  handleOpenMenu(event) {
    this.setState({ openMenu: !this.state.openMenu, anchorEl: event.target })
  }

  handleAssing() {
    const { params } = this.state
    const { assign, finding, online, workers } = this.props
    const allWorker = workers.all || []
    const user = allWorker.find(worker => worker.id.toString() === params.user_id.toString())
    const body = {
      ...params,
      id: finding.id,
      online: online.status,
      user: user.name,
      date_assigned: moment(new Date()).format("YYYY-MM-DD")
    }
    assign(body)
  }

  handleDismiss() {
    const { dismiss, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 4,
        online: online.status
      }
      dismiss(body)
    })
    this.setState({ openMenu: false })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleGoto() {
    const { history, finding, goto } = this.props
    history.push(`/${goto}/${finding.id}`)
  }

  renderTitle() {
    const { title = { main: [], text: [] }, classes } = this.props
    const { main, text } = title
    return (
      <div>
        {main.map(m => (
          <div className={classes.mainText} key={m}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{m}</Typography>}>
              <Typography variant="h4">{m}</Typography>
            </ClickTooltip>
          </div>
        ))}
        {text.map(t => (
          <div className={classes.titleText} key={t}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{t}</Typography>}>
              <Typography variant="caption" className={classes.titleText}>{t}</Typography>
            </ClickTooltip>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { openActions, openMenu, anchorEl, params } = this.state
    const { classes, finding, workers, urls, branch } = this.props
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)

    return (
      <Paper className={classes.container}>
        <div className={classes.title}>
          {this.renderTitle()}
        </div>
        <div className={classes.subtitle}>
          <div>
            <IconButton size="small" onClick={this.handleOpenMenu}>
              <MoreVert />
            </IconButton>
          </div>
          <div className={classes.titleState}>
            <Typography>Pendiente</Typography>
          </div>
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={classes.resume}>
          <div className={classes.statsContainer}>
            <div className={classes.stat}>
              <CalendarToday />
              <Typography variant="body1">Fecha de detección: {finding.date_detected}</Typography>
            </div>
            <div className={classes.stat}>
              <EventAvailable />
              <Typography variant="body1">Fecha Limite: Por asignar...</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Detectado: {finding.user_creator}</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Responsable: Por asignar...</Typography>
            </div>
            <div className={classes.stat}>
              <ErrorOutline style={{ color: criticities[criticities_index[finding.criticality]] }} />
              <Typography variant="caption">
                {criticities_index[finding.criticality]}
              </Typography>
            </div>
          </div>
          <ImageContainer src={finding.evidence ? `${urls[1]}${finding.evidence}` : "/noimage.png"} alt="foto" height="64px" width="64px" />
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={`${classes.actionContainer} ${openActions ? "open" : ""}`}>
          <div className={`${classes.actionButton} ${openActions ? "open" : ""}`} onClick={this.handleOpenActions}>
            <Typography className={`${classes.buttonText} ${openActions ? "open" : ""}`} variant="subtitle1">Asignar Acción Inmediata</Typography>
            <Typography className={`${classes.buttonHiddenText} ${openActions ? "" : "open"}`} variant="subtitle1">Asignando Acción Inmediata</Typography>
            <KeyboardArrowDown className={`${classes.arrowIcon} ${openActions ? "open" : ""}`} />
          </div>
          <div className={classes.form}>
            <SelectInput
              value={params.user_id}
              options={transformToOptions(filteredWorkers)}
              label="Seleccionar responsable"
              placeholder="Responsables"
              onChange={this.handleChange}
              name="user_id"
            />
            <DateInput label="Fecha límite de resolución" onChange={this.handleChange} name="date_limit" value={params.date_limit} />
            <TextInput label="Acción inmediata" onChange={this.handleChange} value={params.inmediate_action} name="inmediate_action" />
            <div className={classes.finalButton} onClick={this.handleAssing}>
              <Button size="small" variant="outlined" color="primary" disabled={!params.user_id}>Asignar</Button>
            </div>
          </div>
        </div>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={this.handleOpenMenu}>
          <MenuItem className={classes.menuItem} onClick={this.handleGoto}>
            <Typography variant="subtitle1">Ver</Typography>
            <Edit />
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.handleDismiss}>
            <Typography variant="subtitle1">Desestimar</Typography>
            <Delete />
          </MenuItem>
        </Menu>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  online: state.online,
  branch: state.branch
})

export default withRouter(connect(mapStateToProps)(withStyles(cardStyle)(PendingCard)))