import React, { Component } from "react"
import {
    Paper,
    Typography,
    Divider,
    Select,
    MenuItem,
    withStyles,
} from "@material-ui/core"
import "./animation.css"

const style = () => ({
    container: {
        width: 400,
        maxWidth: "100%",
    },
    title: {
        padding: 12,
    },
})

class EnterpriseCard extends Component {
    renderStations() {
        const { branches, user, workers } = this.props
        const level = user.account.user.userType
        const allWorkers = workers.all || []
        const filtered = branches.filter(branch => {
            if (level > 1) {
                const mySelf = allWorkers.find(worker => worker.id === user.account.user.id)
                return branch.id.toString() === mySelf?.branch_id?.toString()
            }
            return true
        })
        return filtered.map((branch, index) => (
            <MenuItem key={index} value={branch.id}>
                {branch.name}
            </MenuItem>
        ))
    }

    render() {
        const { classes, branch, onChange, name } = this.props
        return (
            <Paper className={classes.container} square>
                <div className={classes.title}>
                    <Typography variant="subtitle1">{name}</Typography>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className={classes.title}>
                    <Typography variant="subtitle2">Centro de trabajo</Typography>
                    <Select
                        value={branch.id}
                        style={{ width: 200 }}
                        onChange={onChange}
                        variant="outlined"
                        margin="dense"
                    >
                        {this.renderStations()}
                    </Select>
                </div>
            </Paper>
        )
    }
}

export default withStyles(style)(EnterpriseCard)
