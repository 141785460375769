import stateMaker from "../Utils/stateMaker"
const FindingReducer = (state = { selected: {}, all: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case "GET_FINDINGS":
      return stateMaker(newState, "all", "fetch", action)
    case "ASSIGN_FINDING": {
      const newAll = newState.all
      const selectedFinding = newAll.find(finding => finding.id.toString() === action.payload.id.toString())
      const selectedFindingIndex = newAll.findIndex(finding => finding.id.toString() === action.payload.id.toString())
      selectedFinding.user = action.payload.user
      selectedFinding.date_limit = action.payload.date_limit
      selectedFinding.date_assigned = action.payload.date_assigned
      selectedFinding.inmediate_action = action.payload.inmediate_action
      selectedFinding.status = "Procesando"
      newAll[selectedFindingIndex] = selectedFinding
      newState.all = newAll
      newState.selected = selectedFinding
      return newState
    }

    case "SOLVE_FINDING": {
      const newAll = newState.all
      const selectedFinding = newAll.find(finding => finding.id.toString() === action.payload.id.toString())
      const selectedFindingIndex = newAll.findIndex(finding => finding.id.toString() === action.payload.id.toString())
      selectedFinding.status = "Terminado"
      selectedFinding.solution_comment = action.payload.solution_comment
      selectedFinding.solution_evidence = action.payload.base64
      selectedFinding.date_solved = action.payload.date_solved
      newAll[selectedFindingIndex] = selectedFinding
      newState.all = newAll
      newState.selected = selectedFinding
      return newState
    }
    case "APPROVE_FINDING": {
      const newAll = newState.all
      const selectedFinding = newAll.find(finding => finding.id.toString() === action.payload.id.toString())
      const selectedFindingIndex = newAll.findIndex(finding => finding.id.toString() === action.payload.id.toString())
      selectedFinding.status = "Aprobado"
      selectedFinding.date_approved = action.payload.date_approved
      newAll[selectedFindingIndex] = selectedFinding
      newState.all = newAll
      newState.selected = selectedFinding
      return newState
    }
    case "DISMISS_FINDING": {
      const newAll = newState.all
      const selectedFinding = newAll.find(finding => finding.id.toString() === action.payload.id.toString())
      const selectedFindingIndex = newAll.findIndex(finding => finding.id.toString() === action.payload.id.toString())
      selectedFinding.status = "Desestimado"
      selectedFinding.date_approved = action.payload.date_approved
      newAll[selectedFindingIndex] = selectedFinding
      newState.all = newAll
      newState.selected = selectedFinding
      return newState
    }
    case "SELECT_FINDING": {
      const id = action.payload
      const newSelected = newState.all.find(finding => finding.id.toString() === id)
      newState.selected = newSelected
      return newState
    }
    default: {
      return state
    }
  }
}

export default FindingReducer
