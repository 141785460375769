import React, { Component } from "react"
import { withStyles, Typography, Divider, Tab, Paper, Collapse } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { connect } from "react-redux"
import { createSubBranchUserAction, editSubBranchAction, getBranchAction, getSubBranchDocumentsAction, getSubBranchesAction, setSubBranch } from "../../Actions/BranchActions"
import GeneralTable from "../../Shared/GeneralTable"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import { editBranchAction } from "../../Actions/EnterpriseAction"
import { subBranchInfo } from "../Enterprise/Info"
import { ExitToApp } from "@material-ui/icons"
import AddElementButton from "../../Shared/AddElementButton"
import FormContext from "../../Shared/Cards/Provider"
import GeneralForm from "../../Shared/GeneralForm"
import SubBranchDocuments from "./SubBranchDocuments"
import { getSettingsSubBranchCategoryAction } from "../../Actions/SettingsActions"
import { userInfo } from "./Info"
import { transformToOptions } from "../../Utils/functions"
import moment from "moment"
import Licenses from "./SubBranch/Licenses"
import Registers from "./SubBranch/Registers"

const style = () => ({
  container: { margin: 12 },
  title: {
    margin: "26px 0 12px 0 ",
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const info = [{ name: "Nombre", label: "name" }]

class Branch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      tab: 0,
      loading: true,
      open: false,
      openColaborator: false,
      create_params: {},
      colab_params: {
        dateBirth: moment(new Date()).format("YYYY-MM-DD")
      }
    }
    autobind(Branch, this)
  }

  componentDidMount() {
    const { match, setSubBranch, getSettingsSubBranchCategories, getSubBranchDocuments, getSubBranches } = this.props
    const { id, sub_branch_id } = match.params
    const body = { sub_branch_id: sub_branch_id }

    const query = new URLSearchParams(this.props.location.search)
    const queryOption = query.get("option")
    switch (queryOption) {
      case "workers": {
        this.setState({ tab: 0 })
        break
      }
      case "documents": {
        this.setState({ tab: 1 })
        break
      }
      case "permissions": {
        this.setState({ tab: 2 })
        break
      }
      case "lists": {
        this.setState({ tab: 3 })
        break
      }
      default: break
    }

    getSettingsSubBranchCategories()
    getSubBranchDocuments(body)
    getSubBranches({ branch_id: id }).then(() => {
      setSubBranch(body)
      this.setState({ loading: false })
    })
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleSend(params) {
    const { editSubBranch, match } = this.props
    const body = {
      ...params,
      branch_id: match.params.id,
      id: match.params.sub_branch_id
    }
    editSubBranch(body)
  }

  handleGoto(option) {
    return (row) => {
      return () => {
        const { history } = this.props
        history.push(`${option}/${row.id}`)
      }
    }
  }

  handleChange(event) {
    const { target } = event
    const { colab_params } = this.state
    colab_params[target.name] = target.value
    this.setState({ colab_params })
  }

  handleCreate() {
    const { createSubBranchUser, match, enterprise } = this.props
    const { colab_params } = this.state
    const body = {
      ...colab_params,
      sub_branch_id: match.params.sub_branch_id,
      branch_id: match.params.id,
      companyId: enterprise.selected.id,
      name: colab_params.firstname
    }
    createSubBranchUser(body)
    this.setState({ colab_params: {}, openColaborator: false })
  }

  handleOpenColaborator() {
    this.setState({ openColaborator: !this.state.openColaborator })
  }

  setFormInfo(info) {
    const { enterprise, workers } = this.props
    info.map((input) => {
      switch (input.name) {
        case "branch_id": {
          input.options = transformToOptions(enterprise.branches)
          return input
        }
        case "user_id": {
          input.options = transformToOptions(workers.all)
          return input
        }
        default:
          return input
      }
    })

    return info
  }

  render() {
    const { classes, branch, settings } = this.props
    const { loading, tab, openColaborator, colab_params } = this.state
    const selected = branch.selected_sub_branch
    const users = selected ? selected.users : []

    const allCategories = settings.sub_branch_documents.all || []

    const actions = (url) => [
      {
        name: "Ver Colaborador",
        icon: ExitToApp,
        action: this.handleGoto(url),
      },
    ]

    const formattedInfo = this.setFormInfo(userInfo)

    return (
      <div className={classes.container}>
        <MutableInfoCard
          title="Datos contratista"
          submit="Guardar Cambios"
          subtitle="Editar Centro"
          submitAction={this.handleSend}
          formInfo={subBranchInfo}
          showInfo={subBranchInfo}
          response={selected}
          loading={loading}

        />
        <div />
        <Paper square className={classes.title}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab
              label={
                <Typography variant="h1">
                  Colaboradores
                                </Typography>
              }
              value={0}
            />
            <Tab
              label={
                <Typography variant="h1">
                  Documentos
                                </Typography>
              }
              value={1}
            />
            <Tab
              label={
                <Typography variant="h1">
                  Permisos
                                </Typography>
              }
              value={2}
            />
            <Tab
              label={
                <Typography variant="h1">
                  Listas
                                </Typography>
              }
              value={3}
            />
          </MyTabs>
        </Paper>
        <TabPanel value={tab} index={0}>
          <div className={classes.inline}>
            <Typography variant="h1" className={classes.title}>
              Tabla de Colaboradores
          </Typography>
            <AddElementButton open={openColaborator} onClick={this.handleOpenColaborator}>Agregar colaborador</AddElementButton>
          </div>
          <Collapse in={openColaborator}>
            <div>
              <FormContext.Provider value={{ handleChange: this.handleChange, params: colab_params }}>
                <GeneralForm
                  info={formattedInfo}
                  onSubmit={this.handleCreate}
                  open
                />
              </FormContext.Provider>
            </div>
          </Collapse>
          <Divider style={{ marginBottom: 24 }} />
          <GeneralTable
            data={users}
            info={info}
            actions={actions('/workers')}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className={classes.inline}>
            <Typography variant="h1">
              Documentos de Contratista
            </Typography>
          </div>
          <SubBranchDocuments categories={allCategories} documents={[]} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Licenses />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Registers />
        </TabPanel>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enterprise: state.enterprise,
  user: state.user,
  branch: state.branch,
  settings: state.settings,
  workers: state.workers
})

const mapDispatchToProps = (dispatch) => ({
  getBranch: (body) => dispatch(getBranchAction(body)),
  editBranch: (body, snackbar) => dispatch(editBranchAction(body, snackbar)),
  setSubBranch: body => dispatch(setSubBranch(body)),
  getSettingsSubBranchCategories: body => dispatch(getSettingsSubBranchCategoryAction(body)),
  getSubBranchDocuments: body => dispatch(getSubBranchDocumentsAction(body)),
  createSubBranchUser: body => dispatch(createSubBranchUserAction(body)),
  getSubBranches: body => dispatch(getSubBranchesAction(body)),
  editSubBranch: body => dispatch(editSubBranchAction(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Branch))
