import React, { Component } from 'react'
import { Tab, Typography, withStyles } from '@material-ui/core'
import TabPanel from "../../../Shared/TabPanel"
import MyTabs from "../../../Shared/MyTabs"
import autobind from '../../../Utils/autobind'
import SubBranchDocuments from '../SubBranchDocuments/SubBranchDocuments'
import Permissions from './Permissions'
import Lists from './Lists'

const style = () => ({
  tabs: {
    background: 'white'
  }
})

class SubBranches extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0
    }
    autobind(SubBranches, this)
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  render() {
    const { classes } = this.props
    const { tab } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h6">Configuración contratistas</Typography>
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={0} label={<Typography variant="h1">Permisos</Typography>} />
            <Tab value={1} label={<Typography variant="h1">Listas</Typography>} />
            <Tab value={2} label={<Typography variant="h1">Documentos</Typography>} />
          </MyTabs>
        </div >
        <TabPanel value={tab} index={0}>
          <Permissions />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Lists />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <SubBranchDocuments />
        </TabPanel>
      </div>
    )
  }
}

export default withStyles(style)(SubBranches)