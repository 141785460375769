import { Typography, withStyles } from "@material-ui/core";
import React, { Component } from "react";

const style = () => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: 0.8,
    },
});

class InlineInfo extends Component {
    render() {
        const { classes, primary, secondary, color } = this.props;
        return (
            <div className={classes.container}>
                <Typography variant="subtitle2" style={{ color: color }}>
                    {primary}
                </Typography>
                <Typography variant="subtitle2" style={{ color: color }}>
                    {secondary}
                </Typography>
            </div>
        );
    }
}

InlineInfo.propTypes = {};

InlineInfo.defaultProps = {
    primary: "primary",
    secondary: "secondary",
};

export default withStyles(style)(InlineInfo);
