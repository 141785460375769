import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from 'chart.js'

const style = () => ({

})

class SelectChart extends Component {
  componentDidMount() {
    const { data, labels } = this.props
    const { idName } = this.props
    const chartElement = document.getElementById(idName)
    const myChartRef = chartElement.getContext('2d')
    chartElement.parentNode.style.height = '100%'
    chartElement.parentNode.style.width = '100%'

    this.chart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: ["#f2f5ff", "#f6ec79", "#ffab6e", "#ff6c87"],
            borderColor: "white",
            pointRadius: 0,
          },
        ]
      },
    })
  }

  render() {
    const { classes, idName } = this.props
    return (
      <div className={classes.container}>
        <div>
          <canvas id={idName} ref={this.chartRef} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(SelectChart)