import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { connect } from 'react-redux'
import { transformToOptions } from '../../../Utils/functions'
import autobind from '../../../Utils/autobind'
import moment from 'moment'

const style = theme => ({
  container: {
    padding: 12
  },
  title: {
    background: theme.palette.primary.main,
    padding: 24,
    color: 'white',
    fontWeigth: 600
  },
  button: {
    padding: 12
  }
})

class LicenseDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        date_start: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        date_end: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      }
    }
    autobind(LicenseDialog, this)
  }

  componentDidUpdate(prevProps) {
    const { baseParams } = this.props
    if (baseParams && baseParams !== prevProps.baseParams) {
      this.setState({
        params: {
          date_start: baseParams.date_start,
          date_end: baseParams.date_end,
          order_id: baseParams.order_id,
          areas_id: baseParams.areas.map(a => a.id.toString()),
          types_id: baseParams.types.map(t => t.id.toString()),
          description: baseParams.description,
          supervisor_id: baseParams.supervisor_id
        }
      })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { onCreate, onClose } = this.props
    const { params } = this.state
    const body = { ...params }
    onCreate(body)
    this.setState({
      params: {
        date_start: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        date_end: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      }
    })
    onClose()
  }

  render() {
    const { classes, open, onClose, settings, branch, edit = false } = this.props
    const { params } = this.state
    const subBranchUsers = branch?.global_sub_branch?.users || []
    const allAreas = settings?.areas?.all || []
    const allTypes = settings?.types?.all || []
    return (
      <Dialog open={open} onClose={onClose}>
        <Typography variant="h1" className={classes.title}>{edit ? "Editar" : "Nuevo"} Permiso</Typography>
        <div className={classes.container}>
          <TextInput label="Id Orden" name="order_id" value={params.order_id} onChange={this.handleChange} />
          <DateTimeInput label="Fecha inicio" name="date_start" value={params.date_start} onChange={this.handleChange} />
          <DateTimeInput label="Fecha término" name="date_end" value={params.date_end} onChange={this.handleChange} />
          <MultiSelectInput options={transformToOptions(allTypes)} label="Permisos" name="types_id" value={params.types_id} onChange={this.handleChange} />
          <MultiSelectInput options={transformToOptions(allAreas)} label="Área de trabajo" name="areas_id" value={params.areas_id} onChange={this.handleChange} />
          <SelectInput options={transformToOptions(subBranchUsers)} label="Supervisor" name="supervisor_id" value={params.supervisor_id} onChange={this.handleChange} />
          <TextInput options={{ multiline: true, rows: 3 }} label="Descripción" name="description" value={params.description} onChange={this.handleChange} />
        </div>
        <div className={classes.button}>
          <SubmitButton onClick={this.handleCreate}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  branch: state.branch
})

export default connect(mapStateToProps)(withStyles(style)(LicenseDialog))