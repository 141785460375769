/* eslint-disable no-useless-computed-key */
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
    spacing: 6,
    shape: { borderRadius: 5 },
    palette: {
        primary: {
            main: "#202d4c",
            contrastText: "white",
            dark: "#22607b",
            light: "#1e1e2d21",
            lighter: "#dce9f2",
        },
        secondary: {
            main: "#8f4799",
        },
        paper: {
            main: "#ECECEC",
            dark: "#BABABA",
        },
        error: {
            main: "#ff6c87",
        },
        green: {
            main: "#91deb1"
        },
        red: {
            main: "#ff6c87"
        },
        blue: {
            main: "#7373e8",
            light: "#ADF5FF",
            dark: "#202D4C",
            transparent: "#ADF5FF42",
        },
        grey: {
            main: "#ECECEC",
            medium: "#6A6A6A",
            dark: "#3E3E3E",
            dark2: "#6A6A6A",
            border: "#CACACA",
        },
        indigo: {
            main: "#1A237E",
            dark: "#000051",
            light: "#534BAE",
            lighter: "#DCE2F2",
        },
        confirm: {
            main: "#249e24",
        },
        middle: {
            main: "#adaa2e",
        },
        purple: {
            light: "#B865C1",
            dark: "#8F4799"
        },
        orange: {
            main: '#ffab6e'
        }
    },
    props: {
        MuiTypography: {
            variantMapping: {
                caption: 'p'
            }
        }
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
        h1: {
            color: "#202D4C",
            fontSize: "1.4rem",
            fontWeight: 600,
        },
        h2: {
            color: "#6C6C6C",
            fontSize: "1rem",
            fontWeight: 400,
        },
        subtitle1: {
            color: "#6A6A6A",
            fontSize: "0.95rem",
            fontWeight: 400,
        },
        subtitle2: {
            color: "#6A6A6A",
            fontSize: "0.95rem",
            fontWeight: 400,
        },
        body1: {
            letterSpacing: 0,
            color: "#707070",
            fontSize: "0.9rem",
            fontWeight: 400,
        },
        body2: {
            color: "#404040",
            fontSize: "0.95rem",
            ["@media (max-width:500px)"]: {
                fontSize: "0.95rem",
            },
        },
        h3: {
            color: "#6C6C6C",
            fontSize: "0.95rem",
            fontWeight: 400,
        },
        h4: {
            color: "#6C6C6C",
            fontSize: "1.1rem",
            fontWeight: 600,
        },
        h5: {
            color: "#6C6C6C",
            fontSize: "1.3rem",
            fontWeight: 500,
        },
        h6: {
            color: "#202D4C",
            fontSize: "1.6rem",
            fontWeight: 600,
            margin: 24
        },
        subtitle: {
            color: "#6A6A6A",
            fontSize: "1rem",
            fontWeight: 500,
        },
        caption: {
            color: "#6A6A6A",
            fontStyle: "italic",
            fontSize: "0.85rem",
            fontWeight: 400,
        },
    },
    overrides: {
        MuiSelect: {
            select: {
                props: {
                    focus: {
                        background: 'unset'
                    }
                }
            }
        },
        MuiTableCell: {
            body: {
                fontSize: "0.95rem",
                ["@media (max-width:600px)"]: {
                    fontSize: "0.9rem",
                },
                color: "grey",
            },
            head: {
                fontSize: "0.95rem",
                ["@media (max-width:600px)"]: {
                    fontSize: "0.9rem",
                },
                '& h6': {
                    fontWeight: 600
                }
            },
            root: {
                padding: '6px 12px',
                ["@media (max-width:600px)"]: {
                    fontSize: "0.9rem",
                },
                borderBottom: "1px solid #6a6a6a45",
            },
            stickyHeader: {},
        },
        MuiTableRow: {
            root: {
                height: 55,
            },
        },
        MuiButton: {
            root: {
                fontWeight: 500,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "darkgrey",
                opacity: 0.8,
            },
        },
        MuiIconButton: {
            colorSecondary: {
                color: "grey",
                "&:hover": {
                    color: "#cb6a6d",
                },
            },
        },
        MuiMenu: {
            paper: {
                maxHeight: 300,
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 15
            },
            elevation1: {
                boxShadow: "0 0 20px rgb(8 21 66 / 5%)",
            },
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: "#249e24",
            },
            colorSecondary: {
                backgroundColor: "#cb6a6d",
            },
        },
        MuiInputLabel: {
            outlined: {
                backgroundColor: "#FFF",
                paddingLeft: 2,
                paddingRight: 2,
            },
        },
        MuiTableHead: {
            root: {
                background: "#ECECEC",
                fontWeight: 600
            }
        }
    },
})

export default theme
