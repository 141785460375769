import React, { Component } from "react"
import { withStyles, Typography, Divider } from "@material-ui/core"
import GeneralTable from "../../Shared/GeneralTable"
import { ExitToApp } from "@material-ui/icons"
import autobind from "../../Utils/autobind"
import {
	getWorkersAction,
	createWorkerAction,
	getBranches,
} from "../../Actions/EnterpriseAction"
import { connect } from "react-redux"
import Loader from "../../Shared/Loader"
import GeneralForm from "../../Shared/GeneralForm"
import { userInfo, userTableInfo } from "./Info"
import { transformToOptions, validateForm } from "../../Utils/functions"
import FormContext from "../../Shared/Cards/Provider"
import AddElementButton from "../../Shared/AddElementButton"
import SelectInput from "../../Shared/Inputs/SelectInput"
import moment from 'moment'

const style = () => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "6px 0",
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
	},
	filter: {
		maxWidth: 300,
		"@media (max-width:500px)": {
			width: "100%",
			maxWidth: 'unset'
		}
	}
})

class Workers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {
				dateBirth: moment(new Date()).format("YYYY-MM-DD")
			},
			open: false,
			branch: "-1"
		}
		autobind(Workers, this)
	}

	componentDidMount() {
		const { getWorkers, getBranches } = this.props
		const body = { companyId: 4 }
		getWorkers(body)
		getBranches(body)
	}

	handleOpenForm() {
		this.setState({ open: !this.state.open })
	}

	handleGoto(row) {
		return () => {
			const { history } = this.props
			history.push(`/workers/${row.id}`)
		}
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleCreate() {
		const { params } = this.state
		const body = { ...params }
		const { createWorker } = this.props
		body.name = body.firstname
		body.dateBirth = moment(new Date()).format("YYYY-MM-DD")
		if (validateForm(userInfo, params)) {
			createWorker(body).then(response => {
				if (response.payload.data.status === "success") {
					this.setState({ open: false, params: {} })
				}
			})

		}
	}

	handleChangeBranch(event) {
		const { target } = event
		this.setState({ branch: target.value })
	}

	setFormInfo(info) {
		const { enterprise, workers } = this.props
		const { params } = this.state
		const allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === params?.branch_id)
		info.map((input) => {
			switch (input.name) {
				case "branch_id": {
					input.options = transformToOptions(enterprise.branches)
					return input
				}
				case "user_id": {
					input.options = transformToOptions(filteredWorkers)
					return input
				}
				default:
					return input
			}
		})

		return info
	}

	renderTable(actions, data, info) {
		return () => <GeneralTable actions={actions} data={data} info={info} exportable name="Colaboradores" />
	}

	render() {
		const { classes, workers, getWorkers, enterprise, user } = this.props
		const { params, open, branch } = this.state
		const level = user.account.user.userType
		const actions = level === 1 ? [
			{
				name: "Ver Colaborador",
				icon: ExitToApp,
				action: this.handleGoto,
			},
		] : []
		const body = { companyId: 4 }
		const data = workers ? workers.all : []
		const filtered = data.filter(worker => {
			if (branch.toString() !== "-1") {
				if (branch === "not") {
					if (worker.branch_id === null) {
						return true
					} else {
						return false
					}
				} else {
					if (worker.branch_id === branch) {
						return true
					} else {
						return false
					}
				}

			}
			return true
		})

		const formattedInfo = this.setFormInfo(userInfo)
		const allBranches = enterprise.branches ? [{ id: "-1", name: "Todas" }, ...enterprise.branches, { id: "not", name: "No asignado" }] : [{ id: "-1", name: "Todas" }, { id: "not", name: "No asignado" }]

		return (
			<div className={classes.container}>
				<div className={classes.inline}>
					<Typography variant="h1" className={classes.title}>
						Tabla de colaboradores
                    </Typography>
					{level === 1 && <AddElementButton name="Crear Colaborador" open={open} onClick={this.handleOpenForm} />}
				</div>
				<Divider style={{ marginBottom: 42 }} />
				<div className={classes.filter}>
					<SelectInput
						placeholder="seleccione sucursal"
						label="Sucursal"
						name="branch_id"
						value={branch}
						onChange={this.handleChangeBranch}
						options={transformToOptions(allBranches)}
					/>
				</div>
				<FormContext.Provider value={{ handleChange: this.handleChange, params }}>
					<GeneralForm
						info={formattedInfo}
						onSubmit={this.handleCreate}
						open={open}
					/>
				</FormContext.Provider>
				<Loader action={getWorkers} params={[body]}>
					{this.renderTable(actions, filtered, userTableInfo)}
				</Loader>
			</div>
		)
	}
}

Workers.propTypes = {}

Workers.defaultProps = {
	workers: { all: [] },
}

const mapStateToProps = (state) => ({
	workers: state.workers,
	enterprise: state.enterprise,
	user: state.user,
	branch: state.branch

})

const mapDispatchToProps = (dispatch) => ({
	getWorkers: (body) => dispatch(getWorkersAction(body)),
	createWorker: (body) => dispatch(createWorkerAction(body)),
	getBranches: (body) => dispatch(getBranches(body)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Workers))
