import { Divider, Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"

const style = (theme) => ({
    container: {
        padding: 12,
        flexGrow: 1,
        flexBasis: 300,
        maxWidth: 300,
        gap: "12px",
        transition: "all 0.1s linear",
        "@media (max-width:500px)": {
            flexBasis: "100%",
            maxWidth: "unset",
        },
        "&:hover": {
            background: `${theme.palette.blue.light}22`,
            transform: "scale(1.02)",
            cursor: "pointer",
        },
        position: 'relative',
        height: 156,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        textAlign: 'left',
        background: `${theme.palette.blue.light}42`
    },
    amount: {
        height: 52,
        minWidth: 52,
        maxWidth: 52,
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: "50%",
        position: 'relative',
        "& > h5": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        marginLeft: 8
    },
    entry: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})

class MultipleCountCard extends Component {
    renderAmounts() {
        const { titles, amounts, classes, colors } = this.props
        return titles.map((title, index) => {
            const isLast = index === titles.length - 1
            return (
                <React.Fragment key={title}>
                    <div className={classes.entry}>
                        <Typography variant="h4">{title}</Typography>
                        <div className={classes.amount} style={{ background: colors[index] }}>
                            <Typography variant="h5" style={{ color: 'white' }}>
                                {amounts[index]}
                            </Typography>
                        </div>
                    </div>
                    {!isLast && <Divider />}
                </React.Fragment>
            )
        })
    }
    render() {
        const { classes, onClick } = this.props
        return (
            <Paper className={classes.container} onClick={onClick}>
                {this.renderAmounts()}
            </Paper>
        )
    }
}

MultipleCountCard.propTypes = {}

MultipleCountCard.defaultProps = {
    title: "Cantidad de elementos",
    amount: 51,
    color: "",
}

export default withStyles(style)(MultipleCountCard)
