import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import instance from '../../../Utils/instance'
import LoaderComponent from '../../../Shared/LoaderComponent'
import { ErrorOutline } from '@material-ui/icons'
import TextInput from '../../../Shared/Inputs/TextInput'
import CheckListElement from '../CheckListElement'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import autobind from '../../../Utils/autobind'
import { connect } from 'react-redux'
import { logoutAction } from '../../../Actions/LoginActions'

const style = (theme) => ({
  container: {
    margin: 12,
    paddingBottom: 48
  },
  title: {
    padding: 12,
  },
  paper: {
    marginTop: 24,
    padding: 12,
  },
  inline: {
    display: "flex",
    gap: "12px",
    alignItems: "flex-end",
    margin: "3px 0",
  },
  inlineContainer: {
    margin: "12px 0",
  },
  endButton: {
    textAlign: 'end',
    marginTop: 12
  },
  upFab: {
    position: 'fixed',
    bottom: 12,
    right: -64,
    transition: 'all 0.2s ease-in-out'
  },
  observations: {
    marginTop: 24,
    background: 'white',
    padding: 12,
    borderRadius: 15
  },
  progressLabel: {
    position: "sticky",
    top: "78px",
    zIndex: "5",
    width: 200,
  },
  label: {
    background: theme.palette.primary.main,
    maxWidth: "150px",
    borderTopRightRadius: "24px",
    borderBottomRightRadius: "24px",
    opacity: 0.7,
    margin: '3px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 12,
    '&>h6': {
      color: 'white',
      padding: 6
    }
  },
  inlineSep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  responsible: {
    background: theme.palette.green.main,
    padding: '6px 12px',
    borderRadius: 5,
    float: 'right',
    '& > *': {
      color: 'white'
    }
  },
  extraTitle: {
    margin: "12px 0"
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fieldsContainer: {
    background: 'white',
    borderRadius: 15,
    padding: 12,
    marginTop: 12
  },
  extraField: {
    width: '48%',
    margin: "0 1%",
    "@media (max-width:500px)": {
      maxWidth: "unset",
      margin: 0,
      width: '100%'
    }
  },
  finalCard: {
    background: 'white',
    borderRadius: 15,
    padding: 24,
    textAlign: 'center',
    maxWidth: 800,
    '& > *': {
      margin: "12px 0"
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    "@media (max-width:500px)": {
      maxWidth: "unset",
      margin: 12,
      left: 12,
      right: 12,
      transform: 'translate(0, -50%)',
    }
  }
})

class PublicChecklist extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      checklist: {},
      ready: false
    }
    autobind(PublicChecklist, this)
  }
  componentDidMount() {
    const { match, logout } = this.props
    logout()
    const { token } = match.params
    const params = JSON.parse(atob(token))
    this.setState({ loading: true })
    instance({
      method: 'get',
      url: 'checklists/public_get.php',
      params
    }).then(response => {
      const checklist = response.data.info
      this.setState({ loading: false, checklist })
    })
  }

  handleClick(element, value) {
    return () => {
      const { checklist } = this.state
      const newChecklist = { ...checklist }
      const items = [...newChecklist.checklist_items]
      const newElement = { ...element }
      const oldElementIndex = items.findIndex(e => e.id === element.id)
      newElement.answer.value_id = value
      newElement.answer.value = value
      newElement.answer.id = 0
      items[oldElementIndex] = newElement
      newChecklist.checklist_items = items
      this.setState({ checklist: newChecklist })
    }
  }

  handleCrit(element, value) {
    return () => {
      const { checklist } = this.state
      const newChecklist = { ...checklist }
      const items = [...newChecklist.checklist_items]
      const newElement = { ...element }
      const oldElementIndex = items.findIndex(e => e.id === element.id)
      newElement.answer.criticality = value
      items[oldElementIndex] = newElement
      newChecklist.checklist_items = items
      this.setState({ checklist: newChecklist })
    }
  }

  handleComment(element, value) {
    const { checklist } = this.state
    const newChecklist = { ...checklist }
    const items = [...newChecklist.checklist_items]
    const newElement = { ...element }
    const oldElementIndex = items.findIndex(e => e.id === element.id)
    newElement.answer.comments = value
    items[oldElementIndex] = newElement
    newChecklist.checklist_items = items
    this.setState({ checklist: newChecklist })
  }

  handleSelectFile(event, element) {
    const { target } = event
    const file = target.files[0]

    const { checklist } = this.state
    const newChecklist = { ...checklist }
    const items = [...newChecklist.checklist_items]
    const newElement = { ...element }
    const oldElementIndex = items.findIndex(e => e.id === element.id)

    items[oldElementIndex] = newElement
    newChecklist.checklist_items = items

    // Send to db and receive img name to save on the result
    const body = new FormData()
    body.append("file", file)
    const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/public_file.php`
    instance({
      method: 'post',
      url,
      data: body
    }).then(response => {
      newElement.answer.file = response.data?.info?.file_name
      this.setState({ checklist })
    })
  }

  handleChangeExtra(event) {
    const { target } = event
    const { checklist } = this.state
    const extra = checklist.extra_fields
    extra[target.name].value = target.value
    this.setState({ checklist })
  }

  handleChangeExtraImg(event) {
    const { target } = event
    const { checklist } = this.state
    const extra = checklist.extra_fields
    const file = target.value

    //Upload File and get the name of the saved one
    const body = new FormData()
    body.append("file", file)
    const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/public_file.php`
    instance({
      method: 'post',
      url,
      data: body
    }).then(response => {
      extra[target.name].value = response.data?.info?.file_name
      this.setState({ checklist })
    })
  }

  simulateClick(element) {
    return () => {
      const input = document.getElementById(`fileInput-${element.id}`)
      input.click()
    }
  }

  handleChange(event) {
    const { target } = event
    const { checklist } = this.state
    checklist.observations = target.value
    this.setState({ checklist })
  }

  handleSave() {
    const { checklist } = this.state
    const body = { ...checklist }
    this.setState({ ready: true })
    this.componentDidMount()
  }

  getProgress() {
    const { checklist } = this.state
    const checklistElements = checklist.checklist_items || []
    const total = checklistElements.length
    let added = 0
    checklistElements.forEach(element => {
      if (element.answer.value_id !== null) {
        added++
      }
    })
    return Math.round(added / total * 100, 10)
  }

  renderList() {
    const { checklist } = this.state
    const elements = checklist.checklist_items || []
    return elements.map((element, index) => {
      return (
        <CheckListElement
          element={element}
          key={index}
          simulateClick={this.simulateClick}
          handleSelectFile={this.handleSelectFile}
          handleClick={this.handleClick}
          handleCrit={this.handleCrit}
          handleComment={this.handleComment}
          disabled={false}
          online={true}
        />
      )
    })
  }

  renderExtraFields() {
    const { classes } = this.props
    const { checklist } = this.state
    const selected = checklist
    const fields = selected?.extra_fields || []

    function transofrmToExtraOptions(options) {
      return options.map(option => {
        return {
          label: option,
          value: option
        }
      })
    }

    return fields.map((field, index) => {
      switch (field.type) {
        case "text": {
          return (
            <div className={classes.extraField}>
              <TextInput
                disabled={selected.status === "Terminado"}
                name={index}
                value={field.value}
                onChange={this.handleChangeExtra}
                label={field.label}
              />
            </div>
          )
        }
        case "select": {
          return (
            <div className={classes.extraField}>
              <SelectInput
                disabled={selected.status === "Terminado"}
                name={index}
                value={field.value}
                onChange={this.handleChangeExtra}
                label={field.label}
                options={transofrmToExtraOptions(field.options)}
              />
            </div>
          )
        }
        case "img": {
          return (
            <div className={classes.extraField}>
              <ImgInput
                disabled={selected.status === "Terminado"}
                name={index}
                value={field.value}
                onChange={this.handleChangeExtraImg}
                label={field.label}
              />
            </div>
          )
        }
        default: {
          return (
            <div className={classes.extraField}>
              <TextInput
                disabled={selected.status === "Terminado"}
                name={index}
                value={field.value}
                onChange={this.handleChangeExtra}
                label={field.label}
              />
            </div>
          )
        }
      }

    })
  }

  renderReady() {
    const { classes } = this.props
    return (
      <div className={classes.finalCard}>
        <Typography variant="h1">¡Gracias por tus respuestas!</Typography>
        <Typography variant="subtitle1">Si deseas puedes realizar nuevamente este control presionando el siguiente botón</Typography>
        <Button color="primary" variant="contained" size="large" onClick={() => this.setState({ ready: false })}>Realizar Otra</Button>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { loading, checklist, ready } = this.state
    const disabled = false
    const observations = checklist.observations
    const progress = this.getProgress()
    console.log(checklist)
    const items = checklist.checklist_items || []
    if (ready) return this.renderReady()
    return (
      <div className={classes.container}>
        <Paper square className={classes.paper}>
          <LoaderComponent loading={loading}>
            <div className={classes.inlineSep}>
              <div className={classes.title}>
                <Typography variant="h1" >
                  {checklist?.activity || "Actividad"}
                </Typography>
                <Typography variant="caption">
                  {checklist?.preventive_measure || "Medida Preventiva"}
                </Typography>
              </div>
            </div>
          </LoaderComponent>
        </Paper>
        {items.length > 0 &&
          <div className={classes.inlineContainer}>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#ff6c87" }} />
              <Typography variant="caption">Altamente Crítico</Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#ffab6e" }} />
              <Typography variant="caption">Medianamente Crítico</Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#f6ec79" }} />
              <Typography variant="caption">Poco Crítico</Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#f2f5ff" }} />
              <Typography variant="caption">No Crítico</Typography>
            </div>
          </div>
        }
        <LoaderComponent loading={loading}>
          {this.renderList()}
          <div className={classes.fieldsContainer}>
            <Typography variant="h2" className={classes.extraTitle}>Información a completar</Typography>
            <div className={classes.fields}>
              {this.renderExtraFields()}
            </div>
          </div>
          <div className={classes.observations}>
            <Typography variant="body1">Observaciones</Typography>
            <TextInput
              name="observations"
              label="Comentarios"
              onChange={this.handleChange}
              value={observations}
              disabled={disabled}
            />
          </div>
          <div className={classes.endButton}>
            <Button variant="contained" color="primary" size="small" onClick={this.handleSave} disabled={progress < 100}>
              Guardar y Terminar
            </Button>
          </div>
        </LoaderComponent>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction())
})

export default connect(null, mapDispatchToProps)(withStyles(style)(PublicChecklist))