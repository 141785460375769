import React, { Component } from 'react'
import { Button, Collapse, Dialog, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import { AddCircleOutline, Edit, KeyboardArrowRight, TrafficRounded } from '@material-ui/icons'
import GeneralTable from '../../../Shared/GeneralTable'
import { createSettingChecklistAction, editSettingChecklistAction, getSettingChecklistsAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import autobind from '../../../Utils/autobind'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import QuickAccessCell from './QuickAccessCell'
import SubmitButton from '../../../Shared/SubmitButton'

const style = theme => ({
  container: {
    padding: 12,
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
    borderRadius: 15,
    padding: 24
  },
  cardContent: {
    margin: '12px 0'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    maxWidth: 400,
    marginRight: 24,
    width: '100%'
  },
  title: {
    marginBottom: 24
  },
  table: {
    background: theme.palette.blue.main,
    padding: 12,
    "& > h1": {
      color: 'white'
    },
    marginTop: 36,
    marginBottom: 24,
  },
  dialogInput: {
  },
  dialog: {
    padding: 24
  },
  editButton: {
    padding: 12,
    textAlign: 'end'
  },
  dialogTitle: {
    marginBottom: 24
  }
})

const tableInfo = [
  { name: "Nombre", label: "name" },
  { name: "Acceso Rapido", label: "quick_access", render: QuickAccessCell }
]

class ChecklistSettings extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      openDialog: false,
      openCreate: false,
      selected: null,
      loading: TrafficRounded
    }
    autobind(ChecklistSettings, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  componentDidMount() {
    const { getSettingsChecklists } = this.props
    getSettingsChecklists().then(() => {
      this.setState({ loading: false })
    })
  }

  handleGoto(row) {
    return () => {
      const { history } = this.props
      history.push(`/settings/checklists/${row.id}`)
    }
  }

  handleEdit(row) {
    return () => {
      this.setState({
        openDialog: true, selected: row,
        params: {
          ...this.state.params,
          newName: row.name,
          newDescription: row.description,
          newCode: row.code,
          newRevision: row.revision
        }
      })
    }
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  handleCreate() {
    const { params } = this.state
    const { createSettingsChecklist } = this.props
    const body = { ...params }
    this.setState({ params: {} })
    createSettingsChecklist(body)
  }

  handleSendEdit() {
    const { params, selected } = this.state
    const { editSettingsChecklist } = this.props
    const body = {
      name: params.newName,
      code: params.newCode,
      revision: params.newRevision,
      description: params.newDescription,
      quick_access: selected.quick_access,
      id: selected.id
    }
    editSettingsChecklist(body)
    this.setState({ openDialog: false })
  }

  handleOpenCreate() {
    this.setState({ openCreate: !this.state.openCreate })
  }

  render() {
    const { classes, settings } = this.props
    const allChecklists = settings.checklists.all || []

    const actions = [
      { name: "Editar Lista de Chequeo", icon: Edit, action: this.handleEdit, color: "primary" },
      { name: "Ver Lista de Chequeo", icon: KeyboardArrowRight, action: this.handleGoto, color: "primary" },
    ]

    const { params, openDialog, loading, openCreate } = this.state

    return (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>Configuración Listas de Chequeo</Typography>
        <Paper className={classes.card} square>
          <div className={classes.cardTitle}>
            <Typography variant="h1">Crear Lista de Chequeo</Typography>
            <IconButton onClick={this.handleOpenCreate}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <Collapse in={openCreate}>
            <div className={classes.cardContent}>
              <div className={classes.input} >
                <TextInput label="Nombre" placeholder="Ingresar Lista de Chequeo" name="name" value={params.name} onChange={this.handleChange} />
              </div>
              <div className={classes.input} >
                <TextInput options={{ multiline: true }} label="Descripción" name="description" value={params.description} onChange={this.handleChange} />
              </div>
              <div className={classes.input} >
                <TextInput label="Código" name="code" value={params.code} onChange={this.handleChange} />
              </div>
              <div className={classes.input} >
                <TextInput label="Revisión" name="revision" value={params.revision} onChange={this.handleChange} />
              </div>
              <SubmitButton onClick={this.handleCreate}>
                Crear Lista de Chequeo
            </SubmitButton>
            </div>
          </Collapse>
        </Paper>
        <Paper className={classes.table}>
          <Typography variant="h1">Listas de Chequeo</Typography>
        </Paper>
        {loading ?
          <LoaderAnimator />
          :
          <GeneralTable data={allChecklists} info={tableInfo} actions={actions} />
        }
        <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={this.handleCloseDialog}>
          <div className={classes.dialog}>
            <div className={classes.dialogTitle}>
              <Typography variant="h1">Editar Lista de Chequeo</Typography>
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Nuevo Nombre" name="newName" value={params.newName} onChange={this.handleChange} />
            </div>
            <div className={classes.dialogInput} >
              <TextInput options={{ multiline: true }} label="Descripción" name="newDescription" value={params.newDescription} onChange={this.handleChange} />
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Código" name="newCode" value={params.newCode} onChange={this.handleChange} />
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Revisión" name="newRevision" value={params.newRevision} onChange={this.handleChange} />
            </div>
            <div className={classes.editButton}>
              <Button onClick={this.handleSendEdit} color="primary" variant="contained">Editar</Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsChecklists: () => dispatch(getSettingChecklistsAction()),
  createSettingsChecklist: (body) => dispatch(createSettingChecklistAction(body)),
  editSettingsChecklist: (body) => dispatch(editSettingChecklistAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistSettings))