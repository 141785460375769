import React, { Component } from 'react'
import { Button, Checkbox, Dialog, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { createChecklistItemAction, deleteChecklistItemAction, editChecklistItemAction, editSettingChecklistAction, selectSettingsChecklistAction, updateChecklistExtraFieldAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import TextInput from '../../../Shared/Inputs/TextInput'
import autobind from '../../../Utils/autobind'
import { Add, CheckCircleOutline, Delete, Edit, Save } from '@material-ui/icons'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../Shared/SubmitButton'

const style = theme => ({
  container: {
    padding: 12
  },
  title: {
    padding: 24,
  },
  input: {
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: "0 6px"
    }
  },
  cardContent: {
    padding: 24,
    '& > h2': {
      margin: "12px 0"
    }
  },
  divider: {
    padding: 12
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 46,
    position: 'relative',
    '&::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "100%",
      width: 2,
      bottom: 0
    },
    '&:first-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "50%",
      width: 2,
      bottom: 0
    },
    '&:last-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "50%",
      width: 2,
      top: 0
    },
    '&::after': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 18,
      height: 12,
      width: 12,
      borderRadius: '50%'
    },
    '&:only-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "0%",
      width: 2,
      bottom: 0
    },
  },
  optionMain: {
    paddingLeft: 46,
    position: 'relative',
    '&::after': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: 18,
      height: 12,
      width: 12,
      top: 3,
      borderRadius: '50%'
    },
    '&::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "100%",
      width: 2,
      bottom: 0
    },
  },
  optionsContainer: {
    paddingLeft: 24,
    position: 'relative',
    '&::before': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -23,
      height: 2,
      width: 36,
      top: 8
    },
  },
  option: {
    position: 'relative',
    paddingLeft: 8,
    '&::before': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -7,
      height: "100%",
      width: 2,
      bottom: 0
    },
    '&:first-child::before': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -7,
      height: "50%",
      width: 2,
      bottom: 0
    },
    '&:last-child::before': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -7,
      height: "50%",
      width: 2,
      top: 0
    },
    '&::after': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -12,
      height: 12,
      width: 12,
      borderRadius: '50%',
      top: 4
    },
    '&:only-child::before': {
      content: "''",
      background: theme.palette.grey.main,
      position: 'absolute',
      left: -7,
      height: "0%",
      width: 2,
      bottom: 0
    },
  },
  warning: {
    padding: 24
  },
  dialogButtons: {
    padding: 12,
    textAlign: 'end',
    '& > *': {
      marginLeft: 6
    }
  },
  dialogInput: {
    padding: 12
  },
  items: {
    padding: 24
  },
  addExtra: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  extra: {
    padding: 24,
    '& > h2': {
      margin: "12px 0"
    }
  },
  extraField: {
    width: 300,
    marginRight: 12,
    "@media (max-width:500px)": {
      maxWidth: "unset",
      margin: 0,
      width: '100%'
    }
  },
  optionsCreator: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  optionsCreated: {
    marginLeft: 24
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > button': {
      marginLeft: 12
    }
  },
  buttons: {
    display: 'flex'
  }
})

const fields = [
  { label: "Texto", value: "text" },
  { label: "Lista con opciones", value: "select" },
  { label: "Imagen", value: "img" },
  { label: "IDS (Experimental)", value: "ids" }
]

const crits = [
  { label: "No asignar valor por defecto", value: 0 },
  { label: "No Crítico", valule: 1 },
  { label: "Poco Crítico", value: 2 },
  { label: "Medianamente Crítico", value: 3 },
  { label: "Altamente Crítico", value: 4 }
]

function parseOption(option) {
  const [value, message, color] = option.split('&')
  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="subtitle1">{`${value} ${message ? "-" : ""} `}</Typography>
      {' '}
      {message && <Typography style={{ color }} variant="subtitle1">{`${message}`}</Typography>}
    </div>
  )

}

class SingleChecklist extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      selected: {},
      openDialog: false,
      openWarning: false,
      openEditField: false,
      openExtraWarning: false,
      extra: {},
      fieldIndex: -1
    }
    autobind(SingleChecklist, this)
  }

  componentDidMount() {
    const { match, selectSettingsChecklist } = this.props
    const { id } = match.params
    selectSettingsChecklist(id)
    this.setupParams()
  }

  setupParams() {
    const { settings } = this.props
    const selected = settings.checklists.selected
    const params = {
      name: selected.name
    }
    this.setState({ params })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    if (target.name.includes("counter")) {
      params[target.name] = params[target.name] === 1 ? 0 : 1
      return this.setState({ params })
    }
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeExtra(event) {
    const { target } = event
    const { extra } = this.state
    extra[target.name] = target.value
    this.setState({ extra })
  }

  handleOpenDialog(selected) {
    return () => {
      this.setState({
        openDialog: !this.state.openDialog, selected, params: {
          ...this.state.params,
          new_element_name: selected.name,
          new_counter: selected.counter,
          new_default_criticality: selected.default_criticality
        }
      })
    }
  }

  handleOpenExtraDialog(index, element) {
    return () => {
      if (element) {
        this.setState({ openEditField: !this.state.openEditField, fieldIndex: index, extra: { ...this.state.extra, new_name: element.label, new_type: element.type, new_options: element.options } })
      } else {
        this.setState({ openEditField: !this.state.openEditField })
      }
    }
  }

  handleOpenWarning(selected) {
    return () => {
      this.setState({ openWarning: !this.state.openDialog, selected })
    }
  }

  handleClose() {
    this.setState({ openDialog: false })
  }

  handleCloseWarning() {
    this.setState({ openWarning: false })
  }

  handleOpenExtraWarning(index) {
    return () => {
      this.setState({ openExtraWarning: !this.state.openExtraWarning, fieldIndex: index })
    }
  }

  handleEditName() {
    const { params } = this.state
    const { editSettingsChecklist, match } = this.props
    const body = {
      name: params.name,
      id: match.params.id
    }
    editSettingsChecklist(body)
  }

  handleEdit() {
    const { params, selected } = this.state
    const { match, editChecklistItem } = this.props
    const body = {
      name: params.new_element_name,
      counter: params.new_counter,
      default_criticality: params.new_default_criticality,
      id: selected.id,
      checklist_id: match.params.id
    }
    editChecklistItem(body)
    this.setState({ params: {} })
    this.handleClose()
  }

  handleCreate() {
    const { params } = this.state
    const { match, createChecklistItem } = this.props
    const body = {
      name: params.element_name,
      default_criticality: params.default_criticality,
      counter: params.counter,
      checklist_id: match.params.id
    }
    createChecklistItem(body)
    this.setState({ params: {} })
  }

  handleDelete() {
    const { match, deleteChecklistItem } = this.props
    const { selected } = this.state
    const body = {
      checklist_id: match.params.id,
      id: selected.id
    }
    deleteChecklistItem(body)
    this.handleCloseWarning()
  }

  handleAddExtra() {
    const { extra } = this.state
    const { settings, updateExtraField } = this.props
    const selected = settings.checklists.selected
    const oldExtraFields = (selected && selected.extra_fields) || []
    if (extra.options) {
      oldExtraFields.push({ label: extra.label, type: extra.type, value: "", options: extra.options })
    } else {
      oldExtraFields.push({ label: extra.label, type: extra.type, value: "" })
    }
    const body = {
      id: selected.id,
      extra_fields: oldExtraFields
    }
    updateExtraField(body)
    this.setState({ extra: {} })

  }

  handleEditExtra() {
    const { extra, fieldIndex } = this.state
    const { settings, updateExtraField } = this.props
    const selected = settings.checklists.selected
    const oldExtraFields = (selected && selected.extra_fields) || []
    if (extra.new_options) {
      oldExtraFields[fieldIndex] = { label: extra.new_name, type: extra.new_type, value: "", options: extra.new_options }
    } else {
      oldExtraFields[fieldIndex] = { label: extra.new_name, type: extra.new_type, value: "" }
    }

    const body = {
      id: selected.id,
      extra_fields: oldExtraFields
    }
    updateExtraField(body)
    this.setState({ extra: {}, openEditField: false })
  }

  handleDeleteExtra() {
    const { fieldIndex } = this.state
    const { settings, updateExtraField } = this.props
    const selected = settings.checklists.selected
    const oldExtraFields = (selected && selected.extra_fields) || []
    oldExtraFields.splice(fieldIndex, 1)
    const body = {
      id: selected.id,
      extra_fields: oldExtraFields
    }
    updateExtraField(body)
    this.setState({ openExtraWarning: false })
  }

  handleAddOption() {
    const { extra } = this.state
    const options = extra.options ? [...extra.options] : []
    const compiledOption = `${extra.option}${extra.option_message ? `&${extra.option_message}` : ""}${extra.option_color ? `&${extra.option_color}` : ""}`
    options.push(compiledOption)
    const newExtra = { ...extra }
    newExtra.options = options
    newExtra.option = ""
    this.setState({ extra: newExtra })
  }

  handleDeleteOption(index) {
    return () => {
      const { extra } = this.state
      const newExtra = [...extra]
      const newOptions = newExtra.options.splice(index, 1)
      newExtra.options = newOptions
      this.setState({ extra: newExtra })
    }

  }

  renderElements() {
    const { settings, classes } = this.props
    const selected = settings.checklists.selected
    const elements = (selected && selected.checklist_items) || []
    if (elements.length === 0) return <Typography variant="subtitle1">Aún no hay elementos en la lista...</Typography>
    return elements.map((element, index) => {
      const hasCounter = element.counter === 1
      const hasCrit = element.default_criticality !== 0
      return (
        <>
          <div className={classes.element} style={{ "&::before": { background: 'red' } }} key={index}>
            <div>
              <Typography variant="subtitle1">{element.name}</Typography>
            </div>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenDialog(element)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleOpenWarning(element)}>
                <Delete />
              </IconButton>
            </div>

          </div>
          {(hasCounter || hasCrit) &&
            <div className={classes.optionMain}>
              <div className={classes.optionsContainer}>
                {hasCrit &&
                  <div className={classes.option}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Typography variant="subtitle1">{`Criticidad por defecto: ${crits.find(c => c.value === element.default_criticality).label}`} </Typography>
                      </div>
                    </div>
                  </div>
                }
                {hasCounter &&
                  <div className={classes.option}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1" style={{ marginRight: 6 }}>{`Contador `}</Typography>
                      <CheckCircleOutline style={{ color: 'green' }} />
                    </div>
                  </div>}
              </div>
            </div>
          }
        </>
      )
    })
  }

  renderExtraFields() {
    const { settings, classes } = this.props
    const selected = settings.checklists.selected
    const fields = (selected && selected.extra_fields) || []
    if (fields.length === 0) return <Typography variant="subtitle1">Aún no hay campos en la lista</Typography>
    return fields.map((field, index) => {
      return (
        <>
          <div className={classes.element} style={{ "&::before": { background: 'red' } }} key={index}>
            <Typography variant="subtitle1">{`${field.label} [ ${field.type} ]`}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenExtraDialog(index, field)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleOpenExtraWarning(index)}>
                <Delete />
              </IconButton>
            </div>
          </div>
          {field.options && field.options.length > 0 &&
            <div className={classes.optionMain}>
              <div className={classes.optionsContainer}>
                {field.options.map(option => {
                  return (
                    <div className={classes.option}>
                      {parseOption(option)}
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </>
      )
    })
  }

  renderCreatedOptions() {
    const { extra } = this.state
    const { classes } = this.props
    const options = extra.options || []
    return options.map((option, index) => {
      return (
        <div className={classes.element} style={{ "&::before": { background: 'red' } }} key={index}>
          {parseOption(option)}
          <div className={classes.buttons}>
            <IconButton onClick={this.handleDeleteOption(index)}>
              <Delete />
            </IconButton>
          </div>
        </div>
      )
    })
  }

  handleAddEditOption() {
    const { extra } = this.state
    const newExtra = { ...extra }
    const options = newExtra.new_options ? [...newExtra.new_options] : []
    const compiledOption = `${extra.new_option}${extra.new_option_message ? `&${extra.new_option_message}` : ""}${extra.new_option_color ? `&${extra.new_option_color}` : ""}`
    options.push(compiledOption)
    newExtra.new_options = options
    newExtra.new_option = ""
    this.setState({ extra: newExtra })
  }

  handleDeleteEditOption(index) {
    return () => {
      const { extra } = this.state
      const newExtra = { ...extra }
      const options = newExtra.new_options ? [...newExtra.new_options] : []
      options.splice(index, 1)
      newExtra.new_options = options
      this.setState({ extra: newExtra })
    }
  }

  renderEditableOptions() {
    const { extra } = this.state
    const { classes } = this.props
    const options = extra.new_options || []
    return options.map((option, index) => {
      return (
        <div className={classes.element} style={{ "&::before": { background: 'red' } }} key={index}>
          {parseOption(option)}
          <div className={classes.buttons}>
            <IconButton onClick={this.handleDeleteEditOption(index)}>
              <Delete />
            </IconButton>
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes, settings } = this.props
    const { params, openDialog, openWarning, extra, openEditField, openExtraWarning } = this.state
    const selected = settings?.checklists?.selected
    return (
      <div className={classes.container}>
        <Paper className={classes.card}>
          <div className={classes.title}>
            <Typography variant="h1">Editar Lista de Chequeo {selected?.name}</Typography>
          </div>
          <div className={classes.cardContent}>
            <Typography variant="h2">Agregar elemento a la lista de chequeo</Typography>
            <div className={classes.input}>
              <TextInput label="Nombre Elemento de Lista" value={params.element_name} name="element_name" onChange={this.handleChange} />
            </div>
            <div className={classes.input}>
              <SelectInput options={crits} label="Criticidad por defecto" value={params.default_criticality} name="default_criticality" onChange={this.handleChange} />
            </div>
            <div className={classes.input}>
              <Checkbox checked={params.counter === 1} onChange={this.handleChange} name="counter" />
              <Typography variant="subtitle1">Agregar contador a elemento de la lista</Typography>
            </div>
            <SubmitButton onClick={this.handleCreate}>
              Crear Elemento
            </SubmitButton>
          </div>
          <Divider />
          <div className={classes.items}>
            <Typography variant="h4">Elementos de la lista</Typography>
            {this.renderElements()}
          </div>
          <Divider />
          <div className={classes.extra}>
            <Typography variant="h2">Agregar campo extra lista de chequeo</Typography>
            <div className={classes.addExtra}>
              <div className={classes.extraField}>
                <TextInput label="Nombre Campo" onChange={this.handleChangeExtra} name="label" value={extra.label} />
              </div>
              <div className={classes.extraField}>
                <SelectInput label="Tipo" options={fields} onChange={this.handleChangeExtra} name="type" value={extra.type} />
              </div>
              <IconButton color="primary" onClick={this.handleAddExtra} disabled={!extra.label || !extra.type}>
                <Add />
              </IconButton>
            </div>
            {extra.type === "select" &&
              <>
                <div className={classes.optionsCreator}>
                  <div className={classes.extraField}>
                    <TextInput label="Agregar Opción" onChange={this.handleChangeExtra} name="option" value={extra.option} />
                  </div>
                  <div className={classes.extraField}>
                    <TextInput label="Mensaje (Opcional)" onChange={this.handleChangeExtra} name="option_message" value={extra.option_message} />
                  </div>
                  <div className={classes.extraField}>
                    <SelectInput options={[
                      { label: "Rojo", value: "red" },
                      { label: "Verde", value: "green" },
                      { label: "Amarillo", value: "gold" },
                      { label: "Sin Color", value: "unset" },
                    ]}
                      label="Color" onChange={this.handleChangeExtra} name="option_color" value={extra.option_color} />
                  </div>
                  <SubmitButton color="primary" onClick={this.handleAddOption} disabled={!extra.option}>
                    Agregar Opción
                  </SubmitButton>
                </div>
                <div className={classes.optionsCreated}>
                  {this.renderCreatedOptions()}
                </div>
              </>
            }

          </div>
          <Divider />
          <div className={classes.items}>
            <Typography variant="h4">Campos en la lista</Typography>
            {this.renderExtraFields()}
          </div>
        </Paper>
        <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={this.handleClose}>
          <div className={classes.title}>
            <Typography variant="h1">Editar Elemento Lista</Typography>
          </div>
          <div className={classes.dialogInput}>
            <TextInput label="Nuevo Nombre" value={params.new_element_name} name="new_element_name" onChange={this.handleChange} />
            <SelectInput options={crits} label="Criticidad por defecto" value={params.new_default_criticality} name="new_default_criticality" onChange={this.handleChange} />
            <div className={classes.input}>
              <Checkbox checked={params.new_counter === 1} onChange={this.handleChange} name="new_counter" />
              <Typography variant="subtitle1">Agregar contador a elemento de la lista</Typography>
            </div>
            <SubmitButton onClick={this.handleEdit}>
              Guardar Cambios
            </SubmitButton>
          </div>
        </Dialog>
        <Dialog fullWidth maxWidth="sm" open={openEditField} onClose={this.handleOpenExtraDialog()}>
          <div className={classes.title}>
            <Typography variant="h1">Editar Campo Extra</Typography>
          </div>
          <div className={classes.dialogInput}>
            <TextInput label="Nuevo Nombre" value={extra.new_name} name="new_name" onChange={this.handleChangeExtra} />
            <SelectInput label="Tipo" options={fields} onChange={this.handleChangeExtra} name="new_type" value={extra.new_type} />

            {extra.new_type === "select" &&
              <div>
                <Divider style={{ margin: '12px 0' }} />
                <TextInput label="Nueva opción" value={extra.new_option} name="new_option" onChange={this.handleChangeExtra} />
                <TextInput label="Mensaje (Opcional)" onChange={this.handleChangeExtra} name="new_option_message" value={extra.new_option_message} />
                <SelectInput options={[
                  { label: "Rojo", value: "red" },
                  { label: "Verde", value: "green" },
                  { label: "Amarillo", value: "gold" },
                  { label: "Sin Color", value: "unset" },
                ]}
                  label="Color" onChange={this.handleChangeExtra} name="new_option_color" value={extra.new_option_color} />
                <SubmitButton onClick={this.handleAddEditOption} disabled={!extra.new_option}>
                  Agregar Opción
                </SubmitButton>
              </div>
            }
            {extra.new_options &&
              <>
                <Divider />
                <div>
                  {this.renderEditableOptions()}
                </div>
              </>
            }
            <IconButton onClick={this.handleEditExtra}>
              <Save />
            </IconButton>
          </div>
        </Dialog>
        <Dialog fullWidth maxWidth="sm" open={openWarning} onClose={this.handleCloseWarning}>
          <div className={classes.warning}>
            <Typography variant="h2">¿Seguro que deseas eliminar este elemento?</Typography>
          </div>
          <div className={classes.dialogButtons}>
            <Button color="primary" variant="contained" onClick={this.handleCloseWarning}>
              Cancelar
            </Button>
            <Button color="primary" variant="outlined" onClick={this.handleDelete}>
              Eliminar
            </Button>
          </div>
        </Dialog>
        <Dialog fullWidth maxWidth="sm" open={openExtraWarning} onClose={this.handleOpenExtraWarning()}>
          <div className={classes.warning}>
            <Typography variant="h2">¿Seguro que deseas eliminar este campo?</Typography>
          </div>
          <div className={classes.dialogButtons}>
            <Button color="primary" variant="contained" onClick={this.handleOpenExtraWarning()}>
              Cancelar
            </Button>
            <Button color="primary" variant="outlined" onClick={this.handleDeleteExtra}>
              Eliminar
            </Button>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  selectSettingsChecklist: (id) => dispatch(selectSettingsChecklistAction(id)),
  editSettingsChecklist: body => dispatch(editSettingChecklistAction(body)),
  createChecklistItem: (body) => dispatch(createChecklistItemAction(body)),
  editChecklistItem: body => dispatch(editChecklistItemAction(body)),
  deleteChecklistItem: body => dispatch(deleteChecklistItemAction(body)),
  updateExtraField: body => dispatch(updateChecklistExtraFieldAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SingleChecklist))