import React from "react"
import "./App.css"
import Sidebar from "./Components/Sidebar/Sidebar"
import { withStyles } from "@material-ui/core"
import Header from "./Components/Header/Header"
import Routes from "./Routes/Routes"
import PublicRoutes from "./Routes/PublicRoutes"
import autobind from "./Utils/autobind"
import { connect } from "react-redux"
import { setupSnacks } from "./Actions/MainActions"
import { withSnackbar } from "notistack"
import instance from "./Utils/instance"
import { setOnlineStatus } from "./Actions/OnlineActions"
import { removeDoneRequests, setRequest, updateRequestsActions } from "./Actions/RequestAction"
import moment from "moment"
import "moment/locale/es"
import ErrorBoundary from "./Shared/ErrorBoundary"
import * as packageJson from "../package.json"
import UpdateDialog from "./Shared/UpdateDialog"
import Footer from "./Components/Footer/Footer"
localStorage.setItem("version", packageJson.version)

const style = () => ({
    content: {
        marginLeft: 56,
        padding: '15px 50px',
        "@media (max-width:500px)": {
            padding: 0,
            margin: 0,
            marginBottom: 56
        },
    },
    version: {
        position: 'fixed',
        bottom: 12,
        right: 12,
        textAlign: 'end'
    }
})

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())

        const b = Number(versionsB.shift())
        // eslint-disable-next-line no-continue
        if (a === b) {
            continue
        }
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b)
    }
    return false
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            requests: [],
            version: "",
            old: "",
            updateRequired: false
        }

        autobind(App, this)
    }

    componentDidMount() {
        moment.locale("es")
        const { setupSnack, enqueueSnackbar, setOnline, updateRequests } = this.props
        setupSnack(enqueueSnackbar)
        window.addEventListener('online', () => {
            setOnline(true)
            const pendingRequests = JSON.parse(localStorage.getItem("SavedRequests"))
            this.setState({ requests: pendingRequests }, this.handleSyncRequests)

        })
        window.addEventListener('offline', () => setOnline(false))
        window.addEventListener('offlineRequest', () => {
            // When local storage changes, dump the list to
            // the console.
            const pendingRequests = JSON.parse(window.localStorage.getItem('SavedRequests'))
            updateRequests(pendingRequests)
        })

        const self = this

        fetch("/meta.json")
            .then(response => response.json())
            .then(meta => {
                const latestVersion = meta.version
                const localVersion = localStorage.getItem("version")
                self.setState({ old: localVersion, version: latestVersion })
                console.log(localVersion, latestVersion)
                if (semverGreaterThan(latestVersion, localVersion)) {
                    self.setState({ updateRequired: true })
                }
            })

        window.addEventListener('click', () => {
            fetch("/meta.json")
                .then(response => response.json())
                .then(meta => {
                    const latestVersion = meta.version
                    const localVersion = localStorage.getItem("version")
                    self.setState({ old: localVersion, version: latestVersion })
                    console.log(localVersion, latestVersion)
                    if (semverGreaterThan(latestVersion, localVersion)) {
                        self.setState({ updateRequired: true })
                    }
                })
        })
    }

    handleSyncRequests = async () => {
        const { requests = [] } = this.state
        const { updateRequests } = this.props
        const oldRequests = Array.isArray(requests) ? [...requests] : []
        let newRequests = oldRequests.map(request => ({ ...request, status: "pending" }))
        updateRequests(newRequests)
        const updateRequest = async (index) => {
            const request = oldRequests[index]

            // setTimeout(() => {
            //     requestReady = true
            //     if (minTimeReady) {
            //         // console.log(requestTime)
            //         // console.log(minTime)
            //         resolve("Listo esperando a request")
            //     }
            // }, requestTime)

            // Setup request if has a file input
            if (request === undefined) return true
            let processedRequest = { ...request }
            if (Object.keys(request.data).includes("file")) {
                const newData = new FormData()
                const url = request.data.file
                let file
                await fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        file = new File([blob], "File name", { type: "image/png" })
                    })
                Object.entries(request.data).forEach(([key, value]) => {
                    if (key !== "file") {
                        newData.append(key, value)
                    } else {
                        newData.append("file", file)
                    }
                })
                processedRequest.data = newData
            }

            if (Object.keys(request.data).includes("solution_evidence")) {
                const newData = new FormData()
                const url = request.data.base64
                let file
                await fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        file = new File([blob], "File name", { type: "image/png" })
                    })
                Object.entries(request.data).forEach(([key, value]) => {
                    if (key !== "solution_evidence") {
                        newData.append(key, value)
                    } else {
                        newData.append("solution_evidence", file)
                    }
                })
                processedRequest.data = newData
                processedRequest.base64 = ""
            }


            instance(processedRequest).then(() => {
                if (index + 1 < oldRequests.length) {
                    updateRequest(index + 1)
                    const newArray = [...newRequests]
                    newArray[index] = { ...newArray[index], status: "success" }
                    newRequests = newArray
                    updateRequests(newRequests)
                } else {
                    localStorage.setItem("SavedRequests", JSON.stringify([]))
                    const newArray = [...newRequests]
                    newArray[index] = { ...newArray[index], status: "success" }
                    newRequests = newArray
                    updateRequests(newRequests)
                }
            }).catch(() => {
                if (index + 1 < oldRequests.length) {
                    updateRequest(index + 1)
                    const newArray = [...newRequests]
                    newArray[index] = { ...newArray[index], status: "error" }
                    newRequests = newArray
                    updateRequests(newRequests)
                } else {
                    localStorage.setItem("SavedRequests", JSON.stringify([]))
                    const newArray = [...newRequests]
                    newArray[index] = { ...newArray[index], status: "error" }
                    newRequests = newArray
                    updateRequests(newRequests)
                }
            })
        }

        // requests.forEach(async (requestData, index) => {
        //     const response = await updateRequest(index)
        //     console.log(index)
        //     const newArray = [...newRequests]
        //     newArray[index] = { ...newArray[index], status: response }
        //     newRequests = newArray
        //     updateRequests(newRequests)
        // })
        updateRequest(0)
    }

    handleOpenSidebar(value) {
        return () => {
            if (value === undefined) {
                this.setState({ open: !this.state.open })
            } else {
                this.setState({ open: value })
            }
        }
    }

    handleToggleOnline(option) {
        const { setOnline } = this.props
        if (option) {
            setOnline(true)
            const pendingRequests = JSON.parse(localStorage.getItem("SavedRequests"))
            this.setState({ requests: pendingRequests }, this.handleSyncRequests)
        } else {
            setOnline(false)
        }
    }

    render() {
        const { classes, user } = this.props
        // const {online} = this.props
        const { open, requests, updateRequired } = this.state
        const { account } = user
        const jwt = account.jwt
        const isMobile = window.innerWidth <= 500
        return (
            <div>
                <UpdateDialog open={updateRequired} />
                {jwt ? (
                    <>
                        <ErrorBoundary>
                            <Sidebar
                                open={open}
                                handleClose={this.handleOpenSidebar}
                            />
                            <div className={classes.content}>
                                <Header handleOpen={this.handleOpenSidebar} requests={requests} />
                                {/* <button onClick={() => this.handleToggleOnline(!online.status)}>Apagar</button> */}
                                <Routes />
                            </div>
                            {isMobile && <Footer />}
                        </ErrorBoundary>
                    </>
                ) : (
                    <PublicRoutes />
                )}
                {/* <div className={classes.version}>
                    <div>
                        <Typography variant="caption">{`Local: ${old}`}</Typography>
                    </div>
                    <div>
                        <Typography variant="caption">{`Versión: ${version}`}</Typography>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    online: state.online
})

const mapDispatchToProps = (dispatch) => ({
    setupSnack: (action) => dispatch(setupSnacks(action)),
    setOnline: (status) => dispatch(setOnlineStatus(status)),
    updateRequests: (requests) => dispatch(updateRequestsActions(requests)),
    removeDoneRequests: () => dispatch(removeDoneRequests()),
    setRequest: (index, status) => dispatch(setRequest(index, status))
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(App))
)
