import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import Chart from 'chart.js'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'

const style = () => ({
  weekSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 250,
    alignItems: 'center',
    marginBottom: 12
  },
  actualDate: {
    width: 150,
    textAlign: 'center'
  }

})
class OpenVsClose extends Component {
  constructor() {
    super()
    this.state = {
      actualDate: moment(new Date())
    }
    autobind(OpenVsClose, this)
  }
  componentDidMount() {
    const { actualDate } = this.state
    const { idName } = this.props
    const data = this.processData()
    const chartElement = document.getElementById(idName)
    const myChartRef = chartElement.getContext("2d")
    chartElement.parentNode.style.height = "100%"
    chartElement.parentNode.style.width = "100%"

    const startDayOfWeek = actualDate.startOf("week")
    const thisWeek = Array.from(new Array(5)).map(() => {
      return startDayOfWeek.add(1, 'day').format("YYYY-MM-DD")
    })

    const nos = []
    const lows = []
    const meds = []
    const highs = []

    Object.keys(data).forEach(day => {
      nos.push(data[day].open.no)
      nos.push(data[day].closed.no)
      lows.push(data[day].open.low)
      lows.push(data[day].closed.low)
      meds.push(data[day].open.med)
      meds.push(data[day].closed.med)
      highs.push(data[day].open.high)
      highs.push(data[day].closed.high)
    })

    const datasets = [
      { data: nos, label: "No Crítico", backgroundColor: "#f2f5ff", barThickness: 30 },
      { data: lows, label: "Poco Crítico", backgroundColor: "#f6ec79", barThickness: 30 },
      { data: meds, label: "Medianamente Crítico", backgroundColor: "#ffab6e", barThickness: 30 },
      { data: highs, label: "Altamente Crítico", backgroundColor: "#ff6c87", barThickness: 30 },
    ]

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: thisWeek,
        datasets: datasets,
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            id: "status",
            type: "category",
            labels: ["Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado"]
          }, {
            id: "days",
            type: "category",
            labels: thisWeek,
            gridLines: {
              drawOnChartArea: false,
            },
            offset: true,
            ticks: {
              callback: (value) => moment(value).format("DD MMM")
            }
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    })
  }

  handleRedraw() {
    const data = this.processData()
    const { actualDate } = this.state
    const startDayOfWeek = actualDate.startOf("week")
    const thisWeek = Array.from(new Array(5)).map(() => {
      return startDayOfWeek.add(1, 'day').format("YYYY-MM-DD")
    })

    const nos = []
    const lows = []
    const meds = []
    const highs = []

    Object.keys(data).forEach(day => {
      nos.push(data[day].open.no)
      nos.push(data[day].closed.no)
      lows.push(data[day].open.low)
      lows.push(data[day].closed.low)
      meds.push(data[day].open.med)
      meds.push(data[day].closed.med)
      highs.push(data[day].open.high)
      highs.push(data[day].closed.high)
    })

    const datasets = [
      { data: nos, label: "No Crítico", backgroundColor: "#f2f5ff", barThickness: 30 },
      { data: lows, label: "Poco Crítico", backgroundColor: "#f6ec79", barThickness: 30 },
      { data: meds, label: "Medianamente Crítico", backgroundColor: "#ffab6e", barThickness: 30 },
      { data: highs, label: "Altamente Crítico", backgroundColor: "#ff6c87", barThickness: 30 },
    ]

    const options = {
      scales: {
        xAxes: [{
          stacked: true,
          id: "status",
          type: "category",
          labels: ["Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado", "Abierto", "Cerrado"]
        }, {
          id: "days",
          type: "category",
          labels: thisWeek,
          gridLines: {
            drawOnChartArea: false,
          },
          offset: true,
          ticks: {
            callback: (value) => moment(value).format("DD MMM")
          }
        }],
        yAxes: [{
          stacked: true
        }]
      }
    }

    this.chart.data.datasets = datasets
    this.chart.data.labels = thisWeek
    this.chart.options = options
    this.chart.update()
  }

  processData() {
    const { findings } = this.props
    const { actualDate } = this.state

    const startDayOfWeek = actualDate.startOf("week")
    const thisWeek = Array.from(new Array(5)).map(() => {
      return startDayOfWeek.add(1, 'day').format("YYYY-MM-DD")
    })

    const filtered = findings.filter(finding => finding.status !== "Desestimado")
    const no = filtered.filter(finding => finding.criticality === 1)
    const low = filtered.filter(finding => finding.criticality === 2)
    const med = filtered.filter(finding => finding.criticality === 3)
    const high = filtered.filter(finding => finding.criticality === 4)

    const data = {}
    thisWeek.forEach(weekDay => {
      data[weekDay] = {
        open: {
          no: no.filter(finding => finding.date_detected === weekDay).length,
          low: low.filter(finding => finding.date_detected === weekDay).length,
          med: med.filter(finding => finding.date_detected === weekDay).length,
          high: high.filter(finding => finding.date_detected === weekDay).length,
        },
        closed: {
          no: no.filter(finding => finding.date_approved === weekDay).length,
          low: low.filter(finding => finding.date_approved === weekDay).length,
          med: med.filter(finding => finding.date_approved === weekDay).length,
          high: high.filter(finding => finding.date_approved === weekDay).length,
        }
      }
    })

    return data

  }

  handleNextWeek() {
    const { actualDate } = this.state
    const newDate = actualDate.add(1, "week")
    this.setState({ actualDate: newDate }, this.handleRedraw)
  }

  handlePrevWeek() {
    const { actualDate } = this.state
    const newDate = actualDate.subtract(1, "week")
    this.setState({ actualDate: newDate }, this.handleRedraw)
  }

  render() {
    const { classes, idName } = this.props
    const { actualDate } = this.state
    this.processData()
    return (
      <div className={classes.container}>
        <div className={classes.weekSelector}>
          <IconButton size="small" onClick={this.handlePrevWeek}>
            <KeyboardArrowLeft />
          </IconButton>
          <div className={classes.actualDate}><Typography variant="body2">{moment(actualDate).format("[Semana del] DD [de] MMM")}</Typography></div>
          <IconButton size="small" onClick={this.handleNextWeek}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
        <canvas id={idName} ref={this.chartRef} />
      </div>
    )
  }
}

export default withStyles(style)(OpenVsClose)