import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"

export const getPermissionsAreaAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'settings/permissions/areas/get.php'
  })
  return {
    type: "GET_PERMISSION_AREAS",
    payload: REQUEST
  }
}

export const editPermissionsAreaAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/areas/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_PERMISSION_AREA',
    payload: REQUEST
  }
}

export const createPermissionsAreaAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/areas/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_PERMISSION_AREA',
    payload: REQUEST
  }
}