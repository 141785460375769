import stateMaker from "../Utils/stateMaker"

const WorkflowReducer = (state = { all: [] }, action) => {
  switch (action.type) {
    case "GET_WORKFLOWS": {
      return stateMaker(state, "all", "fetch", action)
    }
    case "EDIT_WORKFLOW_STATE": {
      const newState = { ...state }
      if (action.payload.data.status !== "success") return newState
      const index = newState.all.findIndex(element => element.id === action.payload.data.info.id)
      newState.all[index] = action.payload.data.info
      return newState
    }
    case "CREATE_WORKFLOW": {
      return stateMaker(state, 'all', 'create', action)
    }
    default: return state
  }

}

export default WorkflowReducer