import React, { Component } from "react"
import {
  withStyles,
  Paper,
  Tab,
  Typography,
  Divider,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
// import Panel from "../Activities/Panel"
import AddElementButton from "../../Shared/AddElementButton"
import { createMultipleTrainingsAction, createTrainingAction, getTrainingAction, getTrainingsAction } from "../../Actions/TrainingActions"
import { connect } from "react-redux"
import { ExitToApp, GetApp } from "@material-ui/icons"
import moment from 'moment'
import { getSettingsTrainingsAction, getTrainingsCategoriesAction } from "../../Actions/SettingsActions"
import GeneralForm from "../../Shared/GeneralForm"
import FormContext from "../../Shared/Cards/Provider"
import { mergeMultiplePdfFiles, transformToOptions } from "../../Utils/functions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"

const style = () => ({
  container: {
    margin: 12,
  },
  title: {
    margin: "36px 0 12px 0 ",
  },
  divider: {
    marginBottom: 24,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  inline: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
})

const tableInfo = [
  { name: "Fecha Programación", label: "date_scheduled", format: (value) => moment(value).format("YYYY-MM-DD") },
  { name: "Tipo", label: "category_name" },
  { name: "Tema", label: "name" },
  { name: "Responsable", label: "user_name" },
]

const doneTableInfo = [
  { name: "Fecha Realizado", label: "date_saved", format: (value) => moment(value).format("YYYY-MM-DD") },
  { name: "Tipo", label: "category_name" },
  { name: "Tema", label: "name" },
  { name: "Responsable", label: "user_name" },
  { name: "Participantes", label: "participants_signs", format: (value) => value.length }
]

function checkIfFinished(element) {
  return moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")
}

class Trainings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      params: {
        date_scheduled: moment(new Date()).format("YYYY-MM-DD")
      },
      openForm: false
    }
    autobind(Trainings, this)
  }

  componentDidMount() {
    const { getTrainings, getSettingTraining, getWorkers, getTrainingCategories } = this.props
    getTrainings()
    getSettingTraining()
    getWorkers()
    getTrainingCategories()
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleGoto(row) {
    return () => {
      const { history, selectTraining } = this.props
      selectTraining(row.id)
      history.push(`/trainings/${row.id}`)
    }
  }

  handleOpen() {
    this.setState({ openForm: !this.state.openForm })
  }

  handleCreate() {
    const { params } = this.state
    const { createTrainings } = this.props
    const body = { ...params }
    createTrainings(body)
    this.setState({
      openForm: false, params: {
        date_scheduled: moment(new Date()).format("YYYY-MM-DD")
      },
    })
  }

  handleDownload(row) {
    return () => {
      const { user } = this.props
      const file = row.file
      const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_PDF}?id=${row.id}&u=${user.account.user.id}`
      const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER + "/" + file
      if (file.includes(".pdf")) return mergeMultiplePdfFiles([url1, url2], "Reporte Capacitación")
      mergeMultiplePdfFiles([url1], "Reporte Capacitación")
    }
  }

  render() {
    const { classes, trainings, settings, workers, branch, user } = this.props
    const { tab, params, openForm } = this.state
    const allTrainings = trainings.all || []
    const allSettingsTraining = settings.trainings.all || []
    const allTrainingCategories = settings.trainings.categories || []
    const filteredTrainings = allSettingsTraining.filter(training => training.category_id.toString() === params.category_id)
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
    const pendingTrainings = allTrainings.filter(training => moment(training.date_saved).format("YYYY-MM-DD").includes("Fecha"))
    const doneTrainings = allTrainings.filter(training => !moment(training.date_saved).format("YYYY-MM-DD").includes("Fecha"))
    const actions = [
      { name: "Ver Capacitación", icon: ExitToApp, action: this.handleGoto },
      { name: "Descargar Resumen", icon: GetApp, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
    ]

    const formInfo = [
      { name: "user_id", label: "Responsable", type: "select", options: transformToOptions(filteredWorkers) },
      { name: "category_id", label: "Categoría", type: "select", options: transformToOptions(allTrainingCategories) },
      { name: "documents_ids", label: "Capacitación", type: "multiselect", options: transformToOptions(filteredTrainings) },
      { name: "date_scheduled", label: "Fecha de realización", type: "date" }
    ]

    const level = user.account.user.userType

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h1">Capacitaciones</Typography>
          {level !== 3 && <AddElementButton name="Agregar Capacitación" onClick={this.handleOpen} open={openForm} />}
        </div>
        <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
          <GeneralForm open={openForm} title="Crear Capacitación" submit="Crear" info={formInfo} onSubmit={this.handleCreate} />
        </FormContext.Provider>
        <Divider className={classes.divider} />
        <Paper square>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab
              label={
                <Typography variant="h1">
                  Programadas
                                </Typography>
              }
              value={0}
            />
            <Tab
              label={
                <Typography variant="h1">Realizadas</Typography>
              }
              value={1}
            />
          </MyTabs>
        </Paper>
        <TabPanel value={tab} index={0}>
          <Typography variant="h1" className={classes.title}>
            Capacitaciones Programadas
          </Typography>
          <Divider className={classes.divider} />
          <GeneralTable info={tableInfo} data={pendingTrainings} actions={actions} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Typography variant="h1" className={classes.title}>
            Capacitaciones Realizadas
          </Typography>
          <Divider className={classes.divider} />
          <GeneralTable info={doneTableInfo} data={doneTrainings} actions={actions} />
        </TabPanel>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  trainings: state.trainings,
  settings: state.settings,
  workers: state.workers,
  user: state.user,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getTrainings: () => dispatch(getTrainingsAction()),
  createTraining: body => dispatch(createTrainingAction(body)),
  createTrainings: body => dispatch(createMultipleTrainingsAction(body)),
  getSettingTraining: () => dispatch(getSettingsTrainingsAction()),
  getTrainingCategories: () => dispatch(getTrainingsCategoriesAction()),
  selectTraining: id => dispatch(getTrainingAction(id)),
  getWorkers: () => dispatch(getWorkersAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Trainings))