export const userInfo = [
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
        required: true,
    },
    { name: "email", label: "Correo", required: true, },
    { name: "firstname", label: "Nombre", required: true, },
    { name: "lastname", label: "Apellido", required: true, },
    { name: "rut", label: "RUT", required: true, },
    { name: "phone", label: "Teléfono", required: true, },
    { name: "address", label: "Dirección", required: true, },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date", required: true, },
    { name: "position", label: "Cargo", required: true, },
    {
        name: "branch_id",
        label: "Sucursal",
        type: "select",
        options: [],
        placeholder: "Seleccionar Sucursal",
        required: true,
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
        placeholder: "Seleccionar Supervisor",
    },
]

export const userEditInfo = [
    { name: "email", label: "Correo" },
    { name: "firstname", label: "Nombre" },
    { name: "lastname", label: "Apellido" },
    { name: "rut", label: "RUT" },
    { name: "phone", label: "Teléfono" },
    { name: "address", label: "Dirección" },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date" },
    { name: "position", label: "Cargo" },
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
    },
    {
        name: "branch_id",
        label: "Sucursal",
        type: "select",
        options: [],
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
    },
]

export const pwdForm = [
    { name: "newpassword", label: "Contraseña Nueva", type: "password" },
    {
        name: "newpasswordconfirm",
        label: "Confirmación de Contraseña",
        type: "password",
    },
]

export const userTableInfo = [
    { name: "Correo", label: "email" },
    { name: "Nombre", label: "name" },
    { name: "Puesto", label: "position" },
    { name: "RUT", label: "rut" },
]
