import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"

export const getPermissionsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'permissions/get.php',
    params: body
  })
  return {
    type: "GET_PERMISSIONS",
    payload: REQUEST
  }
}

export const createPermissionAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'permissions/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_PERMISSION",
    payload: REQUEST
  }
}

export const editPermissionAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'permissions/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_PERMISSION",
    payload: REQUEST
  }
}