import React, { Component, Fragment } from "react"
import {
    withStyles,
    ListItem,
    Typography,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core"
import sideRoutes from "../../Routes/config"
import { KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons"
import autobind from "../../Utils/autobind"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

const style = (theme) => ({
    container: {
        background: theme.palette.blue.main,
        width: 56,
        height: "100vh",
        position: "fixed",
        transition: "all 0.2s ease-out",
        overflowX: "hidden",
        borderRadius: "0 15px 15px 0",
        zIndex: 1000,
        "@media (max-width:500px)": {
            width: 0,
            marginTop: 60,
        },
    },
    hidden: {
        display: "block",
        "@media (max-width:500px)": {
            display: "none",
        },
    },
    link: {
        margin: "6px 0",
    },
    arrowIcon: {
        minWidth: "unset",
        color: "white",
        transition: "all 0.2s linear",
        transform: "rotate(0deg)",
    },
    openArrowIcon: {
        minWidth: "unset",
        color: "white",
        transition: "all 0.2s linear",
        transform: "rotate(180deg)",
    },
})

class Sidebar extends Component {
    constructor(props) {
        super(props)
        autobind(Sidebar, this)
        this.state = {
            openRoute: -1,
        }
    }

    handleClick(e) {
        let isContained = false
        document.getElementsByName("sidebar").forEach((element) => {
            if (element.contains(e.target)) {
                isContained = true
            }
        })
        if (!isContained) {
            const { handleClose } = this.props
            this.setState({ openRoute: -1 })
            handleClose(false)()
        }
    }

    componentDidMount() {
        window.addEventListener("click", this.handleClick)
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClick)
    }

    handleClickLink(name) {
        return () => {
            const { history, handleClose } = this.props
            history.push(name)
            this.setState({ openRoute: -1 })
            handleClose(false)()
        }
    }

    handleHome(e) {
        const { handleClose } = this.props
        e.stopPropagation()
        this.setState({ openRoute: -1 })
        handleClose()()
    }

    handleSelectRoute(value) {
        return () => {
            const route = this.state.openRoute
            const { handleClose } = this.props
            if (route === value) {
                this.setState({ openRoute: -1 })
            } else {
                this.setState({ openRoute: value })
            }
            handleClose(true)()
        }
    }

    renderRoutes() {
        const { classes, enterprise, user } = this.props
        function getUserType() {
            if (user?.account?.user?.sub_branch_id !== "") return 4
            return user?.account?.user?.userType
        }

        const routes = sideRoutes[getUserType()]
        const selectedId = enterprise?.selected?.id
        const { openRoute } = this.state
        return routes.map((route, index) => {
            const subroutes = route.subroutes
            return (
                <Fragment key={route.name}>
                    <ListItem
                        button
                        className={classes.link}
                        onClick={
                            subroutes
                                ? this.handleSelectRoute(index)
                                : this.handleClickLink(route.link)
                        }
                        key={route.name}
                    >
                        <ListItemIcon>
                            <route.icon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h2" style={{ color: "white" }}>
                                    {route.name}
                                </Typography>
                            }
                        />

                        {subroutes && (
                            <ListItemIcon
                                className={
                                    index === openRoute
                                        ? classes.openArrowIcon
                                        : classes.arrowIcon
                                }
                            >
                                <KeyboardArrowDown />
                            </ListItemIcon>
                        )}
                    </ListItem>
                    {index === openRoute &&
                        subroutes &&
                        subroutes.map((inroute) => (
                            <ListItem
                                button
                                className={classes.link}
                                onClick={this.handleClickLink(inroute.link)}
                                key={inroute.name}
                            >
                                <ListItemIcon>
                                    <KeyboardArrowRight
                                        style={{
                                            height: 12,
                                            width: 12,
                                            paddingLeft: 24,
                                            color: 'white'
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h2"
                                            style={{ color: 'white' }}
                                        >
                                            {inroute.name === "No Conformidades" && selectedId === 14 ? "IPAs" : inroute.name}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                </Fragment>
            )
        })
    }
    render() {
        const { classes, open } = this.props
        return (
            <div
                className={classes.container}
                name="sidebar"
                style={open ? { width: 300 } : {}}
            >
                <div style={{ width: 300 }}>
                    <div className={classes.hidden}>
                        <ListItem
                            style={{ padding: "18px 16px" }}
                            button
                            onClick={
                                open
                                    ? this.handleClickLink("/")
                                    : this.handleHome
                            }
                        >
                            <ListItemIcon onClick={this.handleHome}>
                                <KeyboardArrowRight
                                    style={{
                                        transform: open
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                        transition: "all 0.2s linear",
                                        color: 'white'
                                    }}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </div>
                    {this.renderRoutes()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    enterprise: state.enterprise,
    user: state.user
})

export default connect(mapStateToProps)(withRouter(withStyles(style)(Sidebar)))
