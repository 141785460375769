const OnlineReducer = (
  state = { status: "false" },
  action
) => {
  switch (action.type) {
    case "SET_ONLINE_STATUS":
      const newState = { ...state }
      newState.status = action.payload
      return newState
    default:
      return state
  }
}

export default OnlineReducer
