import stateMaker from "../Utils/stateMaker"

const BranchReducer = (state = { global: {}, selected: {}, sub_branches: [], selected_sub_branch: {}, sub_branch_documents: [] }, action) => {
    switch (action.type) {
        case "SET_BRANCH":
            return stateMaker(state, "global", "fetch", action)
        case "GET_BRANCH":
            return stateMaker(state, "selected", "fetch", action)
        case "GET_SUB_BRANCHES":
            return stateMaker(state, "sub_branches", "fetch", action)
        case "GET_SUB_BRANCH_DOCUMENTS":
            return stateMaker(state, "sub_branch_documents", "fetch", action)
        case "SET_SUB_BRANCH": {
            const newState = { ...state }
            const selectedSubBranch = newState.sub_branches.find(branch => branch.id === action.payload.sub_branch_id)
            newState.selected_sub_branch = selectedSubBranch
            return newState
        }
        case "CREATE_SUB_BRANCH": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                newState.sub_branches.push(action.payload.data.info)
            }
            return newState
        }
        case "CREATE_SUB_BRANCH_USER": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                newState.selected_sub_branch.users.push(action.payload.data.info)
            }
            return newState
        }
        case "CREATE_SUB_BRANCH_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                newState.sub_branch_documents.push(action.payload.data.info)
            }
            return newState
        }
        case "EDIT_SUB_BRANCH_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const oldIndex = newState.sub_branch_documents.findIndex(doc => doc.id === action.payload.data.info.id)
                newState.sub_branch_documents[oldIndex] = action.payload.data.info
            }
            return newState
        }
        case "DELETE_SUB_BRANCH_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                newState.sub_branch_documents = action.payload.data.info
            }
            return newState
        }
        case "SIGN_SUB_BRANCH_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const selectedDocument = newState.sub_branch_documents.find(doc => doc.id.toString() === action.payload.data.info.document_id.toString())
                selectedDocument.signs.push(action.payload.data.info)
            }
            return newState
        }
        default:
            return state
    }
}

export default BranchReducer
