export const updateRequestsActions = (requests) => {
  return {
    type: "UPDATE_REQUESTS",
    payload: { data: requests }
  }
}

export const setRequest = (index, status) => {
  return {
    type: "SET_REQUEST",
    payload: {
      index, status
    }
  }
}

export const removeDoneRequests = () => ({
  type: "REMOVE_REQUESTS"
})