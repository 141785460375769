import React, { Component } from 'react'
import { Button, Collapse, Dialog, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { Backup, Delete, Edit } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../Shared/Inputs/TextInput'
import FileInput from '../../Shared/Inputs/FileInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import SubmitButton from '../../Shared/SubmitButton'
import { transformToOptions } from '../../Utils/functions'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { createEnterpriseDocumentAction, deleteEnterpriseDocumentAction, editEnterpriseDocumentAction } from '../../Actions/EnterpriseAction'
import { callSnackbar } from '../../Utils/snackbar'
import { getSettingsEnterpriseCategoriesAction } from '../../Actions/SettingsActions'
import { getEnterpriseDocumentsAction } from '../../Actions/EnterpriseAction'
import DateInput from '../../Shared/Inputs/DateInput'

const style = theme => ({
  category: {
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    height: 150,
    width: 250,
    "@media (max-width:500px)": {
      width: '100%',
    },

  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '24px 0'
  },
  actions: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  categoryContainer: {
    padding: 12,
    transition: 'all 0.2s ease-in-out',
    "@media (max-width:500px)": {
      width: '100%',
      padding: "12px 0"
    },
  },
  mainCategory: {
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
  },
  documents: {
    margin: 12
  },
  openMain: {
    minHeight: 300,
    width: '100%',
    "@media (max-width:500px)": {
      width: '100%',
    },
  },
  closedMain: {
    height: 0,
    width: 0
  },
  title: {
    padding: 12,
    transition: 'all 0.2s ease-in-out',
  },
  content: {
    padding: 12
  },
  openTitle: {
    background: theme.palette.primary.main,
    color: "white"
  },
  file: {
    width: 150,
    margin: 12,
    border: '1px solid #1a1a1a',
    borderRadius: 12,
    padding: 12,
    "@media (max-width:500px)": {
      width: '100%',
      margin: "12px 0"
    },
  },
  files: {
    display: 'flex',
    padding: 12,
    flexWrap: 'wrap',
  },
  doctText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0'
  },
  doctTextDownload: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0',
    color: theme.palette.blue.main,
    cursor: "pointer"
  },
  doctTextTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0',
    fontWeight: 600
  },
  docButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12
  },
  inputs: {
    padding: 12
  },
  containerTitle: {
    margin: 12,
    '& > *': {
      background: theme.palette.primary.main,
      color: "white",
      padding: 12
    }
  },
  warningContainer: {
    padding: "0px 6px 6px"
  },
  warning: {
    color: theme.palette.red.main,
    margin: "6px 0",
    fontWeight: 600
  },
})

class CompanyDocuments extends Component {
  constructor() {
    super()
    this.state = {
      openCategory: -1,
      category: {},
      document: {},
      openDialog: false,
      openWarning: false,
      openCreate: false,
      openNameWarning: null,
      params: {
        date: moment(new Date()).format("YYYY-MM-DD")
      },
      editParams: {}
    }

    autobind(CompanyDocuments, this)
  }

  componentDidMount() {
    const { getSettingsEnterpriseCategories, getEnterpriseDocuments } = this.props
    getSettingsEnterpriseCategories()
    getEnterpriseDocuments()
  }

  handleOpenCategory = (index, category) => () => {
    const { openCategory } = this.state
    if (openCategory === index) {
      this.setState({ openCategory: -1, category: {} })
    } else {
      this.setState({ openCategory: index, category })
      function showElement() {
        document.getElementById("openDocument").scrollIntoView({ behavior: "smooth" })
      }
      setTimeout(showElement, 500)
    }
  }

  handleOpenDialog(document) {
    return () => {
      if (document) {
        const editParams = {
          name: document.name,
          category_id: document.category_id,
          date: document.date
        }
        this.setState({ openDialog: !this.state.openDialog, document, editParams })
      } else {
        this.setState({ openDialog: !this.state.openDialog })
      }

    }
  }

  handleOpenWarning(document) {
    return () => {
      if (document) {
        this.setState({ openWarning: !this.state.openWarning, document })
      } else {
        this.setState({ openWarning: !this.state.openWarning })
      }
    }
  }

  handleOpenCreate(category) {
    return () => {
      if (category) {
        this.setState({ openCreate: !this.state.openCreate, params: { ...this.state.params, category_id: category.id } })
      } else {
        this.setState({ openCreate: !this.state.openCreate, params: {} })
      }

    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const { enterprise } = this.props
    params[target.name] = target.value
    let existsName = null
    if (target.name === "file") {
      params["name"] = target.value.name
      const allDocuments = enterprise.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value.name)
    }
    if (target.name === "name" && target.value !== "") {
      const allDocuments = enterprise.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value)
    }
    if (existsName) {
      this.setState({ params, openNameWarning: existsName })
    } else {
      this.setState({ params, openNameWarning: null })
    }
  }

  handleChangeEdit(event) {
    const { target } = event
    const { editParams } = this.state
    const { enterprise } = this.props
    editParams[target.name] = target.value
    let existsName = null
    if (target.name === "file") {
      editParams["name"] = target.value.name
      const allDocuments = enterprise.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value.name)
    }
    if (target.name === "name" && target.value !== "") {
      const allDocuments = enterprise.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value)
    }
    if (existsName) {
      this.setState({ editParams, openNameWarning: existsName })
    } else {
      this.setState({ editParams, openNameWarning: null })
    }
  }

  handleSendEdit() {
    const { editParams, document } = this.state
    const { editEnterpriseDocument } = this.props
    const body = new FormData()
    body.append("name", editParams.name)
    body.append("file", editParams.file)
    body.append("category_id", editParams.category_id)
    body.append("id", document.id)
    body.append("date", editParams.date)
    editEnterpriseDocument(body)
    this.setState({ openDialog: false, document: {} })
  }

  handleSendDelete() {
    const { document } = this.state
    const { deleteEnterpriseDocument } = this.props
    const body = {
      id: document.id
    }
    deleteEnterpriseDocument(body)
    this.setState({ openWarning: false, document: {} })
  }

  handleCreate() {
    const { params } = this.state
    const { createEnterpriseDocument } = this.props
    const body = new FormData()
    body.append("name", params.name || "")
    body.append("file", params.file)
    body.append("date", params.date)
    body.append("category_id", params.category_id)
    createEnterpriseDocument(body)
    this.setState({ openCreate: false, params: {} })
  }

  handleDownload(element) {
    return () => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_COMPANY_DOCUMENTS}${element.file}`
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement("a")
          a.href = url
          a.download = `${element.file}`
          a.click()
        })
      }).catch(() => callSnackbar("Error al descargar documento", "error"))
    }
  }

  handleSeeDocument(element) {
    return () => {
      window.open(`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_COMPANY_DOCUMENTS}${element.file}`)
    }
  }

  renderCategories() {
    const { openCategory } = this.state
    const { classes, enterprise, settings, user } = this.props

    const level = user.account.user.userType
    const cantEdit = level > 1

    const allDocuments = enterprise.documents || []
    const allCategories = settings.base_documents.all || []
    const realCategories = allCategories.map((category, index) => {
      const isOpen = openCategory === index
      const categoryDocuments = allDocuments.filter(document => document.category_id === category.id) || []
      return (
        <div className={`${classes.categoryContainer}`}>
          <Paper className={`${classes.category}`}>
            <Typography variant="h1" className={`${classes.title} ${isOpen ? classes.openTitle : ""}`}>{category.name}</Typography>
            <Divider />
            <div className={classes.content}>
              <Typography variant="subtitle1"> Documentos: {categoryDocuments.length}</Typography>
              <div className={classes.actions}>
                <Button color="primary" variant="contained" onClick={this.handleOpenCategory(index, category)}>
                  {isOpen ? "Cerrar" : "Abrir"}
                </Button>
                <Button disabled={cantEdit} color="primary" variant="outlined" onClick={this.handleOpenCreate(category)}>
                  <Backup />
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      )
    })
    const isGeneralOpen = openCategory === "none"
    const noCategoryDocuments = allDocuments.filter(document => document.category_id === null) || []
    const total = noCategoryDocuments.length
    return (
      <>
        {realCategories}
        {total > 0 &&
          <div className={`${classes.categoryContainer}`}>
            <Paper className={`${classes.category}`}>
              <Typography variant="h1" className={`${classes.title} ${isGeneralOpen ? classes.openTitle : ""}`}>Sin Categoría</Typography>
              <Divider />
              <div className={classes.content}>
                <Typography variant="subtitle1"> Documentos: {noCategoryDocuments.length}</Typography>
                <div className={classes.actions}>
                  <Button color="primary" variant="contained" onClick={this.handleOpenCategory("none", { id: null, name: "Sin Categoría" })}>
                    {isGeneralOpen ? "Cerrar" : "Abrir"}
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        }
      </>
    )
  }

  renderCategoryFiles() {
    const { category } = this.state
    const { enterprise, classes, user } = this.props
    const level = user.account.user.userType
    const cantEdit = level > 1
    const allDocuments = enterprise.documents || []
    return allDocuments.filter(document => document.category_id === category.id).map(document => {
      return (
        <div className={classes.file}>
          <Typography variant="h2" className={classes.doctTextTitle}>{document.name || "Sin nombre"}</Typography>
          <Typography variant="body2" className={classes.doctTextDownload} onClick={this.handleSeeDocument(document)}>Ver Documento</Typography>
          <Typography variant="h2" className={classes.doctText}>{document.date}</Typography>
          <Divider />
          <div className={classes.docButtons}>
            {!cantEdit &&
              <div>
                <IconButton size="small" onClick={this.handleOpenDialog(document)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={this.handleOpenWarning(document)}>
                  <Delete />
                </IconButton>
              </div>
            }
            <Button size="small" variant="outlined" color="primary" onClick={this.handleDownload(document)}>
              Descargar
            </Button>
          </div>
        </div >
      )
    })
  }

  render() {
    const { classes, settings } = this.props
    const { openCategory, category, openDialog, openWarning, document, editParams, params, openCreate, openNameWarning } = this.state
    const allCategories = settings.base_documents.all || []
    return (
      <div>
        <div className={classes.containerTitle}>
          <Typography variant="h1">Documentos de Empresa</Typography>
        </div>
        <div className={classes.container}>
          {this.renderCategories()}
        </div>
        <div className={classes.documents}>
          <Paper id="openDocument" className={`${classes.mainCategory} ${openCategory !== -1 ? classes.openMain : classes.closedMain}`}>
            <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>{category.name}</Typography>
            <div className={classes.files}>
              {this.renderCategoryFiles()}
            </div>
          </Paper>
        </div>
        <Dialog open={openDialog} fullWidth maxWidth="sm" onClose={this.handleOpenDialog()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Editar Documento</Typography>
          <div className={classes.inputs}>
            <FileInput onChange={this.handleChangeEdit} label="Documento" name="file" value={editParams.file ? editParams.file.name : "Seleccionar Documento"} />
            <TextInput onChange={this.handleChangeEdit} label="Nombre (opcional)" name="name" value={editParams.name} />
            <Collapse in={openNameWarning}>
              <div className={classes.warningContainer}>
                <Typography variant="body1" className={classes.warning}>¡Ya existe un archivo con este nombre!</Typography>
                <Typography variant="caption" className={classes.warningLocation}>{`${openNameWarning?.category_name}  > ${openNameWarning?.name}`}</Typography>
              </div>
            </Collapse>
            <DateInput onChange={this.handleChangeEdit} label="Fecha" name="date" value={editParams.date} />
            <SelectInput onChange={this.handleChangeEdit} label="Categoría" options={transformToOptions(allCategories)} name="category_id" value={editParams.category_id} />
            <SubmitButton onClick={this.handleSendEdit}>Guardar</SubmitButton>
          </div>
        </Dialog>
        <Dialog open={openWarning} fullWidth maxWidth="sm" onClose={this.handleOpenWarning()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Borrar Documento</Typography>
          <div className={classes.inputs}>
            <Typography variant="subtitle1">¿Estás seguro que deseas borrar este documento?</Typography>
            <div className={classes.file}>
              <Typography variant="h2" className={classes.doctTextTitle}>{document.name || "Sin nombre"}</Typography>
              <Typography variant="body2" className={classes.doctTextDownload} onClick={this.handleSeeDocument(document)}>Ver Documento</Typography>
              <Typography variant="body2" className={classes.doctText}>{document.category_name}</Typography>
            </div>
            <SubmitButton onClick={this.handleSendDelete}>Eliminar</SubmitButton>
          </div>
        </Dialog>
        <Dialog open={openCreate} fullWidth maxWidth="sm" onClose={this.handleOpenCreate()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Agregar Documento</Typography>
          <div className={classes.inputs}>
            <FileInput onChange={this.handleChange} label="Documento" name="file" value={params.file ? params.file.name : "Seleccionar Documento"} />
            <TextInput onChange={this.handleChange} label="Nombre (opcional)" name="name" value={params.name} />
            <Collapse in={openNameWarning}>
              <div className={classes.warningContainer}>
                <Typography variant="body1" className={classes.warning}>¡Ya existe un archivo con este nombre!</Typography>
                <Typography variant="caption" className={classes.warningLocation}>{`${openNameWarning?.category_name}  > ${openNameWarning?.name}`}</Typography>
              </div>
            </Collapse>
            <DateInput onChange={this.handleChange} label="Fecha" name="date" value={params.date} />
            <SelectInput onChange={this.handleChange} label="Categoría" options={transformToOptions(allCategories)} name="category_id" value={params.category_id} disabled />
            <SubmitButton onClick={this.handleCreate}>Guardar</SubmitButton>
          </div>
        </Dialog>
      </div>
    )
  }
}

CompanyDocuments.propTypes = {

}


CompanyDocuments.defaultProps = {
  categories: []
}

const mapStateToProps = state => ({
  enterprise: state.enterprise,
  settings: state.settings,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  editEnterpriseDocument: body => dispatch(editEnterpriseDocumentAction(body)),
  deleteEnterpriseDocument: body => dispatch(deleteEnterpriseDocumentAction(body)),
  createEnterpriseDocument: body => dispatch(createEnterpriseDocumentAction(body)),
  getSettingsEnterpriseCategories: () => dispatch(getSettingsEnterpriseCategoriesAction()),
  getEnterpriseDocuments: () => dispatch(getEnterpriseDocumentsAction())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CompanyDocuments)))