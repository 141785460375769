import React, { Component } from 'react'
import { Button, Collapse, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { getSettingChecklistsAction } from '../../Actions/SettingsActions'
import SubmitButton from '../../Shared/SubmitButton'
import autobind from '../../Utils/autobind'
import { createChecklistAction } from '../../Actions/CheckListActions'
import moment from 'moment'
import { withRouter } from 'react-router'
import { getBranches, getWorkersAction } from '../../Actions/EnterpriseAction'
import { setBranchAction } from '../../Actions/BranchActions'

const style = theme => ({
  container: {
    position: 'relative',
    minHeight: 150
  },
  lists: {
    padding: 24
  },
  quick: {
    background: theme.palette.blue.transparent,
    borderRadius: 15,
    padding: 12,
    '& > h4': {
      color: theme.palette.purple.light
    }
  },
  quicks: {
    '& > *': {
      marginBottom: 6
    }
  },
  centerButton: {
    textAlign: 'center',
    marginTop: 12,
    '& > button': {
      width: 200
    }
  }
})

class QuickAccessChecklists extends Component {
  constructor() {
    super()
    autobind(QuickAccessChecklists, this)
    this.state = {
      loading: true,
      quickChecklists: [],
      selected: null,
      open: false
    }
  }
  componentDidMount() {
    const { load, getSettingsChecklists, getBranches, user, setBranch, getWorkers, branch } = this.props
    load().then(() => {
      getSettingsChecklists().then(() => {
        this.setState({ loading: false })
        this.setupInformation()
      })
    })
    const companyId = user?.account?.user?.idCompany
    const body = { companyId }
    getBranches(body).then((response) => {
      getWorkers().then((workersResponse) => {
        if (workersResponse.payload.data.status === "success") {
          const allWorkers = workersResponse.payload.data.info
          const selectedWorker = allWorkers.find(worker => worker.id.toString() === user?.account?.user?.id?.toString())
          if (response.payload.data.status === "success") {
            if (selectedWorker.branch_id === null) return 0
            const allBranches = response.payload.data.info || []
            const branchToUse = selectedWorker.branch_id === null ? allBranches[0] : allBranches.find(b => b.id === selectedWorker.branch_id.toString())
            if (branchToUse?.id !== branch?.global?.id) {
              setBranch(branchToUse)
              setTimeout(() => window.location.reload(), 1000)
            }
          }
        }
      })
    })

  }

  handleCreate(selected) {
    return () => {
      const { createChecklist, user } = this.props
      const body = {
        user_id: user?.account?.user?.id,
        date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
        preventive_measure_id: selected.checklist.id,
        activity_id: selected.activity.id,
      }
      createChecklist(body).then(response => {
        const checklist_id = response.payload.data.info.id
        const { history } = this.props
        history.push(`/checklists/${checklist_id}`)
      })
    }
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  setupInformation() {
    const { activities, settings } = this.props
    const allChecklists = settings.checklists.all || []
    const allQuickLists = []
    const allActivities = activities.all || []
    allActivities.forEach(activity => {
      const checklists = activity?.preventive_measures[0] || []
      checklists.forEach(checklist => {
        const original = allChecklists.find(cl => cl?.id?.toString() === checklist?.id?.toString())
        if (original.quick_access === 1) {
          allQuickLists.push({ activity, checklist })
        }
      })
    })
    this.setState({ quickChecklists: allQuickLists })
  }

  renderChecklists() {
    const { quickChecklists, open } = this.state
    const { classes } = this.props
    if (quickChecklists.length === 0) return <Typography variant="subtitle1">No hay listas de chequeo con acceso rápido</Typography>
    const lists = quickChecklists.slice(0, 3).map(element => {
      return (
        <div className={classes.quick}>
          <Typography variant="h4">{element.activity.name}</Typography>
          <Typography variant="subtitle1">{element.checklist.name}</Typography>
          <SubmitButton color="secondary" onClick={this.handleCreate(element)}>Realizar</SubmitButton>
        </div>
      )
    })
    const hiddenLists = quickChecklists.slice(3).map(element => {
      return (
        <div className={classes.quick}>
          <Typography variant="h4">{element.activity.name}</Typography>
          <Typography variant="subtitle1">{element.checklist.name}</Typography>
          <SubmitButton color="secondary" onClick={this.handleCreate(element)}>Realizar</SubmitButton>
        </div>
      )
    })
    return (
      <>
        <div className={classes.quicks}>
          {lists}
        </div>
        <Collapse in={open}>
          <div className={classes.quicks}>
            {hiddenLists}
          </div>
        </Collapse>
        {hiddenLists.length > 3 && <div className={classes.centerButton}>
          <Button onClick={this.handleOpen} color="primary" variant="outlined" size="large">
            Ver {open ? "Menos" : "Más"}
          </Button>
        </div>}
      </>
    )
  }

  render() {
    const { classes } = this.props
    const { loading } = this.state
    return (
      <div className={classes.container}>
        { loading
          ? <LoaderAnimator loading />
          : <div className={classes.lists}>
            {this.renderChecklists()}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activities: state.activities,
  user: state.user,
  settings: state.settings,
  workers: state.workers,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getSettingsChecklists: () => dispatch(getSettingChecklistsAction()),
  createChecklist: body => dispatch(createChecklistAction(body)),
  getBranches: (body) => dispatch(getBranches(body)),
  setBranch: body => dispatch(setBranchAction(body)),
  getWorkers: () => dispatch(getWorkersAction())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(QuickAccessChecklists)))