import { Divider, Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import Progress from "./Progress"

const style = theme => ({
    container: {
        padding: 12,
        flexBasis: 300,
        flexGrow: 1,
        maxWidth: 300,
        transition: "all 0.1s linear",
        "@media (max-width:500px)": {
            flexBasis: "100%",
            maxWidth: "unset",
        },
        "&:hover": {
            background: theme.palette.blue.main,
            transform: "scale(1.02)",
            cursor: "pointer",
        },
        background: theme.palette.blue.dark,
        '& *': {
            color: 'white'
        },
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    divider: {
        margin: "12px 0",
    },
    title: {
        marginBottom: 6,
    },
})

class StatusCard extends Component {
    render() {
        const { classes, title, total, actual, legend, color, onClick } = this.props
        return (
            <Paper className={classes.container} onClick={onClick}>
                <div className={classes.inline}>
                    <div className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography variant="caption">{`${legend} / Total`}</Typography>
                    </div>
                    <Typography variant="h1">{`${actual} / ${total}`}</Typography>
                </div>
                <Divider className={classes.divider} />
                <Progress
                    percentage={total > 0 ? Math.round((actual / total) * 100) : 0}
                    legend={legend}
                    color={color}
                    name={title}
                />
                {this.props.children}
            </Paper>
        )
    }
}

StatusCard.propTypes = {}

StatusCard.defaultProps = {
    title: "Titulo de la tarjeta",
    total: 5,
    actual: 2,
    legend: "Leyenda",
    color: "#91deb1",
}

export default withStyles(style)(StatusCard)
