import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/lib/integration/react"
import { SnackbarProvider } from "notistack"
import { MuiThemeProvider } from "@material-ui/core"
import theme from "./theme"
import store from "./store"
// import CacheBuster from "./CacheBuster"

const persistor = persistStore(store)

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <BrowserRouter>
                    <SnackbarProvider
                        disableWindowBlurListener
                        autoHideDuration={2000}
                        maxSnack={5}
                    >
                        <App />
                    </SnackbarProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById("root")
)
