import React, { Component } from 'react'
import { Checkbox, Collapse, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../../../Shared/Inputs/TextInput'
import autobind from '../../../../../Utils/autobind'
import ImgInput from '../../../../../Shared/Inputs/ImgInput'

const style = () => ({
  inline: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 8
    }
  }
})

class TextStepCreate extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(TextStepCreate, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { open } = this.state
    const { classes, params, onChange, noImage } = this.props
    return (
      <div className={classes.container}>
        <TextInput label="Titulo" name="label" value={params.label} onChange={onChange} />
        {!noImage &&
          <>
            <div className={classes.inline}>
              <Checkbox onClick={this.handleOpen} checked={open} />
              <Typography variant="subtitle1">Agregar imágen a campo de texto</Typography>
            </div>
            <Collapse in={open}>
              <ImgInput value={params.image} name="image" onChange={onChange} label="Imágen" />
            </Collapse>
          </>}
      </div>
    )
  }
}

export default withStyles(style)(TextStepCreate)