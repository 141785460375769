import React, { Component } from "react"
import { Checkbox, Collapse, Divider, Typography, withStyles } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { activityEditInfo } from "./Info"
import autobind from "../../Utils/autobind"
import { activityDispatcher } from "../../Actions/ActivityActions"
import { connect } from "react-redux"
import { transformToOptions } from "../../Utils/functions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import GeneralTable from "../../Shared/GeneralTable"
import SelectInput from "../../Shared/Inputs/SelectInput"
import DateInput from "../../Shared/Inputs/DateInput"
import moment from "moment"

const style = () => ({
    container: {
        margin: 12,
    },
    input: {
        maxWidth: 500,
        margin: '24px 0',
        padding: '12px 18px',
        background: 'white',
        borderRadius: 15
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 12
        }
    }
})

class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            params: {
                date_start: moment(new Date()).format("YYYY-MM-DD"),
                date_end: moment(new Date()).add(1, 'months').format("YYYY-MM-DD")
            },
            enabledDates: false
        }
        autobind(Activity, this)
    }

    componentDidMount() {
        const { getActivity, getWorkers, getActivityParams } = this.props
        const body = {
            companyId: 4,
            id: this.props.match.params.id,
        }
        getWorkers({ companyId: 4 })
        getActivityParams({ companyId: 4 })
        getActivity(body).then(() => {
            this.setState({ loading: false })
        })
    }

    handleEndableDates() {
        this.setState({ enabledDates: !this.state.enabledDates })
    }

    handleSelectPM(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleEdit(params) {
        const { user } = this.props
        const body = { ...params }
        body.id = this.props.match.params.id
        body.companyId = user.account.user.idCompany
        const { editActivity } = this.props
        editActivity(body)
    }

    setFormInfo(info) {
        const { workers, activities, enterprise, branch } = this.props
        const params = activities.params[0]
        const allWorkers = workers.all || []
        const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
        info.map((input) => {
            switch (input.name) {
                case "user_id": {
                    input.options = transformToOptions(filteredWorkers)
                    return input
                }
                case "probability_id": {
                    input.options = transformToOptions(params.probabilities)
                    return input
                }
                case "consequence_id": {
                    input.options = transformToOptions(params.consequences)
                    return input
                }
                case "residual_probability_id": {
                    input.options = transformToOptions(params.probabilities)
                    return input
                }
                case "residual_consequence_id": {
                    input.options = transformToOptions(params.consequences)
                    return input
                }
                case "risks_id": {
                    input.options = transformToOptions(params.risks)
                    return input
                }
                case "epps_id": {
                    input.options = transformToOptions(params.epps)
                    return input
                }
                case "measures_id": {
                    input.options = transformToOptions(
                        params.preventive_measures
                    )
                    return input
                }
                case "branch_id": {
                    input.options = transformToOptions(enterprise.branches)
                    return input
                }
                default:
                    return input
            }
        })

        return info
    }

    render() {
        const { classes, activities, settings, checklists, match } = this.props
        const { loading, params, enabledDates } = this.state

        const allActivities = activities?.all || []
        const allMeasures = allActivities.find(activity => activity.id === match.params.id).preventive_measures[0] || []

        const allChecklists = checklists.done
            .filter(
                checklist => checklist.activity_id === match.params.id
                    && checklist.preventive_measure_id === params.preventive_measure
            ).filter(ch => enabledDates ? moment(ch.date_done).isBetween(moment(params.date_start), moment(params.date_end)) : true)

        const allAnswers = allChecklists.map(checklist => checklist.checklist_items
            .concat(checklist.extra_fields.concat([{ label: "observation", value: checklist.observations }])
                .concat([{ label: "user", value: checklist.user }])
                .concat([{ label: "date_done", value: checklist.date_done }])
            ))

        const settingChecklists = settings.checklists.all || []
        const selectedMeasure = settingChecklists.find(measure => measure.id === params.preventive_measure)
        const selectedExtraFields = selectedMeasure?.extra_fields || []
        const selectedChecklistElements = selectedMeasure?.checklist_items || []

        const tableInfo = selectedChecklistElements.concat(selectedExtraFields).map(element => ({
            name: element.name || element.label, label: element.name || element.label
        }))
        tableInfo.push({ name: "Observaciones", label: "observation" })
        tableInfo.push({ name: "Responsable", label: "user" })
        tableInfo.push({ name: "Fecha", label: "date_done" })

        const tableData = allAnswers.map(answer => {
            const keys = answer.map(element => element.name || element.label)
            const values = answer.map(element => {
                if (element.name) {
                    return element.answer.value
                } else if (element.label) {
                    return element.value
                }
            })
            const result = {}
            keys.forEach((key, index) => { result[key] = values[index] })
            return result
        })

        return (
            <div className={classes.container}>
                <MutableInfoCard
                    title="Datos de la Actividad"
                    submit="Guardar"
                    subtitle="Editar actividad"
                    submitAction={this.handleEdit}
                    showInfo={this.setFormInfo(activityEditInfo)}
                    formInfo={this.setFormInfo(activityEditInfo)}
                    response={activities.selected}
                    loading={loading}
                />
                <div className={classes.input}>
                    <SelectInput
                        label="Medida Preventiva"
                        value={params.preventive_measure}
                        onChange={this.handleSelectPM}
                        options={transformToOptions(allMeasures)}
                        name="preventive_measure"
                    />
                    <Divider style={{ margin: 12 }} />
                    <div className={classes.checkbox}>
                        <Checkbox checked={enabledDates} name="enable_dates" onChange={this.handleEndableDates} />
                        <Typography variant="subtitle1">Seleccionar intervalo de fechas</Typography>
                    </div>
                    <Collapse in={enabledDates}>
                        <DateInput
                            label="Fecha inicio"
                            value={params.date_start}
                            name="date_start"
                            onChange={this.handleSelectPM}
                        />
                        <DateInput
                            label="Fecha de término"
                            value={params.date_end}
                            name="date_end"
                            onChange={this.handleSelectPM}
                        />
                    </Collapse>
                </div>
                <GeneralTable
                    info={tableInfo}
                    data={tableData}
                />
            </div>
        )
    }
}

Activity.propTypes = {}

Activity.defaultProps = {
    workers: { all: [] },
    activities: { selected: [{}], params: [{}] },
}

const mapStateToProps = (state) => ({
    user: state.user,
    workers: state.workers,
    activities: state.activities,
    enterprise: state.enterprise,
    branch: state.branch,
    settings: state.settings,
    checklists: state.checklists
})

const mapDispatchToProps = (dispatch) => ({
    getWorkers: (body) => dispatch(getWorkersAction(body)),
    ...activityDispatcher(dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(Activity))
