import React, { Component } from 'react'
import { IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { getFindingsAction } from '../../Actions/FindingActions'
import { getNonConformitiesAction } from '../../Actions/NoConfActions'
import { getPendingChecklistsAction } from '../../Actions/CheckListActions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import moment from 'moment'
import { ExitToApp } from '@material-ui/icons'

const style = () => ({
  pending: {
    padding: 12,
    margin: "12px 0",
    maxWidth: 700,
    position: 'relative'
  },
  pendingTitle: {
    fontWeight: 600,
    marginBottom: 12
  },
  pendingDate: {
    textAlign: 'end',
    marginTop: 6
  },
  pendingContent: {
    maxWidth: "90%",
    textAlign: 'justify',
    lineHeight: 1.75
  },
  message: {
    padding: 24,
    fontSize: 14
  },
  floatingButton: {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translate(0, -50%)'
  }
})

class Pendings extends Component {
  constructor() {
    super()
    autobind(Pendings, this)
  }

  componentDidMount() {
    const { getFindings, getChecklists, getNonConformities } = this.props
    getFindings()
    getChecklists()
    getNonConformities()
  }

  handleGotoPending(link) {
    return () => {
      const { history } = this.props
      history.push(link)
    }
  }

  renderPendings() {
    const { checklists, findings, unconformities, user, classes } = this.props
    const user_id = user.account.user.id
    const allChecklists = checklists.pending || []
    const allFindings = findings.all || []
    const allNonConformities = unconformities.all || []

    const pendingChecklists = allChecklists.filter(checklist => {
      return checklist.user_id.toString() === user_id.toString()
    })

    const pendingFindings = allFindings.filter(finding => {
      const isSameCreator = finding.user_creator_id?.toString() === user_id.toString()
      const isSameUser = finding.user_id?.toString() === user_id.toString()
      const isMine = isSameCreator || isSameUser
      const isNotDone = finding.status !== "Aprobado" && finding.status !== "Terminado"
      return isMine && isNotDone
    })

    const pendingNonConformities = allNonConformities.filter(nonConformity => {
      const isSameCreator = nonConformity.user_creator_id?.toString() === user_id.toString()
      const isSameUser = nonConformity.user_id?.toString() === user_id.toString()
      const isMine = isSameCreator || isSameUser
      const isNotDone = nonConformity.status !== "Aprobado" && nonConformity.status !== "Terminado"
      return isMine && isNotDone
    })

    const allPendings = []

    pendingChecklists.forEach(checklist => {
      const pendingInfo = {
        name: "Lista de Chequeo",
        link: `/checklists/${checklist.id}`,
        content: `Tienes una lista de chequeo pendiente: ${checklist.activity}`,
        date: checklist.date_scheduled
      }
      allPendings.push(pendingInfo)
    })

    pendingFindings.forEach(finding => {
      const pendingInfo = {
        name: "Hallazgo",
        link: `/findings/${finding.id}`,
        content: `Tienes un hallazgo pendiente de revisión: ${finding.checklist_item_name}`,
        date: finding.date_limit
      }
      allPendings.push(pendingInfo)
    })

    pendingNonConformities.forEach(unconformity => {
      const pendingInfo = {
        name: "No Conformidad",
        link: `/unconformities/${unconformity.id}`,
        content: `Tienes una no conformidad pendiente de revisión: ${unconformity.name}`,
        date: unconformity.date_limit
      }
      allPendings.push(pendingInfo)
    })

    allPendings.sort((a, b) => {
      if (!moment(a.date).isValid()) {
        return 1
      }
      if (!moment(b.date).isValid()) {
        return -1
      }
      if (moment(a.date) > moment(b.date)) {
        return 1
      }
      if (moment(a.date) < moment(b.date)) {
        return -1
      }
      return 0
    })

    if (allPendings.length > 0) {
      return allPendings.map(pending => {
        return (
          <Paper key={pending.link} className={classes.pending} onClick={this.handleGotoPending(pending.link)}>
            <Typography variant="h2" className={classes.pendingTitle}>{pending.name}</Typography>
            <Typography variant="body1" className={classes.pendingContent}>{pending.content}</Typography>
            <div className={classes.pendingDate}>
              <Typography variant="caption" style={{ color: moment(new Date()) > moment(pending.date) ? "#ff6c87" : "" }}>{moment(pending.date).isValid() ? pending.date : "Sin fecha asignada"}</Typography>
            </div>
            <div className={classes.floatingButton}>
              <IconButton size="small" color="primary">
                <ExitToApp />
              </IconButton>
            </div>
          </Paper>
        )
      })
    } else {
      return <Typography variant="h2" className={classes.message}>¡No tienes pendientes!</Typography>
    }

  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderPendings()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists,
  findings: state.findings,
  unconformities: state.unconformities,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getFindings: () => dispatch(getFindingsAction()),
  getNonConformities: () => dispatch(getNonConformitiesAction()),
  getChecklists: () => dispatch(getPendingChecklistsAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Pendings))