import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import GeneralTable from '../../../Shared/GeneralTable'
import { getWorkersAction } from '../../../Actions/EnterpriseAction'
import { ExitToApp } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import { getUserDocumentsAction } from '../../../Actions/WorkerActions'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > *": {
      color: "white"
    },
    maxWidth: 500,
    marginBottom: 12
  }
})



class UserDocumentsResume extends Component {
  constructor() {
    super()
    autobind(UserDocumentsResume, this)
  }


  handleGotoProfile(row) {
    return () => {
      const { history } = this.props
      history.push(`/workers/${row.id}`)
    }
  }

  handleCheckIfHasFile(worker) {
    const { getUserDocuments, match } = this.props
    const body = { user_id: worker.id }
    getUserDocuments(body).then(response => {
      const documents = response.payload.data.info
      const hasElement = documents.find(doc => doc.category_id.toString() === match.params.id)
      if (hasElement) {
        return "Archivo subido"
      } else {
        return "No hay archivo"
      }
    })

  }

  render() {

    const { classes, match, settings, workers } = this.props
    const id = match.params.id
    const allCategories = settings.user_documents.all || []
    const selected = allCategories.find(cat => cat.id.toString() === id)
    const allWorkers = workers.all || []

    const tableData = allWorkers.map(worker => {
      return {
        id: worker.id,
        name: worker.name,
        email: worker.email,
        hasFile: this.handleCheckIfHasFile(worker)
      }
    })

    const tableInfo = [
      { label: "name", name: "Nombre" },
      { label: "email", name: `Correo` },
      { label: "hasFile", name: "Estado de archivo" },
    ]

    const actions = [
      { name: "Ir a perfil", action: this.handleGotoProfile, icon: ExitToApp }
    ]


    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">{selected?.name}</Typography>
        </div>
        <GeneralTable data={tableData} info={tableInfo} actions={actions} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  workers: state.workers
})

const mapDispatchToProps = dispatch => ({
  getWorkers: () => dispatch(getWorkersAction()),
  getUserDocuments: body => dispatch(getUserDocumentsAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(UserDocumentsResume))