import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import "./animation.css"

const style = () => ({

})
class LoaderAnimator extends Component {

  render() {
    return (
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    )
  }
}

export default withStyles(style)(LoaderAnimator)