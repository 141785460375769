import React, { Component } from 'react'
import { Checkbox, Typography, withStyles, Button } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import SubmitButton from '../../../Shared/SubmitButton'
import { CheckCircleOutline } from '@material-ui/icons'

const style = (theme) => ({
  container: {
    padding: 12,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phaseStatus: {
    height: 85,
    width: 85,
    border: '1px solid grey',
    borderRadius: 15,
    position: 'relative'
  },
  phaseContent: {
    flexGrow: 1,
    marginRight: 24
  },
  checkIcon: {
    color: theme.palette.green.main,
    height: 64,
    width: 64,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.2s linear'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
    '& > *': {
      marginRight: 12
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 12
    }
  }
})
class TextPhase extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      checked: false
    }
    autobind(TextPhase, this)
  }
  componentDidMount() {
    const { phase } = this.props
    if (phase.value) {
      const startingParams = {
        [phase.label]: phase.value
      }
      this.setState({ params: startingParams, checked: true })
    }
  }

  handleEdit() {
    this.setState({ checked: false })
  }

  handleChange() {
    const { params } = this.state
    const { phase } = this.props
    const name = phase.label
    params[name] = !params[name]
    this.setState({ params })
  }

  handleSubmit() {
    const { onSubmit, phase, index } = this.props
    const { params } = this.state
    const body = {
      ...phase,
      value: params[phase.label]
    }
    onSubmit(body, index)
    this.setState({ checked: true })
  }

  render() {
    const { params, checked } = this.state
    const { classes, phase, disabled } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.phaseContent}>
          <Typography variant="subtitle1">{phase.label}</Typography>
          <div className={classes.inline}>
            <Checkbox disabled={checked || disabled} checked={params[phase.label] || false} onClick={this.handleChange} />
            <Typography variant="caption">{phase.label}</Typography>
          </div>
          <div className={classes.buttons}>
            {!disabled && checked && <Button variant="outlined" onClick={this.handleEdit} size="small">
              Editar
            </Button>}
            <SubmitButton onClick={this.handleSubmit} disabled={checked || !params[phase.label] || disabled}>Terminar Fase</SubmitButton>
          </div>
        </div>
        <div className={classes.phaseStatus}>
          <CheckCircleOutline className={classes.checkIcon} style={{ opacity: checked ? 1 : 0 }} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(TextPhase)