import React, { Component } from 'react'
import { Tab, Typography, withStyles } from '@material-ui/core'
import MyTabs from '../../../Shared/MyTabs'
import autobind from '../../../Utils/autobind'
import TabPanel from '../../../Shared/TabPanel'
import Accidents from './Accidents'
import { createSettingsWorkflowAction, editSettingsWorkflowAction, getSettingsWorkflowsAction } from '../../../Actions/WorkFlows'
import { connect } from 'react-redux'

const style = () => ({
  container: {
    marginTop: 36,
    margin: 12
  },
  tabs: {
    background: 'white',
    marginTop: 12,
  }
})

const tipos = {
  1: "Accidentes",
  2: "Incidentes",
  3: "Procedimientos",
  4: "Protocolos",
  5: "Generales"
}

class Processes extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0
    }
    autobind(Processes, this)
  }

  componentDidMount() {
    const { getSettingsWorkflows } = this.props
    getSettingsWorkflows()
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  handleCreate(body) {
    const { createSettingsWorkflow } = this.props
    createSettingsWorkflow(body)
  }

  handleEdit(body) {
    const { editSettingsWorkflow } = this.props
    editSettingsWorkflow(body)
  }

  render() {
    const { classes, settings } = this.props
    const { tab } = this.state
    const allWorkflows = settings.workflows.all || []
    return (
      <div className={classes.container}>
        <Typography variant="h1">Configuración de procesos</Typography>
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={0} label={<Typography variant="h2">Accidentes</Typography>} />
            <Tab value={1} label={<Typography variant="h2">Incidentes</Typography>} />
            <Tab value={2} label={<Typography variant="h2">Generales</Typography>} />
          </MyTabs>
        </div>
        <TabPanel index={0} value={tab}>
          <Accidents workflows={allWorkflows} onCreate={this.handleCreate} onEdit={this.handleEdit} />
        </TabPanel>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  createSettingsWorkflow: body => dispatch(createSettingsWorkflowAction(body)),
  editSettingsWorkflow: body => dispatch(editSettingsWorkflowAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Processes))