import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"
import { enqueueRequest } from "../Utils/offline"

export const getFindingsAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: 'findings/get.php'
  })
  return {
    type: "GET_FINDINGS",
    payload: REQUEST
  }
}

export const assignFindingAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "findings/assign.php",
    data: body,
    name: "Asignación de Hallazgo"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "ASSIGN_FINDING",
    payload: body
  }
}

export const approveFindingAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "findings/approve.php",
    data: body,
    name: "Aprobación de Hallazgo"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "APPROVE_FINDING",
    payload: body
  }
}

export const dismissFindingAction = (body) => {
  const requestBody = {
    method: 'post',
    url: "findings/dismiss.php",
    data: body,
    name: "Desestimación de Hallazgo"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(requestBody)
  }

  return {
    type: "DISMISS_FINDING",
    payload: body
  }
}

export const solveFindingAction = (body, formData) => {
  const requestBody = {
    method: 'post',
    url: "findings/solve.php",
    data: formData,
    name: "Resolución de Hallazgo"
  }
  const fileBody = {
    method: 'post',
    url: "findings/solve.php",
    data: body,
    name: "Resolución de Hallazgo"
  }
  if (body.online) {
    createSnackbar(instance(requestBody))
  } else {
    enqueueRequest(fileBody)
  }

  return {
    type: "SOLVE_FINDING",
    payload: body
  }
}

export const selectFindingAction = (id) => {
  return {
    type: "SELECT_FINDING",
    payload: id
  }
}