import React, { Component } from 'react'
import { Button, Collapse, Dialog, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { Backup, Delete, Edit } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../Shared/Inputs/TextInput'
import FileInput from '../../Shared/Inputs/FileInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import SubmitButton from '../../Shared/SubmitButton'
import { transformToOptions } from '../../Utils/functions'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { createMultipleDocumentAction, createUserDocumentAction, deleteUserDocumentAction, editUserDocumentAction } from '../../Actions/WorkerActions'
import { callSnackbar } from '../../Utils/snackbar'
import DateInput from '../../Shared/Inputs/DateInput'
import SignDocument from './SignDocument'
import ImageContainer from '../../Shared/ImageContainer'
import SelectMultipleDocuments from './SelectMultipleDocuments'

const style = theme => ({
  category: {
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    height: 150,
    width: 250,
    "@media (max-width:500px)": {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '24px 0'
  },
  actions: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  categoryContainer: {
    padding: 12,
    transition: 'all 0.2s ease-in-out',
    "@media (max-width:500px)": {
      width: '100%',
      padding: "12px 0"
    },
  },
  mainCategory: {
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
    position: 'relative'
  },
  openMain: {
    minHeight: 300,
    width: '100%',
    "@media (max-width:500px)": {
      width: '100%',
    },
  },
  closedMain: {
    height: 0,
    width: 0
  },
  title: {
    padding: 12,
    transition: 'all 0.2s ease-in-out',
  },
  content: {
    padding: 12
  },
  openTitle: {
    background: theme.palette.primary.main,
    color: "white",
  },
  wrong: {
    padding: 12,
    transition: 'all 0.2s ease-in-out',
    background: theme.palette.red.main,
    color: "white",
  },
  openWrong: {
    background: theme.palette.red.main,
    color: "#1a1a1a",
  },
  file: {
    width: 200,
    margin: 12,
    border: '1px solid #1a1a1a',
    borderRadius: 12,
    padding: 12,
    position: 'relative',
    "@media (max-width:500px)": {
      width: '100%',
      margin: "12px 0"
    },
  },
  subcategory: {
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    height: 159,
    width: 226,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
    },
    border: "1px solid #1a1a1a"
  },
  files: {
    display: 'flex',
    padding: 12,
    flexWrap: 'wrap',
    marginTop: 75
  },
  doctText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0'
  },
  doctTextDownload: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0',
    color: theme.palette.blue.main,
    cursor: 'pointer'
  },
  doctTextTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: '6px 0',
    fontWeight: 600
  },
  docButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12
  },
  inputs: {
    padding: 12
  },
  warningContainer: {
    padding: "0px 6px 6px"
  },
  warning: {
    color: theme.palette.red.main,
    margin: "6px 0",
    fontWeight: 600
  },
  signButton: {
    position: 'absolute',
    top: 58,
    right: 12,
    textAlign: 'end',
    '& > div > span': {
      color: theme.palette.green.main
    }
  },
  docSigns: {
    padding: 12,
    display: 'flex',
    flexWrap: 'wrap',
  },
  docSign: {
    width: 240,
    padding: 12,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
    },
  },
  signTitle: {
    color: 'white',
    background: theme.palette.primary.main,
    padding: 12
  },
  caption: {
    textAlign: 'end'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    right: 12,
    width: 300,
    '& > div': {
      width: 200,
      marginLeft: 12
    }
  },
  filesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 12px'
  }
})

class WorkerDocuments extends Component {
  constructor() {
    super()
    this.state = {
      openCategory: -1,
      openSubcategory: -1,
      openSubSubcategory: -1,
      category: {},
      subcategory: null,
      subsubcategory: null,
      document: {},
      openDialog: false,
      openWarning: false,
      isSubEdit: false,
      isSubSubEdit: false,
      openCreate: false,
      openNameWarning: null,
      selectedDocument: null,
      openSigns: null,
      openMultiple: false,
      params: {
        date: moment(new Date()).format("YYYY-MM-DD")
      },
      editParams: {},
      filter: 1
    }

    autobind(WorkerDocuments, this)
  }

  handleToggleMultiple() {
    this.setState({ openMultiple: !this.state.openMultiple })
  }

  handleOpenCategory = (index, category, isSub = false, isSubSub = false) => () => {
    const { openCategory, openSubcategory, openSubSubcategory } = this.state
    if (isSubSub) {
      if (openSubSubcategory === index) return this.setState({ openSubSubcategory: -1, subsubcategory: null })
      return this.setState({ openSubSubcategory: index, subsubcategory: category })
    }
    if (isSub) {
      if (openSubcategory === index) return this.setState({ openSubcategory: -1, subcategory: null })
      return this.setState({ openSubcategory: index, subcategory: category })
    }
    if (openCategory === index) return this.setState({ openCategory: -1, category: {}, subcategory: null, openSubcategory: -1 })
    this.setState({ openCategory: index, category, subcategory: null, openSubcategory: -1 })
    function showElement() {
      document.getElementById("openDocument").scrollIntoView({ behavior: "smooth" })
    }
    setTimeout(showElement, 500)
  }

  handleOpenSigns(document) {
    return () => {
      this.setState({ openSigns: document })
    }
  }

  handleOpenSign(document) {
    return () => {
      this.setState({ selectedDocument: document })
    }
  }

  handleOpenDialog(document) {
    return () => {
      if (document) {
        const editParams = {
          name: document.name,
          category_id: document.category_id,
          subcategory_id: document.subcategory_id,
          sub_subcategory_id: document.sub_subcategory_id,
          date: document.date || moment(new Date()).format("YYYY-MM-DD")
        }
        this.setState({ openDialog: !this.state.openDialog, document, editParams })
      } else {
        this.setState({ openDialog: !this.state.openDialog })
      }

    }
  }

  handleOpenWarning(document) {
    return () => {
      if (document) {
        this.setState({ openWarning: !this.state.openWarning, document })
      } else {
        this.setState({ openWarning: !this.state.openWarning })
      }
    }
  }

  handleOpenCreate(category, isSub = false, isSubSub = false) {
    return () => {
      if (category) {
        this.setState({ openCreate: !this.state.openCreate, isSubEdit: isSub, isSubSubEdit: isSubSub, params: { ...this.state.params, category_id: category.id } })
      } else {
        this.setState({ openCreate: !this.state.openCreate, isSubEdit: isSub, isSubSubEdit: isSubSub, params: {} })
      }

    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const { workers } = this.props
    params[target.name] = target.value
    let existsName = null
    if (target.name === "file") {
      params["name"] = target.value.name
      const allDocuments = workers.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value.name)
    }
    if (target.name === "name" && target.value !== "") {
      const allDocuments = workers.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value)
    }
    if (existsName) {
      this.setState({ params, openNameWarning: existsName })
    } else {
      this.setState({ params, openNameWarning: null })
    }
  }

  handleChangeEdit(event) {
    const { target } = event
    const { editParams } = this.state
    const { workers } = this.props
    editParams[target.name] = target.value
    let existsName = null
    if (target.name === "file") {
      editParams["name"] = target.value.name
      const allDocuments = workers.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value.name)
    }
    if (target.name === "name" && target.value !== "") {
      const allDocuments = workers.documents || []
      existsName = allDocuments.find(doc => doc.name === target.value)
    }
    if (existsName) {
      this.setState({ editParams, openNameWarning: existsName })
    } else {
      this.setState({ editParams, openNameWarning: null })
    }
  }

  handleSendEdit() {
    const { editParams, document } = this.state
    const { match, editUserDocument } = this.props
    const body = new FormData()
    body.append("name", editParams.name)
    body.append("file", editParams.file)
    body.append("category_id", editParams.category_id)
    body.append("subcategory_id", editParams.subcategory_id)
    body.append("sub_subcategory_id", editParams.sub_subcategory_id)
    body.append("id", document.id)
    body.append("user_id", match.params.id)
    body.append("date", editParams.date)
    editUserDocument(body)

    this.setState({ openDialog: false, document: {} })
  }

  handleSendDelete() {
    const { document } = this.state
    const { match, deleteUserDocument } = this.props
    const body = {
      user_id: match.params.id,
      id: document.id
    }
    deleteUserDocument(body)
    this.setState({ openWarning: false, document: {} })
  }

  handleCreate() {
    const { params, isSubEdit, category, subcategory, isSubSubEdit } = this.state
    const { match, createUserDocument } = this.props
    const body = new FormData()
    body.append("name", params.name || "")
    body.append("file", params.file)
    body.append("date", params.date)
    body.append("user_id", match.params.id)
    if (isSubSubEdit) {
      body.append("category_id", category.id)
      body.append("subcategory_id", subcategory.id)
      body.append("sub_subcategory_id", params.category_id)
    }
    else if (isSubEdit) {
      body.append("category_id", category.id)
      body.append("subcategory_id", params.category_id)
    } else {
      body.append("category_id", params.category_id)
    }
    createUserDocument(body)
    this.setState({ openCreate: false, params: {} })
  }

  handleCreateMultipleDocuments(dataObject) {
    const { category, subcategory, subsubcategory } = this.state
    const { match, createMultipleDocuments } = this.props

    console.log(category)
    console.log(subcategory)
    console.log(subsubcategory)

    const body = new FormData()
    body.append("date", dataObject.date)
    body.append("user_id", match.params.id)
    for (let i = 0; i < dataObject.files.length; i++) {
      body.append("file[]", dataObject.files[i])
    }
    if (subsubcategory !== null) {
      body.append("category_id", category.id)
      body.append("subcategory_id", subcategory.id)
      body.append("sub_subcategory_id", subsubcategory.id)

    } else if (subcategory !== null) {
      body.append("category_id", category.id)
      body.append("subcategory_id", subcategory.id)
    } else {
      body.append("category_id", category.id)
    }
    createMultipleDocuments(body)

    this.setState({ openMultiple: false })
  }

  handleDownload(element) {
    return () => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${element.file}`
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement("a")
          a.href = url
          a.download = `${element.file}`
          a.click()
        })
      }).catch(() => callSnackbar("Error al descargar documento", "error"))
    }
  }

  handleSeeDocument(element) {
    return () => {
      window.open(`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${element.file}`)
    }
  }

  renderSigns() {
    const { classes } = this.props
    const { openSigns } = this.state
    const signs = openSigns?.signs || []
    if (signs.length > 0) {
      return signs.map(sign => {
        const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
        return (
          <Paper className={classes.docSign}>
            <Typography variant="subtitle1">{sign.name}</Typography>
            <ImageContainer src={url || "/noimage.png"} height="100px" width="200" />
            <div className={classes.caption}>
              <Typography variant="caption">{moment(sign.date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </div>
          </Paper>
        )
      })
    } else {
      return (
        <div>
          <Typography variant="subtitle1">No hay firmas aún en el documento</Typography>
        </div>
      )
    }

  }

  renderCategories() {
    const { openCategory } = this.state
    const { categories, classes, workers, user } = this.props

    const level = user.account.user.userType
    const cantEdit = level > 1

    const allDocuments = workers.documents || []
    const realCategories = categories.map((category, index) => {
      let isUpToDate = true
      const categoryDocuments = allDocuments.filter(document => document.category_id === category.id) || []

      if (category.frequency === 30) {
        const cutDate = moment(new Date()).startOf("month").add(category.cut_day, 'days')
        if (moment(new Date()) >= cutDate) {
          const validDocs = categoryDocuments.filter(doc => moment(doc.date).isSame(new Date(), "month"))
          if (validDocs.length === 0) {
            isUpToDate = false
          }
        }
      }

      if (category.frequency === 365) {
        const cutDate = moment(new Date()).startOf("year").add(category.cut_day, 'months')
        if (moment(new Date()) >= cutDate) {
          const validDocs = categoryDocuments.filter(doc => moment(doc.date).isSame(new Date(), "year"))
          if (validDocs.length === 0) {
            isUpToDate = false
          }
        }
      }

      if (category.frequency === 1 && categoryDocuments.length === 0) isUpToDate = false

      const isOpen = openCategory === index

      return (
        <div className={`${classes.categoryContainer}`} key={index}>
          <Paper className={`${classes.category}`}>
            <Typography variant="h1" className={`${isUpToDate ? classes.title : classes.wrong} ${isOpen ? (!isUpToDate ? classes.openWrong : classes.openTitle) : ""}`} > {category.name}</Typography>
            <Divider />
            <div className={classes.content}>
              <Typography variant="subtitle1"> Documentos: {categoryDocuments.length}</Typography>
              <div className={classes.actions}>
                <Button color="primary" variant="contained" onClick={this.handleOpenCategory(index, category)}>
                  {isOpen ? "Cerrar" : "Abrir"}
                </Button>
                <Button disabled={cantEdit} color="primary" variant="outlined" onClick={this.handleOpenCreate(category)}>
                  <Backup />
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      )
    })
    const isGeneralOpen = openCategory === "none"
    const noCategoryDocuments = allDocuments.filter(document => document.category_id === null) || []
    const total = noCategoryDocuments.length
    return (
      <>
        {realCategories}
        {total > 0 &&
          <div className={`${classes.categoryContainer}`}>
            <Paper className={`${classes.category}`}>
              <Typography variant="h1" className={`${classes.title} ${isGeneralOpen ? classes.openTitle : ""}`}>Sin Categoría</Typography>
              <Divider />
              <div className={classes.content}>
                <Typography variant="subtitle1"> Documentos: {noCategoryDocuments.length}</Typography>
                <div className={classes.actions}>
                  <Button color="primary" variant="contained" onClick={this.handleOpenCategory("none", { id: null, name: "Sin Categoría" })}>
                    {isGeneralOpen ? "Cerrar" : "Abrir"}
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        }
      </>
    )
  }

  renderCategoryFiles() {
    const { category, subcategory, filter, subsubcategory } = this.state
    const { workers, classes, user, } = this.props
    const level = user.account.user.userType
    const cantEdit = level > 1
    const allDocuments = workers.documents || []
    const docRender = allDocuments
      .sort((a, b) => {
        if (filter === 1) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
        }
        if (filter === 2) {
          if (moment(a.date) > moment(b.date)) return 1
          if (moment(b.date) > moment(a.date)) return -1
        }
        return 0
      })
      .filter(document => document.category_id === category.id
        && ((document.subcategory_id === 0 && !subcategory)
          || document.subcategory_id === subcategory?.id)
        && ((document.sub_subcategory_id === 0 && !subsubcategory)
          || document.sub_subcategory_id === subsubcategory?.id))
      .map(document => {
        return (
          <div className={classes.file} key={document.id}>
            <Typography variant="h2" className={classes.doctTextTitle}>{document.name || "Sin nombre"}</Typography>
            <Typography variant="body2" className={classes.doctTextDownload} onClick={this.handleSeeDocument(document)}>Ver Documento</Typography>
            <Typography variant="body2" className={classes.doctTextDownload} onClick={this.handleOpenSigns(document)}>Ver Firmas</Typography>
            <Typography variant="h2" className={classes.doctText}>{document.date}</Typography>
            <Divider />
            <div className={classes.docButtons}>
              {!cantEdit &&
                <div>
                  <IconButton size="small" onClick={this.handleOpenDialog(document)}>
                    <Edit />
                  </IconButton>
                  <IconButton size="small" onClick={this.handleOpenWarning(document)}>
                    <Delete />
                  </IconButton>
                </div>
              }
              <Button size="small" variant="outlined" color="primary" onClick={this.handleDownload(document)}>
                Descargar
            </Button>
            </div>
            <div className={classes.signButton}>
              <Button size="small" variant="outlined" onClick={this.handleOpenSign(document)} disabled={document.signs.map(sign => sign.user_id.toString()).includes(user.account.user.id.toString())}>
                Firmar
              </Button>
              <div>
                {document.signs.length > 0 && <Typography variant="caption">Firmado</Typography>}
              </div>
            </div>
          </div>
        )
      })
    return (
      <>
        {this.renderSubcategories()}
        {this.renderSubSubcategories()}
        {docRender}
      </>
    )
  }

  renderSubcategories() {
    const { category, openSubcategory, openSubSubcategory } = this.state
    const { workers, classes, user } = this.props
    const level = user.account.user.userType
    const cantEdit = level > 1
    const allDocuments = workers.documents || []
    const allSubcategories = category.subcategories || []
    return allSubcategories.filter((subcat, index) => openSubSubcategory === -1 && (openSubcategory === -1 || openSubcategory === subcat.id)).map((subcategory, index) => {
      const isSubOpen = openSubcategory === subcategory.id
      const subcategoryDocuments = allDocuments.filter(document => document.category_id === category.id && document.subcategory_id === subcategory.id) || []
      return (
        <div className={classes.subcategory} key={subcategory.id}>
          <Typography variant="h1" className={`${classes.title} ${isSubOpen ? classes.openTitle : ""}`}>{subcategory.name}</Typography>
          <Divider />
          <div className={classes.content}>
            <Typography variant="subtitle1"> Documentos: {subcategoryDocuments.length}</Typography>
            <div className={classes.actions}>
              <Button color="primary" variant="contained" onClick={this.handleOpenCategory(subcategory.id, subcategory, true)}>
                {isSubOpen ? "Cerrar" : "Abrir"}
              </Button>
              <Button disabled={cantEdit} color="primary" variant="outlined" onClick={this.handleOpenCreate(subcategory, true)}>
                <Backup />
              </Button>
            </div>
          </div>
        </div>
      )
    })
  }

  renderSubSubcategories() {
    const { category, openSubSubcategory, subcategory } = this.state
    const { workers, classes, user } = this.props
    const level = user.account.user.userType
    const cantEdit = level > 1
    const allDocuments = workers.documents || []
    const allSubSubcategories = subcategory?.sub_sub_categories || []
    return allSubSubcategories.filter((subcat, index) => openSubSubcategory === -1 || openSubSubcategory === subcat.id).map((subsubcategory, index) => {
      const isSubOpen = openSubSubcategory === subsubcategory.id
      const subcategoryDocuments = allDocuments
        .filter(document => document.category_id === category.id
          && document.subcategory_id === subcategory.id
          && document.sub_subcategory_id === subsubcategory.id) || []
      return (
        <div className={classes.subcategory} key={subsubcategory.id}>
          <Typography variant="h1" className={`${classes.title} ${isSubOpen ? classes.openTitle : ""}`}>{subsubcategory.name}</Typography>
          <Divider />
          <div className={classes.content}>
            <Typography variant="subtitle1"> Documentos: {subcategoryDocuments.length}</Typography>
            <div className={classes.actions}>
              <Button color="primary" variant="contained" onClick={this.handleOpenCategory(subsubcategory.id, subsubcategory, true, true)}>
                {isSubOpen ? "Cerrar" : "Abrir"}
              </Button>
              <Button disabled={cantEdit} color="primary" variant="outlined" onClick={this.handleOpenCreate(subsubcategory, true, true)}>
                <Backup />
              </Button>
            </div>
          </div>
        </div>
      )
    })
  }

  handleSelectFilter(event) {
    const { target } = event
    this.setState({ filter: target.value })
  }

  render() {
    const { classes, categories } = this.props
    const {
      openCategory,
      category,
      openDialog,
      openWarning,
      document,
      editParams,
      params,
      openCreate,
      openNameWarning,
      isSubEdit,
      subcategory,
      selectedDocument,
      openSigns,
      filter,
      openMultiple,
      isSubSubEdit,
      subsubcategory
    } = this.state
    const allSubcategories = category?.subcategories || []
    const allSubSubcategories = subcategory?.sub_sub_categories || []
    const selectedSubcategories = categories.find(cat => cat.id.toString() === editParams?.category_id?.toString())?.subcategories.concat([{ name: "Sin Sub-Carpeta", id: 0 }]) || []
    const selectedSubSubcategories = allSubcategories.find(cat => cat.id.toString() === editParams?.subcategory_id?.toString())?.sub_sub_categories.concat([{ name: "Sin Sub-Carpeta", id: 0 }]) || []
    return (
      <div>
        <SignDocument document={selectedDocument} open={selectedDocument !== null} onClose={this.handleOpenSign(null)} />
        <div className={classes.container}>
          {this.renderCategories()}
        </div>
        <Paper id="openDocument" className={`${classes.mainCategory} ${openCategory !== -1 ? classes.openMain : classes.closedMain}`} square>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>
            {`${category.name} ${subcategory ? `> ${subcategory.name}` : ""} ${subsubcategory ? `> ${subsubcategory.name}` : ""}`}
          </Typography>
          <div className={classes.filesHeader}>
            <div>
              <Button color="primary" variant="contained" onClick={this.handleToggleMultiple}>Subir varios documentos</Button>
            </div>
            <div className={classes.filters}>
              <Typography variant="subtitle1">Ordenar por:</Typography>
              <div>
                <SelectInput
                  onChange={this.handleSelectFilter}
                  value={filter}
                  options={[
                    { value: 1, label: "Nombre" },
                    { value: 2, label: "Fecha" }
                  ]}
                  label=""
                />
              </div>
            </div>
          </div>
          <div className={classes.files}>
            {this.renderCategoryFiles()}
          </div>
        </Paper>
        <Dialog open={openSigns !== null} fullWidth maxWidth="sm" onClose={this.handleOpenSigns(null)}>
          <Typography variant="h2" className={classes.signTitle}>Firmas del documento</Typography>
          <div className={classes.docSigns}>
            {this.renderSigns()}
          </div>
        </Dialog>
        <Dialog open={openDialog} fullWidth maxWidth="sm" onClose={this.handleOpenDialog()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Editar Documento</Typography>
          <div className={classes.inputs}>
            <FileInput onChange={this.handleChangeEdit} label="Documento" name="file" value={editParams.file ? editParams.file.name : "Seleccionar Documento"} />
            <TextInput onChange={this.handleChangeEdit} label="Nombre (opcional)" name="name" value={editParams.name} />
            <Collapse in={openNameWarning}>
              <div className={classes.warningContainer}>
                <Typography variant="body1" className={classes.warning}>¡Ya existe un archivo con este nombre!</Typography>
                <Typography variant="caption" className={classes.warningLocation}>{`${openNameWarning?.category_name}  > ${openNameWarning?.name}`}</Typography>
              </div>
            </Collapse>
            <DateInput onChange={this.handleChangeEdit} label="Fecha" name="date" value={editParams.date} />
            <SelectInput onChange={this.handleChangeEdit} label="Carpeta" options={transformToOptions(categories)} name="category_id" value={editParams.category_id} />
            <Collapse in={selectedSubcategories.length > 0}>
              <SelectInput onChange={this.handleChangeEdit} label="Sub-Carpeta" options={transformToOptions(selectedSubcategories)} name="subcategory_id" value={editParams.subcategory_id} />
              <SelectInput onChange={this.handleChangeEdit} label="Sub-Sub-Carpeta" options={transformToOptions(selectedSubSubcategories)} name="sub_subcategory_id" value={editParams.sub_subcategory_id} />
            </Collapse>
            <SubmitButton onClick={this.handleSendEdit}>Guardar</SubmitButton>
          </div>
        </Dialog>
        <Dialog open={openWarning} fullWidth maxWidth="sm" onClose={this.handleOpenWarning()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Borrar Documento</Typography>
          <div className={classes.inputs}>
            <Typography variant="subtitle1">¿Estás seguro que deseas borrar este documento?</Typography>
            <div className={classes.file}>
              <Typography variant="h2" className={classes.doctTextTitle}>{document.name || "Sin nombre"}</Typography>
              <Typography variant="body2" className={classes.doctTextDownload} onClick={this.handleSeeDocument(document)}>Ver Documento</Typography>
              <Typography variant="body2" className={classes.doctText}>{document.category_name}</Typography>
            </div>
            <SubmitButton onClick={this.handleSendDelete}>Eliminar</SubmitButton>
          </div>
        </Dialog>
        <Dialog open={openCreate} fullWidth maxWidth="sm" onClose={this.handleOpenCreate()}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>Agregar Documento</Typography>
          <div className={classes.inputs}>
            <FileInput onChange={this.handleChange} label="Documento" name="file" value={params.file ? params.file.name : "Seleccionar Documento"} />
            <TextInput onChange={this.handleChange} label="Nombre (opcional)" name="name" value={params.name} />
            <Collapse in={openNameWarning}>
              <div className={classes.warningContainer}>
                <Typography variant="body1" className={classes.warning}>¡Ya existe un archivo con este nombre!</Typography>
                <Typography variant="caption" className={classes.warningLocation}>{`${openNameWarning?.category_name}  > ${openNameWarning?.name}`}</Typography>
              </div>
            </Collapse>
            <DateInput onChange={this.handleChange} label="Fecha" name="date" value={params.date} />
            <SelectInput onChange={this.handleChange} label="Carpeta" options={transformToOptions(isSubSubEdit ? allSubSubcategories : isSubEdit ? allSubcategories : categories)} name="category_id" value={params.category_id} disabled />
            <SubmitButton onClick={this.handleCreate}>Guardar</SubmitButton>
          </div>
        </Dialog>
        <SelectMultipleDocuments open={openMultiple} onClose={this.handleToggleMultiple} onCreate={this.handleCreateMultipleDocuments} />
      </div>
    )
  }
}

WorkerDocuments.propTypes = {

}


WorkerDocuments.defaultProps = {
  categories: []
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  editUserDocument: body => dispatch(editUserDocumentAction(body)),
  deleteUserDocument: body => dispatch(deleteUserDocumentAction(body)),
  createUserDocument: body => dispatch(createUserDocumentAction(body)),
  createMultipleDocuments: body => dispatch(createMultipleDocumentAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(WorkerDocuments)))