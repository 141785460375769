import React, { Component } from 'react'
import { Button, Collapse, Divider, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../Shared/Inputs/SelectInput'
import DateInput from '../../Shared/Inputs/DateInput'
import { connect } from 'react-redux'
import { transformToOptions } from '../../Utils/functions'
import autobind from '../../Utils/autobind'
import moment from 'moment'
import { createChecklistAction } from '../../Actions/CheckListActions'
import { withRouter } from 'react-router-dom'
import { callSnackbar } from '../../Utils/snackbar'

const style = theme => ({
  title: {
    background: theme.palette.blue.main,
    padding: 12,
    marginBottom: 12,
    textAlign: 'center',
    '&>h1': {
      color: 'white'
    }
  },
  buttonContainer: {
    textAlign: 'center',
    paddingBottom: 12
  },
  button: {
    padding: 12,
    textAlign: 'center',
  },
  container: {
    border: '1px solid #1a1a1a55'
  },
  scheduler: {
    padding: "12px 12px 0 12px",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '&>*': {
      maxWidth: '45%',
      minWidth: 120
    }
  }
})
class AddChecklistCard extends Component {
  constructor() {
    super()
    this.state = {
      schedule: {},
      link: null,
    }

    autobind(AddChecklistCard, this)
  }

  componentDidMount() {
    const { user, preventiveMeasure, activity } = this.props
    this.setState({
      schedule: {
        user_id: user.account.user.id,
        inmediant_user_id: user.account.user.id,
        date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
        preventive_measure_id: preventiveMeasure.id,
        activity_id: activity.id,
      }
    })
  }

  handleChange(event) {
    const { target } = event
    const { schedule } = this.state
    schedule[target.name] = target.value
    this.setState({ schedule })
  }

  renderMenu() {
    const { workers, branch } = this.props
    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
    const options = transformToOptions(filteredWorkers)
    return options
  }

  handleSchedule() {
    const { createChecklist } = this.props
    const body = { ...this.state.schedule }
    createChecklist(body)
  }

  handleCreate() {
    const { createChecklist, preventiveMeasure, activity } = this.props
    const { schedule } = this.state
    const body = {
      user_id: schedule.inmediant_user_id,
      date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
      preventive_measure_id: preventiveMeasure.id,
      activity_id: activity.id,
    }
    createChecklist(body).then(response => {
      const checklist_id = response.payload.data.info.id
      const { history } = this.props
      history.push(`/checklists/${checklist_id}`)
    })
  }

  handleCreateLink() {
    const { user, preventiveMeasure, activity } = this.props
    const { origin } = window.location

    const keyValues = {
      a: activity.id,
      c: user.account.user.idCompany,
      pm: preventiveMeasure.id
    }

    const keys = JSON.stringify(keyValues)
    const encoded = btoa(keys)
    const newLink = `${origin}/public/${encoded}`

    this.setState({ link: newLink })
    this.copyToClipboard(newLink)
  }

  copyToClipboard(value) {
    const element = document.getElementById('hiddenInput')
    console.log(element)
    element.value = value
    element.select()
    element.setSelectionRange(0, 99999)
    document.execCommand('copy')
    callSnackbar("Enlace copiado", "success")
  }

  render() {
    const { classes, preventiveMeasure } = this.props
    const { schedule, link } = this.state
    const pm = preventiveMeasure
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h1">{pm.name}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button color="secondary" variant="contained" size="large" onClick={this.handleCreateLink}>
            Generar Link Público
          </Button>
          <Collapse in={link !== null}>
            <div>
              <Typography variant="subtitle1">Enlace para realizar chequeo:</Typography>
              <Typography variant="caption" style={{ padding: 12 }}>{link}</Typography>
            </div>
          </Collapse>
        </div>
        <Divider />
        <div className={classes.scheduler}>
          <SelectInput
            label="Responsable"
            options={this.renderMenu()}
            name="inmediant_user_id"
            onChange={this.handleChange}
            value={schedule.inmediant_user_id}
          />
        </div>
        <div className={classes.button}>
          <Button color="primary" variant="contained" size="large" onClick={this.handleCreate}>
            Realizar Chequeo Inmediato
          </Button>
        </div>
        <Divider />
        <div className={classes.scheduler}>
          <SelectInput
            label="Responsable"
            options={this.renderMenu()}
            name="user_id"
            onChange={this.handleChange}
            value={schedule.user_id}
          />
          <DateInput label="Fecha" name="date_scheduled" onChange={this.handleChange} value={schedule.date_scheduled} />
        </div>
        <div className={classes.buttonContainer}>
          <Button color="primary" variant="outlined" onClick={this.handleSchedule}>
            Agendar Chequeo
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  createChecklist: body => dispatch(createChecklistAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AddChecklistCard)))