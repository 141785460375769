import { Typography } from '@material-ui/core'
import React from 'react'
import { callSnackbar } from './snackbar'
import Resizer from 'react-image-file-resizer'
import { PDFDocument } from 'pdf-lib'

export const compareValuesByKey = (orderBy, orderOn) => {
    const order = orderOn === "desc" ? 1 : -1
    return function sort(a, b) {
        let result = 0
        if (a[orderBy] < b[orderBy]) {
            result = -1
        }
        if (a[orderBy] > b[orderBy]) {
            result = 1
        }
        return result * order
    }
}

export const transformToOptions = (arrayOfObjects) => {
    if (Array.isArray(arrayOfObjects)) {
        const result = arrayOfObjects.map((element) => {
            return { value: element.id.toString(), label: element.name }
        })
        return result
    }
    return []
}

export const getCellValue = (data, cell) => {
    let value = data[cell.name]
    const type = cell.type ? cell.type : false
    if (type && type === "select") {
        const elem_id = data[cell.name]
        const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
        value = option ? option.label : "No asignado..."
    }
    if (type && type === "multiselect") {
        const elem_ids = data[cell.name] || []
        value = elem_ids.map((elem_id) => {
            const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
            return option ? `${option.label} ` : "No asignado..."
        })
        value = value.length > 0 ? value : "No asignado..."
    }
    return value
}

export const getTableCellValue = (element, header) => {
    const keys = header.label.split("&")
    if (header.label === "all") {
        return element
    }
    if (keys.length === 1) {
        return element[header.label]
    } else {
        return element[keys[0]] ? element[keys[0]][keys[1]] : ""
    }
}

export const superDispatch = (dispatch, func, type) => {
    dispatch({ type: `REQUEST_${type}` })
    return dispatch(func)
        .then((response) => {
            dispatch({ type: `SUCCESS_${type}`, payload: response })
        })
        .catch(() => {
            dispatch({ type: `ERROR_${type}` })
        })
}

export const transformToSuperDispatch = (mapDispatchToProps) => {
    return (dispatch) => {
        const toReturn = mapDispatchToProps(dispatch)
        const newProps = {}
        Object.entries(toReturn).forEach(([name, func]) => {
            newProps[name] = transformToMakerDispatch(dispatch, func, name)
        })
        return newProps
    }
}

export const transformToMakerDispatch = (dispatch, dispatchedFunc, type) => {
    return (...args) => {
        dispatch({ type: `REQUEST_${type}` })
        return dispatchedFunc(...args)
            .then(() => {
                dispatch({ type: `SUCCESS_${type}` })
            })
            .catch(() => {
                dispatch({ type: `ERROR_${type}` })
            })
    }
}

export const formatDownloadableDocument = (basePath) => {
    return (fileName) => {
        const url = `${basePath}/${fileName}`
        function downloadThisFile() {
            fetch(url).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = `${fileName}`
                    a.click()
                })
            }).catch(() => callSnackbar("Error al descargar documento", "error"))
        }

        return (<Typography variant="subtitle1" onClick={downloadThisFile} style={{ color: "#5867dd", cursor: "pointer" }}>Descargar Documento</Typography>)
    }
}

export const resizeImageAndGetFile = (file, callback) => {

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
            uri => {
                resolve(uri)
            },
            'base64'
        )
    })

    resizeFile(file).then(image => {
        fetch(image).then(res => res.blob())
            .then(blob => {
                const newFile = new File([blob], file.name, { type: "image/png" })
                callback(image, newFile)
            })
    })
}

export const validateForm = (info, params) => {
    const bools = info.map(input => {
        if (input.required) {
            const value = params[input.name]
            if (value === undefined) {
                return false
            }
        }
        return true
    })
    const isValid = bools.filter(b => !b).length === 0
    if (!isValid) callSnackbar(`Falta rellenar información`, 'warning')
    return isValid
}

export const mergeMultiplePdfFiles = async (files = [], fileName = "merged.pdf") => {
    const mergedPDF = await PDFDocument.create()

    const promises = files.map(async (file) => {
        const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
        const bytes = new Uint8Array(pdfBytes)

        let document1 = await PDFDocument.load(bytes)
        const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

        copiedPages.forEach(page => mergedPDF.addPage(page))
    })

    await Promise.all(promises)

    const resultInBytes = await mergedPDF.save()

    var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
}

export const mergeMultiplePdfFilesAndGet = async (files = [], fileName = "merged.pdf") => {
    const mergedPDF = await PDFDocument.create()

    const promises = files.map(async (file) => {
        const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
        const bytes = new Uint8Array(pdfBytes)

        let document1 = await PDFDocument.load(bytes)
        const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

        copiedPages.forEach(page => mergedPDF.addPage(page))
    })

    await Promise.all(promises)

    return mergedPDF
}


export const signDocument = async (documentToSign, sign, imageUrl, fileName) => {
    const pngImageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer())
    const pngImage = await documentToSign.embedPng(pngImageBytes)
    const pages = documentToSign.getPages()
    pages.forEach((page, index) => {

        const { height, width } = page.getSize()
        console.log(height, width)

        page.drawImage(pngImage, {
            x: 40,
            y: 30,
            height: 60,
            width: 120
        })

        page.drawText(sign.name, {
            x: 30,
            y: 30,
            size: 10,
        })
        page.drawText(sign.date, {
            x: 30,
            y: 15,
            size: 10,
        })

        page.drawText(`Página ${index + 1} de ${pages.length}`, {
            x: (width / 2) - 20,
            y: 15,
            size: 8,
        })

    })
    const resultInBytes = await documentToSign.save()

    var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
}