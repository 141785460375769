import React, { Component } from 'react'
import { Switch, withStyles } from '@material-ui/core'
import { editSettingChecklistAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import autobind from '../../../Utils/autobind'
import LoadingDialog from '../../../Shared/LoadingDialog'

const style = () => ({

})
class QuickAccessCell extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
    autobind(QuickAccessCell, this)
  }

  handleChangeAccess() {
    const { editSettingsChecklist, element, value } = this.props
    const body = { ...element }
    body.quick_access = value === 0 ? 1 : 0
    this.setState({ loading: true })
    editSettingsChecklist(body).then(() => this.setState({ loading: false }))
  }

  render() {
    const { classes, value } = this.props
    const { loading } = this.state
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <Switch checked={value} onClick={this.handleChangeAccess} />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  editSettingsChecklist: (body) => dispatch(editSettingChecklistAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(QuickAccessCell))