import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"

export const getUserDocumentsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'users/get_documents.php',
    params: body
  })
  return {
    type: "GET_USER_DOCUMENTS",
    payload: REQUEST
  }
}

export const editUserDocumentAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: "users/edit_document.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_USER_DOCUMENT",
    payload: REQUEST
  }
}

export const createUserDocumentAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'users/create_document.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_USER_DOCUMENT",
    payload: REQUEST
  }
}

export const createMultipleDocumentAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'users/create_multiple_document.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_MULTIPLE_USER_DOCUMENTS",
    payload: REQUEST
  }
}

export const deleteUserDocumentAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'users/delete_document.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "DELETE_USER_DOCUMENT",
    payload: REQUEST
  }
}

export const addUserSignAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'users/user_sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "ADD_USER_SIGN",
    payload: REQUEST
  }
}

export const signDocumentAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'users/user_document_sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "SIGN_USER_DOCUMENT",
    payload: REQUEST
  }
}