import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({
  container: {
    padding: '6px 12px',
    borderRadius: 24,
    textAlign: 'center'
  }
})
class Chip extends Component {

  getColor() {
    const { value } = this.props
    return value === "Vencido" ? "#ff5b5b" : "#5578eb"
  }

  render() {
    const { classes, value } = this.props
    return (
      <div className={classes.container} style={{ background: this.getColor() }}>
        <Typography variant="body1" style={{ color: "white" }}>{value}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(Chip)