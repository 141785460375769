import stateMaker from "../Utils/stateMaker"

const PermissionsReducer = (
  state = { all: [] },
  action
) => {
  switch (action.type) {
    case "GET_PERMISSIONS":
      return stateMaker(state, "all", "fetch", action)
    case "CREATE_PERMISSION":
      return stateMaker(state, "all", "create", action)
    case "EDIT_PERMISSION": {
      return stateMaker(state, "all", "edit", action)
    }
    default:
      return state
  }
}

export default PermissionsReducer
