import axios from "axios"
import store from "../store"

const instance = axios.create({
    baseURL: `https://app.safeasy.cl/api`,
    timeout: 30000,
})

instance.interceptors.request.use((config) => {
    const newConfig = { ...config }
    const accessToken = store.getState().user?.account?.jwt
    const userId = store.getState().user?.account.user?.id
    const companyId = store.getState().user?.account?.user?.idCompany
    const branch_id = store.getState().branch?.global?.id
    newConfig.headers["Token"] = accessToken
    newConfig.headers["User-Id"] = userId
    newConfig.headers["Company-Id"] = companyId
    newConfig.headers["Branch-Id"] = branch_id
    return config
})

// instance.interceptors.response.use(response => response,
//   () => store.getState().controller.snackbar('Error de conexión', { variant: 'error' }))

export default instance
