import stateMaker from "../Utils/stateMaker";

const UserReducer = (state = { account: {} }, action) => {
    switch (action.type) {
        case "LOGIN":
            return stateMaker(state, "account", "fetch", action);
        case "LOGOUT":
            return { account: {} };
        case "TEST_TOKEN":
            return state;
        default:
            return state;
    }
};

export default UserReducer;
