import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import { getSettingChecklistsAction } from '../../../Actions/SettingsActions'
import { transformToOptions } from '../../../Utils/functions'
import { getActivityAction } from '../../../Actions/ActivityActions'
import { getDoneChecklistsAction } from '../../../Actions/CheckListActions'
import GeneralTable from '../../../Shared/GeneralTable'
import SelectChart from './SelectChart'
import BarElements from './BarElements'

const style = theme => ({
  container: {
    padding: 12,
  },
  title: {
    padding: 12,
    background: theme.palette.primary.main,
    color: 'white'
  },
  selectors: {
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24
  },
  form: {
    padding: 24
  },
  chart: {
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
    '& > h1': {
      marginBottom: 12
    }
  },
  link: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  }
})

class ActivityReport extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      extraFields: [],
      checklistElements: {}
    }
    autobind(ActivityReport, this)
  }

  componentDidMount() {
    const { getActivities, getDoneChecklists, getSettingsChecklists } = this.props
    getActivities()
    getDoneChecklists()
    getSettingsChecklists()
  }

  handleSeeImage(file) {
    return () => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file}`
      window.open(url)
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const { settings, checklists } = this.props

    params[target.name] = target.value
    if (target.name === "activity") {
      params.preventive_measure = undefined
    }
    this.setState({ params }, () => {
      const settingChecklists = settings.checklists.all || []
      const selectedMeasure = settingChecklists.find(measure => measure.id === params.preventive_measure)
      const selectedExtraFields = selectedMeasure?.extra_fields || []

      const extraFieldsAnswers = selectedExtraFields.map(field => ({ label: field.label, type: field.type, answers: [], options: field.options }))

      const allChecklists = checklists.done
        .filter(
          checklist => checklist.activity_id === params.activity
            && checklist.preventive_measure_id === params.preventive_measure
        )

      allChecklists.forEach(checklist => {
        checklist.extra_fields.forEach(field => {
          if (field.value !== "") {
            const answerField = extraFieldsAnswers.find(extra => extra.label === field.label)
            answerField.answers.push(field.value)
          }
        })
      })

      const allItems = allChecklists.map(checklist => checklist.checklist_items)

      let noItems = {}
      const crits = ["empty", "no", "low", "med", "high"]
      allItems.forEach(items => {
        items.forEach(item => {
          if (item.answer.value_id === 2) {
            if (!noItems[item.name]) {
              noItems[item.name] = {
                empty: 0,
                no: 0,
                low: 0,
                med: 0,
                high: 0
              }
            }
            noItems[item.name][crits[item.answer.criticality]] += 1
          }
        })
      })

      this.setState({ extraFields: extraFieldsAnswers, checklistElements: noItems })

    })
  }

  renderCharts() {
    const { extraFields, checklistElements } = this.state
    const { classes } = this.props
    const allCharts = []

    if (Object.keys(checklistElements).length > 0) {
      const labels = Object.keys(checklistElements)
      const data = checklistElements
      allCharts.push(<div className={classes.chart}>
        <Typography variant="h1">Registros</Typography>
        <BarElements data={data} labels={labels} idName="bar-elements" />
      </div>)
    }

    if (extraFields.length > 0) {
      extraFields.forEach((field, index) => {
        if (field.answers.length > 0) {
          allCharts.push(this.createChart(field, index))
        }
      })
    }
    return allCharts.length > 0 ? allCharts : null
  }

  createChart(field, index) {
    const { classes } = this.props
    if (field.type === "select") {
      const data = field.options.map(option => {
        return field.answers.filter(value => value === option).length
      })
      return <div className={classes.chart}>
        <Typography variant="h1">{field.label}</Typography>
        <SelectChart data={data} idName={`select-chart-${index}`} labels={field.options} />
      </div>
    } else if (field.type === "text") {
      return (
        <div className={classes.chart}>
          <Typography variant="h1">{field.label}</Typography>
          {field.answers.map((answer, index) => {
            const isLast = index === field.answers.length - 1
            return (<Typography variant="body1">{answer}{!isLast && ", "}</Typography>)
          })}
        </div>
      )
    } else if (field.type === "img") {
      return (
        <div className={classes.chart}>
          <Typography variant="h1">{field.label}</Typography>
          {field.answers.map((answer, index) => {
            const isLast = index === field.answers.length - 1
            return (<Typography variant="body1" className={classes.link} onClick={this.handleSeeImage(answer)}>{"Ver Imagen"}{!isLast && ", "}</Typography>)
          })}
        </div>
      )
    }
  }

  createBarChart(elements) {

  }

  render() {
    const { classes, activities, checklists, settings } = this.props
    const { params } = this.state
    const allActivities = activities?.all || []
    const allMeasures = params.activity ? allActivities.find(activity => activity.id === params.activity).preventive_measures[0] : []
    const allChecklists = checklists.done
      .filter(
        checklist => checklist.activity_id === params.activity
          && checklist.preventive_measure_id === params.preventive_measure
      )
    const allItems = allChecklists.map(checklist => checklist.checklist_items)
    const allAnswers = allChecklists.map(checklist => checklist.checklist_items
      .concat(checklist.extra_fields.concat([{ label: "observation", value: checklist.observations }])
        .concat([{ label: "user", value: checklist.user }])
        .concat([{ label: "date_done", value: checklist.date_done }])
      ))
    let noItems = {}
    allItems.forEach(items => {
      items.forEach(item => {
        if (item.answer.value_id === 2) {
          noItems[item.name] ? noItems[item.name].push(item.answer.criticality) : noItems[item.name] = [item.answer.criticality]
        }
      })
    })

    //Extra fields setup
    const settingChecklists = settings.checklists.all || []
    const selectedMeasure = settingChecklists.find(measure => measure.id === params.preventive_measure)
    const selectedExtraFields = selectedMeasure?.extra_fields || []
    const selectedChecklistElements = selectedMeasure?.checklist_items || []
    const extraFieldsAnswers = selectedExtraFields.map(field => ({ label: field.label, type: field.type, answers: [] }))

    allChecklists.forEach(checklist => {
      checklist.extra_fields.forEach(field => {
        if (field.value !== "") {
          const answerField = extraFieldsAnswers.find(extra => extra.label === field.label)
          answerField.answers.push(field.value)
        }
      })
    })

    // Table Setup
    const tableInfo = selectedChecklistElements.concat(selectedExtraFields).map(element => ({
      name: element.name || element.label, label: element.name || element.label
    }))
    tableInfo.push({ name: "Observaciones", label: "observation" })
    tableInfo.push({ name: "Responsable", label: "user" })
    tableInfo.push({ name: "Fecha", label: "date_done" })

    const tableData = allAnswers.map(answer => {
      const keys = answer.map(element => element.name || element.label)
      const values = answer.map(element => {
        if (element.name) {
          return element.answer.value
        } else if (element.label) {
          return element.value
        }
      })
      const result = {}
      keys.forEach((key, index) => { result[key] = values[index] })
      return result
    })

    console.log(allChecklists)

    return (
      <div className={classes.container}>
        <Typography variant="h1">Reporte de actividades</Typography>
        <div className={classes.selectorContainer}>
          <Paper className={classes.selectors}>
            <Typography variant="h1" className={classes.title}>Seleccionar Actividad y Medida preventiva</Typography>
            <div className={classes.form}>
              <SelectInput
                label="Actividad"
                value={params.activity}
                onChange={this.handleChange}
                options={transformToOptions(allActivities.map(activity => { return { ...activity, name: `${activity.name} (${activity.start_date} - ${activity.end_date})` } }))}
                name="activity"
              />
              <SelectInput
                disabled={!params.activity}
                label="Medida preventiva"
                value={params.preventive_measure}
                onChange={this.handleChange}
                options={transformToOptions(allMeasures)}
                name="preventive_measure" />
            </div>
          </Paper>
          {this.renderCharts()}
          <GeneralTable info={tableInfo} data={tableData} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activities: state.activities,
  checklists: state.checklists,
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getActivities: body => dispatch(getActivityAction(body)),
  getDoneChecklists: body => dispatch(getDoneChecklistsAction(body)),
  getSettingsChecklists: () => dispatch(getSettingChecklistsAction())
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ActivityReport))