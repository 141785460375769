import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import TextPhase from './TextPhase'
import SelectPhase from './SelectPhase'
import CheckPhase from './CheckPhase'

const style = () => ({

})
class PhaseRenderer extends Component {
  renderPhase() {
    const { phase } = this.props
    switch (phase.type) {
      case "text": {
        return <TextPhase phase={phase} />
      }
      case "select": {
        return <SelectPhase phase={phase} />
      }
      case "check": {
        return <CheckPhase phase={phase} />
      }
      case "multiselect": {
        return <SelectPhase phase={phase} />
      }
      case "date": {
        return <TextPhase phase={phase} />
      }
      case "number": {
        return <TextPhase phase={phase} />
      }
      case "file": {
        return <TextPhase phase={phase} />
      }
      default: {
        return "Aun no desarrollado"
      }
    }
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderPhase()}
      </div>
    )
  }
}

export default withStyles(style)(PhaseRenderer)