import React, { Component } from 'react'
import { Button, Divider, IconButton, Menu, MenuItem, Paper, Typography, withStyles } from '@material-ui/core'
import { AccountCircle, CalendarToday, Check, Delete, Edit, ErrorOutline, EventAvailable, MoreVert } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { criticities, criticities_index } from './Findings'
import ImageContainer from '../../Shared/ImageContainer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { cardStyle } from './CardStyles'
import ClickTooltip from '../../Shared/ClickTooltip'

function getImage(path, url) {
  if (path.includes("data:image")) {
    return path
  } else {
    return `${url}${path}`
  }
}

class PendingCard extends Component {
  constructor() {
    super()
    this.state = {
      openMenu: false,
      anchorEl: ""
    }

    autobind(PendingCard, this)
  }

  handleOpenMenu(event) {
    this.setState({ openMenu: !this.state.openMenu, anchorEl: event.target })
  }

  handleApprove() {
    const { approve, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 1,
        online: online.status,
        date_approved: moment(new Date()).format("YYYY-MM-DD")
      }
      approve(body)
    })
  }

  handleDismiss() {
    const { dismiss, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 4,
        online: online.status
      }
      dismiss(body)
    })
    this.setState({ openMenu: false })
  }

  handleGoto() {
    const { history, finding, goto } = this.props
    history.push(`/${goto}/${finding.id}`)
  }

  renderTitle() {
    const { title = { main: [], text: [] }, classes } = this.props
    const { main, text } = title
    return (
      <div>
        {main.map((m, index) => (
          <div className={classes.mainText} key={index + m}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{m}</Typography>}>
              <Typography variant="h4">{m}</Typography>
            </ClickTooltip>
          </div>
        ))}
        {text.map((t, index) => (
          <div className={classes.titleText} key={index + t}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{t}</Typography>}>
              <Typography variant="caption" className={classes.titleText}>{t}</Typography>
            </ClickTooltip>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { openMenu, anchorEl } = this.state
    const { classes, finding, urls, user } = this.props
    const level = user.account.user.userType
    return (
      <Paper className={classes.container}>
        <div className={classes.title}>
          {this.renderTitle()}
        </div>
        <div className={classes.subtitle}>
          <div>
            <IconButton size="small" onClick={this.handleOpenMenu}>
              <MoreVert />
            </IconButton>
          </div>
          <div className={classes.titleStateFinished}>
            <Typography>{finding.status}</Typography>
          </div>
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={classes.resume}>
          <div className={classes.statsContainer}>
            <div className={classes.stat}>
              <CalendarToday />
              <Typography variant="body1">Fecha de detección: {finding.date_detected}</Typography>
            </div>
            <div className={classes.stat}>
              <EventAvailable />
              <Typography variant="body1">Fecha limite: {finding.date_limit}</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Detectado: {finding.user_creator}</Typography>
            </div>
            <div className={classes.stat}>
              <AccountCircle />
              <Typography variant="body1">Responsable: {finding.user}</Typography>
            </div>
            <div className={classes.stat}>
              <ErrorOutline style={{ color: criticities[criticities_index[finding.criticality]] }} />
              <Typography variant="caption">
                {criticities_index[finding.criticality]}
              </Typography>
            </div>
          </div>
          <ImageContainer src={finding.evidence ? `${urls[1]}${finding.evidence}` : "/noimage.png"} alt="foto" height="64px" width="64px" />
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <div className={classes.action}>
          <Typography variant="subtitle1">Acción Inmediata:</Typography>
          <Typography variant="body2">{finding.inmediate_action || "No especifica..."}</Typography>
        </div>
        <div className={classes.resolveContainer}>
          <div>
            <Typography variant="subtitle1">Comentarios:</Typography>
            <Typography variant="body2">{finding.solution_comment}</Typography>
          </div>
          <ImageContainer src={finding.solution_evidence ? getImage(finding.solution_evidence, urls[0]) : "/noimage.png"} alt="foto" height="64px" width="64px" />
        </div>
        {finding.status !== "Aprobado" &&
          <>
            <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
            <div className={classes.finalContainer}>
              <div className={classes.finalButton}>
                {level === 1 ?
                  <Button
                    variant="contained" classes={{
                      label: classes.label
                    }}
                    className={classes.greenButton}
                    onClick={this.handleApprove}
                  >
                    Aprobar
                  <Check />
                  </Button>
                  :
                  <Typography variant="caption">Esperando aprobación de administrador...</Typography>
                }
              </div>
            </div>
          </>
        }
        <Menu open={openMenu} anchorEl={anchorEl} onClose={this.handleOpenMenu}>
          <MenuItem className={classes.menuItem} onClick={this.handleGoto}>
            <Typography variant="subtitle1">Ver</Typography>
            <Edit />
          </MenuItem>
          {level === 1 &&
            <MenuItem className={classes.menuItem} onClick={this.handleDismiss}>
              <Typography variant="subtitle1">Desestimar</Typography>
              <Delete />
            </MenuItem>
          }
        </Menu>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  online: state.online,
  user: state.user
})

export default withRouter(connect(mapStateToProps)(withStyles(cardStyle)(PendingCard)))