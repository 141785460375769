import { IconButton, Typography, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { Component } from "react";

const style = () => ({
    container: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    },
    rotated: {
        transition: "all 0.3s linear",
        transform: "rotate(45deg)",
    },
    normal: {
        transition: "all 0.3s linear",
        transform: "rotate(0deg)",
    },
});

class AddElementButton extends Component {
    render() {
        const { classes, open, name, onClick } = this.props;
        return (
            <div className={classes.container}>
                <Typography variant="subtitle1">{name}</Typography>
                <IconButton
                    color="primary"
                    className={open ? classes.rotated : classes.normal}
                    onClick={onClick}
                >
                    <Add />
                </IconButton>
            </div>
        );
    }
}

AddElementButton.propTypes = {};

AddElementButton.defaultProps = {
    name: "Agregar",
    open: false,
    onClick: undefined,
};

export default withStyles(style)(AddElementButton);
