import React, { Component } from "react"
import { withStyles, Typography, Divider, Tab, Paper } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { connect } from "react-redux"
import { createSubBranchAction, getBranchAction, getSubBranchesAction } from "../../Actions/BranchActions"
import GeneralTable from "../../Shared/GeneralTable"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import { editBranchAction } from "../../Actions/EnterpriseAction"
import { branchInfo, subBranchInfo } from "../Enterprise/Info"
import { ExitToApp } from "@material-ui/icons"
import AddElementButton from "../../Shared/AddElementButton"
import FormContext from "../../Shared/Cards/Provider"
import GeneralForm from "../../Shared/GeneralForm"

const style = () => ({
	container: { margin: 12 },
	title: {
		margin: "26px 0 12px 0 ",
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
})

const info = [{ name: "Nombre", label: "name" }]

class Branch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {},
			tab: 0,
			loading: true,
			open: false,
			create_params: {}
		}
		autobind(Branch, this)
	}

	componentDidMount() {
		const { match, getBranch, user, getSubBranches } = this.props
		const { id } = match.params
		const companyId = user.account.user.idCompany
		const body = { id, companyId: companyId }
		getBranch(body).then(() => {
			this.setState({ loading: false })
		})
		getSubBranches({ branch_id: id })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleOpen() {
		this.setState({ open: !this.state.open })
	}

	handleSend(params) {
		const { editBranch, enqueueSnackbar, branch, enterprise } = this.props
		const body = { ...params }
		body.id = branch.selected.id
		body.companyId = enterprise.selected.id
		editBranch(body, enqueueSnackbar)
	}

	handleGoto(option) {
		return (row) => {
			return () => {
				const { history } = this.props
				history.push(`${option}/${row.id}`)
			}
		}
	}

	handleChange(event) {
		const { target } = event
		const { create_params } = this.state
		create_params[target.name] = target.value
		this.setState({ create_params })
	}

	handleCreate() {
		const { match, createSubBranch } = this.props
		const { create_params } = this.state
		const body = {
			...create_params,
			branch_id: match.params.id
		}
		createSubBranch(body)
		this.setState({ create_params: {}, open: false })
	}

	render() {
		const { classes, branch, match } = this.props
		const { loading, tab, open, create_params } = this.state
		const selected = branch.selected
		const users = selected ? selected.users : []
		const sub_branches = branch.sub_branches || []
		const actions = (url) => [
			{
				name: "Ver Colaborador",
				icon: ExitToApp,
				action: this.handleGoto(url),
			},
		]
		return (
			<div className={classes.container}>
				<MutableInfoCard
					title="Datos centro de trabajo"
					submit="Guardar Cambios"
					subtitle="Editar centro de trabajo"
					submitAction={this.handleSend}
					formInfo={branchInfo}
					showInfo={branchInfo}
					response={selected}
					loading={loading}

				/>
				<div />
				<Paper square className={classes.title}>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h1">
									Colaboradores
                                </Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Contratistas
                                </Typography>
							}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<Typography variant="h1" className={classes.title}>
						Tabla de Colaboradores
                    </Typography>
					<Divider style={{ marginBottom: 24 }} />
					<GeneralTable
						data={users}
						info={info}
						actions={actions('/workers')}
					/>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<div className={classes.inline}>
						<Typography variant="h1">
							Tabla de Contratistas
                        </Typography>
						<AddElementButton name="Agregar contratista" onClick={this.handleOpen} open={open}></AddElementButton>
					</div>
					<FormContext.Provider value={{ handleChange: this.handleChange, params: create_params }}>
						<GeneralForm
							open={open}
							info={subBranchInfo}
							onSubmit={this.handleCreate}
							submit="Crear Contratista"
							title="Nueva contratista"
						/>
					</FormContext.Provider>
					<Divider style={{ marginBottom: 24 }} />
					<GeneralTable
						data={sub_branches}
						info={info}
						actions={actions(`/branches/${match.params.id}/sub_branches`)}
					/>
				</TabPanel>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise,
	user: state.user,
	branch: state.branch,
})

const mapDispatchToProps = (dispatch) => ({
	getBranch: (body) => dispatch(getBranchAction(body)),
	editBranch: (body, snackbar) => dispatch(editBranchAction(body, snackbar)),
	getSubBranches: body => dispatch(getSubBranchesAction(body)),
	createSubBranch: body => dispatch(createSubBranchAction(body))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Branch))
