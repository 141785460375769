import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { getFindingsAction } from '../../../Actions/FindingActions'
import { connect } from 'react-redux'
import OpenVsClose from './OpenVsClose'
import CriticalityVsStatus from './CriticalityVsStatus'
import CriticalityDistribution from './CriticalityDistribution'
import DateInput from '../../../Shared/Inputs/DateInput'
import moment from 'moment'
import autobind from '../../../Utils/autobind'
import { getNonConformitiesAction } from '../../../Actions/NoConfActions'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    padding: 12,
    background: theme.palette.primary.main,
    maxWidth: 600,
    marginBottom: 24
  },
  title: {
    color: "white"
  },
  paper: {
    maxWidth: 700,
    position: 'relative',
    marginTop: 24
  },
  chartTitle: {
    padding: 12,
    color: "white",
    background: theme.palette.primary.main
  },
  chartContainer: {
    padding: 24,
  },
  chartsContainer: {
    flexBasis: '50%',
    maxWidth: 700,
    margin: 12
  },
  dateSelectors: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 700,
    '& > *': {
      maxWidth: 300
    },
    margin: 'auto'
  },
  inlineCharts: {

    flexWrap: 'wrap'
  },
  inline: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

class Reports extends Component {
  constructor() {
    super()
    this.state = {
      startDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      endDate: moment(new Date()).endOf("month").format("YYYY-MM-DD")
    }
    autobind(Reports, this)
  }

  componentDidMount() {
    const { getFindings, getUnconformities } = this.props
    getFindings()
    getUnconformities()
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { classes, findings, unconformities, enterprise } = this.props
    const { startDate, endDate } = this.state
    const allFindings = findings.all || []
    const allUnconformities = unconformities.all || []

    const selectedId = enterprise?.selected?.id

    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>Reportes</Typography>
        </div>
        <div className={classes.dateSelectors}>
          <DateInput value={startDate} name="startDate" onChange={this.handleChange} label="Fecha desde" />
          <DateInput value={endDate} name="endDate" onChange={this.handleChange} label="Fecha hasta" />
        </div>
        <div className={classes.inline}>
          <div className={classes.chartsContainer}>
            <div className={classes.inlineCharts}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" className={classes.chartTitle}>Cantidad de Hallazgos por Estado y Criticidad</Typography>
                <div className={classes.chartContainer}>
                  <CriticalityVsStatus idName={"cristvsstatus-findings"} findings={allFindings} startDate={startDate} endDate={endDate} />
                </div>
              </Paper>
            </div>
            <div className={classes.inlineCharts}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" className={classes.chartTitle}>Distribución de Criticidad de Hallazgos</Typography>
                <div className={classes.chartContainer}>
                  <CriticalityDistribution idName={"critsdist-findings"} findings={allFindings} startDate={startDate} endDate={endDate} />
                </div>
              </Paper>
            </div>
            <div className={classes.inlineCharts}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" className={classes.chartTitle}>Hallazgos Levantados y Cerrados en la última semana</Typography>
                <div className={classes.chartContainer}>
                  <OpenVsClose idName={"openvsclose-findings"} findings={allFindings} />
                </div>
              </Paper>
            </div>
          </div>
          <div className={classes.chartsContainer}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.chartTitle}>Cantidad de {selectedId === 14 ? "IPAs" : `No Conformidades`} por Estado y Criticidad</Typography>
              <div className={classes.chartContainer}>
                <CriticalityVsStatus idName={"cristvsstatus-unconf"} findings={allUnconformities} startDate={startDate} endDate={endDate} />
              </div>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.chartTitle}>Distribución de Criticidad de {selectedId === 14 ? "IPAs" : `No Conformidades`}</Typography>
              <div className={classes.chartContainer}>
                <CriticalityDistribution idName={"critsdist-unconf"} findings={allUnconformities} startDate={startDate} endDate={endDate} />
              </div>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.chartTitle}>{selectedId === 14 ? "IPAs" : `No Conformidades`} Levantadas y Cerradas en la última semana</Typography>
              <div className={classes.chartContainer}>
                <OpenVsClose idName={"openvsclose-unconf"} findings={allUnconformities} />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  findings: state.findings,
  unconformities: state.unconformities,
  enterprise: state.enterprise
})

const mapDispatchToProps = dispatch => ({
  getFindings: () => dispatch(getFindingsAction()),
  getUnconformities: () => dispatch(getNonConformitiesAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Reports))