import React, { Component } from "react"
import { IconButton, Paper, Typography, withStyles } from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { getEnterpriseAction, editEnterpriseAction, changeEnterpriseLogoAction, } from "../../Actions/EnterpriseAction"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { enterpriseDataTypes, enterpriseFormInfo } from "./Info"
import ImageContainer from "../../Shared/ImageContainer"
import { Edit } from "@material-ui/icons"

const style = theme => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 24,
    },
    container: {
        margin: 12,
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 24,
    },
    label: {
        marginBottom: 6,
    },
    logoContainer: {
        maxWidth: 700,
        margin: 'auto',
        marginTop: 24,
        marginBottom: 24,
        position: 'relative'
    },
    logoTitle: {
        padding: 12,
        background: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center'
    },
    imageContainer: {
        textAlign: 'center',
        padding: 12
    },
    logoEditButton: {
        position: 'absolute',
        bottom: 6,
        right: 6
    },
    hidden: {
        display: 'none'
    }
})

function simulateClick() {
    document.getElementById("logoinput").click()
}

class EnterpriseInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            able: false,
            params: {},
            loading: true
        }
        autobind(EnterpriseInfo, this)
    }

    componentDidMount() {
        const { getEnterprise, user } = this.props
        const companyId = user.account.user.idCompany
        const body = { id: companyId }
        getEnterprise(body).then(() => this.endLoading())
    }

    endLoading() {
        this.setState({ loading: false })
    }

    handleSend(params) {
        const { editEnterprise, enqueueSnackbar, user } = this.props
        const body = { ...params }
        body.id = user.account.user.idCompany
        editEnterprise(body, enqueueSnackbar)
    }

    handleSelectLogo(event) {
        const { target } = event
        const { changeEnterpriseLogo } = this.props
        const file = target.files[0]
        const body = new FormData()
        body.append("file", file)
        changeEnterpriseLogo(body)
    }

    render() {
        const { classes, enterprise, user } = this.props
        const { loading } = this.state
        const selected = enterprise.selected
        const logoUrl = `${process.env.REACT_APP_IMG_URL}/${process.env.REACT_APP_ENTERPRISE_LOGO}`
        const level = user.account.user.userType
        return (
            <div className={classes.container}>
                <MutableInfoCard
                    title="Datos de la Empresa"
                    submit="Guardar Cambios"
                    submitAction={this.handleSend}
                    subtitle="Editar Empresa"
                    formInfo={enterpriseFormInfo}
                    showInfo={enterpriseDataTypes}
                    response={enterprise.selected}
                    loading={loading}
                    disableEdit={level > 1}
                />
                <Paper className={classes.logoContainer}>
                    <Typography variant="h2" className={classes.logoTitle}>Logo de la Empresa</Typography>
                    <div className={classes.imageContainer}>
                        <ImageContainer src={selected.logo ? `${logoUrl}${selected.logo}` : "/noimage.png"} width="250px" border="0" />
                    </div>
                    <IconButton className={classes.logoEditButton} onClick={simulateClick} disabled={level > 1}>
                        <Edit />
                    </IconButton>
                </Paper>
                <input className={classes.hidden} type="file" onChange={this.handleSelectLogo} id="logoinput" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    enterprise: state.enterprise,
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    getEnterprise: (id) => dispatch(getEnterpriseAction(id)),
    editEnterprise: (body, snackbar) =>
        dispatch(editEnterpriseAction(body, snackbar)),
    changeEnterpriseLogo: body => dispatch(changeEnterpriseLogoAction(body))
})

export default withSnackbar(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(style)(EnterpriseInfo))
)
