import { combineReducers } from "redux"
import UserReducer from "./UserReducer"
import EnterpriseReducer from "./EnterpriseReducer"
import BranchReducer from "./BranchReducer"
import WorkerReducer from "./WorkerReducer"
import MainReducer from "./MainReducer"
import ActivityReducer from "./ActivityReducer"
import ChecklistReducer from "./ChecklistReducer"
import OnlineReducer from "./OnlineReducer"
import FindingReducer from "./FindingReducer"
import RequestReducer from "./RequestReducer"
import SettingsReducer from "./SettingsReducer"
import ParamsReducer from "./ParamsReducer"
import TalksReducer from "./TalksReducer"
import TrainingReducer from "./TrainingReducer"
import NonConformityReducer from "./NonConformitiesReducer"
import PermissionsReducer from './PermissionsReducer'
import ListReducer from "./ListsReducer"
import WorkflowReducer from "./WorkflowReducer"

const reducers = combineReducers({
    user: UserReducer,
    enterprise: EnterpriseReducer,
    branch: BranchReducer,
    workers: WorkerReducer,
    controller: MainReducer,
    activities: ActivityReducer,
    checklists: ChecklistReducer,
    online: OnlineReducer,
    findings: FindingReducer,
    requests: RequestReducer,
    settings: SettingsReducer,
    params: ParamsReducer,
    talks: TalksReducer,
    trainings: TrainingReducer,
    unconformities: NonConformityReducer,
    permissions: PermissionsReducer,
    lists: ListReducer,
    workflows: WorkflowReducer
})

export default reducers
