import React, { Component } from "react"
import { withStyles, Paper, Tab, Typography, Divider } from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import PendingCard from "./PendingCard"
import AsignedCard from "./AsignedCard"
import FinishedCard from "./FinishedCard"
import { approveFindingAction, assignFindingAction, dismissFindingAction, getFindingsAction, solveFindingAction } from "../../Actions/FindingActions"
import { connect } from "react-redux"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import GroupDialog from "./GroupDialog"

const style = theme => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0 ",
	},
	divider: {
		marginBottom: 24,
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 24,
	},
	cardContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		gap: '12px',
		'&>*': {
			maxWidth: 350,
			"@media (max-width:500px)": {
				width: '100%',
				maxWidth: 'unset'
			}
		}
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: "36px 0 12px 0 "
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px'
	},
	filterContainer: {
		display: 'flex',
		padding: 12,
		'&>*': {
			maxWidth: 350,
			flexBasis: 350
		}
	},
	titleText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	mainText: {
		height: 50
	},
	tooltip: {
		color: "white"
	},
	badge: {
		background: theme.palette.blue.main,
		textAlign: 'center',
		width: 24,
		height: 24,
		borderRadius: '50%',
		position: 'absolute',
		top: -6,
		right: -6,
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			color: 'white',
			fontWeight: 600
		},
		cursor: 'pointer'
	}
})

export const criticities = {
	"Altamente Critico": "#ff6c87",
	"Medianamente Critico": "#ffab6e",
	"Poco Critico": "darkgoldenrod",
	"No Critico": "#f2f5ff",
	"No Asignado": "black"
}

export const criticities_index = {
	0: "No Asignado",
	1: 'No Critico',
	2: "Poco Critico",
	3: "Medianamente Critico",
	4: "Altamente Critico"
}

const criticalityFormat = (level) => {
	switch (level) {
		case 0: { return "No Asignado" }
		case 1: { return 'No Critico' }
		case 2: { return "Poco Critico" }
		case 3: { return "Medianamente Critico" }
		case 4: { return "Altamente Critico" }
		default: { return "No Asignado" }
	}
}

const criticalityDeFormat = (level) => {
	switch (level) {
		case "No Asignado": { return 0 }
		case 'No Critico': { return 1 }
		case "Poco Critico": { return 2 }
		case "Medianamente Critico": { return 3 }
		case "Altamente Critico": { return 4 }
		default: { return 0 }
	}
}

const tableInfo = [
	{ name: "Medida Preventiva", label: "preventive_measure" },
	{ name: "Descripción", label: "description" },
	{ name: "Estado", label: "status" },
	{ name: "Responsable", label: "user" },
	{ name: "Fecha Limite", label: "date_limit" },
	{ name: "Fecha Limite", label: "date_approved" },
	{ name: "Fecha Limite", label: "date_assigned" },
	{ name: "Fecha Limite", label: "date_solved" },
	{ name: "Fecha Limite", label: "checklist_item_name" },
	{ name: "Fecha Limite", label: "inmediate_action" },
	{ name: "Fecha Limite", label: "solution_comment" },
	{ name: "Fecha Limite", label: "user_creator" },
	{ name: "Fecha Limite", label: "activity" },
]

const filters = [
	{ name: "Nombre", label: "user" },
	{ name: "Estado", label: "status" },
	{ name: "Criticidad", label: "criticality", format: criticalityFormat, unformat: criticalityDeFormat },
]



const sortables = [
	{ name: "Fecha Limite", label: "date_limit" },
	{ name: "Fecha Detectado", label: "date_detected" },
	{ name: "Nombre", label: "checklist_item_name" },
	{ name: "Usuario", label: "user" }
]

class Findings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 0,
			loading: true,
			selectedGroup: null,
			pendingGroups: [],
			approvedGroups: []
		}
		autobind(Findings, this)
	}

	componentDidMount() {
		const { getFindings, getWorkers } = this.props
		getFindings().then(() => {
			this.setState({ loading: false })
		})
		getWorkers()
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	renderCallbackFinding(element, index) {
		const { assignFinding, solveFinding, approveFinding, dismissFinding, classes } = this.props
		const finding = element.finding
		const title = {
			main: [finding.checklist_item_name],
			text: [finding.preventive_measure, finding.activity]
		}
		const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
		const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_FINDING_IMG_FOLDER}`
		function getFindingRender(finding) {
			switch (finding.status) {
				case "Pendiente": return <PendingCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} assign={assignFinding} dismiss={dismissFinding} />
				case "Procesando": return <AsignedCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} solve={solveFinding} dismiss={dismissFinding} />
				case "Vencido": return <AsignedCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} solve={solveFinding} dismiss={dismissFinding} />
				case "Terminado": return <FinishedCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} approve={approveFinding} dismiss={dismissFinding} />
				case "Aprobado": return <FinishedCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} approve={approveFinding} dismiss={dismissFinding} />
				default: return <PendingCard group={element.group} urls={[firstImgURL, secondImgURL]} goto="findings" title={title} key={finding.id} finding={finding} assign={assignFinding} dismiss={dismissFinding} />
			}
		}
		return (
			<>{element.length > 1 ?
				<div style={{ position: 'relative' }} key={finding.id}>
					{/* <Badge style={{ cursor: 'pointer' }} badgeContent={element.length} color="primary" onClick={() => this.setState({ selectedGroup: element.group })}>
					</Badge> */}
					<div className={classes.badge} onClick={() => this.setState({ selectedGroup: element.group })}>
						<Typography variant="subtitle1">{element.length}</Typography>
					</div>
					{getFindingRender(finding)}
				</div>
				:
				getFindingRender(finding)
			}
			</>
		)
	}

	groupFindings() {
		const { findings } = this.props
		const allFindings = findings.all || []
		const groups = {}
		allFindings
			.filter(finding => finding.status !== "Desestimado")
			.filter(finding => finding.status !== "Aprobado")
			.forEach(finding => {
				const key = {
					activity_id: finding.activity_id,
					preventive_measure_id: finding.preventive_measure_id,
					checklist_item_name: finding.checklist_item_name,
					date: "",
					status: finding.status === 'Terminado' ? "Ready" : "NotReady",
					id: finding.id
				}
				const stringKey = JSON.stringify(key)
				const group = groups[stringKey]
				if (group !== undefined) {
					if (group[0].date_detected === finding.date_detected) {
						key.date = finding.id
						const newStringKey = JSON.stringify(key)
						groups[newStringKey] = [finding]
					} else {
						group.push(finding)
						groups[stringKey] = group
					}
				} else {
					groups[stringKey] = [finding]
				}
			})
		const reducedGroups = Object.values(groups).map(group => {
			const reducedGroup = {}
			reducedGroup.finding = group[0]
			reducedGroup.length = group.length
			reducedGroup.group = group
			return reducedGroup
		})
		return reducedGroups
	}

	groupApprovedFindings() {
		const { findings } = this.props
		const allFindings = findings.all || []
		const groups = {}
		allFindings
			.filter(finding => finding.status !== "Desestimado")
			.filter(finding => finding.status === "Aprobado")
			.forEach(finding => {
				const key = {
					activity_id: finding.activity_id,
					preventive_measure_id: finding.preventive_measure_id,
					checklist_item_name: finding.checklist_item_name,
					date_approved: finding.date_approved,
					id: finding.id
				}
				const stringKey = JSON.stringify(key)
				const group = groups[stringKey]
				if (group !== undefined) {
					group.push(finding)
					groups[stringKey] = group
				} else {
					groups[stringKey] = [finding]
				}
			})
		const reducedGroups = Object.values(groups).map(group => {
			const reducedGroup = {}
			reducedGroup.finding = group[0]
			reducedGroup.length = group.length
			reducedGroup.group = group
			return reducedGroup
		})
		return reducedGroups
	}

	render() {
		const { classes } = this.props
		const { tab, loading, selectedGroup } = this.state
		const pendingGroups = this.groupFindings()
		const approvedGroups = this.groupApprovedFindings()
		return (
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography variant="h1">Hallazgos</Typography>
				</div>
				<Divider className={classes.divider} />
				<Paper square>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h4">
									Hallazgos en proceso
                                </Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h4">
									Hallazgos aprobados
                                </Typography>
							}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<div className={classes.inline}>
						<Typography variant="h1">
							Hallazgos en proceso
                        </Typography>
					</div>
					<Divider className={classes.divider} />
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							data={pendingGroups} onlyCards callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							sortings={sortables}
							filters={filters}
						/>
					}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						Hallazgos aprobados
                    </Typography>
					<Divider className={classes.divider} />
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							data={approvedGroups} onlyCards callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							sortings={sortables}
							filters={filters}
						/>
					}
				</TabPanel>
				<GroupDialog open={selectedGroup !== null} group={selectedGroup} onClose={() => this.setState({ selectedGroup: null })} />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	findings: state.findings,
})

const mapDispatchToProps = dispatch => ({
	getFindings: () => dispatch(getFindingsAction()),
	getWorkers: () => dispatch(getWorkersAction()),
	assignFinding: body => dispatch(assignFindingAction(body)),
	dismissFinding: body => dispatch(dismissFindingAction(body)),
	approveFinding: (body) => dispatch(approveFindingAction(body)),
	solveFinding: (body, formData) => dispatch(solveFindingAction(body, formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Findings))
