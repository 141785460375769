import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import { CancelOutlined, CheckCircleOutline, WifiOff } from '@material-ui/icons'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'

const style = (theme) => ({
  request: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "6px 12px",
    position: 'relative'
  },
  container: {
    width: 400,
    maxWidth: "100%",
  },
  success: {
    color: theme.palette.green.main,
    width: 32,
    height: 32,
  },
  error: {
    color: theme.palette.red.main,
    width: 32,
    height: 32,
  }
})

class WifiCard extends Component {
  renderRequests() {
    const { requests, classes } = this.props
    return requests.map(request => {
      let secondary = null
      switch (request.status) {
        case "pending": {
          secondary = <MiniLoaderAnimator />
          break
        }
        case "success": {
          secondary = <CheckCircleOutline className={classes.success} />
          break
        }
        case "error": {
          secondary = <CancelOutlined className={classes.error} />
          break
        }
        default: {
          secondary = <WifiOff />
          break
        }
      }
      return (
        <>
          <div className={classes.request}>
            <Typography variant="subtitle1">{request.name}</Typography>
            {secondary}
          </div>
          <Divider />
        </>
      )
    })
  }

  render() {
    const { classes, requests } = this.props
    return (
      <div className={classes.container}>
        {requests.length > 0 ?
          this.renderRequests()
          :
          (
            <div className={classes.request}>
              <Typography variant="subtitle1">Todos los cambios han sido guardados...</Typography>
            </div>
          )
        }
      </div>
    )
  }
}

export default withStyles(style)(WifiCard)