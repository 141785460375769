import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import PublicChecklist from "../Components/Checklists/PublicChecklist/PublicChecklist"
import Login from "../Components/Login/Login"

export default class PublicRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/public/:token" exact component={PublicChecklist} />
        <Route path="*" exact component={Login} />
      </Switch>
    )
  }
}
