import {
	Button,
	Collapse,
	Dialog,
	Divider,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	withStyles,
} from "@material-ui/core"
import { AddAPhoto, AddComment, BookmarkBorderOutlined, Check, Clear, ErrorOutline } from "@material-ui/icons"
import React, { Component } from "react"
import TextInput from "../../Shared/Inputs/TextInput"
import MiniLoaderAnimator from "../../Shared/MiniLoaderAnimator"
import SubmitButton from "../../Shared/SubmitButton"
import autobind from "../../Utils/autobind"

const style = theme => ({
	success: {
		color: "#91deb1",
		margin: "0 0 0 6px",
		padding: "7px 15px",
	},
	error: {
		color: "#ff6c87",
		margin: "0 0 0 6px",
		padding: "7px 15px",
	},
	neutral: {
		color: "blue",
		margin: "0 0 0 6px",
		padding: "7px 15px",
	},
	successSelected: {
		background: "#0abb87",
		color: "white",
		margin: "0 0 0 6px",
		padding: "7px 15px",
		"&:hover": {
			background: "#0abb87",
		},
	},
	errorSelected: {
		background: "#ff5b5b",
		color: "white",
		margin: "0 0 0 6px",
		padding: "7px 15px",
		"&:hover": {
			background: "#ff5b5b",
		},
	},
	neutralSelected: {
		background: "#5d78ff",
		color: "white",
		margin: "0 0 0 6px",
		padding: "7px 15px",
		"&:hover": {
			background: "#5d78ff",
		},
	},
	listItem: {
		flexWrap: "wrap",
		position: "relative",
	},
	paper: {
		margin: "6px 0",
	},
	listMain: {
		flexGrow: 1,
		display: "flex",
		flexBasis: '100%',
		marginBottom: 12,
	},
	menu: {
		display: "flex",
		alignItems: "center",
		gap: "12px",
		marginLeft: 6,
		marginBottom: 6,
		justifyContent: "flex-end",
		"@media (max-width:500px)": {
			flexGrow: 1,
			marginLeft: 0,
			marginBottom: 0,
		},
	},
	select: {
		width: 200,
	},
	icons: {
		display: "flex",
		alignItems: "center",
		"@media (max-width:500px)": {
			flexDirection: "column",
			gap: "6px",
			justifyContent: "center",
		},
	},
	text: {
		textAlign: "justify",
		marginRight: 12,
		lineHeight: 1.75,
	},
	crits: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		direction: "rtl",
		transition: "all 0.3s linear",
	},
	critButton: {
		width: 12,
		height: 12,
		borderRadius: "50%",
	},
	critOptionButton: {
		position: "absolute",
		padding: 7,
		marginRight: 1,
	},
	closed: {
		width: 33.5,
	},
	open: {
		width: "100%",
	},
	options: {
		display: "flex",
		overflow: "hidden",
		transition: "all 0.3s linear",
		justifyContent: "space-between",
		border: "1px solid grey",
		borderRadius: "24px",
		gap: "12px",
	},
	critIndicator: {
		height: 3,
		width: "100%",
		opacity: 0.6,
		transition: "all 0.3s linear",
	},
	imgContainer: {
		borderRadius: "50%",
		cursor: "pointer"
	},
	fileGroup: {
		display: 'flex',
		gap: '12px',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: 12,
		'& > *': {
			marginLeft: 18
		}
	},
	comment: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '6px 12px'
	},
	dialogTitle: {
		color: 'white',
		background: theme.palette.primary.main,
		padding: 12
	},
	dialog: {
		padding: 12
	},
	menuContainer: {
		width: '100%',
		textAlign: 'end'
	},
	fileButton: {
		position: 'relative',
		height: 64,
		width: 64,
		border: '1px dashed lightgrey',
		borderRadius: "50%",
	},
	fileEdit: {
		position: 'absolute',
		bottom: -6,
		right: -16,

	}
})

function getOption(element) {
	const answer = element.answer
	const options = ["success", "error", "neutral"]
	if (answer.value) {
		return options[answer.value_id - 1]
	}
	return 0
}

function getCriticity(element) {
	const answer = element.answer
	const criticality = answer.criticality
	if (criticality) {
		const crits = ["#f2f5ff", "#f6ec79", "#ffab6e", "#ff6c87"]
		return crits[criticality - 1]
	}
	return "lightgrey"

}

class CheckListElement extends Component {
	constructor(props) {
		super(props)
		this.state = {
			openCrits: true,
			criticity: "lightgrey",
			fileSelected: "",
			openDialog: false,
			loadingFile: false,
			openComment: false,
			openCounter: false,
			comments: "",
			counter: 0
		}
		autobind(CheckListElement, this)
	}

	componentDidMount() {
		const { element } = this.props
		const hasCriticality = element.answer.criticality !== 0
		if (hasCriticality) {
			this.setState({ openCrits: false })
		}
	}

	handleOpenCrits(e) {
		e.stopPropagation()
		this.setState({ openCrits: !this.state.openCrits })
	}

	handleOpenComment() {
		const { element } = this.props
		this.setState({ openComment: !this.state.openComment, comments: element.answer.comments })
	}

	handleOpenCounter() {
		const { element } = this.props
		this.setState({ openCounter: !this.state.openCounter, counter: element.answer.counter })
	}

	handleSelectCrit(criticity) {
		return () => {
			this.setState({ openCrits: !this.state.openCrits })
			const { handleCrit, element } = this.props
			handleCrit(element, criticity)()
		}
	}

	handleClickOption(value) {
		return () => {
			const { handleClick, element } = this.props
			if (value === 2) {
				this.setState({ openCrits: true })
			}
			handleClick(element, value)()
		}
	}

	handleSelectFile() {
		const { element } = this.props
		const file = element.answer.file
		let path = ""
		if (file && file.includes("data:image")) {
			path = file
		} else {
			path = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file}`
		}
		this.setState({ fileSelected: path, openDialog: true })
	}

	handleClickFileSelector(e, element, index) {
		const { handleSelectFile } = this.props
		this.setState({ loadingFile: true })
		handleSelectFile(e, element, index)
		this.setState({ loadingFile: false })
	}

	handleToggleDialog() {
		this.setState({ openDialog: !this.state.openDialog })
	}

	handleComment(event) {
		const { target } = event
		this.setState({ comments: target.value })
	}

	handleChangeCounter(event) {
		const { target } = event
		this.setState({ counter: target.value })
	}

	handleSendComment() {
		const { comments } = this.state
		const { handleComment, element } = this.props
		handleComment(element, comments)
		this.handleOpenComment()
	}

	handleSendCounter() {
		const { counter } = this.state
		const { handleCounter, element } = this.props
		handleCounter(element, counter)
		this.handleOpenCounter()
	}

	renderCrits() {
		const { classes, element, disabled } = this.props
		const { openCrits } = this.state
		const crits = ["#ff6c87", "#ffab6e", "#f6ec79", "#f2f5ff"]
		const values = [4, 3, 2, 1]

		const hasCriticality = element.answer.criticality !== 0
		const criticality = element.answer.criticality
		const hasDefaultCriticality = element.default_criticality !== 0
		const defaultCriticality = element.default_criticality
		if ((!hasCriticality || (hasCriticality && criticality !== defaultCriticality)) && hasDefaultCriticality) {
			const defCrit = element.default_criticality
			this.handleSelectCrit(defCrit)()
		}

		const options = crits.map((crit, index) => {
			return (
				<IconButton
					key={index}
					onClick={this.handleSelectCrit(values[index])}
					disabled={!openCrits || disabled || hasDefaultCriticality}
					style={{ padding: 7 }}
				>
					<ErrorOutline
						style={{
							color: crit,
							opacity: openCrits && !disabled && !hasDefaultCriticality ? 1 : 0,
							transition: "all 0.3s",
						}}
					/>
				</IconButton>
			)
		})
		return (
			<>
				<IconButton
					onClick={this.handleOpenCrits}
					className={classes.critOptionButton}
					disabled={disabled || hasDefaultCriticality}
				>
					<ErrorOutline
						style={{
							color: getCriticity(element),
							opacity: openCrits && !disabled && !hasDefaultCriticality ? 0 : 1,
							transition: "all 0.3s",
						}}
					/>
				</IconButton>
				<div
					className={`${openCrits && !disabled && !hasDefaultCriticality ? classes.open : classes.closed} ${classes.options
						}`}
				>
					{options}
				</div>
			</>
		)
	}
	render() {
		const { classes, element, simulateClick, disabled } = this.props
		const { openDialog, fileSelected, loadingFile, openComment, comments, counter, openCounter } = this.state
		const option = getOption(element)
		const criticity = getCriticity(element)
		const file = element.answer.file
		const file2 = element.answer.file2
		const file3 = element.answer.file3
		const answer = element.answer.value_id
		const answer_comments = element.answer.comments
		const answer_counter = element?.answer?.counter
		let filePath = ""
		let filePath2 = ""
		let filePath3 = ""

		if (file?.includes("data:image")) {
			filePath = file
		} else {
			filePath = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file}`
		}
		if (file2?.includes("data:image")) {
			filePath2 = file2
		} else {
			filePath2 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file2}`
		}
		if (file3?.includes("data:image")) {
			filePath3 = file3
		} else {
			filePath3 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file3}`
		}

		const isAnswered = answer !== null

		return (
			<Paper square key={element.name} className={classes.paper}>
				<div
					className={classes.critIndicator}
					style={{ background: answer === 2 ? criticity : "lightgrey" }}
				/>
				<ListItem className={classes.listItem}>
					<div className={classes.listMain}>
						<ListItemText
							primary={
								<Typography
									variant="subtitle2"
									className={classes.text}
								>
									{element.name}
								</Typography>
							}
						/>
						<ListItemIcon className={classes.icons}>
							<Button
								variant="outlined"
								onClick={this.handleClickOption(1)}
								disabled={disabled}
								className={
									option === "success"
										? classes.successSelected
										: classes.success
								}
							>
								<Check />
							</Button>
							<Button
								variant="outlined"
								onClick={this.handleClickOption(2)}
								disabled={disabled}
								className={
									option === "error"
										? classes.errorSelected
										: classes.error
								}
							>
								<Clear />
							</Button>
							<Button
								variant="outlined"
								onClick={this.handleClickOption(3)}
								disabled={disabled}
								className={
									option === "neutral"
										? classes.neutralSelected
										: classes.neutral
								}
							>
								N/A
                            </Button>
						</ListItemIcon>
					</div>
					<Collapse in={option === "error"} className={classes.menuContainer}>
						<div className={classes.menu}>
							<div className={`${classes.crits}`}>
								{this.renderCrits()}
							</div>
							<input
								type="file"
								id={`fileInput-${element.id}`}
								style={{
									visibility: "hidden",
									display: "none",
								}}
								onChange={e => this.handleClickFileSelector(e, element, "")}
							/>
							<input
								type="file"
								id={`fileInput2-${element.id}`}
								style={{
									visibility: "hidden",
									display: "none",
								}}
								onChange={e => this.handleClickFileSelector(e, element, "2")}
							/>
							<input
								type="file"
								id={`fileInput3-${element.id}`}
								style={{
									visibility: "hidden",
									display: "none",
								}}
								onChange={e => this.handleClickFileSelector(e, element, "3")}
							/>
						</div>
						<div className={classes.fileGroup}>
							<div className={classes.fileButton} >
								{file && !loadingFile &&
									<img
										className={classes.imgContainer}
										onClick={e => this.handleSelectFile(e, element)}
										src={filePath}
										alt="foto" height={64} width={64}
									/>
								}
								<IconButton onClick={simulateClick(element, "")} size="small" className={classes.fileEdit}>
									<AddAPhoto />
								</IconButton>
							</div>
							<div className={classes.fileButton} >
								{file2 && !loadingFile &&
									<img
										className={classes.imgContainer}
										onClick={e => this.handleSelectFile(e, element)}
										src={filePath2}
										alt="foto" height={64} width={64}
									/>
								}
								<IconButton onClick={simulateClick(element, "2")} size="small" className={classes.fileEdit}>
									<AddAPhoto />
								</IconButton>
							</div>
							<div className={classes.fileButton} >
								{file3 && !loadingFile &&
									<img
										className={classes.imgContainer}
										onClick={e => this.handleSelectFile(e, element)}
										src={filePath3}
										alt="foto" height={64} width={64}
									/>
								}
								<IconButton onClick={simulateClick(element, "3")} size="small" className={classes.fileEdit}>
									<AddAPhoto />
								</IconButton>
							</div>
							{/* <Button
								variant="outlined"
								onClick={simulateClick(element)}
								style={{ padding: "7px 12px" }}
								disabled={disabled}
							>
								<AddAPhoto />
							</Button>
							{file && !loadingFile &&
								<img
									className={classes.imgContainer}
									onClick={e => this.handleSelectFile(e, element)}
									src={filePath}
									alt="foto" height={32} width={32}
								/>
							} */}
							{loadingFile && <MiniLoaderAnimator />}
						</div>
					</Collapse>
				</ListItem>
				<Divider />
				<Collapse in={isAnswered}>
					<div className={classes.comment}>
						<Typography variant="body1">Comentario: {answer_comments || "Sin comentarios..."}</Typography>
						<IconButton color="primary" onClick={this.handleOpenComment} disabled={disabled}>
							<AddComment />
						</IconButton>
					</div>
					{element.counter === 1 && option === 'error' &&
						<>
							<Divider />
							<div className={classes.comment}>
								<Typography variant="body1">Cantidad: {answer_counter || "No ingresado..."}</Typography>
								<IconButton color="primary" onClick={this.handleOpenCounter} disabled={disabled}>
									<BookmarkBorderOutlined />
								</IconButton>
							</div>
						</>
					}
				</Collapse>
				<Dialog fullWidth maxWidth="sm" open={openDialog} onClose={this.handleToggleDialog} >
					<img src={fileSelected} alt="foto" width="100%" />
				</Dialog>
				<Dialog fullWidth maxWidth="sm" open={openComment} onClose={this.handleOpenComment}>
					<Typography variant="h2" className={classes.dialogTitle}>Agregar Comentario</Typography>
					<div className={classes.dialog}>
						<TextInput name="comments" value={comments} onChange={this.handleComment} label="Comentario" />
						<SubmitButton onClick={this.handleSendComment}>
							Guardar
						</SubmitButton>
					</div>
				</Dialog>
				<Dialog fullWidth maxWidth="sm" open={openCounter} onClose={this.handleOpenCounter}>
					<Typography variant="h2" className={classes.dialogTitle}>Indicar Cantidad</Typography>
					<div className={classes.dialog}>
						<TextInput type="number" name="counter" value={counter} onChange={this.handleChangeCounter} label="Cantidad" />
						<SubmitButton onClick={this.handleSendCounter}>
							Guardar
						</SubmitButton>
					</div>
				</Dialog>
			</Paper >
		)
	}
}

export default withStyles(style)(CheckListElement)
