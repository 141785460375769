import React, { Component } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

const style = () => ({
  button: {
    transition: 'all 0.2s linear'
  },
  open: {
    transform: 'rotate(180deg)'
  },
  closed: {
    transform: 'rotate(0deg)'
  }
})

class ArrowButton extends Component {

  render() {
    const { classes, open, onClick } = this.props
    return (
      <IconButton onClick={onClick}>
        <KeyboardArrowDown className={`${classes.button} ${open ? classes.open : classes.closed}`} />
      </IconButton>
    )
  }
}

export default withStyles(style)(ArrowButton)