import React, { Component } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from '@fullcalendar/interaction'
import { Dialog, IconButton, Paper, Tooltip, Typography, withStyles } from "@material-ui/core"
import { connect } from "react-redux"
import SelectInput from "../../Shared/Inputs/SelectInput"
import autobind from "../../Utils/autobind"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { transformToOptions } from "../../Utils/functions"
import { createChecklistAction, deleteChecklistAction, editChecklistAction, getDoneChecklistsAction, getPendingChecklistsAction } from "../../Actions/CheckListActions"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import Conditional from "../../Shared/Conditional"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import { callSnackbar } from "../../Utils/snackbar"
import { Close } from "@material-ui/icons"
import SubmitButton from "../../Shared/SubmitButton"
import { withRouter } from "react-router"
import { getActivityAction } from "../../Actions/ActivityActions"


const style = () => ({
	container: {
		padding: 12,
	},
	paper: {
		height: "80vh",
		padding: 12,
	},
	event: {
		padding: 6,
		"& > p": {
			color: "white",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	formContainer: {
		display: 'flex',
		flexWrap: "wrap",
	},
	inputContainer: {
		maxWidth: 700,
		minWidth: 300,
		marginRight: 14,
		"@media (max-width:500px)": {
			minWidth: "100%"
		},
		display: 'flex',
		alignItems: 'center'

	},
	circle: {
		paddingLeft: 24,
		position: 'relative',
		'&::before': {
			content: '""',
			height: 8,
			width: 8,
			borderRadius: '50%',
			background: 'white',
			position: 'absolute',
			left: 0,
			top: '50%',
			transform: 'translate(0, -50%)'
		}

	},
	textInput: {
		margin: "0 12px"
	},
	deleteButton: {
		color: 'white',
		zIndex: 1,
	},
	dialog: {
		padding: 24,
		'& > *': {
			margin: '6px 0'
		}
	}
})


function checkIfDateInRange(date) {
	const threeDaysAhead = moment(new Date()).add(3, "days")
	return moment(date) <= threeDaysAhead
}



class Calendar extends Component {
	constructor() {
		super()
		this.state = {
			user_id: "",
			months: 3,
			events: [],
			loading: true,
			viewType: "",
			openDelete: false,
			selectedEvent: null,
			newChecklist: null
		}
		this.selected = ""
		autobind(Calendar, this)
	}

	componentDidMount() {
		const { getWorkers, getPendings, getDone, online, getActivities } = this.props
		getActivities()
		if (online.status) {
			let isOneReady = false
			getWorkers()
			getPendings().then(() => {
				if (isOneReady) {
					this.setState({ loading: false, events: this.getEvents(this.filter()) })
				} else {
					isOneReady = true
				}
			})
			getDone().then(() => {
				if (isOneReady) {
					this.setState({ loading: false, events: this.getEvents(this.filter()) })
				} else {
					isOneReady = true
				}
			})
		} else {
			this.setState({ events: this.getEvents(this.filter()), loading: false })
		}
	}

	componentDidUpdate(prevProps) {
		const { checklists } = this.props
		if (prevProps.checklists.pending.length !== checklists.pending.length) {
			this.setState({ events: this.getEvents(this.filter()) })
		}

	}

	getEvents(pendings) {
		return pendings.map(event => ({
			title: event.preventive_measure,
			start: event.date_scheduled,
			backgroundColor: event.status === "Vencido" ? "#ff5b5b" : event.status === "Terminado" ? "#0abb87" : "#5867dd",
			borderColor: event.status === "Vencido" ? "#ff5b5b" : event.status === "Terminado" ? "#0abb87" : "#5867dd",
			editable: event.status === "Programado" ? true : false,
			eventStartEditable: event.status === "Programado" ? true : false,
			extendedProps: {
				user_id: event.user_id,
				user: event.user,
				id: event.id,
				activity: event.activity,
				date_scheduled: event.date_scheduled,
				title: event.preventive_measure,
				start_date: event.date_scheduled,
				status: event.status
			},
		}))
	}

	handleChange(event) {
		const { target } = event
		const value = target.value
		this.setState({ [target.name]: value },
			() => this.setState({ events: this.getEvents(this.filter()) })
		)
	}

	handleOpenDelete(info) {
		const selectedEvent = info.event.extendedProps
		this.setState({ openDelete: !this.state.openDelete, selectedEvent })
	}

	handleDelete() {
		const { selectedEvent } = this.state
		const { deleteChecklist } = this.props
		const body = { id: selectedEvent.id }
		deleteChecklist(body)
		this.setState({ openDelete: false, selectedEvent: null })
	}

	handleDrop(info) {
		const { editChecklist } = this.props
		const body = {
			...this.selected,
			date_scheduled: info.dateStr
		}
		editChecklist(body)
	}

	handleDropStop(info) {
		this.selected = {
			user_id: info.event.extendedProps.user_id,
			id: info.event.extendedProps.id,
		}
	}

	handleChangeCreate(event) {
		const { target } = event
		const { newChecklist } = this.state
		newChecklist[target.name] = target.value
		this.setState({ newChecklist })
	}

	handleCreate(info) {
		const { user } = this.props
		const newChecklist = {
			date_scheduled: info.dateStr,
			user_id: user.account.user.id
		}
		this.setState({ newChecklist })
	}

	handleCreateChecklist() {
		const { createChecklist } = this.props
		const { newChecklist } = this.state
		const body = {
			user_id: newChecklist.user_id,
			date_scheduled: newChecklist.date_scheduled,
			preventive_measure_id: newChecklist.preventive_measure,
			activity_id: newChecklist.activity,
		}
		createChecklist(body)
		this.setState({ newChecklist: null })
	}

	handleClick(info) {
		return () => {
			const { history } = this.props
			const checklistId = info.event.extendedProps.id
			const isAble = checkIfDateInRange(info.event.extendedProps.date_scheduled)
			if (isAble) {
				history.push(`/checklists/${checklistId}`)
			} else {
				callSnackbar("No se puede adelantar esa listas de chequeo", "error")
			}
		}
	}

	getOptions() {
		const { workers, checklists, branch } = this.props
		const { months } = this.state
		const allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
		const workersWithEvents = filteredWorkers.filter(worker => {
			const pendings = checklists.pending ? checklists.pending : []
			const done = checklists.done ? checklists.done : []
			const events = pendings.concat(done)
			const userEvents = events.filter(event => {
				const isSameId = event.user_id.toString() === worker.id.toString()
				const eventDate = event.date_scheduled
				const startDate = moment(new Date()).subtract(months, "months")
				const endDate = moment(new Date()).add(1, 'years')
				const isBetweenDates = moment(eventDate).isBetween(startDate, endDate)
				return isSameId && isBetweenDates
			})
			return userEvents.length > 0
		})
		const options = transformToOptions(workersWithEvents || [])
		options.push({ value: "", label: "Todos" })
		return options
	}

	filter() {
		const { user_id, months } = this.state
		const { checklists } = this.props
		const pendings = checklists.pending ? checklists.pending : []
		const done = checklists.done ? checklists.done : []
		const events = pendings.concat(done)
		let result = [...events]
		result = events.filter(event => {
			let isSameId = true
			if (user_id !== "") {
				isSameId = event.user_id.toString() === user_id.toString()
			}
			const eventDate = event.date_scheduled
			const startDate = moment(new Date()).subtract(months, "months").startOf("month")
			const isBetweenDates = moment(eventDate).isSameOrAfter(startDate)
			return isSameId && isBetweenDates
		}
		)
		return result
	}

	handleRenderEvent(info) {
		const { classes } = this.props
		const { viewType } = this.state
		return (
			<Tooltip title={
				<div>
					<Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{info.event.title}</Typography>
					<Typography variant="subtitle2" style={{ color: 'white' }}>Actividad: {info.event.extendedProps.activity}</Typography>
					<Typography variant="subtitle2" style={{ color: 'white' }}>Responsable: {info.event.extendedProps.user}</Typography>
				</div>
			} className={classes.tooltip}>
				<div className={classes.event} onClick={this.handleClick(info)}>
					<Typography
						className={classes.circle}
						variant="body1"
						style={{ color: viewType === 'listWeek' ? '#1a1a1a' : "white" }}
					>{info.event.title}
					</Typography>
					{info.event.extendedProps.status !== 'Terminado' &&
						<IconButton
							className={classes.deleteButton}
							size="small"
							style={{ color: viewType === 'listWeek' ? '#1a1a1a' : "white" }}
							onClick={e => {
								e.stopPropagation()
								this.handleOpenDelete(info)
							}}
						>
							<Close />
						</IconButton>
					}
				</div>
			</Tooltip>
		)

	}

	render() {
		const { classes, activities, workers, branch } = this.props
		const { user_id, events, loading, months, selectedEvent, newChecklist } = this.state
		const isMobile = window.innerWidth <= 500

		const allWorkers = workers.all || []
		const filteredWorkers = allWorkers.filter(worker => worker.branch_id === branch?.global?.id)
		const allActivities = activities?.all || []
		const allMeasures = newChecklist?.activity ? allActivities.find(activity => activity.id === newChecklist.activity).preventive_measures[0] : []

		return (
			<div className={classes.container}>
				<div className={classes.formContainer}>
					<div className={classes.inputContainer}>
						<SelectInput label="responsable" name="user_id" onChange={this.handleChange} options={this.getOptions()} value={user_id} />
					</div>
					<div className={classes.inputContainer}>
						<Typography variant="subtitle1">Hasta</Typography>
						<div className={classes.textInput}>
							<SelectInput label="" name="months" onChange={this.handleChange} value={months} options={[...Array(60).keys()].map(number => ({ value: number + 1, label: number + 1 }))} />
						</div>
						<Typography variant="subtitle1">meses atrás</Typography>
					</div>
				</div>
				<Paper className={classes.paper}>
					<Conditional condition={loading} hasElse>
						<LoaderAnimator />
						<FullCalendar
							plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
							initialView={isMobile ? "listWeek" : "dayGridMonth"}
							headerToolbar={{
								left: "prev,today,next",
								center: "title",
								right: "listWeek,dayGridMonth",
							}}
							buttonText={{
								prev: "Atrás",
								next: "siguiente",
								today: "Hoy",
								month: "Mes",
								week: "Semana"

							}}
							events={events}
							height={"80vh"}
							editable={true}
							eventDurationEditable={false}
							eventDragStop={this.handleDropStop}
							drop={this.handleDrop}
							locale="es"
							eventContent={this.handleRenderEvent.bind(this)}
							datesSet={(info) => this.setState({ viewType: info.view.type })}
							dateClick={this.handleCreate}
						/>
					</Conditional>
				</Paper>
				<Dialog fullWidth maxWidth="sm" open={selectedEvent !== null} onClose={() => this.setState({ openDelete: false, selectedEvent: null })}>
					<div className={classes.dialog}>
						<Typography variant="h1">¿Borrar {selectedEvent?.title}?</Typography>
						<Typography variant="caption">Evento agendado el: {selectedEvent?.start_date}</Typography>
						<SubmitButton onClick={this.handleDelete} >Eliminar</SubmitButton>
					</div>
				</Dialog>
				<Dialog fullWidth maxwidth="sm" open={newChecklist !== null} onClose={() => this.setState({ newChecklist: null })}>
					<div className={classes.dialog}>
						<Typography variant="h1">Crear lista de chequeo</Typography>
						<Typography variant="caption">Lista agendada para el: {' '}
							<span style={{ fontWeight: 600 }}>
								{moment(newChecklist?.date_scheduled).format("DD [de] MMMM [del] YYYY")}
							</span>
						</Typography>

						<SelectInput
							label="Actividad"
							value={newChecklist?.activity}
							onChange={this.handleChangeCreate}
							options={transformToOptions(allActivities.map(activity => { return { ...activity, name: `${activity.name} (${activity.start_date} - ${activity.end_date})` } }))}
							name="activity"
						/>
						<SelectInput
							disabled={!newChecklist?.activity}
							label="Medida preventiva"
							value={newChecklist?.preventive_measure}
							onChange={this.handleChangeCreate}
							options={transformToOptions(allMeasures)}
							name="preventive_measure" />
						<SelectInput value={newChecklist?.user_id} options={transformToOptions(filteredWorkers)} label="Responsable" name="user_id" onChange={this.handleChangeCreate} />
						<SubmitButton variant="contained" onClick={this.handleCreateChecklist} disabled={newChecklist && Object.keys(newChecklist)?.length < 3}>Crear</SubmitButton>
					</div>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	checklists: state.checklists,
	workers: state.workers,
	user: state.user,
	online: state.online,
	activities: state.activities,
	branch: state.branch
})

const mapDispatchToProps = dispatch => ({
	getWorkers: body => dispatch(getWorkersAction(body)),
	editChecklist: body => dispatch(editChecklistAction(body)),
	getPendings: body => dispatch(getPendingChecklistsAction(body)),
	getDone: body => dispatch(getDoneChecklistsAction(body)),
	deleteChecklist: body => dispatch(deleteChecklistAction(body)),
	getActivities: body => dispatch(getActivityAction(body)),
	createChecklist: body => dispatch(createChecklistAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Calendar)))
