import { Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"

const style = (theme) => ({
    container: {
        padding: 12,
        flexGrow: 1,
        flexBasis: 300,
        textAlign: "center",
        maxWidth: 300,
        gap: "12px",
        transition: "all 0.1s linear",
        "@media (max-width:500px)": {
            flexBasis: "100%",
            maxWidth: "unset",
        },
        "&:hover": {
            background: "#FeFeFe",
            transform: "scale(1.02)",
            cursor: "pointer",
        },
        position: 'relative',
        height: 156
    },
    amount: {
        height: 52,
        minWidth: 52,
        maxWidth: 52,
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: "50%",
        position: "absolute",
        "& > h5": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
})

class CountCard extends Component {
    render() {
        const { classes, title, amount, color, onClick } = this.props
        return (
            <Paper className={classes.container} onClick={onClick}>
                <Typography variant="h4">{title}</Typography>
                <div className={classes.amount} style={{ borderColor: color }}>
                    <Typography variant="h5" style={{ color: color }}>
                        {amount}
                    </Typography>
                </div>
            </Paper>
        )
    }
}

CountCard.propTypes = {}

CountCard.defaultProps = {
    title: "Cantidad de elementos",
    amount: 51,
    color: "",
}

export default withStyles(style)(CountCard)
