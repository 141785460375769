import React, { Component } from "react"
import { withStyles, Paper, Tab, Typography, Divider } from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import PendingCard from "../Findings/PendingCard"
import AsignedCard from "../Findings/AsignedCard"
import FinishedCard from "../Findings/FinishedCard"
import { connect } from "react-redux"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import { approveNonConformityAction, assignNonConformityAction, createNonConformityAction, dismissNonConformityAction, getNonConformitiesAction, solveNonConformityAction } from "../../Actions/NoConfActions"
import AddElementButton from "../../Shared/AddElementButton"
import GeneralForm from "../../Shared/GeneralForm"
import FormContext from "../../Shared/Cards/Provider"
import { transformToOptions } from "../../Utils/functions"
import moment from 'moment'

const style = () => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0 ",
	},
	divider: {
		marginBottom: 24,
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 24,
	},
	cardContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		gap: '12px',
		'&>*': {
			maxWidth: 350,
			"@media (max-width:500px)": {
				width: '100%',
				maxWidth: 'unset'
			}
		}
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: "36px 0 12px 0 "
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px'
	},
	filterContainer: {
		display: 'flex',
		padding: 12,
		'&>*': {
			maxWidth: 350,
			flexBasis: 350
		}
	}
})

export const criticities = {
	"Altamente Critico": "#ff6c87",
	"Medianamente Critico": "#ffab6e",
	"Poco Critico": "#f6ec79",
	"No Critico": "#f2f5ff",
	"No Asignado": "black"
}

export const criticities_index = {
	0: "No Asignado",
	1: 'No Critico',
	2: "Poco Critico",
	3: "Medianamente Critico",
	4: "Altamente Critico"
}

const criticalityFormat = (level) => {
	switch (level) {
		case 0: { return "No Asignado" }
		case 1: { return 'No Critico' }
		case 2: { return "Poco Critico" }
		case 3: { return "Medianamente Critico" }
		case 4: { return "Altamente Critico" }
		default: { return "No Asignado" }
	}
}

const criticalityDeFormat = (level) => {
	switch (level) {
		case "No Asignado": { return 0 }
		case 'No Critico': { return 1 }
		case "Poco Critico": { return 2 }
		case "Medianamente Critico": { return 3 }
		case "Altamente Critico": { return 4 }
		default: { return 0 }
	}
}

const tableInfo = [
	{ name: "Medida Preventiva", label: "preventive_measure" },
	{ name: "Descripción", label: "description" },
	{ name: "Estado", label: "status" },
	{ name: "Responsable", label: "user" },
	{ name: "Fecha Limite", label: "date_limit" },
	{ name: "Fecha Limite", label: "date_approved" },
	{ name: "Fecha Limite", label: "date_assigned" },
	{ name: "Fecha Limite", label: "date_solved" },
	{ name: "Fecha Limite", label: "checklist_item_name" },
	{ name: "Fecha Limite", label: "inmediate_action" },
	{ name: "Fecha Limite", label: "solution_comment" },
	{ name: "Fecha Limite", label: "user_creator" },
	{ name: "Fecha Limite", label: "name" },
]

const filters = [
	{ name: "Nombre", label: "user" },
	{ name: "Estado", label: "status" },
	{ name: "Criticidad", label: "criticality", format: criticalityFormat, unformat: criticalityDeFormat },
]



const sortables = [{ name: "Fecha Limite", label: "date_limit" }]

class NonConformities extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 0,
			loading: true,
			openForm: false,
			params: {
				date_detected: moment(new Date()).format("YYYY-MM-DD")
			}
		}
		autobind(NonConformities, this)
	}

	componentDidMount() {
		const { getNonConformities, getWorkers } = this.props
		getNonConformities().then(() => {
			this.setState({ loading: false })
		})
		getWorkers()
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleOpen() {
		this.setState({ openForm: !this.state.openForm })
	}

	handleCreate() {
		const { createNonConformity } = this.props
		const { params } = this.state
		const body = new FormData()
		body.append("name", params.name)
		body.append("description", params.description)
		body.append("criticality", params.criticality)
		body.append("date_detected", params.date_detected)
		body.append("evidence", params.evidence)
		createNonConformity(body)
	}

	renderCallbackFinding(unconformity, index) {
		const { assignNonConformity, solveNonConformity, approveNonConformity, dismissNonConformity } = this.props
		const title = {
			main: [unconformity.name],
			text: [unconformity.description]
		}
		const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_NON_CONFORMITIES}`
		const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_NON_CONFORMITIES}`
		switch (unconformity.status) {
			case "Pendiente": return <PendingCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} assign={assignNonConformity} dismiss={dismissNonConformity} />
			case "Procesando": return <AsignedCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} solve={solveNonConformity} dismiss={dismissNonConformity} />
			case "Vencido": return <AsignedCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} solve={solveNonConformity} dismiss={dismissNonConformity} />
			case "Terminado": return <FinishedCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} approve={approveNonConformity} dismiss={dismissNonConformity} />
			case "Aprobado": return <FinishedCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} approve={approveNonConformity} dismiss={dismissNonConformity} />
			default: return <PendingCard group={[unconformity]} urls={[firstImgURL, secondImgURL]} goto="unconformities" title={title} key={index} finding={unconformity} assign={assignNonConformity} dismiss={dismissNonConformity} />
		}
	}

	render() {
		const { classes, unconformities, enterprise } = this.props
		const { tab, loading, openForm, params } = this.state
		const allNonConformities = unconformities.all || []
		const activeNonConformities = allNonConformities.filter(unconformity => unconformity.status !== "Desestimado")
		const notApprovedNonConformities = activeNonConformities.filter(unconformity => unconformity.status !== "Aprobado")
		const approvedNonConformities = activeNonConformities.filter(unconformity => unconformity.status === "Aprobado")

		const formInfo = [
			{ name: "name", label: "Nombre" },
			{ name: "description", label: "Descripción" },
			{ name: "criticality", label: "Criticidad", type: "select", options: transformToOptions([{ id: 1, name: "No Crítico" }, { id: 2, name: "Poco Crítico" }, { id: 3, name: "Medianamente Crítico" }, { id: 4, name: "Altamente Crítico" }]) },
			// { name: "date_detected", label: "Fecha", type: "date" },
			{ name: "evidence", label: "Evidencia", type: "img" }
		]

		const selectedId = enterprise?.selected?.id

		return (
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography variant="h1">{selectedId === 14 ? "IPAs" : `No Conformidades`}</Typography>
					<AddElementButton name={`Agregar ${selectedId === 14 ? "IPA" : "No Conformidad"}`} onClick={this.handleOpen} open={openForm} />
				</div>
				<FormContext.Provider value={{ handleChange: this.handleChange, params }}>
					<GeneralForm open={openForm} title={`Crear ${selectedId === 14 ? "IPA" : "No Conformidad"}`} submit="Crear" info={formInfo} onSubmit={this.handleCreate} />
				</FormContext.Provider>
				<Divider className={classes.divider} />
				<Paper square>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h1">
									{selectedId === 14 ? "IPAs" : `No Conformidades`} en proceso
                                </Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h1">
									{selectedId === 14 ? "IPAs" : `No Conformidades`} aprobados
                                </Typography>
							}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<div className={classes.inline}>
						<Typography variant="h1">
							{selectedId === 14 ? "IPAs" : `No Conformidades`} en proceso
                        </Typography>
					</div>
					<Divider className={classes.divider} />
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							data={notApprovedNonConformities} onlyCards callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							sortings={sortables}
							filters={filters}
						/>
					}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						{selectedId === 14 ? "IPAs" : `No Conformidades`} aprobados
                    </Typography>
					<Divider className={classes.divider} />
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							data={approvedNonConformities} onlyCards callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							sortings={sortables}
							filters={filters}
						/>
					}
				</TabPanel>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	unconformities: state.unconformities,
	enterprise: state.enterprise
})

const mapDispatchToProps = dispatch => ({
	getNonConformities: () => dispatch(getNonConformitiesAction()),
	getWorkers: () => dispatch(getWorkersAction()),
	assignNonConformity: body => dispatch(assignNonConformityAction(body)),
	dismissNonConformity: body => dispatch(dismissNonConformityAction(body)),
	approveNonConformity: (body) => dispatch(approveNonConformityAction(body)),
	solveNonConformity: (body, formData) => dispatch(solveNonConformityAction(body, formData)),
	createNonConformity: body => dispatch(createNonConformityAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(NonConformities))
