import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"

export const getTrainingsAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: "trainings/get.php"
  })
  return {
    type: "GET_TRAININGS",
    payload: REQUEST
  }
}

export const getTrainingAction = id => {
  return {
    type: "SELECT_TRAINING",
    payload: id
  }
}

export const signTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "SIGN_TRAINING",
    payload: REQUEST
  }
}

export const endTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/end.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "END_TRAINING",
    payload: REQUEST
  }
}

export const userSignTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/user_sign.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "USER_SIGN_TRAINING",
    payload: REQUEST
  }
}

export const editTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_TRAINING",
    payload: REQUEST
  }
}

export const createTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_TRAINING",
    payload: REQUEST
  }
}

export const createMultipleTrainingsAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'trainings/create_multiple.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_MULTIPLE_TRAININGS",
    payload: REQUEST
  }
}