import React, { Component } from 'react'
import { Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../Shared/ImageContainer'
import moment from 'moment'

const style = () => ({
  container: {
    padding: 24
  },
  finding: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    '& > *:first-child': {
      marginRight: 18
    },
    '& > *:nth-child(2) > *': {
      margin: '3px 0'
    }
  },
  title: {
    marginBottom: 24
  }
})

class GroupDialog extends Component {
  renderGroup() {
    const { group, classes } = this.props
    if (!group) return null
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
    return group.map((element, index) => {
      return (
        <React.Fragment key={index}>
          {index !== 0 && <Divider />}
          <div className={classes.finding}>
            <ImageContainer src={element.evidence ? `${url}${element.evidence}` : "/noimage.png"} alt="foto" height="64px" width="64px" />
            <div>
              <Typography variant="subtitle1">Levantado por: {element.user_creator}</Typography>
              <Typography variant="caption">Fecha: {moment(element.date_detected).format("YYYY-MM-DD")}</Typography>
              {index === 0 && <Typography variant="body1">Original</Typography>}
            </div>
          </div>
        </React.Fragment>
      )
    })
  }
  render() {
    const { classes, open, onClose } = this.props
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <div className={classes.container}>
          <div className={classes.title}>
            <Typography variant="h1">Lista de hallazgos duplicados:</Typography>
          </div>
          {this.renderGroup()}
        </div>
      </Dialog>
    )
  }
}

GroupDialog.propTypes = {

}


GroupDialog.defaultProps = {
  group: []
}

export default withStyles(style)(GroupDialog)