import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { getDoneChecklistsAction, getPendingChecklistsAction } from '../../Actions/CheckListActions'
import { connect } from 'react-redux'
import moment from 'moment'
import StatusCard from './StatusCard'
import InlineInfo from './InlineInfo'
import CountCard from './CountCard'
import autobind from '../../Utils/autobind'
import { withRouter } from 'react-router-dom'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import MultipleCountCard from './MultipleCountCard'

const style = () => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 12,
    "& > *": {
      margin: 12
    }
  },
  loader: {
    position: 'relative',
    height: 300
  }

})
class ChecklistsManagement extends Component {
  constructor() {
    super()
    this.state = {
      loading: true
    }
    autobind(ChecklistsManagement, this)
  }

  componentDidMount() {
    const { getDoneChecklist, getPendingChecklists } = this.props
    getDoneChecklist()
    getPendingChecklists().then(() => this.setState({ loading: false }))
  }

  handleClick(option) {
    return () => {
      const { history } = this.props
      history.push(`/panel/checklists/${option}`)
    }
  }

  render() {
    const { classes, checklists } = this.props
    const { loading } = this.state

    const pendings = checklists.pending || []
    const done = checklists.done || []

    // week
    const weekPending = pendings.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), 'week'))
    const weekDone = done.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), 'week'))
    const weekOverdue = weekPending.filter(checklist => checklist.status === "Vencido")
    const allWeek = weekPending.concat(weekDone)
    const weekCompliance = (weekDone.length + weekOverdue.length) > 0 ? Math.round(weekDone.length / (weekDone.length + weekOverdue.length) * 100, 10) : 0

    // month
    const monthPending = pendings.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), 'month'))
    const monthDone = done.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), 'month'))
    const monthOverdue = monthPending.filter(checklist => checklist.status === "Vencido")
    const allmonth = monthPending.concat(monthDone)
    const monthCompliance = (monthDone.length + monthOverdue.length) > 0 ? Math.round(monthDone.length / (monthDone.length + monthOverdue.length) * 100, 10) : 0

    // pastMonths

    const startOfMonth = moment(new Date()).startOf("month")
    const startOfLastMonth = moment(new Date()).subtract(1, "month").startOf("month")
    const endOfLastMonth = moment(new Date()).subtract(1, "month").endOf("month")

    const allPastMonths = pendings.filter(checklist => startOfMonth > moment(checklist.date_scheduled))
    const lastMonthPending = pendings.filter(checklist => moment(checklist.date_scheduled).isBetween(startOfLastMonth, endOfLastMonth))
    const lastMonthDone = done.filter(checklist => moment(checklist.date_scheduled).isBetween(startOfLastMonth, endOfLastMonth))
    const lastMonthOverdue = lastMonthPending.filter(checklist => checklist.status === "Vencido")
    const pastMonthCompliance = (lastMonthDone.length + lastMonthOverdue.length) > 0 ? Math.round(lastMonthDone.length / (lastMonthDone.length + lastMonthOverdue.length) * 100, 10) : 0



    return (
      <div className={classes.container}>
        {loading ?
          <div className={classes.loader}>
            <LoaderAnimator />
          </div>
          :
          <div className={classes.cardContainer}>
            <StatusCard
              title="Chequeos - Semana"
              total={allWeek.length}
              actual={weekDone.length}
              color="#ff6c87"
              legend="Realizadas"
              onClick={this.handleClick("week")}
            >
              <InlineInfo primary="Programadas" secondary={weekPending.length - weekDone.length} />
              <InlineInfo
                primary="Vencidas"
                secondary={weekOverdue.length}
              />
              <InlineInfo
                primary="Cumplimiento"
                secondary={weekCompliance + " %"}
              />
            </StatusCard>
            <StatusCard
              title="Chequeos - Mes"
              total={allmonth.length}
              actual={monthDone.length}
              color="#ff6c87"
              legend="Realizadas"
              onClick={this.handleClick("month")}
            >
              <InlineInfo primary="Programadas" secondary={monthPending.length - monthDone.length} />
              <InlineInfo
                primary="Vencidas"
                secondary={monthOverdue.length}
              />
              <InlineInfo
                primary="Cumplimiento"
                secondary={monthCompliance + " %"}
              />
            </StatusCard>
            <MultipleCountCard
              titles={["Listas de cheque pendientes de meses anteriores", "Porcentaje de cumplimiento mes anterior"]}
              amounts={[allPastMonths.length, pastMonthCompliance]}
              colors={["#B865C1", "#8F4799"]}
            />
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists,
  enterprise: state.enterprise,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getDoneChecklist: () => dispatch(getDoneChecklistsAction()),
  getPendingChecklists: () => dispatch(getPendingChecklistsAction()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistsManagement)))