import React, { Component } from "react"
import {
    Paper,
    Tab,
    Typography,
    withStyles,
    Divider,
    IconButton,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import { Add, ExitToApp, GetApp } from "@material-ui/icons"
import AddChecklist from "./AddChecklist"
import { getChecklistAction, getDoneChecklistsAction, getPendingChecklistsAction } from "../../Actions/CheckListActions"
import { connect } from "react-redux"
import { doneTableInfo, pendingTableInfo } from "./Info"
import LoaderComponent from "../../Shared/LoaderComponent"
import { getEnterpriseActivitiesAction } from "../../Actions/ActivityActions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import moment from 'moment'
import { callSnackbar } from "../../Utils/snackbar"

const style = () => ({
    container: { margin: 12 },
    title: {
        margin: "36px 0 12px 0 ",
    },
    divider: {
        marginBottom: 24,
    },
    inline: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            margin: "0 0 0 12px",
        },
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 24,
    },
    open: {
        transition: "all 0.3s linear",
        maxHeight: "500vh",
        overflow: "hidden",
    },
    closed: {
        transition: "all 0.3s linear",
        maxHeight: 0,
        overflow: "hidden",
    },
})

const filters = [
    { name: "Nombre", label: "user" },
]

function checkIfDateOutOfRange(checklist) {
    const threeDaysAhead = moment(new Date()).add(3, 'days')
    return moment(checklist.date_scheduled) >= threeDaysAhead
}

function checkIfFinished(checklist) {
    return checklist.status !== "Terminado"
}

class Checklists extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            open: false,
            loadingDone: true,
            loadingPending: true
        }
        autobind(Checklists, this)
    }

    componentDidMount() {
        const { getPendingChecklists, getDoneChecklists, getActivities, enterprise, branch, getWorkers, online } = this.props
        const body = {
            companyId: enterprise.selected.id,
            branch_id: branch.global.id,
        }
        if (online.status) {
            getPendingChecklists(body).then(() => this.setState({ loadingPending: false }))
            getDoneChecklists(body).then(() => this.setState({ loadingDone: false }))
            getActivities(body)
            getWorkers(body)
        } else {
            this.setState({ loadingDone: false, loadingPending: false })
        }
    }

    componentDidUpdate(prevProps) {
        const { branch } = this.props
        if (branch.global.id !== prevProps.branch.global.id) {
            this.setState({ loadingDone: true, loadingPending: true }, this.componentDidMount)
        }
    }

    handleGoto(row) {
        return () => {
            const { history } = this.props
            history.push(`/checklists/${row.id}`)
        }
    }

    handleDownload(row) {
        const { user } = this.props
        return () => {
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${user.account.user.id}`
            fetch(url).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = `Reporte Lista de Chequeo`
                    a.click()
                })
            }).catch(() => callSnackbar("Error al descargar documento", "error"))
        }
    }

    handleChangeTab(event, value) {
        this.setState({ tab: value })
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { classes, checklists, user } = this.props
        const { tab, open, loadingPending, loadingDone } = this.state
        const pendings = checklists.pending || []
        const done = checklists.done || []
        const actions = [
            { name: "Ver lista de chequeo", icon: ExitToApp, action: this.handleGoto, color: "primary", disabled: checkIfDateOutOfRange },
            { name: "Descargar Resumen", icon: GetApp, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
        ]
        const level = user?.account?.user?.userType
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h1">Listas de Chequeo</Typography>
                    {level < 3 && <div className={classes.inline}>
                        <Typography variant="subtitle1">
                            Agregar lista de chequeo
                        </Typography>
                        <IconButton onClick={this.handleOpen}>
                            <Add />
                        </IconButton>
                    </div>}
                </div>
                {open && <AddChecklist />}
                <Divider className={classes.divider} />
                <Paper square>
                    <MyTabs value={tab} onChange={this.handleChangeTab}>
                        <Tab
                            label={
                                <Typography variant="h4">
                                    Listas de Chequeo Abiertas
                                </Typography>
                            }
                            value={0}
                        />
                        <Tab
                            label={
                                <Typography variant="h4">
                                    Listas de Chequeo Realizadas
                                </Typography>
                            }
                            value={1}
                        />
                    </MyTabs>
                </Paper>
                <TabPanel value={tab} index={0}>
                    <Typography variant="h1" className={classes.title}>
                        Listas de Chequeo Abiertas
                    </Typography>
                    <Divider className={classes.divider} />
                    <LoaderComponent loading={loadingPending}>
                        <GeneralTable
                            info={pendingTableInfo}
                            data={pendings}
                            filters={filters}
                            actions={actions}
                        />
                    </LoaderComponent>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Typography variant="h1" className={classes.title}>
                        Listas de Chequeo Realizadas
                    </Typography>
                    <Divider className={classes.divider} />
                    <LoaderComponent loading={loadingDone}>
                        <GeneralTable
                            info={doneTableInfo}
                            data={done}
                            filters={filters}
                            actions={actions}
                        />
                    </LoaderComponent>
                </TabPanel>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    checklists: state.checklists,
    branch: state.branch,
    enterprise: state.enterprise,
    online: state.online,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    getPendingChecklists: (body) => dispatch(getPendingChecklistsAction(body)),
    getDoneChecklists: (body) => dispatch(getDoneChecklistsAction(body)),
    getChecklist: (body) => dispatch(getChecklistAction(body)),
    getActivities: (body) => dispatch(getEnterpriseActivitiesAction(body)),
    getWorkers: (body) => dispatch(getWorkersAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Checklists))
