import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import SubmitButton from '../../Shared/SubmitButton'
import DateInput from '../../Shared/Inputs/DateInput'
import moment from 'moment'

const style = () => ({
  container: {
    padding: 24
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  file: {
    margin: 6
  }
})

function simulateClick() {
  document.getElementById('files').click()
}

class SelectMultipleDocuments extends Component {
  constructor() {
    super()
    autobind(SelectMultipleDocuments, this)
    this.state = {
      files: [],
      fileNames: [],
      date: moment(new Date()).format("YYYY-MM-DD")
    }
  }

  handleChange(event) {
    const { target } = event
    const files = target.files
    const fileNames = Array.from(files).map(f => f.name)
    this.setState({ fileNames, files })
  }

  handleChangeDate(event) {
    const { target } = event
    this.setState({ date: target.value })
  }

  handleCreate() {
    const { onCreate } = this.props
    const { files, date } = this.state
    const body = { files, date }
    onCreate(body)
    this.setState({ files: [], fileNames: [], date: moment(new Date()).format("YYYY-MM-DD") })
  }

  render() {
    const { classes, onClose, open } = this.props
    const { fileNames, date } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant="h1">Subir varios archivos</Typography>
            <Button variant="outlined" color="primary" onClick={simulateClick}>Seleccionar Archivos</Button>
          </div>
          <input type="file" multiple id="files" onChange={this.handleChange} style={{ display: 'none' }} />
          {fileNames.length > 0 &&
            <Typography variant="h2">Archivos Seleccionados:</Typography>
          }
          {fileNames.map(name => (
            <Typography variant="h3" key={name} className={classes.file}>{name}</Typography>
          ))}
          {fileNames.length > 0 &&
            <>
              <DateInput value={date} onChange={this.handleChangeDate} label="Fecha" />
              <SubmitButton variant="contained" onClick={this.handleCreate}>Guardar</SubmitButton>
            </>
          }
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(SelectMultipleDocuments)