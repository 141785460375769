import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import moment from "moment"

export default class DateTimeInput extends Component {
    render() {
        const { value, onChange, label, name, required } = this.props
        return (
            <TextField
                value={value || moment().format("YYYY-MM-DD")}
                onChange={onChange}
                label={`${label}${required ? "*" : ""}`}
                name={name}
                variant="outlined"
                fullWidth
                style={{ margin: "12px 0" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type="datetime-local"
            />
        )
    }
}
