import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({

})
class TextPhase extends Component {

  render() {
    const types = {
      text: "Texto",
      date: "Fecha",
      number: "Número",
      file: "Subir Documento"
    }
    const { classes, phase } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Tipo de fase: {types[phase.type]}</Typography>
        <Typography variant="caption">Titulo: {phase.label}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(TextPhase)