import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import SubmitButton from './SubmitButton'
import { withRouter } from 'react-router'
import autobind from '../Utils/autobind'

const style = () => ({
  container: {
    background: 'white',
    padding: 12,
    margin: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& > *': {
      margin: '6px 0'
    }
  }
})

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    autobind(ErrorBoundary, this)
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
    console.log(error)
    console.log("ERRORR!!!!")
  }

  handleBack() {
    localStorage.clear()
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { classes } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.container}>
          <Typography variant="h1">¡Lo sentimos!</Typography>
          <Typography variant="subtitle1">Algo salió mal... enviamos un reporte de este problema a administración para que sea revisado</Typography>
          <Typography variant="subtitle2">Pulsa "volver" para continuar</Typography>
          <SubmitButton onClick={this.handleBack}>Volver</SubmitButton>
        </div>
      )
    }
    return this.props.children
  }
}

export default withRouter(withStyles(style)(ErrorBoundary))