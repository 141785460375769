import instance from "../Utils/instance"

export const getParamsAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: "params/get.php"
  })
  return {
    type: "GET_PARAMS",
    payload: REQUEST
  }
}