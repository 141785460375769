import React, { Component } from 'react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import Calendar from './Calendar'
import TalkCalendar from './TalkCalendar'
import TrainingsCalendar from './TrainingsCalendar'

const style = () => ({
  paper: {
    background: 'white',
    margin: 12
  }
})

class CalendarTabs extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0
    }
    autobind(CalendarTabs, this)
  }

  handleChangeTab(event, tab) {
    this.setState({ tab })
  }

  renderTab() {
    const { tab } = this.state
    switch (tab) {
      case 0: {
        return <Calendar />
      }
      case 1: {
        return <TalkCalendar />
      }
      case 2: {
        return <TrainingsCalendar />
      }
      default: {
        return <Calendar />
      }
    }
  }

  render() {
    const { classes } = this.props
    const { tab } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.paper}>
          <Tabs onChange={this.handleChangeTab} value={tab}>
            <Tab label="Listas de Chequeo" />
            <Tab label="Charlas" />
            <Tab label="Capacitaciones" />
          </Tabs>
        </div>
        {this.renderTab()}
      </div>
    )
  }
}

export default withStyles(style)(CalendarTabs)