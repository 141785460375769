import React, { Component } from "react"
import { TextField, withStyles } from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { Backup } from "@material-ui/icons"

const style = () => ({
    fileInput: {
        visibility: "hidden",
        display: "none",
    },
})

class FileInput extends Component {
    constructor(props) {
        super(props)
        autobind(FileInput, this)
    }

    handleClick() {
        const { name } = this.props
        const input = document.getElementById(`input-file-${name}`)
        input.click()
    }

    handleChange(event) {
        const { onChange } = this.props
        const { target } = event
        const newValue = target.files[0]
        const newEvent = {
            target: {
                name: target.name,
                value: newValue,
            },
        }
        onChange(newEvent)
    }

    render() {
        const { value, label, name, classes, required } = this.props
        return (
            <>
                <TextField
                    value={value || ""}
                    label={`${label}${required ? "*" : ""}`}
                    name={name}
                    variant="outlined"
                    fullWidth
                    style={{ margin: "12px 0" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onClick={this.handleClick}
                    type="text"
                    InputProps={{ endAdornment: <Backup /> }}
                />
                <input
                    type="file"
                    id={`input-file-${name}`}
                    name={name}
                    onChange={this.handleChange}
                    className={classes.fileInput}
                />
            </>
        )
    }
}

export default withStyles(style)(FileInput)
